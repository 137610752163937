import React, { createContext, useContext, useState, useEffect } from 'react'
import { Platform } from 'react-native'
import StaticLenseData from '../../../web/appdata/assessment-lenses_v2'
import StaticManufacturerData from '../../../web/appdata/lense-manufacturers_v1'

const useProvideLensesEvaluation = () => {
  const [evaluatedLenses, setEvaluatedLenses] = useState()
  const [lenseData, setLenseData] = useState(
    Platform.OS === 'web' ? StaticLenseData : undefined
  )

  const [manufacturerData, setManufacturerData] = useState(
    Platform.OS === 'web' ? StaticManufacturerData : undefined
  )

  const [lenseProperties, setLenseProperties] = useState({}) // stuff like tier, cycle...

  useEffect(() => {
    if (Platform.OS !== 'web') {
      getLenseData()
      getManufacturerData()
    }
  }, [])

  const evaluateLenses = (
    assessmentData,
    lensesPassport,
    wantsMf,
    cycleOverride,
    tierOverride
  ) => {
    if (lenseData && assessmentData && lensesPassport)
      setEvaluatedLenses(
        getLenses(
          assessmentData,
          lensesPassport,
          lenseData,
          wantsMf,
          cycleOverride,
          tierOverride
        )
      )
  }

  const cylConversion = cyl => {
    if (cyl <= 0) return cyl
    return cyl * -1
  }

  const sphConversion = (cyl, sph) => {
    if (cyl <= 0) return sph
    return sph - cyl
  }

  // eslint-disable-next-line
  const axisConversion = a => {
    // from 0 - 180
    let r = a + 90
    if (r > 180) r -= 180
  }

  const detectType = (eyedata, cycle, tier, wantsMf) => {
    if (eyedata) {
      const cyl = cylConversion(eyedata.cylinder)
      const add = eyedata.add
      if (cycle === 'daily') {
        if (wantsMf && add >= 0.5) {
          return cyl <= -0.75 ? 'custom' : 'mf'
        }
        if (!cyl || cyl > -0.75) return 'regular'
        if (cyl <= -0.75 && cyl >= -2.25) return 'toric'
        else return 'custom'
      } else if (cycle === 'weekly') {
        if (wantsMf && add >= 0.5) {
          return cyl <= -0.75 ? 'custom' : 'mf'
        }
        if (!cyl || cyl > -0.75) return 'regular'
        if (cyl <= -0.75 && cyl >= -2.75) return 'toric'
        else return 'custom'
      } else if (cycle === 'monthly') {
        if (wantsMf && add >= 0.5) {
          return cyl <= -0.75 ? 'custom' : 'mf'
        }
        if (!cyl || cyl > -0.75) return 'regular'
        if (cyl <= -0.75 && cyl >= -2.25) return 'toric'
        else return 'custom'
      }
    }
    return 'regular'
  }

  const getLenses = (
    assessmentData,
    lensesPassport,
    LenseData,
    wantsMf,
    cycleOverride,
    tierOverride
  ) => {
    const cycle = cycleOverride || assessmentData.lensesCycle
    const tier =
      tierOverride ||
      (cycle !== 'weekly' &&
      (assessmentData.displayDuration > 3 ||
        assessmentData.lensesDuration > 4 ||
        (assessmentData.lensesProblem &&
          assessmentData.lensesProblem.length > 0))
        ? 'premiumTier'
        : 'standardTier')
    const typeLeft = detectType(lensesPassport.leftData, cycle, tier, wantsMf)
    const typeRight = detectType(lensesPassport.rightData, cycle, tier, wantsMf)
    const leftLense = crawlLenseData(
      LenseData,
      cycle,
      tier,
      typeLeft,
      sphConversion(
        lensesPassport.leftData.cylinder,
        lensesPassport.leftData.sphere
      )
    )
    const rightLense = crawlLenseData(
      LenseData,
      cycle,
      tier,
      typeRight,
      lensesPassport.rightData.sphere
    )
    let i = 0
    const result = []
    while (i < leftLense.length || i < rightLense.length) {
      result[i] = {
        left: i < leftLense.length ? leftLense[i] : null,
        right: i < rightLense.length ? rightLense[i] : null
      }
      i++
    }
    setLenseProperties({
      cycle,
      tier,
      typeLeft,
      typeRight
    })
    return result
  }

  function shuffle (a) {
    if (!a) return a
    var j, x, i
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = a[i]
      a[i] = a[j]
      a[j] = x
    }
    return a
  }

  const getManufacturers = shuffled => {
    const result = []
    if (!manufacturerData) {
      getManufacturerData()
      return []
    }
    const typeLeft = lenseProperties?.typeLeft
    // const typeRight = lenseProperties?.typeRight
    const tier = lenseProperties?.tier

    manufacturerData.map(x => {
      if (x[tier] && x[tier][typeLeft]) {
        result.push([
          x.image,
          x[tier][typeLeft],
          x.agreement === true ? true : false,
          x.name
        ])
      } else {
        result.push([
          x.image,
          x.homepage,
          x.agreement === true ? true : false,
          x.name
        ])
      }
    })
    if (shuffled) return shuffle(result)
    return result
  }

  const crawlLenseData = (LenseData, cycle, tier, type, sph) => {
    let unfiltered = []
    if (
      LenseData &&
      LenseData[cycle] &&
      LenseData[cycle][tier] &&
      LenseData[cycle][tier][type]
    ) {
      unfiltered = LenseData[cycle][tier][type]
    }
    let result = []
    let iter = 0
    let idex = 0
    while (iter < unfiltered.length) {
      if (
        sph >= unfiltered[iter].sphRange[0] &&
        sph <= unfiltered[iter].sphRange[1]
      ) {
        result[idex] = unfiltered[iter]
        idex++
      }
      iter++
    }

    if (result && result.length <= 0) {
      result = [
        {
          manufacturer: 'Optiker kontaktieren',
          name: ' ',
          description:
            'Für Ihre optischen Daten können wir Ihnen leider keine Kontaktlinsen empfehlen. Bitte kontaktieren Sie Ihren lokalen Optiker.',
          url: null,
          image: null,
          price: []
        }
      ]
    }
    // console.log('Data: ' + JSON.stringify(result, null, 4))
    return result
  }

  const getLenseData = () => {
    fetch('https://vispecs.com/appdata/assessment-lenses_v2.json')
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        setLenseData(responseJson)
      })
  }

  const getManufacturerData = () => {
    fetch('https://vispecs.com/appdata/lense-manufacturers_v1.json')
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        setManufacturerData(responseJson)
      })
  }

  return { evaluatedLenses, lenseProperties, evaluateLenses, getManufacturers }
}

const LensesEvaluationContext = createContext()

export const LensesEvaluationProvider = ({ children }) => {
  const value = useProvideLensesEvaluation()

  return (
    <LensesEvaluationContext.Provider value={value}>
      {children}
    </LensesEvaluationContext.Provider>
  )
}

export const useLensesEvaluation = () => useContext(LensesEvaluationContext)
