import React, { useEffect } from 'react'
import { Text, Paragraph } from 'react-native-paper'
import DefaultScreen from '../components/screens/DefaultScreen'
import { Platform, Linking, View, Image, StyleSheet } from 'react-native'
import GooglePlayBadge from '../components/Social/GooglePlayBadge'
import AppStoreBadge from '../components/Social/AppStoreBadge'
// import UseBrowserBadge from '../components/Social/UseBrowserBadge'
import DownloadHand from '../../assets/download_phone.png'
import Background from '../../assets/bg_hexa@3.5x_mirror.png'
import MediaQuery from 'react-native-web-responsive'

export default () => {
  useEffect(() => {
    // hier sollte im browser erkennt werden ob man ein Iphone oder Android besitzt und dann auf den richtigen store redirecten
    if (Platform.OS === 'android') {
      Linking.openURL(
        'https://play.google.com/store/apps/details?id=com.vispecs.app'
      )
    } else if (Platform.OS === 'ios') {
      Linking.openURL('https://apps.apple.com/at/app/vispecs/id1508489528')
    }
  }, [])

  // const handleAndroid = () => {
  //   Linking.openURL(
  //     'https://play.google.com/store/apps/details?id=com.vispecs.app'
  //   )
  // }

  // const handleIos = () => {
  //   Linking.openURL('https://apps.apple.com/at/app/vispecs/id1508489528')
  // }

  return (
    <DefaultScreen background={Background}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <MediaQuery minWidth={1050}>
          <Image
            source={{ uri: DownloadHand }}
            style={{ width: 284 * 1.2, height: 703 * 1.2 }}
          />
        </MediaQuery>
        <View style={{ flex: 1, alignItems: 'flex-start', marginLeft: 30 }}>
          <Text style={styles.header}>DOWNLOAD NOW</Text>
          <Paragraph style={styles.p}>
            Die Revolution der optischen Dienste erwartet Sie.
          </Paragraph>
          <Paragraph style={styles.p}>
            Ein digitaler Optiker für Ihre Bedürfnisse
          </Paragraph>
          <Paragraph style={styles.p}>
            Probieren Sie unsere kostenlose Version mit digitalem Brillenpass.
          </Paragraph>
          <MediaQuery minWidth={600}>
            <View
              style={{
                alignItems: 'center',
                flexDirection: 'row',
                marginTop: 30
              }}
            >
              <GooglePlayBadge />
              <AppStoreBadge />
              {/* <UseBrowserBadge /> */}
            </View>
          </MediaQuery>
          <MediaQuery maxWidth={599}>
            <View
              style={{
                alignItems: 'center',
                alignSelf: 'center',
                flexDirection: 'column',
                marginTop: 30,
                marginBottom: 10
              }}
            >
              <GooglePlayBadge />
              <View style={{ width: '100%', height: 10 }} />
              <AppStoreBadge />
              {/* <UseBrowserBadge /> */}
            </View>
          </MediaQuery>
        </View>
      </View>
    </DefaultScreen>
  )
}

const styles = StyleSheet.create({
  header: {
    fontSize: 34,
    fontWeight: '700'
  },
  p: {
    fontSize: 24,
    marginTop: 20,
    lineHeight: 35
  }
})
