import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
import MapsScreen from '../screens/Maps'
import settings from './settings'
import Download from '../screens/Download'
import Landing from '../screens/Landing'
import Legal from '../screens/Legal'
import Profile from '../screens/Profile'
import AssessmentNavigator from './AssessmentNavigator'
import { Platform } from 'react-native'
import Contact from '../screens/Contact'
import { useUser } from '../hooks/user'
import LoginScreen from '../screens/LoginScreen'
import TabNavigator from './TabNavigator'
// import Home from '../screens/Home'
import EvaluationTabNavigator from './EvaluationTabNavigator'
import PassportCreationScreen from '../screens/Passport/PassportCreationScreen'
import PassportUpdate from '../screens/Passport/PassportUpdate'
import PassportEditor from '../screens/Passport/PassportEditor'
import SetupHome from '../screens/Setup/SetupHome'
import Forbidden from '../screens/Forbidden'
import ProfileOptician from '../screens/Optician/Profile.optician'
import SetupOptician from '../screens/Setup/SetupOptician'
import DrawerNavigatorOptician from './SubRouters/DrawerNavigator.optician'

const Stack = createStackNavigator()

const platformHacks =
  Platform.OS === 'web'
    ? { headerShown: false }
    : Platform.OS === 'ios'
    ? { headerTransparent: true }
    : undefined

export default () => {
  const { user, userAttributes, isOptician } = useUser()

  const isDoneSetup =
    (isOptician &&
      userAttributes?.name &&
      userAttributes['custom:localOpticianId'] &&
      userAttributes['custom:identityId']) ||
    (!isOptician &&
      user &&
      userAttributes &&
      userAttributes.name &&
      userAttributes.gender &&
      userAttributes.birthdate &&
      userAttributes['custom:identityId'])

  const requireAuth = component => {
    if (user) {
      return component // show desired component
    } else return LoginScreen // show Login if no current user
  }

  const promptSetup = component => {
    if (user && !isDoneSetup) return isOptician ? SetupOptician : SetupHome
    else return component // show desired component
  }

  const requireOpticianUser = component => {
    return isOptician ? component : Forbidden
  }

  return (
    <Stack.Navigator
      {...settings.navigator}
      headerMode={Platform.OS === 'ios' ? 'float' : 'screen'}
    >
      <Stack.Screen
        name='Landing'
        options={{ title: 'ViSpecs', ...platformHacks }}
        component={Landing}
      />
      <Stack.Screen
        name='Login'
        options={{ title: 'Login', ...platformHacks }}
        component={LoginScreen}
      />
      <Stack.Screen
        name='Home'
        options={{ title: 'ViSpecs', headerShown: false }}
        component={
          Platform.OS === 'web'
            ? requireOpticianUser(DrawerNavigatorOptician)
            : promptSetup(TabNavigator)
        }
      />
      <Stack.Screen
        name='Survey'
        options={{ title: 'Bedarfsanalyse', headerShown: false }}
        component={AssessmentNavigator}
      />
      <Stack.Screen
        name='Download'
        options={{ title: 'App herunterladen', ...platformHacks }}
        component={Download}
      />
      <Stack.Screen
        name='Legal'
        options={{ title: 'ViSpecs', ...platformHacks }}
        component={Legal}
      />
      <Stack.Screen
        name='Profile'
        options={{ title: 'Mein Profil', ...platformHacks }}
        component={requireAuth(isOptician ? ProfileOptician : Profile)}
      />
      <Stack.Screen
        name='Recommendation'
        options={{ title: 'Auswertung', ...platformHacks }}
        component={EvaluationTabNavigator}
      />
      <Stack.Screen
        name='Map'
        options={{ title: 'Optiker-Karte', ...platformHacks }}
        component={MapsScreen}
      />
      <Stack.Screen
        name='Contact'
        options={{ title: 'Kontakt', ...platformHacks }}
        component={Contact}
      />
      <Stack.Screen
        name='Passport'
        options={{ title: 'Digitaler Pass', ...platformHacks }}
        component={PassportCreationScreen}
      />
      <Stack.Screen
        name='PassportUpdate'
        options={{ title: 'Digitaler Pass', headerShown: false }}
        component={PassportUpdate}
      />
      <Stack.Screen
        name='PassportEditor'
        options={{ title: 'Digitaler Pass', headerShown: false }}
        component={PassportEditor}
      />
      <Stack.Screen
        name='Optician'
        options={{ title: 'Optiker', ...platformHacks }}
        component={requireOpticianUser(DrawerNavigatorOptician)}
      />
    </Stack.Navigator>
  )
}
