import { DefaultTheme } from 'react-native-paper'

const theme = {
  ...DefaultTheme,
  roundness: 4,
  colors: {
    ...DefaultTheme.colors,
    primary: '#9a305e',
    accent: '#d15237',
    background: '#fcfbfa',
    // custom
    foreground: 'white',
    borders: '#dcdbda',
    footer: '#611e3b',
    bone: '#db9cb7',
    boneHighlight: '#e0b8c9',
    pinkShade: '#f7ebf4',
    error: '#c5221f',
    gray: '#3c4043',
    vibrant: '#f33387'
  }
}

export default theme
