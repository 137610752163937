import React, { createContext, useContext, useState } from 'react'
import { Platform } from 'react-native'
import { privacyMd, imprintMd, consentMd } from '../../../web/appdata/legal'

const useProvideLegal = () => {
  const [imprint, setImprint] = useState(undefined)
  const [privacy, setPrivacy] = useState(undefined)
  const [consent, setConsent] = useState('Einen Moment ...')

  const isWeb = Platform.OS === 'web'

  const fetchLegal = () => {
    // loading files as static resource for web build, because of cors
    if (isWeb) {
      setPrivacy(privacyMd.content)
      setImprint(imprintMd.content)
      setConsent(consentMd.content)
    }
    // eslint-disable-next-line
    else {
      fetch('https://vispecs.com/appdata/impressum.md')
        .then(res => res.text())
        .then(setImprint)
        .then(
          // eslint-disable-next-line
          fetch('https://vispecs.com/appdata/datenschutz.md')
            .then(res => res.text())
            .then(setPrivacy)
        )
        .then(
          // eslint-disable-next-line
          fetch('https://vispecs.com/appdata/consent.md')
            .then(res => res.text())
            .then(setConsent)
        )
        .catch(err => {
          console.error(err)
          setImprint('Fehler bei Serververbindung.')
          setPrivacy('Fehler bei Serververbindung.')
          setConsent('Fehler bei Serververbindung.')
        })
    }
  }

  return { imprint, privacy, consent, fetchLegal }
}

const LegalContext = createContext()

export const LegalProvider = ({ children }) => {
  const value = useProvideLegal()

  return <LegalContext.Provider value={value}>{children}</LegalContext.Provider>
}

export const useLegal = () => useContext(LegalContext)
