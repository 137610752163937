export default [
  {
    id: 48417322,
    name: 'Wutscher Optik Krems',
    address: 'Wiener Straße 91 Top 115, 3500 Krems',
    lat: '48.417322',
    lng: '15.619968'
  },
  {
    id: 47280753,
    name: 'Wutscher Optik Hartberg - vormals Optik Achatz',
    address: 'Im Hatric 11, 8230 Hartberg',
    lat: '47.280753',
    lng: '15.994576'
  },
  {
    id: 47799982,
    name: 'Wutscher Optik - Getreidegasse',
    address: 'Getreidegasse 47, 5020 Salzburg',
    lat: '47.799982',
    lng: '13.040238'
  },
  {
    id: 47289160,
    name: 'Wutscher Optik Oberwart - vormals Optik Achatz',
    address: 'Bahnhofstraße 1, 7400 Oberwart',
    lat: '47.289160',
    lng: '16.210945'
  },
  {
    id: 47262670,
    name: 'Wutscher Optik Innsbruck DEZ',
    address: 'Amraser-See-Straße 56a, Innsbruck 6020',
    lat: '47.262670',
    lng: '11.430920'
  },
  {
    id: 48207709,
    name: 'Wutscher Optik Wien Kärntner Straße',
    address: 'Kärntner Straße 2 , 1010 Wien',
    lat: '48.207709',
    lng: '16.371561'
  },
  {
    id: 47503600,
    name: 'Wutscher Optik Bregenz vormals Optik Nasahl ',
    address: 'Schulgasse 4, 6900 Bregenz',
    lat: '47.503600',
    lng: '9.746250'
  },
  {
    id: 47286887,
    name: 'Wutscher Optik Oberwart EO - vormals Optik Achatz',
    address: 'Europastraße 1, 7400 Oberwart',
    lat: '47.286887',
    lng: '16.187721'
  },
  {
    id: 48301964,
    name: 'Wutscher Optik Linz - Landstraße',
    address: 'Landstrasse 32, 4020 Linz',
    lat: '48.301964',
    lng: '14.289603'
  },
  {
    id: 47069645,
    name: 'Wutscher Optik Graz - Herrengasse',
    address: 'Herrengasse 18, 8010 Graz',
    lat: '47.069645',
    lng: '15.440153'
  },
  {
    id: 47774482,
    name: 'Wutscher Optik Salzburg - Alpenstraße',
    address: 'Alpenstraße 114 Top E13-15, 5020 Salzburg',
    lat: '47.774482',
    lng: '13.068574'
  },
  {
    id: 48244213,
    name: 'Wutscher Optik Pasching - Plus City',
    address: 'Pluskaufstraße 7, 4061 Pasching',
    lat: '48.244213',
    lng: '14.236631'
  },
  {
    id: 46627363,
    name: 'Wutscher Optik Klagenfurt - City Arkaden',
    address: 'St. Veiter Ring 20 Top 116, 9020 Klagenfurt',
    lat: '46.627363',
    lng: '14.309716'
  },
  {
    id: 48207610,
    name: 'Wutscher Optik Wien Mitte - The Mall',
    address: 'Landstraßer Hauptstraße 1B7, Top 110 A, 1030 Wien',
    lat: '48.20761073605286',
    lng: '16.38527823988113'
  },
  {
    id: 47040772,
    name: 'Wutscher Optik Graz - FR!TZ Marken-Store',
    address: 'Ostbahnstraße 3 Shop 143, 8041 Graz',
    lat: '47.040772',
    lng: '15.463058'
  },
  {
    id: 48223547,
    name: 'Wutscher Optik St. Pölten - Traisenpark',
    address: 'Dr. Adolf Schärf-Straße 5-9 Top 4, 3107 St. Pölten',
    lat: '48.223547',
    lng: '15.637466'
  },
  {
    id: 47561985,
    name: 'Wutscher Optik Liezen - ELI',
    address: 'Salzburger Straße 1 Top 42, 8940 Liezen',
    lat: '47.561985',
    lng: '14.245346'
  },
  {
    id: 48196381,
    name: 'Wutscher Optik Wien - Mariahilfer Straße',
    address: 'Mariahilfer Straße 115 , 1060 Wien',
    lat: '48.196381',
    lng: '16.343149'
  },
  {
    id: 47975722,
    name: 'Wutscher Optik Parndorf',
    address: 'Designer Outlet Straße 1 Unit 10, 7111 Parndorf',
    lat: '47.975722',
    lng: '16.850683'
  },
  {
    id: 48185403,
    name: 'Wutscher Optik Wien - Hauptbahnhof',
    address: 'Am Hauptbahnhof 1 EG 118, 1100 Wien',
    lat: '48.185403',
    lng: '16.37679'
  },
  {
    id: 47381615,
    name: 'Wutscher Optik Leoben LCS',
    address: 'Hauptplatz 19 Top 40, 8700 Leoben',
    lat: '47.381615',
    lng: '15.094852'
  },
  {
    id: 48242103,
    name: 'Wutscher Optik Wien - Donauzentrum',
    address: 'Wagramer Straße 81 Shop 104, 1220 Wien',
    lat: '48.242103',
    lng: '16.435104'
  },
  {
    id: 48110340,
    name: 'Wutscher Optik Wien - SCS Vösendorf',
    address: 'Top Allee 94 Eingang 5, 2334 Vösendorf ',
    lat: '48.11034 ',
    lng: '16.317678'
  },
  {
    id: 48202644,
    name: 'Wutscher Optik Wien - Landstraße',
    address: 'Landstraßer Hauptstraße 48 , 1030 Wien',
    lat: '48.202644',
    lng: '16.389965'
  },
  {
    id: 48344280,
    name: 'Wutscher Optik Korneuburg',
    address: 'Hauptplatz 37, 2100 Korneuburg',
    lat: '48.34428',
    lng: '16.332931'
  },
  {
    id: 47012076,
    name: 'Wutscher Optik Graz - Seiersberg',
    address: 'SCS Top 2/2/18 , 8055 Seiersberg',
    lat: '47.012076',
    lng: '15.411925'
  },
  {
    id: 47060396,
    name: 'Wutscher Optik Graz - Citypark',
    address: 'Lazarettgürtel 55 Shop 73, 8020 Graz',
    lat: '47.060396',
    lng: '15.426898'
  },
  {
    id: 47071954,
    name: 'Wutscher Optik Graz - Kastner & Öhler',
    address: 'Sackstraße 7-13 1. UG, 8010 Graz',
    lat: '47.071954',
    lng: '15.43696'
  },
  {
    id: 48455732,
    name: 'Wutscher Optik Schärding',
    address: 'Linzer Straße 4a, 4780 Schärding',
    lat: '48.455732',
    lng: '13.434306'
  },
  {
    id: 48206994,
    name: 'Wutscher Optik Ried',
    address: 'Weberzeile 1, 4910 Ried im Innkreis',
    lat: '48.206994',
    lng: '13.485620'
  },
  {
    id: 47410081,
    name: 'Wutscher Optik Bruck an der Mur',
    address: 'Mittergasse, Ecke Koloman-Wallisch-Platz, 8600 Bruck an der Mur',
    lat: '47.410081',
    lng: '15.270003'
  },
  {
    id: 48210149,
    name: 'Wutscher Optik Wien - Bognergasse',
    address: 'Bognergasse 7 , 1010 Wien',
    lat: '48.210149',
    lng: '16.368028'
  },
  {
    id: 48314552,
    name: 'Wutscher Optik Linz - EKZ Lentia City',
    address: 'Hauptstraße 54, 4040 Linz',
    lat: '48.314552',
    lng: '14.282954'
  },
  {
    id: 48196425,
    name: 'Wutscher Optik Haid - EKZ Haid Center',
    address: 'Ikea Platz 8/Top 77, 4053 Haid bei Ansfelden',
    lat: '48.196425',
    lng: '14.248514'
  },
  {
    id: 47183866,
    name: 'Wutscher Optik Fohnsdorf',
    address: 'Arena am Waldfeld 5, 8753 Fohnsdorf',
    lat: '47.183866',
    lng: '14.681748'
  },
  {
    id: 47072391,
    name: 'Wutscher Optik Graz - Sporgasse',
    address: 'Sporgasse 21, 8010 Graz',
    lat: '47.072391',
    lng: '15.438861'
  },
  {
    id: 47792176,
    name: 'Wutscher Optik Salzburg - Designer Outlet',
    address: 'Kasernenstraße 1 , 5073 Wals',
    lat: '47.792176',
    lng: '12.989855'
  },
  {
    id: 47381001,
    name: 'Wutscher Optik Altenmarkt',
    address: 'Obere Marktstraße 28, 5541 Altenmarkt',
    lat: '47.381001',
    lng: '13.417911'
  },
  {
    id: 47607000,
    name: 'Wutscher Optik Mürzzuschlag',
    address: 'Toni-Schruf-Gasse 3, 8680 Mürzzuschlag',
    lat: '47.607',
    lng: '15.673761'
  },
  {
    id: 48175790,
    name: 'Wutscher Optik Wien - Favoriten',
    address: ' Favoritenstraße 109, 1100 Wien',
    lat: '48.175790',
    lng: '16.377810'
  },
  {
    id: 47798208,
    name: 'Wutscher Optik Salzburg - Untersbergstraße',
    address: 'Untersbergstraße 4, 5020 Salzburg',
    lat: '47.798208',
    lng: '13.035192'
  },
  {
    id: 46816260,
    name: 'Wutscher Optik Deutschlandsberg - vormals Optik Schweighofer',
    address: 'FMZ Frauentaler Str. 83, Halle 2, Top 9, 8530 Deutschlandsberg',
    lat: '46.816260',
    lng: '15.215770'
  },
  {
    id: 48254569,
    name: 'Wutscher Optik Wien - Donaufelder Straße',
    address: 'Donaufelder Straße 8, 1210 Wien',
    lat: '48.2545699',
    lng: '16.4091683'
  },
  {
    id: 47459446,
    name: 'Wutscher Optik Höchst - vormals Optik Reinblick',
    address: 'Hauptstraße 17, 6973 Höchst',
    lat: '47.4594461',
    lng: '9.64095050000003'
  },
  {
    id: 48103380,
    name: 'Wutscher Optik Mattighofen',
    address: 'Stadtplatz 46-48, 5230 Mattighofen',
    lat: '48.103380',
    lng: '13.149910'
  },
  {
    id: 48258652,
    name: 'Wutscher Optik Braunau',
    address: 'Stadtplatz 13, 5280 Braunau',
    lat: '48.258652',
    lng: '13.034710'
  },
  {
    id: 48272653,
    name: 'Wutscher Optik Wien - Großfeldzentrum',
    address: 'Kürschnergasse 9, Top 23, 1210 Wien',
    lat: '48.272653',
    lng: '16.446242'
  },
  {
    id: 47444946,
    name: 'Wutscher Optik Gröbming',
    address: 'Stoderplatzl 1 , 8962 Gröbming',
    lat: '47.444946',
    lng: '13.900861'
  },
  {
    id: 48258417,
    name: 'Wutscher Optik Wien - Floridsdorf',
    address: 'Schlosshoferstraße 1, 1210 Wien',
    lat: '48.258417',
    lng: '16.398387'
  },
  {
    id: 48208100,
    name: 'Wutscher Optik Wien - Auhofcenter',
    address: 'Albert-Schweitzer-Gasse 6, 1140 Wien',
    lat: '48.2081001',
    lng: '16.2160611'
  },
  {
    id: 47270858,
    name: 'Wutscher Optik Salzburg - Kaprun',
    address: 'Salzburger Platz 7 , 5710 Kaprun',
    lat: '47.270858',
    lng: '12.757546'
  },
  {
    id: 47542871,
    name: 'Wutscher Optik Eisenerz',
    address: 'Dr.-Theodor-Körner-Platz 3 , 8790 Eisenerz',
    lat: '47.542871',
    lng: '14.88506'
  },
  {
    id: 46830340,
    name: 'Wutscher Optik Lienz',
    address: 'Muchargasse 15 , 9900 Lienz',
    lat: '46.83034',
    lng: '12.765419'
  },
  {
    id: 48213727,
    name: 'Wutscher Optik Wien - Ottakringer Straße',
    address: 'Ottakringer Straße 188, 1160 Wien',
    lat: '48.213727',
    lng: '16.31253'
  },
  {
    id: 47442737,
    name: 'Wutscher Optik Kapfenberg',
    address: 'Wiener Straße 28 , 8605 Kapfenberg',
    lat: '47.442737',
    lng: '15.29016'
  },
  {
    id: 47171427,
    name: 'Wutscher Optik Bad Hofgastein',
    address: 'Kaiser-Franz-Platz 5 , 5630 Bad Hofgastein',
    lat: '47.171427',
    lng: '13.106022'
  },
  {
    id: 48041880,
    name: 'Wutscher Optik Traisen',
    address: 'Mariazellerstraße 74, 3160 Traisen',
    lat: '48.041880',
    lng: '15.609120'
  },
  {
    id: 47391862,
    name: 'Wutscher Optik Schladming',
    address: 'Hauptplatz 38 , 8970 Schladming',
    lat: '47.391862',
    lng: '13.689763'
  },
  {
    id: 48263827,
    name: 'Wutscher Optik Wien - Citygate',
    address: 'Wagramer Straße 195 Top 7, 1210 Wien',
    lat: '48.2638277',
    lng: '16.4530389'
  },
  {
    id: 46660307,
    name: 'Wutscher Optik Völkermarkt',
    address: 'Hauptplatz 3, 9100 Völkermarkt',
    lat: '46.660307',
    lng: '14.634247'
  },
  {
    id: 47567525,
    name: 'Wutscher Optik Liezen Hauptplatz',
    address: 'Hauptplatz 16, 8940 Liezen',
    lat: '47.567525',
    lng: '14.240353'
  },
  {
    id: 48344110,
    name: 'Wutscher Optik Wien - Gerasdorf G3',
    address: 'G3 - Platz 1, 2201 Gerasdorf bei Wien',
    lat: '48.34411',
    lng: '16.462196'
  },
  {
    id: 47525973,
    name: 'Wutscher Optik Rottenmann',
    address: 'Hauptstraße 36, 8786 Rottenmann',
    lat: '47.5259733',
    lng: '14.36144939999997'
  },
  {
    id: 47347490,
    name: 'Wutscher Optik Schwaz - Stadtgalerien',
    address: 'Andreas-Hofer-Straße 10, 6130 Schwaz',
    lat: '47.34749',
    lng: '11.707646'
  },
  {
    id: 48296534,
    name: 'Wutscher Optik Linz - Stockhofstraße',
    address: 'Stockhofstraße 8, 4020 Linz',
    lat: '48.296534',
    lng: '14.287076'
  }
]
