import React, { useState } from 'react'
import { Subheading } from 'react-native-paper'
import SliderSelector from './SliderSelector'
import { Platform, View } from 'react-native'

export default props => {
  const { data, input, width } = props
  const { onChange } = input
  const [cycle, setCycle] = useState(data.cycle)
  const [tier, setTier] = useState(data.tier)
  const [wantsMf, setWantsMf] = useState(false)

  const updateTier = newTier => {
    setTier(newTier)
    onChange({ cycle: cycle, tier: newTier, wantsMf: wantsMf })
  }

  const updateCycle = newCycle => {
    setCycle(newCycle)
    onChange({
      cycle: newCycle,
      tier: newCycle === 'weekly' ? 'standardTier' : tier,
      wantsMf: wantsMf
    })
  }

  const updateMf = newMf => {
    setWantsMf(newMf === 'yes')
    onChange({ cycle: cycle, tier: tier, wantsMf: newMf === 'yes' })
  }

  const sizes = Platform.OS === 'ios' ? [185, 145] : [165, 125]

  return (
    <View
      style={{
        alignSelf: 'flex-start',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        width: width,
        height: data.mfPossible ? sizes[0] : sizes[1]
      }}
    >
      <Subheading style={{ fontSize: 20 }}>Anpassungen</Subheading>
      {data.mfPossible && (
        <SliderSelector
          name='Gleitsichtlinse'
          initital='no'
          size={2}
          options={[
            { id: 'no', text: 'Nein' },
            { id: 'yes', text: 'Ja' }
          ]}
          input={{
            onChange: value => {
              updateMf(value)
            }
          }}
        />
      )}
      <SliderSelector
        name='Tragerythmus'
        initital={data.cycle}
        size={3}
        options={[
          { id: 'daily', text: 'Täglich' },
          { id: 'weekly', text: 'Wöchentlich' },
          { id: 'monthly', text: 'Monatlich' }
        ]}
        input={{
          onChange: value => {
            updateCycle(value)
          }
        }}
      />
      <SliderSelector
        name='Linsenqualität'
        initital={data.tier}
        disabled={cycle === 'weekly'}
        size={2}
        options={[
          { id: 'standardTier', text: 'Standard' },
          { id: 'premiumTier', text: 'Premium' }
        ]}
        input={{
          onChange: value => {
            updateTier(value)
          }
        }}
      />
    </View>
  )
}
