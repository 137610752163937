import React from 'react'
import { View } from 'react-native'
import IdCard from '../../components/Passport/IdCard.web.jsx'
import Passport from '../../components/Passport/Passport'

export default () => {
  return (
    <View style={{ flex: 1, flexDirection: 'row', marginTop: 24 }}>
      <View style={{ marginHorizontal: 48 }}>
        <IdCard title='Brillenpass'>
          <Passport passportName='Brillenpass' />
        </IdCard>
      </View>
      <View style={{ marginHorizontal: 48 }}>
        <IdCard title='Kontaktlinsenpass'>
          <Passport passportName='Kontaktlinsenpass' />
        </IdCard>
      </View>
    </View>
  )
}
