import { useFocusEffect } from '@react-navigation/native'
import Constants from 'expo-constants'
import React from 'react'
import {
  ImageBackground,
  Platform,
  ScrollView,
  StatusBar,
  StyleSheet,
  View
} from 'react-native'
import Background from '../../../assets/bg_hexa_trans.png'
import theme from '../../styles/theme'
import { scale, moderateScale } from 'react-native-size-matters'

const fixedHeight = scale(250)
const paddingVertical = moderateScale(12)

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    backgroundColor: theme.colors.primary,
    paddingHorizontal: scale(36),
    paddingTop: paddingVertical + Constants.statusBarHeight,
    paddingBottom: (paddingVertical + fixedHeight) / 2
  },
  content: {
    marginTop: -fixedHeight / 2,
    flex: 1,
    marginBottom: scale(12)
    // width: Platform.OS === 'web' ? '80%' : undefined
  }
})

const Dashboard = ({ children }) => {
  useFocusEffect(
    React.useCallback(() => {
      Platform.OS === 'android' && StatusBar.setBackgroundColor('#ffffff00')
      StatusBar.setBarStyle('light-content')
    })
  )

  return <View style={styles.container}>{children}</View>
}

const DashboardHeader = ({ children }) => {
  return (
    <ImageBackground source={Background} style={styles.header}>
      {children}
    </ImageBackground>
  )
}

const DashboardContent = ({ children }) => {
  return <ScrollView style={styles.content}>{children}</ScrollView>
}

export default class extends React.Component {
  static Header = DashboardHeader
  static Content = DashboardContent

  render () {
    return <Dashboard {...this.props} />
  }
}
