import { useHeaderHeight } from '@react-navigation/stack'
import React from 'react'
import { Platform, View } from 'react-native'
import theme from '../../styles/theme'
import NavScreen from './NavScreen'

export default ({
  children,
  fullscreen,
  background,
  isLanding,
  isOptician
}) => {
  const headerHeight = useHeaderHeight()
  return (
    <>
      {Platform.OS === 'web' ? (
        <NavScreen
          fullscreen={fullscreen}
          background={background}
          isLanding={isLanding}
          isOptician={isOptician}
        >
          {children}
        </NavScreen>
      ) : Platform.OS === 'ios' ? (
        <>
          <View
            style={{
              backgroundColor: theme.colors.background,
              height: headerHeight
            }}
          />
          {children}
        </>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
