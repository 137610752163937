/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAssessment = /* GraphQL */ `
  mutation CreateAssessment(
    $input: CreateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    createAssessment(input: $input, condition: $condition) {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const updateAssessment = /* GraphQL */ `
  mutation UpdateAssessment(
    $input: UpdateAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    updateAssessment(input: $input, condition: $condition) {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const deleteAssessment = /* GraphQL */ `
  mutation DeleteAssessment(
    $input: DeleteAssessmentInput!
    $condition: ModelAssessmentConditionInput
  ) {
    deleteAssessment(input: $input, condition: $condition) {
      beginTimestamp
      endTimestamp
      userId
      assessmentData
      type
      version
      createdAt
      updatedAt
    }
  }
`
export const createUserMetadata = /* GraphQL */ `
  mutation CreateUserMetadata(
    $input: CreateUserMetadataInput!
    $condition: ModelUserMetadataConditionInput
  ) {
    createUserMetadata(input: $input, condition: $condition) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const updateUserMetadata = /* GraphQL */ `
  mutation UpdateUserMetadata(
    $input: UpdateUserMetadataInput!
    $condition: ModelUserMetadataConditionInput
  ) {
    updateUserMetadata(input: $input, condition: $condition) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const deleteUserMetadata = /* GraphQL */ `
  mutation DeleteUserMetadata(
    $input: DeleteUserMetadataInput!
    $condition: ModelUserMetadataConditionInput
  ) {
    deleteUserMetadata(input: $input, condition: $condition) {
      id
      localOpticianId
      profilePictureId
      createdAt
      updatedAt
    }
  }
`
export const createPassportUpload = /* GraphQL */ `
  mutation CreatePassportUpload(
    $input: CreatePassportUploadInput!
    $condition: ModelPassportUploadConditionInput
  ) {
    createPassportUpload(input: $input, condition: $condition) {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const updatePassportUpload = /* GraphQL */ `
  mutation UpdatePassportUpload(
    $input: UpdatePassportUploadInput!
    $condition: ModelPassportUploadConditionInput
  ) {
    updatePassportUpload(input: $input, condition: $condition) {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const deletePassportUpload = /* GraphQL */ `
  mutation DeletePassportUpload(
    $input: DeletePassportUploadInput!
    $condition: ModelPassportUploadConditionInput
  ) {
    deletePassportUpload(input: $input, condition: $condition) {
      userId
      uploadTimestamp
      fileName
      status
      processedByUserId
      processedTimestamp
      createdAt
      updatedAt
    }
  }
`
export const createPassport = /* GraphQL */ `
  mutation CreatePassport(
    $input: CreatePassportInput!
    $condition: ModelPassportConditionInput
  ) {
    createPassport(input: $input, condition: $condition) {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
export const updatePassport = /* GraphQL */ `
  mutation UpdatePassport(
    $input: UpdatePassportInput!
    $condition: ModelPassportConditionInput
  ) {
    updatePassport(input: $input, condition: $condition) {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
export const deletePassport = /* GraphQL */ `
  mutation DeletePassport(
    $input: DeletePassportInput!
    $condition: ModelPassportConditionInput
  ) {
    deletePassport(input: $input, condition: $condition) {
      userId
      createdTimestamp
      createdByUserId
      passportUpload {
        items {
          userId
          uploadTimestamp
          fileName
          status
          processedByUserId
          processedTimestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      originalTimestamp
      metaData
      rightData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      leftData {
        sphere
        cylinder
        axis
        add
        prism
        base
        pd
        height
        diameter
        radius
      }
      type
      createdAt
      updatedAt
    }
  }
`
