import React, { useEffect, useState } from 'react'
import { Menu, Divider } from 'react-native-paper'
import { useUser } from '../../hooks/user'
import { useLinkTo } from '@react-navigation/native'
import UserMenu from './UserMenu'
import { View } from 'react-native'

export default () => {
  const { userAttributes, fetchUserPicture, signOut } = useUser()

  const [visible, setVisible] = useState(false)

  const linkTo = useLinkTo()

  const logout = () => {
    signOut()
    linkTo('/')
  }

  const goHome = () => {
    setVisible(false)
    linkTo('/home')
  }

  const goProfile = () => {
    setVisible(false)
    linkTo('/profile')
  }

  useEffect(() => {
    fetchUserPicture()
  }, [userAttributes?.userPicture])

  return (
    <>
      {userAttributes ? (
        <Menu
          visible={visible}
          onDismiss={() => setVisible(false)}
          style={{ marginTop: 70 }}
          anchor={<UserMenu onPress={() => setVisible(true)} />}
        >
          <Menu.Item
            onPress={goHome}
            titleStyle={{ fontSize: 22 }}
            title='Startseite'
          />
          <Menu.Item
            onPress={goProfile}
            titleStyle={{ fontSize: 22 }}
            title='Mein Benutzerkonto'
          />
          <Divider />
          <Menu.Item
            onPress={logout}
            titleStyle={{ fontSize: 22 }}
            title='Abmelden'
          />
        </Menu>
      ) : (
        <UserMenu onPress={() => linkTo('/login')} />
      )}
    </>
  )
}
