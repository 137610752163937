import React, { useRef, useState } from 'react'
import { Picker, StyleSheet, Text } from 'react-native'
import { DataTable, Menu, TextInput } from 'react-native-paper'
import theme from '../../../styles/theme'

export default props => {
  const [value, setValue] = useState(
    props.value === undefined || props.value === null ? '' : props.value
  )
  const [errors, setErrors] = useState([])
  const [errorsVisible, setErrorsVisible] = useState(false)
  const [errorDelay, setErrorDelay] = useState(false)

  const ref1 = useRef()

  const { required, range } = props

  const min = range?.length > 0 ? range[0] : undefined
  const max = range?.length > 1 ? range[1] : undefined
  const steps = range?.length > 2 ? range[2] : undefined

  const validate = val => {
    let errorList = []
    let success = true
    if (!min && !max) {
      // it is a string and doesn't need validation
      setErrors([])
      return success
    }
    if (
      val !== '' &&
      required &&
      (isNaN(val) ||
        (val.toString().indexOf('.') !== -1 &&
          val.toString().indexOf(',') !== -1))
    ) {
      success = false
      errorList.push('Kein gültiger Wert!')
    } else {
      if (min && parseFloat(val) < min) {
        success = false
        errorList.push(
          `Der angegebene Wert ist kleiner als der Minimalwert (${min.toFixed(
            2
          )})!`
        )
      }
      if (max && parseFloat(val) > max) {
        success = false
        errorList.push(
          `Der angegebene Wert ist größer als der Maximalwert (${max.toFixed(
            2
          )})!`
        )
      }
      if (steps && parseFloat(val) % steps !== 0) {
        success = false
        errorList.push(
          `Der Wert muss sich in ${steps.toFixed(2)}er Schritten bewegen!`
        )
      }
    }
    setErrors(errorList)
    if (typeof props.onErrorChange === 'function')
      props.onErrorChange(errorList.length === 0)
    return success
  }

  const handleChange = val => {
    if (typeof props.onChangeText === 'function') props.onChangeText(val)
    validate(val)
    setValue(val)
  }

  const styles = StyleSheet.create({
    cell: {
      paddingHorizontal: 0
    },
    input: {
      height: props.large ? 100 : 30,
      width: props.large || props.long ? 330 : 84,
      lineHeight: 12,
      fontSize: 14,
      paddingHorizontal: 4,
      backgroundColor: 'transparent',
      borderColor: errors.length <= 0 ? theme.colors.pinkShade : '#c00000',
      borderLeftWidth: 2.5,
      borderRightWidth: 2.5,
      borderTopWidth: 2.5,
      marginVertical: props.large ? 6 : undefined,
      maxWidth: '75%'
    }
  })

  if (props.lenseCycle)
    return (
      <DataTable.Cell
        style={{ ...styles.cell, ...props.style }}
        numeric={!props.notNumeric}
      >
        <Picker
          selectedValue={value}
          onValueChange={itemValue => handleChange(itemValue)}
        >
          <Picker.Item label='Unbekannt' value='LENSES' />
          <Picker.Item label='Tageslinsen' value='LENSESDAILY' />
          <Picker.Item label='Wochenlinsen' value='LENSESWEEKLY' />
          <Picker.Item label='Monatslinsen' value='LENSESMONTHLY' />
        </Picker>
      </DataTable.Cell>
    )

  return (
    <>
      <DataTable.Cell
        style={{ ...styles.cell, ...props.style }}
        numeric={!props.notNumeric}
      >
        <Menu
          visible={errors.length > 0 && errorsVisible}
          onDismiss={() => {
            // you might be thinking wtf, but:
            // after the modal is 'blurred' then the prevous gets focused
            // so we wait some time to blur the textinput
            setTimeout(() => {
              ref1?.current?.blur()
            }, 100) // blur TextInput
            setTimeout(() => {
              setErrorDelay(false)
            }, 2500) // re-enable error menu after 2.5s
            setErrorsVisible(false)
          }}
          overlayAccessibilityLabel='Schließen'
          style={{ marginTop: 40 }}
          contentStyle={{ padding: 14 }}
          anchor={
            <TextInput
              ref={ref1}
              multiline={props.large}
              scrollEnabled={!props.large}
              mode='flat'
              underlineColor={theme.colors.primary}
              value={value}
              style={styles.input}
              error={errors.length > 0}
              onChangeText={data => {
                handleChange(props.large ? data : data.replace(',', '.'), 0)
              }}
              onBlur={() => {
                if (!errorDelay) {
                  setErrorDelay(true)
                  setErrorsVisible(true)
                }
              }}
            />
          }
        >
          {errors.map((item, id) => {
            return (
              <Text
                key={id}
                style={{ color: '#c00000', fontSize: 14, marginVertical: 4 }}
              >
                {item}
              </Text>
            )
          })}
        </Menu>
        {/* {props.unit && <Text> {props.unit}</Text>} */}
      </DataTable.Cell>
    </>
  )
}
