import React from 'react'
import { View, Text, Image } from 'react-native'
import { translateValue } from '../../services/assessmentFormatter'
import Logo from '../../../assets/glass_evaluation_logo.png'

export default ({ data }) => {
  const TableItem = (index, key, value) => {
    return (
      <View
        key={index}
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 10
        }}
      >
        <Text style={{ fontWeight: '300' }}>{key}</Text>
        <Text style={{ fontWeight: 'bold' }}>{value}</Text>
      </View>
    )
  }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        marginVertical: 6,
        marginTop: 4
      }}
    >
      <Image
        source={Logo}
        style={{ flex: 3, maxHeight: 90 }}
        resizeMode='contain'
      />
      <View
        style={{
          flex: 7,
          justifyContent: 'center'
        }}
      >
        {data.map((item, i) => {
          if (item && item[0] && item[1])
            return TableItem(i, item[0], translateValue(item[1]))
        })}
      </View>
    </View>
  )
}
