import React from 'react'
import { Image, View } from 'react-native'

export default () => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          paddingHorizontal: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <Image
          source={require('../../../assets/undraw/undraw_completing_6bhr.png')}
          style={{ width: 1384 * 0.22, height: 912 * 0.22, marginTop: 10 }}
        />
      </View>
    </View>
  )
}
