import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Image, View } from 'react-native'
import theme from '../../styles/theme'
import { Headline } from 'react-native-paper'
import { scale } from 'react-native-size-matters'
import Logo from '../../../assets/logo_text.png'

export default props => {
  return (
    <LinearGradient
      colors={[theme.colors.primary, theme.colors.accent]}
      start={[0, 0]}
      end={[1, 0]}
      style={{
        borderWidth: 1,
        borderColor: theme.colors.borders,
        borderRadius: theme.roundness,
        overflow: 'hidden',
        flex: props.noFlex ? 0 : props.flex || 1,
        ...props.style
      }}
    >
      <View
        style={{
          marginTop: theme.roundness,
          paddingBottom: theme.roundness,
          backgroundColor: 'white'
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: scale(24),
            paddingVertical: scale(16),
            borderBottomWidth: 1,
            borderColor: theme.colors.borders
          }}
        >
          <Headline>{props.title}</Headline>
          {props.showLogo ? (
            <Image
              style={{ height: scale(814 / 20), width: scale(2455 / 20) }}
              resizeMode='contain'
              source={Logo}
            />
          ) : null}
        </View>
        <View
          style={{
            paddingHorizontal: scale(24),
            paddingVertical: scale(16)
          }}
        >
          {props.children}
        </View>
      </View>
    </LinearGradient>
  )
}
