import React, { useCallback } from 'react'
import ActionCard from '../../components/ActionCard'
import { Linking, Share } from 'react-native'
import Background from '../../../assets/backgrounds/hombre-guapo-tienda-optica/21985lr.jpg'
import { Paragraph } from 'react-native-paper'

export default () => {
  const handlePress = useCallback(async () => {
    const msg =
      'ViSpecs - Der digital Optiker. Lade die App jetzt auf Android: https://play.google.com/store/apps/details?id=com.vispecs.app oder IOS https://apps.apple.com/at/app/vispecs/id1508489528 herunter'
    const url = 'whatsapp://send?text=' + msg
    // const supported = await Linking.canOpenURL(url)
    const supported = false // always open share menu

    if (supported) {
      await Linking.openURL(url)
    } else {
      try {
        const result = await Share.share({
          message: msg
        })
        if (result.action === Share.sharedAction) {
          if (result.activityType) {
            // shared with activity type of result.activityType
          } else {
            // shared
          }
        } else if (result.action === Share.dismissedAction) {
          // dismissed
        }
      } catch (error) {
        console.debug(error)
      }
    }
  })

  return (
    <ActionCard
      title='ViSpecs empfehlen'
      background={Background}
      handlePress={handlePress}
    >
      <Paragraph>
        Zufrieden mit ViSpecs? Dann empfehlen Sie die App doch Ihren Freunden.
      </Paragraph>
    </ActionCard>
  )
}
