import React, { useEffect, useState } from 'react'
import { View, Text, Image } from 'react-native'
import { DataTable, Divider } from 'react-native-paper'
import { useOptician } from '../../hooks/opticians'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import SkeletonContent from 'react-native-skeleton-content'

export default () => {
  const { currentCustomer, customerPicture } = useOptician()

  const accountDetails = () => {
    if (!currentCustomer?.userAttributes) return []
    const a = currentCustomer.userAttributes
    let result = []
    // if (a.name) result.push(['Name', a.name])
    if (a.birthdate) result.push(['Geburtsjahr', a.birthdate.substring(0, 4)])
    if (a.gender) result.push(['Geschlecht', translateValue(a.gender)])
    if (a.phone_number) result.push(['Telefonnummer', a.phone_number])
    if (a.email) result.push(['E-Mail', a.email])
    if (currentCustomer.userCreateDate)
      result.push([
        'Mitglied seit',
        new Date(currentCustomer.userCreateDate).toLocaleDateString()
      ])

    return result
  }

  const [imageLoading, setImageLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoading(false)
    }, 1000)
    return () => clearInterval(timer)
  }, [])

  const translateValue = str => {
    switch (str) {
      case 'male':
        return 'männlich'
      case 'female':
        return 'weiblich'
      case 'other':
        return 'keine Angabe'
      default:
        return str
    }
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {customerPicture ? (
          <SkeletonContent
            isLoading={imageLoading}
            containerStyle={{
              flex: 1,
              width: '100%',
              height: '100%',
              alignSelf: 'center',
              alignItems: 'center'
            }}
            layout={{
              key: 'picture',
              alignSelf: 'center',
              height: 130,
              width: 130,
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: 8
            }}
          >
            <Image
              style={{
                height: 130,
                width: 130,
                maxWidth: '100%',
                maxHeight: '100%',
                borderRadius: 8
              }}
              resizeMode='contain'
              source={{ uri: customerPicture }}
            />
          </SkeletonContent>
        ) : (
          <Icon
            name='account-outline'
            size={40}
            style={{ flex: 1, alignSelf: 'center' }}
          />
        )}
        <Divider
          style={{
            marginHorizontal: 6,
            marginVertical: 6,
            height: 1,
            width: '100%',
            color: '#aaa'
          }}
        />
        {currentCustomer?.userAttributes?.name && (
          <Text
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              fontWeight: '500'
            }}
          >
            {currentCustomer.userAttributes.name}
          </Text>
        )}
      </View>
      <Divider style={{ width: 1.5, height: '100%', margin: 4 }} />
      <View style={{ flex: 3, paddingHorizontal: 8 }}>
        {/* <Text style={{ fontWeight: 'bold' }}>Persöhnliche Daten</Text> */}
        {/* <Divider style={{ height: 2, color: '#555', marginVertical: 10 }} /> */}
        <DataTable>
          {accountDetails().map((x, i) => (
            <DataTable.Row key={i} style={{ flex: 1 }}>
              <DataTable.Cell>
                <Text style={{ color: '#111', fontWeight: '400' }}>{x[0]}</Text>
              </DataTable.Cell>
              <DataTable.Cell style={{ flex: 2 }}>
                <Text style={{ color: '#000', fontWeight: 'bold' }}>
                  {x[1]}
                </Text>
              </DataTable.Cell>
            </DataTable.Row>
          ))}
        </DataTable>
      </View>
    </View>
  )
}
