import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useOptician } from '../../../hooks/opticians'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import theme from '../../../styles/theme'

export default ({ index, userObj, navigate, appearance, onSelect }) => {
  const { selectCurrentCustomer } = useOptician()

  const formatGender = inp => {
    if (inp === 'male') return 'Männlich'
    else if (inp === 'female') return 'Weiblich'
    else return ''
  }

  if (!userObj) return <></>
  let descriptionString = ''
  const { birthdate, phone_number, email, gender } = userObj.userAttributes

  if (gender) descriptionString += formatGender(gender)
  if (birthdate)
    descriptionString += ', Geburtsjahr: ' + birthdate.substring(0, 4)
  if (phone_number) descriptionString += ', ' + phone_number
  if (email) descriptionString += ', ' + email
  if (descriptionString.startsWith(', '))
    descriptionString = descriptionString.substring(2)

  const selectUser = () => {
    selectCurrentCustomer(userObj)
    if (typeof onSelect === 'function') onSelect()
  }

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    icon: {
      marginHorizontal: 8,
      paddingHorizontal: 2,
      color: appearance === 'light' ? '#777' : '#444'
    },
    chatIcon: {
      marginHorizontal: 8,
      paddingHorizontal: 2,
      color: appearance === 'light' ? theme.colors.primary : '#d01868'
    },
    content: {
      flex: 1,
      marginHorizontal: 8,
      paddingHorizontal: 2,
      marginVertical: 6
    },
    title: {
      fontWeight: '500',
      color: appearance === 'light' ? theme.colors.gray : '#000',
      fontSize: 16
    },
    description: {
      fontWeight: '400',
      fontSize: 16,
      color: appearance === 'light' ? theme.colors.gray : '#000'
    }
  })

  return (
    <View key={index} style={styles.container}>
      <Icon
        name='account-outline'
        onPress={selectUser}
        size={32}
        style={styles.icon}
      />
      <TouchableOpacity onPress={selectUser} style={styles.content}>
        <Text style={styles.title}>{userObj.userAttributes.name}</Text>
        <Text style={styles.description}>{descriptionString}</Text>
      </TouchableOpacity>
      <Icon
        name='chat-outline'
        onPress={() => {
          selectUser()
          navigate('Chat')
        }}
        size={32}
        style={styles.chatIcon}
      />
    </View>
  )
}
