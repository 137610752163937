import React, { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Modal, Paragraph, IconButton, Portal } from 'react-native-paper'
import VispecsSurface from '../Surfaces/VispecsSurface'
import { scale, Button, Text } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const [visible, setVisible] = useState(false)
  const linkTo = useLinkTo()
  const handleExit = () => {
    setVisible(false)
    linkTo('/home')
  }
  return (
    <>
      <IconButton
        icon='exit-to-app'
        color='#444'
        size={28}
        style={{ marginRight: 20 }}
        onPress={() => setVisible(true)}
      />
      <Portal>
        <Modal visible={visible} onDismiss={() => setVisible(false)}>
          <View style={styles.container}>
            <VispecsSurface title='Sind Sie sicher?' showLogo noFlex>
              <Paragraph>
                Möchten Sie die Befragung wirklich abbrechen?
              </Paragraph>
              <Button
                style={styles.button}
                mode='outlined'
                onPress={handleExit}
              >
                <Text style={{ color: theme.colors.primary }}>
                  Formlar verlassen
                </Text>
              </Button>
              <Button
                style={styles.button}
                mode='contained'
                onPress={() => setVisible(false)}
              >
                <Text style={{ color: '#fff' }}>Weiter</Text>
              </Button>
            </VispecsSurface>
          </View>
        </Modal>
      </Portal>
    </>
  )
}
const styles = StyleSheet.create({
  container: {
    margin: scale(12)
  },
  button: {
    marginTop: scale(12)
  }
})
