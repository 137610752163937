import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import { useUser } from '../../hooks/user'
import { useMarker } from '../../hooks/map/marker'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const { userAttributes } = useUser()
  const { markerList, fetchMarkerList, getLocalOpticianFromId } = useMarker()

  const linkTo = useLinkTo()

  const [localOptician, setLocalOptician] = useState()

  useEffect(() => {
    fetchMarkerList()
  }, [markerList])

  useEffect(() => {
    if (
      markerList &&
      userAttributes &&
      userAttributes['custom:localOpticianId']
    ) {
      setLocalOptician(
        getLocalOpticianFromId(userAttributes['custom:localOpticianId'])
      )
    }
  }, [userAttributes['custom:localOpticianId'], markerList])

  const handleChangeOptician = () => {
    linkTo('/map')
  }

  if (!userAttributes['custom:localOpticianId']) {
    return (
      <>
        <Button
          icon='map-search'
          mode='contained'
          style={{
            marginTop: 8,
            marginHorizontal: 4,
            height: 45,
            justifyContent: 'center'
          }}
          onPress={handleChangeOptician}
        >
          Meine Filliale auswählen
        </Button>
      </>
    )
  }

  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1 }}>
        <Text style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 3 }}>
          {localOptician?.name}
        </Text>
        <Text>{localOptician?.address}</Text>
      </View>
    </View>
  )
}
