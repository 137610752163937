import React, { createContext, useContext, useState } from 'react'
import { getAllMarkers } from '../../services/markerDataFormatter'

const useProvideMarker = () => {
  const [markerList, setMarkerList] = useState([])

  const fetchMarkerList = () => {
    setMarkerList(getAllMarkers)
  }

  const getLocalOpticianFromId = localOpticianId => {
    if (markerList && localOpticianId !== null) {
      const opticianId = localOpticianId
      let optician = {}
      let unformattedOptician = {}

      unformattedOptician = markerList.find(e => String(e.id) === opticianId)

      if (unformattedOptician)
        optician = {
          name: unformattedOptician.name,
          address: unformattedOptician.address
        }
      return optician
    }
  }

  return { markerList, fetchMarkerList, getLocalOpticianFromId }
}

const MarkerContext = createContext()

export const MarkerProvider = ({ children }) => {
  const value = useProvideMarker()

  return (
    <MarkerContext.Provider value={value}>{children}</MarkerContext.Provider>
  )
}

export const useMarker = () => useContext(MarkerContext)
