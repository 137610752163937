import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import { Modal } from 'react-native-paper'
import VispecsSurface from '../Surfaces/VispecsSurface'
import { scale, Button, Text } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'
import { useUser } from '../../hooks/user'

export default ({ children, visible, onDismiss, onConfirm, onCancel }) => {
  const { user } = useUser()

  const [hasUser, setHasUser] = useState(false)

  useEffect(() => {
    if (user) setHasUser(true)
  }, [user])

  return (
    <Modal visible={visible && !hasUser} onDismiss={onDismiss}>
      <View style={styles.container}>
        <VispecsSurface title='Anmelden' showLogo noFlex>
          <Image
            source={require('../../../assets/undraw/undraw_authentication_fsn5.png')}
            style={{
              marginTop: 0,
              marginBottom: 0,
              width: 1406 * 0.22,
              height: 965 * 0.22
            }}
          />
          {children}

          <Button style={styles.button} mode='contained' onPress={onConfirm}>
            <Text style={{ color: '#fff' }}>Anmelden</Text>
          </Button>
          <Button style={styles.button} mode='outlined' onPress={onCancel}>
            <Text style={{ color: theme.colors.primary }}>
              als Gast fortfahren
            </Text>
          </Button>
        </VispecsSurface>
      </View>
    </Modal>
  )
}
const styles = StyleSheet.create({
  container: {
    margin: scale(12)
  },
  button: {
    marginTop: 2 + scale(12)
  }
})
