import { API, graphqlOperation } from '@aws-amplify/api'
import * as queries from '../graphql/queries'
import moment from 'moment'

const getLastAssessments = userId =>
  API.graphql(
    graphqlOperation(queries.listAssessments, {
      userId
    })
  ).then(({ data }) =>
    data.listAssessments.items.reduce((obj, item) => {
      obj[item.type] = item.endTimestamp
      return obj
    }, {})
  )

const getAllAssessments = userId => {
  return API.graphql(
    graphqlOperation(queries.listAssessments, {
      userId
    })
  ).then(({ data }) => data.listAssessments.items)
}

const getGlassAssessmentIsDone = userId =>
  API.graphql(
    graphqlOperation(queries.listAssessments, {
      userId
    })
  ).then(({ data }) =>
    data.listAssessments.items.filter(x => x.type === 'GLASSES')
  )

const getAssessmentsDone = userId =>
  getLastAssessments(userId).then(lastAssessments => {
    const dates = Object.values(lastAssessments)
    return (
      dates.length === 3 &&
      dates.every(date => moment(date).isAfter(moment().subtract(1, 'year')))
    )
  })

const getAllPassports = (userId, type) =>
  API.graphql(
    graphqlOperation(queries.listPassports, {
      userId
    })
  ).then(({ data }) =>
    data.listPassports.items.filter(x => x.type.includes(type))
  )

export {
  getLastAssessments,
  getAssessmentsDone,
  getAllAssessments,
  getGlassAssessmentIsDone,
  getAllPassports
}
