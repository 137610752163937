import React from 'react'
import { Button, Text } from '../scaling/ScaledUI'
import Blank from '../components/templates/Blank'
import theme from '../styles/theme'
import Auth from '@aws-amplify/auth'
import * as mutations from '../graphql/mutations'
import * as queries from '../graphql/queries'
import { useUser } from '../hooks/user'
import API, { graphqlOperation } from '@aws-amplify/api'
import { Divider } from 'react-native-paper'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const { reload, user, userAttributes } = useUser()

  const linkTo = useLinkTo()

  const handlePressReset = () => {
    Promise.all([
      Auth.updateUserAttributes(user, {
        name: '',
        birthdate: '',
        email: '',
        email_verified: '',
        gender: '',
        address: ''
      }),
      API.graphql(
        graphqlOperation(mutations.updateUserMetadata, {
          input: {
            id: userAttributes.sub,
            localOpticianId: null
          }
        })
      ),
      API.graphql(
        graphqlOperation(queries.listPassports, {
          userId: userAttributes.sub
        })
      ).then(({ data }) =>
        Promise.all(
          data.listPassports.items.map(item =>
            API.graphql(
              graphqlOperation(mutations.deletePassport, {
                input: {
                  userId: item.userId,
                  createdTimestamp: item.createdTimestamp
                }
              })
            )
          )
        )
      ),
      API.graphql(
        graphqlOperation(queries.listPassportUploads, {
          userId: userAttributes.sub
        })
      ).then(({ data }) =>
        Promise.all(
          data.listPassportUploads.items.map(item =>
            API.graphql(
              graphqlOperation(mutations.deletePassportUpload, {
                input: {
                  userId: item.userId,
                  uploadTimestamp: item.uploadTimestamp
                }
              })
            )
          )
        )
      ),
      API.graphql(
        graphqlOperation(queries.listAssessments, {
          userId: userAttributes.sub
        })
      ).then(({ data }) =>
        Promise.all(
          data.listAssessments.items.map(item =>
            API.graphql(
              graphqlOperation(mutations.deleteAssessment, {
                input: {
                  userId: item.userId,
                  endTimestamp: item.endTimestamp
                }
              })
            )
          )
        )
      )
    ])
      .then(reload)
      .catch(console.error)
  }

  const handlePressSignOut = () => {
    Auth.signOut()
      .then(reload)
      .catch(console.error)
  }

  const handleOptician = () => {
    linkTo('/optician')
  }

  return (
    <Blank scrollable>
      <Button
        style={{ marginTop: 100, marginBottom: 8 }}
        mode='outlined'
        onPress={handlePressSignOut}
      >
        <Text style={{ color: theme.colors.primary }}>Sign Out</Text>
      </Button>
      <Button
        style={{ marginVertical: 8 }}
        mode='outlined'
        onPress={handlePressReset}
      >
        <Text style={{ color: theme.colors.primary }}>Reset</Text>
      </Button>
      <Divider />
      <Button
        style={{ marginVertical: 40, height: 70 }}
        mode='contained'
        onPress={handleOptician}
      >
        <Text style={{ color: '#fff' }}>OPTIKER ZUGANG</Text>
      </Button>
    </Blank>
  )
}
