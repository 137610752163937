import { useFocusEffect } from '@react-navigation/native'
import React, { useState } from 'react'
import { View, Platform } from 'react-native'
import AssessmentButton from '../components/Assessment/AssessmentButton'
import PlainSurface from '../components/Surfaces/PlainSurface'
import Hexagon from '../components/templates/Hexagon'
import {
  Paragraph,
  Headline,
  moderateScale,
  ImageChri
} from '../scaling/ScaledUI'

import { useAssessment } from '../hooks/user/assessment'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useUser } from '../hooks/user'
import { Divider } from 'react-native-paper'

export default () => {
  const {
    getLastAssessmentDates,
    getLastAssessmentDatesLocal
  } = useAssessment()
  const { user } = useUser()

  const [lastAssessments, setLastAssessments] = useState({})

  useFocusEffect(
    React.useCallback(() => {
      if (user) {
        getLastAssessmentDates().then(data =>
          setLastAssessments(
            data
            // data.sort((a, b) => (a.endTimestamp > b.endTimestamp ? 1 : -1))
          )
        )
      } else {
        getLastAssessmentDatesLocal().then(data => {
          setLastAssessments(data)
        })
      }
    }, [])
  )

  return (
    <DefaultScreen>
      <Hexagon>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginHorizontal: moderateScale(10, 2)
          }}
        >
          <View style={{ marginTop: 50 }} />
          <ImageChri />
          <PlainSurface style={{ width: '100%' }}>
            <Headline>{"Los geht's"}</Headline>
            <Paragraph>
              In den folgenden Schritten werden wir auf Ihre Bedürfnisse
              eingehen, um Ihrem Fachoptiker die Arbeit zu erleichtern.
            </Paragraph>
            <Paragraph style={{ marginBottom: moderateScale(10, 2.5) }}>
              Wählen Sie einen der Beratungsbereiche:
            </Paragraph>
            <AssessmentButton
              navigateTo='AssessmentGlasses'
              doneDate={lastAssessments.GLASSES}
            >
              Brillengläser
            </AssessmentButton>
            <AssessmentButton
              navigateTo='AssessmentFrame'
              doneDate={lastAssessments.FRAME}
              disabled={Platform.OS === 'web'}
            >
              Brillenfassung
            </AssessmentButton>
            <AssessmentButton
              navigateTo='AssessmentLenses'
              doneDate={lastAssessments.LENSES}
            >
              Kontaktlinsen
            </AssessmentButton>
            {lastAssessments &&
              lastAssessments !== [] &&
              lastAssessments !== {} && (
                <>
                  <Divider style={{ marginVertical: 18 }} />
                  <AssessmentButton navigateTo='AssessmentResult'>
                    Meine bisherigen antworten
                  </AssessmentButton>
                </>
              )}
          </PlainSurface>
        </View>
      </Hexagon>
    </DefaultScreen>
  )
}
