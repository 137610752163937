import React, { useState, useEffect } from 'react'
import { Platform, View } from 'react-native'
import SliderSelector from './SliderSelector'
import { Subheading } from 'react-native-paper'

export default props => {
  const { assessmentData, input, width, alignSelf } = props
  const { onChange } = input
  const [hadDigitalTier, setHadDigitalTier] = useState(false)

  const isQualityEnabled = getCategory(assessmentData) !== 'Digitalglas'

  useEffect(() => {
    setHadDigitalTier(assessmentData.wantsDigital[0] !== null)
  }, [])

  function getCategory (assessmentData) {
    if (!assessmentData) return ''
    if (assessmentData?.varifocalTier[0]) return 'Gleitsichtglas'
    if (
      assessmentData?.wantsDigital[0] &&
      assessmentData.wantsDigital[0] !== 'no'
    )
      return 'Digitalglas'
    if (assessmentData?.workstationTier[0]) return 'Arbeitsplatzglas'
    if (assessmentData?.singleVisionTier[0]) return 'Einstärkenglas'
    // Keine Sehprobleme
    return 'Planglas'
  }

  function getTier () {
    if (!assessmentData) return ''
    if (assessmentData?.varifocalTier[0]) return assessmentData.varifocalTier[0]
    if (
      assessmentData?.wantsDigital[0] &&
      assessmentData.wantsDigital[0] !== 'no'
    )
      return assessmentData.wantsDigital[0]
    if (assessmentData?.workstationTier[0])
      return assessmentData.workstationTier[0]
    if (assessmentData?.singleVisionTier[0])
      return assessmentData.singleVisionTier[0]
    return ''
  }

  function updateTier (newTier) {
    if (!assessmentData || !assessmentData.varifocalTier) {
      return
    }
    if (assessmentData?.varifocalTier[0])
      assessmentData.varifocalTier[0] = newTier
    if (
      assessmentData?.wantsDigital[0] &&
      assessmentData.wantsDigital[0] !== 'no'
    )
      assessmentData.wantsDigital[0] = newTier
    if (assessmentData?.workstationTier[0])
      assessmentData.workstationTier[0] = newTier
    if (assessmentData?.singleVisionTier[0])
      assessmentData.singleVisionTier[0] = newTier
    onChange(assessmentData)
  }

  function updateAntireflective (newTier) {
    assessmentData.wantsAntireflective[0] = newTier
    onChange(assessmentData)
  }

  function updateDigital (newTier) {
    if (newTier === 'yes') {
      assessmentData.singleVisionTier[0] = null
    } else {
      assessmentData.singleVisionTier[0] = 'bestseller'
    }
    assessmentData.wantsDigital[0] = newTier
    onChange(assessmentData)
  }

  const sizes = Platform.OS === 'ios' ? [185, 145] : [165, 125]

  return (
    <View
      style={{
        alignSelf: alignSelf || 'flex-start',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        width: width,
        height: hadDigitalTier ? sizes[0] : sizes[1]
      }}
    >
      <Subheading style={{ fontSize: 20 }}>Anpassungen</Subheading>
      <SliderSelector
        disabled={!isQualityEnabled}
        name='Glasqualität'
        initital={getTier() === 'yes' ? 'bestseller' : getTier()}
        size={3}
        options={[
          { id: 'standard', text: 'Standard' },
          { id: 'bestseller', text: 'Bestseller' },
          { id: 'premium', text: 'Premium' }
        ]}
        input={{
          onChange: value => {
            updateTier(value)
          }
        }}
      />
      <SliderSelector
        name='Entspiegelung'
        initital={assessmentData.wantsAntireflective[0]}
        size={2}
        options={[
          // { id: 'no', text: 'keine' },
          { id: 'standard', text: 'Standard' },
          { id: 'premium', text: 'Premium' }
        ]}
        input={{
          onChange: value => {
            updateAntireflective(value)
          }
        }}
      />
      {hadDigitalTier && (
        <SliderSelector
          name='Digitalglas'
          initital='yes'
          size={2}
          options={[
            { id: 'no', text: 'Nein' },
            { id: 'yes', text: 'Ja' }
          ]}
          input={{
            onChange: value => {
              updateDigital(value)
            }
          }}
        />
      )}
    </View>
  )
}
