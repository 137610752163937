import { Auth } from '@aws-amplify/auth'
import { API, graphqlOperation } from '@aws-amplify/api'
import Storage from '@aws-amplify/storage'
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect
} from 'react'
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import { Hub } from '@aws-amplify/core'
import DeviceStore from '../../services/deviceStore'

// for testing
// const USERNAME = 'testuser'
// const PASSWORD = 'VtNiXnGUC2iK'

const useProvideUser = (googleSignIn, facebookSignIn) => {
  const [user, setUser] = useState()
  const [loadingAuth, setLoadingAuth] = useState(false)
  const [userPicture, setUserPicture] = useState(null)
  const [userMetadata, setUserMetadata] = useState()
  const [userGroups, setUserGroups] = useState()
  const [userAttributes, setUserAttributes] = useState(
    user?.attributes ? user.attributes : undefined
  )
  const [isFederated, setIsFederated] = useState(false)
  const [isOptician, setIsOptician] = useState(false)

  useEffect(() => {
    listenForFederation()
    loadUser()
  }, [])

  useEffect(() => {
    if (user && user.attributes && user.attributes.sub) loadUserMetadata()
    uploadLocallyStoredStuff()
  }, [user?.attributes])

  const [firstRun, setFirstRun] = useState(true) // unfortunate workaround to capture first effect call

  useEffect(() => {
    if (!firstRun) setUserAttributes(user ? user?.attributes : null)
    else setFirstRun(false)
  }, [user])

  const signIn = (username, password) => {
    setLoadingAuth(true)
    Auth.signIn(username, password)
      .then(user => {
        setUser(user)
      })
      // .catch(console.error)
      .finally(() => setLoadingAuth(false))
  }

  const listenForFederation = () => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data)
          if (data && data.username) {
            setUserAttributes(data.attributes)
            reload()
          }
          break
        case 'signOut':
          setUser(null)
          break
        case 'customOAuthState':
          setUser(data)
      }
    })
  }

  const uploadLocallyStoredStuff = async () => {
    const glass = await DeviceStore.getItem('GLASSES')
    const frame = await DeviceStore.getItem('FRAME')
    const lense = await DeviceStore.getItem('LENSES')
    if (glass) {
      uploadLocalAssessment(JSON.parse(glass).input).then(
        DeviceStore.removeItem('GLASSES')
      )
    }
    if (frame) {
      uploadLocalAssessment(JSON.parse(frame).input).then(
        DeviceStore.removeItem('FRAME')
      )
    }
    if (lense) {
      uploadLocalAssessment(JSON.parse(lense).input).then(
        DeviceStore.removeItem('LENSES')
      )
    }
  }

  const uploadLocalAssessment = input => {
    input.userId = user?.attributes?.sub
    // console.log('uploading : ' + JSON.stringify(input, null, 4))
    return API.graphql(
      graphqlOperation(mutations.createAssessment, {
        input: input
      })
    )
  }

  const googleLogin = useCallback(() => {
    googleSignIn()
  }, [])

  const facebookLogin = useCallback(() => {
    facebookSignIn()
  }, [])

  const appleLogin = useCallback(() => {
    Auth.federatedSignIn({ provider: 'SignInWithApple' })
  }, [])

  const openHostedUI = useCallback(() => {
    Auth.federatedSignIn().catch(console.error)
  })

  const signOut = async () => {
    Auth.signOut()
      .then(() => {
        setUser()
      })
      .catch(console.error)
  }

  const getFormattedPhoneNumber = () => {
    return parsePhoneNumberFromString(
      userAttributes.phone_number
    ).formatInternational()
  }

  const fetchUserPicture = () => {
    if (user && userAttributes && userAttributes.picture) {
      return Storage.get(userAttributes.picture, { level: 'protected' }).then(
        setUserPicture
      )
    } else {
      setUserPicture(undefined)
      return Promise.resolve()
    }
  }

  const reload = useCallback(() => {
    loadUser()
  }, [])

  const loadUser = () => {
    return Auth.currentAuthenticatedUser()
      .then(userObj => {
        setUser(userObj)
        loadUserGroups(userObj)
      })
      .catch(() => {
        console.debug('UserProvider: no user currently authenticated')
        setUser(null)
      })
  }

  const updateUserAttributes = values => {
    Auth.updateUserAttributes(user, values)
      .then(res => {
        if (res === 'SUCCESS') {
          reload()
        }
      })
      .catch(console.error)
  }

  const updateLocalOptician = id => {
    return Auth.updateUserAttributes(user, {
      'custom:localOpticianId': !id ? '' : id
    })
      .then(res => {
        if (res === 'SUCCESS') {
          reload()
        }
      })
      .catch(console.error)
  }

  const loadUserMetadata = () => {
    if (!user) return setUserMetadata(undefined)

    API.graphql(
      graphqlOperation(queries.getUserMetadata, {
        id: user?.attributes?.sub
      })
    )
      .then(({ data }) => {
        if (data.getUserMetadata === null) {
          console.log('creating usermetadata')
          const initUserMetadata = {
            id: user.attributes.sub
          }
          API.graphql(
            graphqlOperation(mutations.createUserMetadata, {
              input: initUserMetadata
            })
          )
          setUserMetadata(initUserMetadata)
        } else {
          setUserMetadata(data.getUserMetadata)
        }
      })
      .catch(console.error)
  }

  const loadUserGroups = userObj => {
    let myUser = userObj ?? user
    if (!myUser) return setUserGroups(undefined)

    let federated = false
    myUser.signInUserSession.accessToken.payload['cognito:groups']?.map(x => {
      if (
        x.toLowerCase().includes('google') ||
        x.toLowerCase().includes('facebook') ||
        x.toLowerCase().includes('apple')
      )
        federated = true
      else if (x.toLowerCase().includes('shop')) {
        setIsOptician(true)
      }
    })
    setIsFederated(federated)

    setUserGroups(
      myUser.signInUserSession.accessToken.payload['cognito:groups']
    )
  }

  return {
    user,
    userAttributes,
    userMetadata,
    userGroups,
    loadingAuth,
    userPicture,
    isFederated,
    isOptician,
    signIn,
    signOut,
    googleLogin,
    facebookLogin,
    appleLogin,
    openHostedUI,
    listenForFederation,
    getFormattedPhoneNumber,
    fetchUserPicture,
    loadUserMetadata,
    updateUserAttributes,
    updateLocalOptician,
    loadUserGroups,
    reload
  }
}

const UserContext = createContext()

export const UserProvider = ({ children, googleSignIn, facebookSignIn }) => {
  const value = useProvideUser(googleSignIn, facebookSignIn)

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
