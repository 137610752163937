import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Modal } from 'react-native-paper'
import VispecsSurface from '../Surfaces/VispecsSurface'
import { scale, Button, Text } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'

export default ({
  children,
  visible,
  onDismiss,
  onConfirm,
  onCancel,
  title,
  buttonTitle,
  cancelTitle
}) => {
  return (
    <Modal visible={visible} onDismiss={onDismiss}>
      <View style={styles.container}>
        <VispecsSurface title={title || 'Datenweitergabe'} showLogo noFlex>
          {children}
          {typeof onConfirm === 'function' && (
            <Button style={styles.button} mode='contained' onPress={onConfirm}>
              <Text style={{ color: '#fff' }}>
                {buttonTitle || 'Zustimmen'}
              </Text>
            </Button>
          )}
          {typeof onCancel === 'function' && (
            <Button style={styles.button} mode='outlined' onPress={onCancel}>
              <Text style={{ color: theme.colors.primary }}>
                {cancelTitle || 'Abbrechen'}
              </Text>
            </Button>
          )}
        </VispecsSurface>
      </View>
    </Modal>
  )
}
const styles = StyleSheet.create({
  container: {
    margin: scale(12)
  },
  button: {
    marginTop: scale(12)
  }
})
