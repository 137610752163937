import React, { useEffect, useState } from 'react'
import { View, ScrollView, Platform, TouchableOpacity } from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useMediaQuery } from 'react-responsive'

export default ({
  scrollable,
  collapsible,
  children,
  header,
  style,
  height,
  innerStyle,
  icon,
  minWidth,
  width,
  collapsed,
  setCollapsed
}) => {
  const ViewComponent = scrollable ? ScrollView : View

  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (typeof setCollapsed === 'function') {
      if (collapsed === expanded) setExpanded(!expanded)
    }
  }, [collapsed])

  const handleExpand = () => {
    if (!collapsible) return
    if (typeof setCollapsed === 'function') {
      setCollapsed(!collapsed)
      setExpanded(collapsed)
    } else setExpanded(!expanded)
  }

  const cardStyles =
    Platform.OS === 'web'
      ? {
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.16,
          shadowRadius: 8,
          elevation: 4
        }
      : {}

  return (
    <View
      style={{
        ...style,
        ...cardStyles,
        minWidth:
          Platform.OS === 'web' &&
          minWidth &&
          useMediaQuery({ minDeviceWidth: minWidth })
            ? minWidth
            : 210,
        marginHorizontal: 15,
        marginTop: 0,
        marginBottom: 20,
        flex: expanded ? 1 : 0,
        maxHeight: scrollable ? height || 310 : undefined,
        minHeight: 55,
        height: expanded ? undefined : 55,
        maxWidth: '100%',
        width: width,
        backgroundColor: '#fff',
        borderRadius: 8
      }}
    >
      {header && (
        <TouchableOpacity onPress={handleExpand}>
          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            {icon && (
              <Icon
                name={icon}
                size={30}
                style={{ marginLeft: 12, alignSelf: 'center' }}
              />
            )}
            <Text
              style={{
                fontSize: 18,
                fontWeight: '400',
                lineHeight: 24,
                alignSelf: 'center',
                marginLeft: 12
              }}
            >
              {header}
            </Text>
            {collapsible && (
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Icon
                  name={!expanded ? 'chevron-down' : 'chevron-up'}
                  size={24}
                  color='#555'
                  style={{ marginRight: 10, alignSelf: 'center' }}
                  onPress={handleExpand}
                />
              </View>
            )}
          </View>
        </TouchableOpacity>
      )}
      {expanded && (
        <ViewComponent
          visible={expanded}
          style={{ ...innerStyle, marginTop: 12, marginHorizontal: 4 }}
        >
          {children}
        </ViewComponent>
      )}
    </View>
  )
}
