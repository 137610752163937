import React from 'react'
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import Logo from '../../../assets/logo_text.png'
import { useLinkProps } from '@react-navigation/native'
import FooterElement from './FooterElement'
import theme from '../../styles/theme'
import MediaQuery from 'react-native-web-responsive'

export default ({ width }) => {
  const { onPress } = useLinkProps({ to: '/' })
  return (
    <View style={styles.main}>
      <View
        style={{
          maxWidth: width,
          width: '100%',
          height: 70,
          alignSelf: 'center',
          flex: 1,
          flexDirection: 'row'
        }}
      >
        {/* <TouchableOpacity style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-start', minWidth: 120 }} onPress={onPress}> */}
        {/* <Image source={Logo} style={styles.logo} /> */}
        {/* </TouchableOpacity> */}
        <MediaQuery minWidth={570}>
          <View style={styles.spacer} />
        </MediaQuery>
        <MediaQuery maxWidth={570}>
          <View style={styles.miniSpacer} />
        </MediaQuery>
        <FooterElement to='/legal'>Impressum & Datenschutz</FooterElement>
        <MediaQuery maxWidth={570}>
          <View style={styles.miniSpacer} />
        </MediaQuery>
        <FooterElement align='flex-end'>© ViSpecs GmbH</FooterElement>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'column',
    height: 70,
    width: '100%',
    left: 0,
    backgroundColor: theme.colors.footer
  },
  logo: {
    tintColor: 'white',
    marginTop: 15,
    marginLeft: 5,
    height: 40,
    width: 120
  },
  spacer: {
    width: 50
  },
  miniSpacer: {
    width: 10
  }
})
