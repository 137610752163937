import React, { useState, useCallback, useEffect } from 'react'
import { GiftedChat } from 'react-native-gifted-chat'
import { useOptician } from '../../hooks/opticians'
import UserblockComponent from './Userblock.component'

export default () => {
  const [messages, setMessages] = useState([])
  const { currentCustomer } = useOptician()

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: 'Hello developer',
        createdAt: new Date(),
        user: {
          _id: 2,
          name: 'React Native',
          avatar: 'https://placeimg.com/140/140/any'
        }
      }
    ])
  }, [])

  const onSend = useCallback((messages = []) => {
    setMessages(previousMessages =>
      GiftedChat.append(previousMessages, messages)
    )
  }, [])

  return (
    <>
      {currentCustomer && (
        <UserblockComponent
          appearance='selected'
          userObj={currentCustomer}
          key={0}
          index={0}
          onSelect={() => {}}
          basic
        />
      )}
      <GiftedChat
        messages={messages}
        onSend={messages => onSend(messages)}
        user={{
          _id: 1
        }}
      />
    </>
  )
}
