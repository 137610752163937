import React, { useEffect, useState } from 'react'
import { usePassport } from '../../hooks/user/passport'
import { useAssessment } from '../../hooks/user/assessment'

import { View } from 'react-native'
import LensesEvaluationManipulator from '../../components/Evaluation/LensesEvaluationManipulator'
import EvaluationWrapper from '../../components/Evaluation/EvaluationWrapper'
import { useLensesEvaluation } from '../../hooks/general/lensesEvaluation'
import GenericLenseEvaluation from '../../components/Evaluation/GenericLenseEvaluation'
import { Divider } from 'react-native-paper'
import ManufacturerList from '../../components/Evaluation/ManufacturerList'
import SkeletonContent from 'react-native-skeleton-content'

export default ({ noWrapper }) => {
  const { lensesPassport, fetchLensesPassport } = usePassport()
  const { lensesAssessment, fetchLensesAssessment } = useAssessment()
  const {
    evaluatedLenses,
    getManufacturers,
    lenseProperties,
    evaluateLenses
  } = useLensesEvaluation()

  const [manufacturerList, setManufacturerList] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!lensesPassport) fetchLensesPassport()
    if (!lensesAssessment) fetchLensesAssessment()
    if (lensesPassport && lensesAssessment) {
      evaluateLenses(
        lensesAssessment.assessmentData,
        lensesPassport,
        false,
        undefined,
        lensesAssessment.assessmentData.lensesCycle !== 'weekly' &&
          (lensesAssessment.assessmentData.displayDuration > 3 ||
            lensesAssessment.assessmentData.lensesDuration > 4 ||
            lensesAssessment.assessmentData.lensesProblem?.length > 0)
          ? 'premiumTier'
          : 'standardTier'
      )
    }
  }, [lensesPassport, lensesAssessment])

  useEffect(() => {
    if (evaluatedLenses) setManufacturerList(getManufacturers(true))
  }, [evaluatedLenses])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 200)
    return () => clearInterval(timer)
  }, [])

  const WrapperComponent = noWrapper ? View : EvaluationWrapper

  return (
    <WrapperComponent>
      {evaluatedLenses && (
        <>
          <GenericLenseEvaluation
            data={evaluatedLenses}
            lenseProperties={lenseProperties}
          />
          <Divider
            style={{
              marginTop: 8,
              marginBottom: 4,
              height: 1.5,
              backgroundColor: '#ccc'
            }}
          />
          <LensesEvaluationManipulator
            width={'80%'}
            data={{
              mfPossible: !!(
                lensesPassport.leftData.add >= 0.5 ||
                lensesPassport.rightData.add
              ),
              cycle: lensesAssessment.assessmentData.lensesCycle,
              tier:
                lensesAssessment.assessmentData.lensesCycle !== 'weekly' &&
                (lensesAssessment.assessmentData.displayDuration > 3 ||
                  lensesAssessment.assessmentData.lensesDuration > 4 ||
                  lensesAssessment.assessmentData.lensesProblem?.length > 0)
                  ? 'premiumTier'
                  : 'standardTier'
            }}
            input={{
              onChange: value => {
                evaluateLenses(
                  lensesAssessment.assessmentData,
                  lensesPassport,
                  value.wantsMf,
                  value.cycle,
                  value.tier
                )
              }
            }}
          />
          <Divider
            style={{
              marginTop: 8,
              marginBottom: 2,
              height: 1.5,
              backgroundColor: '#ccc'
            }}
          />
          <SkeletonContent
            isLoading={loading}
            // boneColor={theme.colors.bone}
            // highlightColor={theme.colors.boneHighlight}
            layout={[
              {
                key: 'line1',
                width: '90%',
                height: 60,
                alignSelf: 'center',
                marginBottom: 8
              },
              {
                key: 'line3',
                width: '60%',
                height: 60,
                alignSelf: 'center',
                marginBottom: 8
              }
            ]}
          >
            <ManufacturerList
              description={
                'Erfahren Sie mehr über Kontaktlinsen in Ihrer Kategorie:'
              }
              data={manufacturerList}
            />
          </SkeletonContent>
        </>
      )}
    </WrapperComponent>
  )
}
