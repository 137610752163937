import { LinearGradient } from 'expo-linear-gradient'
import React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Logo from '../../../assets/logo_text.png'
import theme from '../../styles/theme'
import { Headline } from 'react-native-paper'

const width = 300 * 2.1
const height = 189 * 2.1
const borderRadius = 30

export default ({ children, onChangeSize, title }) => {
  return (
    <View style={{ ...styles.outer, borderRadius }}>
      <LinearGradient
        colors={['#AB3668', '#DD7F69']}
        start={[0, 0]}
        end={[1, 1]}
        style={{
          ...styles.inner,
          width: width,
          height: height,
          borderRadius: borderRadius
        }}
      >
        <View style={styles.topRow}>
          <Headline
            style={{
              color: 'white',
              fontSize: 32
            }}
          >
            {title}
          </Headline>
          <Image
            style={{
              width: 160,
              height: 52,
              tintColor: 'white'
            }}
            source={Logo}
            resizeMode='contain'
          />
        </View>
        <View style={styles.content}>{children}</View>
      </LinearGradient>
    </View>
  )
}

const styles = StyleSheet.create({
  outer: {
    backgroundColor: theme.colors.primary,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 8
  },
  inner: {
    paddingVertical: 2,
    overflow: 'hidden'
  },
  topRow: {
    paddingTop: 24,
    paddingHorizontal: 50,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 0
  },
  content: {
    justifyContent: 'center',
    flex: 1
  }
})
