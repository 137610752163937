import React from 'react'
import { View } from 'react-native'

export default ({ children }) => {
  return (
    <View
      style={{ flexDirection: 'row', alignItems: 'flex-start', height: 70 }}
    >
      {children}
    </View>
  )
}
