import React, { useEffect } from 'react'
import DefaultScreen from '../components/screens/DefaultScreen'
import Blank from '../components/templates/Blank'
import Markdown from 'react-native-easy-markdown'
import { useLegal } from '../hooks/general/legal'
import { Divider } from 'react-native-paper'
import { moderateScale } from '../scaling/ScaledUI'
import theme from '../styles/theme'
import { StyleSheet, Platform } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content'

export default () => {
  const { imprint, privacy, fetchLegal } = useLegal()

  useEffect(() => {
    fetchLegal()
  }, [])

  return (
    <DefaultScreen>
      <Blank ignoreHeader scrollable={Platform.OS !== 'web'}>
        <SkeletonContent
          containerStyle={{
            flex: 1,
            width: Platform.OS !== 'web' ? 350 : undefined
          }}
          isLoading={!imprint}
          layout={[
            { key: 'header', width: 120, height: 36, marginBottom: 18 },
            { key: 'someOtherId', width: 350, height: 20, marginBottom: 10 },
            { key: 'block', width: 350, height: 60, marginBottom: 18 },
            { key: 'someOtherId2', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId31', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId32', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId33', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId34', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId35', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId5', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId6', width: 350, height: 20, marginBottom: 10 }
          ]}
        >
          <Markdown
            markdownStyles={Platform.OS === 'web' ? undefined : styles}
            useDefaultStyles
          >
            {imprint}
          </Markdown>
        </SkeletonContent>
        <Divider style={{ marginTop: 40 }} />
        <SkeletonContent
          containerStyle={{
            flex: 1,
            width: Platform.OS !== 'web' ? 350 : undefined
          }}
          isLoading={!privacy}
          layout={[
            { key: 'header', width: 300, height: 36, marginBottom: 18 },
            { key: 'someOtherId', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId2', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId3', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId4', width: 350, height: 20, marginBottom: 20 },
            { key: 'someOtherId54', width: 270, height: 28, marginBottom: 10 },
            { key: 'someOtherId5', width: 350, height: 20, marginBottom: 10 },
            { key: 'someOtherId6', width: 350, height: 20, marginBottom: 10 }
          ]}
        >
          <Markdown
            markdownStyles={Platform.OS === 'web' ? undefined : styles}
            useDefaultStyles
          >
            {privacy}
          </Markdown>
        </SkeletonContent>
      </Blank>
    </DefaultScreen>
  )
}

const text = { fontSize: moderateScale(12) }

const styles = StyleSheet.create({
  h1: { fontSize: moderateScale(20) },
  h2: { fontSize: moderateScale(17) },
  h3: { fontSize: moderateScale(15) },
  h4: { fontSize: moderateScale(13) },
  h5: { fontSize: moderateScale(11) },
  h6: { fontSize: moderateScale(8) },
  text: text,
  link: { color: theme.colors.primary, textDecorationLine: 'underline' }
})
