import { API, graphqlOperation } from '@aws-amplify/api'
import { useLinkTo } from '@react-navigation/native'
import React, { useCallback } from 'react'
import FormScreen from '../../../form-wizard/FormScreen'
import FormWizard from '../../../form-wizard/FormWizard'
import MultilineTextInput from '../../../form-wizard/inputs/MultilineTextInput'
import PassportDataInput from '../../../form-wizard/inputs/PassportDataInput'
import Question from '../../../form-wizard/Question'
import * as mutations from '../../../graphql/mutations'
import { useUser } from '../../../hooks/user'
import { usePassport } from '../../../hooks/user/passport'

export default props => {
  const linkTo = useLinkTo()

  const { userAttributes } = useUser()
  const { fetchPassports } = usePassport()
  const pp = props.passport

  const handleSubmit = useCallback(data => {
    const rightData = {
      sphere: data.sphere[0],
      cylinder: data.cylinder[0],
      axis: data.axis[0],
      add: data.add === undefined ? null : data.add[0],
      prism: data.prism === undefined ? null : data.prism[0],
      base:
        data.prism === undefined ||
        data.prism[0] === null ||
        data.base === undefined
          ? null
          : data.base[0],
      pd:
        data.distance === undefined || isNaN(data.distance[0])
          ? null
          : data.distance[0],
      height:
        data.height === undefined || isNaN(data.height[0])
          ? null
          : data.height[0],
      diameter: null,
      radius: null
    }

    const leftData = {
      sphere: data.sphere[1],
      cylinder: data.cylinder[1],
      axis: data.axis[1],
      add: data.add === undefined ? null : data.add[1],
      prism: data.prism === undefined ? null : data.prism[1],
      base:
        data.prism === undefined ||
        data.prism[0] === null ||
        data.base === undefined
          ? null
          : data.base[1],
      pd:
        data.distance === undefined || isNaN(data.distance[1])
          ? null
          : data.distance[1],
      height:
        data.height === undefined || isNaN(data.height[1])
          ? null
          : data.height[1],
      diameter: null,
      radius: null
    }

    // console.log(
    //   'Creating passport:\n' +
    //     JSON.stringify(rightData, null, 4) +
    //     '\n' +
    //     JSON.stringify(leftData, null, 4)
    // )

    API.graphql(
      graphqlOperation(mutations.createPassport, {
        input: {
          userId: userAttributes.sub,
          createdTimestamp: Date.now(),
          createdByUserId: userAttributes.sub,
          metaData: data.comments,
          rightData,
          leftData,
          type: 'GLASSES'
        }
      })
    )
      .then(() => fetchPassports())
      .then(() => {
        linkTo('/home')
      })
      .catch(console.error)
  }, [])

  return (
    <FormWizard onSubmit={handleSubmit}>
      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='sphere'
          head='Sphäre (SPH / S)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp ? [pp.rightData.sphere, pp.leftData.sphere] : [0, 0]
            }
            min={-8}
            max={8}
            decimalPlaces={2}
            steps={0.25}
            description='Die Sphäre steht im Brillenpass meist an erster Stelle und bewegt sich in 0.25er Schritten.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_SPH.jpg')}
            info='Die Sphäre beschreibt die Stärke des Brillenglases oder der
            Kontaktlinse. Üblicherweise wird dieser Wert mit den Vorzeichen
            +/- angegeben. Handelt es sich um das Vorzeichen + sind Sie
            weitsichtig. Steht vor dem sphärischen Wert ein - sind Sie kurzsichtig.
            Sollte kein Vorzeichen angegeben sein, handelt es sich in den meisten Fällen um einen + Wert.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='cylinder'
          head='Zylinder (ZYL / CYL)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp ? [pp.rightData.cylinder, pp.leftData.cylinder] : [0, 0]
            }
            min={-8}
            max={8}
            decimalPlaces={2}
            steps={0.25}
            description='Der Zylinder steht im Brillenpass meist an zweiter Stelle und bewegt sich in 0.25er Schritten.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_CYL.jpg')}
            info='Der Zylinder ist der Wert der die Höher der Hornhautverkrümmung
            beschreibt. Bei einer Horhautverkrümmung handelt es sich um zwei
            unterschiedlich Starke Radien auf Ihrer Hornhautoberfläche.
            Diese Differenz kann mit einem dioptrischen Wert ausgeglichen werden.
            Dieser Wert kann ebenso in + oder – angegeben werden. Hier beschreibt
            das Vorzeichen jedoch nicht die Art der Fehlsichtigkeit.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='axis'
          head='Achse (A / Ach / Axis)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp ? [pp.rightData.axis, pp.leftData.axis] : [100, 100]
            }
            min={0}
            max={180}
            decimalPlaces={0}
            steps={1}
            description='Die Achse steht im Brillenpass meist an dritter Stelle.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_A.jpg')}
            info='Die Achse steht in dringender Verbindung mit dem Wert
            des ZYL / CYL. Ist keine Hornhautverkrümmung vorhanden
            wird auch der Wert der Achse unrelevant. Die Achse
            beschreibt die Achsenlage auf welcher die Hornhautverkrümmung
            herrscht und somit auf wie viel Grad diese korrigiert werden muss.
            Dieser Wert liegt immer zwischen 0° - 180°.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='add'
          head='Addition (ADD)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp && pp.add !== null
                ? [pp.rightData.add, pp.leftData.add]
                : [2, 2]
            }
            min={0.5}
            max={3.0}
            decimalPlaces={2}
            steps={0.25}
            optionalText='Mein Brillenpass enthält diesen Wert nicht'
            description='Steht an im Brillenpass manchmal an vierter Stelle.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_Add.jpg')}
            info='Die Addition (Nahzusatz) ist jener Wert welcher benötigt
            wird damit Sie auch in der Nähe scharf sehen können.
            (z.B.: beim Lesen) Auch dieser wird in Dioptrie angegeben
            und ist immer mit dem Vorzeichen +. In fast allen Fällen
            ist dieser Wert für beide Augen ident und somit oft nur
            einmal auf einem Brillenpass angeführt.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='distance'
          head='Pupillendistanz (PD)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={pp ? [pp.rightData.pd, pp.leftData.pd] : [31, 31]}
            min={10}
            max={40}
            decimalPlaces={2}
            staticUnit='mm'
            optionalText='Mein Brillenpass enthält diesen Wert nicht'
            description='Steht im Brillenpass meist an erster Stelle in der zweiten Zeile.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_PD.jpg')}
            info='Die Pupilllendistanz ist der Abstand welcher zwischen
            Ihren Augen herscht. Gemessen wird von Pupillenmitte
            zu Pupillenmitte. Dieser Wert wird für jedes Auge einzeln
            und in Millimeter angegeben.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='height'
          head='Einschleifhöhe / Nahteilhöhe (H / Höhe / NTH)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp && pp.height !== null
                ? [pp.rightData.height, pp.leftData.height]
                : [18, 18]
            }
            min={0}
            max={50}
            decimalPlaces={2}
            staticUnit='mm'
            optionalText='Mein Brillenpass enthält diesen Wert nicht'
            description='Steht manchmal an zweiter Stelle in der zweiten Zeile.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_H.jpg')}
            info='Die Nahteilhöhe gibt den Abstand von unterem Brillenrand
            bis zur jeweiligen Pupillenmitte an. Dieser Wert wird
            ebenfalls in Millimeter angegeben und ist nicht immer
            am Brillenpass ersichtlich.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren' head='Prisma (Pr)'>
        <Question
          id='prism'
          head='Prisma (P)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp && pp.prism !== null
                ? [pp.rightData.prism, pp.leftData.prism]
                : [0, 0]
            }
            min={0}
            max={2000}
            decimalPlaces={2}
            staticUnit='cm/m'
            optionalText='Mein Brillenpass enthält diesen Wert nicht'
            description='Steht manchmal an dritter Stelle in der zweiten Zeile.'
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_Prism.jpg')}
            info='Das Prisma ist ein Wert welcher in cm/m angegeben wird
            und für eine Korrektur einer Augenfehlstellung (Schielen)
            verantwortlich ist.'
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Brillenpass digitalisieren'
        condition={values => values.prism[0] !== null}
      >
        <Question
          id='base'
          head='Basis (B)'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <PassportDataInput
            initialValue={
              pp && pp.base !== null
                ? [pp.rightData.base, pp.leftData.base]
                : [0, 0]
            }
            dropDown={['BA', 'BI', 'BO', 'BU']}
            dropDownDescription={[
              'rechts 180°\nlinks 0°',
              'rechts 0°\nlinks 180°',
              'rechts 90°\nlinks 90°',
              'rechts 270°\nlinks 270°'
            ]}
            description={`Steht meistens an vierter Stelle in der zweiten Zeile. Möglichkeiten:
            \t\t\tBA = Basis außen
            \t\t\tBI = Basis innen
            \t\t\tBO = Basis oben
            \t\t\tBU = Basis unten`}
            image={require('../../../../assets/Passport/Brillenpass/Brillenpass_Prism.jpg')}
            info='Die Basis kann nur gewählt werden wenn ein Prisma vorhanden ist.
            Dieser Wert beschreibt auf welcher Achsenlage das Prisma wirkt.'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Brillenpass digitalisieren'>
        <Question
          id='comments'
          head='Möchten Sie noch eine Anmerkung hinzufügen?'
        >
          <MultilineTextInput
            initialValue={pp && pp.metaData !== null ? pp.metaData : ''}
          />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
