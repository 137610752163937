import React, { useEffect } from 'react'
import { useGlassEvaluation } from '../../../hooks/general/glassEvaluation'
import { useLensesEvaluation } from '../../../hooks/general/lensesEvaluation'
import { useOptician } from '../../../hooks/opticians'
import EvaluationList from './EvaluationList'

export default ({ type }) => {
  const { evaluatedGlasses, evaluateAssessment } = useGlassEvaluation()
  const { evaluatedLenses, evaluateLenses } = useLensesEvaluation()

  const {
    getCustomerAssessments,
    getCustomerPassports,
    customerGlassAssessment,
    customerLensePassports,
    customerRawLenseAssessment
  } = useOptician()

  useEffect(() => {
    if (type === 'glasses') {
      if (!customerGlassAssessment) getCustomerAssessments()
      else evaluateAssessment(customerGlassAssessment.assessment)
    }
  }, [customerGlassAssessment])

  function compare (a, b) {
    if (a.createdTimestamp < b.createdTimestamp) {
      return -1
    }
    if (a.createdTimestamp > b.createdTimestamp) {
      return 1
    }
    return 0
  }

  const getLatestPassport = data => {
    if (!data) return data
    const sorted = data.sort(compare)
    return sorted[sorted.length - 1]
  }

  useEffect(() => {
    if (type === 'lenses') {
      if (!customerLensePassports) getCustomerPassports()
      if (!customerRawLenseAssessment) getCustomerAssessments()
      if (customerLensePassports && customerRawLenseAssessment) {
        evaluateLenses(
          customerRawLenseAssessment.assessmentData,
          getLatestPassport(customerLensePassports),
          false
        )
      }
    }
  }, [customerLensePassports, customerRawLenseAssessment])

  return (
    <EvaluationList
      type={type}
      evaluatedGlasses={evaluatedGlasses}
      evaluatedLenses={evaluatedLenses}
    />
  )
}
