// import 'react-native-gesture-handler' // must be top
import Amplify, { I18n } from '@aws-amplify/core'
import { NavigationContainer } from '@react-navigation/native'
import moment from 'moment'
import 'moment/locale/de-at'
import React from 'react'
import { Provider as PaperProvider } from 'react-native-paper'
import { enableScreens } from 'react-native-screens'
import awsconfig from './aws-exports'
import { UserProvider } from './src/hooks/user'
import Router from './src/router'
import theme from './src/styles/theme'
import linking from './src/router/linking'
import { LegalProvider } from './src/hooks/general/legal'
import { MarkerProvider } from './src/hooks/map/marker'
import { GlassEvaluationProvider } from './src/hooks/general/glassEvaluation'
import { AssessmentProvider } from './src/hooks/user/assessment'
import { PassportProvider } from './src/hooks/user/passport'
import { LensesEvaluationProvider } from './src/hooks/general/lensesEvaluation'
import { withOAuth } from 'aws-amplify-react-native'
import { LogBox, Platform } from 'react-native'
import './src/hack'
import { OpticianProvider } from './src/hooks/opticians'
import { urlOpener } from './src/urlOpener'

const urlWeb = 'https://ln-2-master.d25810vot2j20.amplifyapp.com/'
// const urlWeb = 'https://staging.vispecs.com/'
// const urlWeb = 'http://localhost:19006/--/'
const url = 'vispecs://'
// const url = 'exp://192.168.0.210:19000/--/'

awsconfig.oauth.redirectSignIn = Platform.OS === 'web' ? urlWeb : url
awsconfig.oauth.redirectSignOut = Platform.OS === 'web' ? urlWeb : url
awsconfig.oauth.urlOpener = urlOpener
Amplify.configure(awsconfig)

I18n.setLanguage('de')
moment.locale('de-at')

if (Platform.OS !== 'web') LogBox.ignoreAllLogs(true)

enableScreens()

global.Buffer = global.Buffer || require('buffer').Buffer

function App (props) {
  return (
    <UserProvider
      googleSignIn={props.googleSignIn}
      facebookSignIn={props.facebookSignIn}
    >
      <MarkerProvider>
        <LegalProvider>
          <AssessmentProvider>
            <PassportProvider>
              <LensesEvaluationProvider>
                <GlassEvaluationProvider>
                  <OpticianProvider>
                    <PaperProvider theme={theme}>
                      <NavigationContainer
                        linking={linking}
                        // fallback={Platform.OS === 'web' ? <Landing /> : <Home />}
                        theme={theme}
                      >
                        <Router />
                      </NavigationContainer>
                    </PaperProvider>
                  </OpticianProvider>
                </GlassEvaluationProvider>
              </LensesEvaluationProvider>
            </PassportProvider>
          </AssessmentProvider>
        </LegalProvider>
      </MarkerProvider>
    </UserProvider>
  )
}

export default withOAuth(App)
