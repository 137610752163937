import React, { useState, useEffect } from 'react'
import { Image, View } from 'react-native'
import theme from '../../styles/theme'
import { scale, Subheading } from '../../scaling/ScaledUI'
import Slider from '@react-native-community/slider'

export default props => {
  const { style, options, input } = props
  const { onChange } = input

  const [step, setStep] = useState(1)
  const [containerWidth, setContainerWidth] = useState(0)
  const [ratio, setRatio] = useState(2 / 3)

  const handleSize = e => {
    setContainerWidth(e.nativeEvent.layout.width)
    const { width, height } = Image.resolveAssetSource(options[0].image)
    setRatio(height / width)
  }

  const handleSlidingComplete = step => {
    onChange(options[step].id)
  }

  useEffect(() => {
    onChange(options[step].id)
  }, [])

  return (
    <View style={style}>
      <View onLayout={handleSize}>
        <Image
          style={{
            width: containerWidth,
            height: containerWidth * ratio
          }}
          source={options[step].image}
        />
      </View>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          marginVertical: scale(8),
          marginHorizontal: scale(24)
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center'
          }}
        >
          <Subheading
            style={{
              color: theme.colors.primary,
              lineHeight: scale(60),
              alignSelf: 'center'
            }}
          >
            {options[step].text}
          </Subheading>
          {/* {options[step].price && (
            <Subheading style={{ marginLeft: scale(8), color: theme.colors.primary, lineHeight: scale(60) }}>
              +{options[step].price}
            </Subheading>)} */}
        </View>

        {/* <Divider /> */}

        <Slider
          style={{ flex: 1, height: scale(40), width: '100%' }}
          value={step}
          onValueChange={setStep}
          onSlidingComplete={handleSlidingComplete}
          minimumTrackTintColor={theme.colors.primary}
          // thumbImage={require('../../../assets/slider_hollow_100x100.png')}
          thumbTintColor={theme.colors.primary}
          minimumValue={0}
          maximumValue={options.length - 1}
          step={1}
        />
      </View>
    </View>
  )
}
