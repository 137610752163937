import React, { createContext, useContext, useState } from 'react'
import { useUser } from './index'
import { getAllAssessments } from '../../services/dbMethods'
import { formatAssessment } from '../../services/assessmentFormatter'
import { API, graphqlOperation } from '@aws-amplify/api'
import * as mutations from '../../graphql/mutations'
import * as queries from '../../graphql/queries'
import moment from 'moment'
import DeviceStore from '../../services/deviceStore'

const useProvideAssessment = () => {
  const { user, userAttributes } = useUser()

  const [glassAssessment, setGlassAssessment] = useState()
  const [lensesAssessment, setLensesAssessment] = useState()
  const [formattedGlassAssessment, setFormattedGlassAssessment] = useState()
  const [formattedLensesAssessment, setFormattedLensesAssessment] = useState()

  const fetchGlassAssessment = async () => {
    if (!user) {
      const glass = await DeviceStore.getItem('GLASSES')
      const a = JSON.parse(glass)?.input
      setGlassAssessment(a || null)
      setFormattedGlassAssessment(
        a ? formatAssessment(a, a.version, 'GLASSES') : null
      )
    } else {
      getAllAssessments(userAttributes?.sub).then(data => {
        const a = findLastAssessment(data, 'GLASSES')
        setGlassAssessment(a || null)
        setFormattedGlassAssessment(
          a ? formatAssessment(a, a.version, 'GLASSES') : null
        )
      })
    }
  }

  const fetchLensesAssessment = () => {
    return getAllAssessments(userAttributes?.sub).then(data => {
      const a = findLastAssessment(data, 'LENSES')
      setLensesAssessment(a || null)
      setFormattedLensesAssessment(
        a ? formatAssessment(a, a.version, 'LENSES') : null
      )
    })
  }

  const storeAssessmentLocally = (data, type, beginTimestamp, version) => {
    DeviceStore.setItem(
      type,
      JSON.stringify({
        input: {
          beginTimestamp: beginTimestamp,
          endTimestamp: moment().valueOf(),
          userId: userAttributes?.sub,
          assessmentData: JSON.stringify(data),
          type: type,
          version: version
        }
      })
    )
  }

  const storeAssessment = (data, type, beginTimestamp, version) => {
    if (!user) storeAssessmentLocally(data, type, beginTimestamp, version)
    else {
      return API.graphql(
        graphqlOperation(mutations.createAssessment, {
          input: {
            beginTimestamp: beginTimestamp,
            endTimestamp: moment().valueOf(),
            userId: userAttributes?.sub,
            assessmentData: JSON.stringify(data),
            type: type,
            version: version
          }
        })
      ).then(() => {
        fetchGlassAssessment()
        fetchLensesAssessment()
      })
    }
  }

  async function getLastAssessmentDatesLocal () {
    const result = []
    const glass = await DeviceStore.getItem('GLASSES')
    const frame = await DeviceStore.getItem('FRAME')
    const lense = await DeviceStore.getItem('LENSES')
    if (glass) result.GLASSES = JSON.parse(glass).input.endTimestamp
    if (frame) result.FRAME = JSON.parse(frame).input.endTimestamp
    if (lense) result.LENSES = JSON.parse(lense).input.endTimestamp
    return result
  }

  const getLastAssessmentDates = () => {
    return API.graphql(
      graphqlOperation(queries.listAssessments, {
        userId: userAttributes?.sub
      })
    ).then(({ data }) =>
      data.listAssessments.items.reduce((obj, item) => {
        obj[item.type] = item.endTimestamp
        return obj
      }, {})
    )
  }

  return {
    glassAssessment,
    lensesAssessment,
    formattedGlassAssessment,
    formattedLensesAssessment,
    fetchGlassAssessment,
    fetchLensesAssessment,
    storeAssessment,
    getLastAssessmentDates,
    getLastAssessmentDatesLocal
  }
}

// #region helper functions
function findLastAssessment (assessments, type) {
  let res = null
  assessments.map(a => {
    if (a.type === type) res = a
  })
  return res
}
// #endregion helper functions

const AssessmentContext = createContext()

export const AssessmentProvider = ({ children }) => {
  const value = useProvideAssessment()

  return (
    <AssessmentContext.Provider value={value}>
      {children}
    </AssessmentContext.Provider>
  )
}

export const useAssessment = () => useContext(AssessmentContext)
