import React, { useEffect, useState } from 'react'
import { Text } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useOptician } from '../../hooks/opticians'
import UserblockComponent from './Userblock.component'

export default () => {
  const { customers, fetchCustomers, selectCurrentCustomer } = useOptician()
  const [activeChat, setActiveChat] = useState()

  useEffect(() => {
    fetchCustomers()
  }, [])

  return (
    <ScrollView style={{ paddingRight: 8 }}>
      {customers && customers?.length > 0 ? (
        <>
          {customers.map((x, i) => (
            <UserblockComponent
              key={i}
              userObj={x}
              index={i}
              appearance={
                x?.userAttributes?.sub === activeChat ? 'selected' : 'regular'
              }
              onSelect={val => {
                selectCurrentCustomer(val)
                setActiveChat(val?.userAttributes?.sub)
              }}
              messageCount={i < 3 ? 3 - i : 0}
            />
          ))}
        </>
      ) : (
        <Text>Keine Kunden verfügbar</Text>
      )}
    </ScrollView>
  )
}
