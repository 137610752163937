import React from 'react'
import { Image, View, Text } from 'react-native'
import { useMediaQuery } from 'react-responsive'

export default ({ title, description, size }) => {
  const multiplier = size || useMediaQuery({ maxDeviceWidth: 600 }) ? 0.3 : 0.6
  return (
    <View
      style={{
        flexGrow: 1,
        flexDirection: 'column',
        width: '100%',
        paddingHorizontal: 20,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      {title && (
        <Text
          style={{
            marginTop: 20,
            marginBottom: 40,
            fontSize: 28,
            color: '#151515',
            fontWeight: '400',
            textAlign: 'center'
          }}
        >
          {title}
        </Text>
      )}
      <Image
        source={require('../../../assets/undraw/vispecs_optician_splash.png')}
        resizeMode='center'
        style={{
          width: 1241 * multiplier,
          height: 899 * multiplier,
          maxWidth: '98%'
        }}
      />
      {description && (
        <Text
          style={{
            marginTop: 30,
            marginBottom: 10,
            fontSize: 17,
            color: '#222'
          }}
        >
          {description}
        </Text>
      )}
    </View>
  )
}
