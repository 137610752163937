import React from 'react'
import ActionCard from '../../components/ActionCard'
import Background from '../../../assets/backgrounds/doctor-holding-pair-black-framed-glasses/2952500lr.jpg'
import { Paragraph } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'

export default props => {
  const navigation = useNavigation()

  const handlePress = () => {
    navigation.navigate('PassportUpdate')
  }

  return (
    <ActionCard
      title='Brillenpass'
      subtitle='Oder Kontaktlinsenpass'
      background={Background}
      handlePress={handlePress}
    >
      <Paragraph>
        Finden Sie alle Ihre optischen Daten von Brillen und Kontaktlinsen
      </Paragraph>
    </ActionCard>
  )
}
