export default [
  {
    storeID: '8921',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.156,
    longitude: 9.82,
    distance: 5329.543653688481,
    PHONE: '+43 5552 622050',
    address: 'Rathausgasse 1',
    POST_CODE: '6700',
    CITY: 'Bludenz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '09:00', UNTIL: '13:30' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:30' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:30' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:30' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:30' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:30' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'bludenz'
  },
  {
    storeID: '8780',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.27,
    longitude: 9.614,
    distance: 5338.35267647258,
    PHONE: '+43 5522 76441',
    address: 'K\u00f6nigshofstr. 79, Top EG 004',
    POST_CODE: '6800',
    CITY: 'Feldkirch',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'feldkirch'
  },
  {
    storeID: '8121',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.219,
    longitude: 10.735,
    distance: 5352.935083379169,
    PHONE: '+43 5412 64429',
    address: 'Industriezone 30',
    POST_CODE: '6460',
    CITY: 'Imst',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'imst'
  },
  {
    storeID: '8112',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.596,
    longitude: 13.836,
    distance: 5353.885097813733,
    PHONE: '+43 4242 34580',
    address: 'K\u00e4rntner Str. 34',
    POST_CODE: '9500',
    CITY: 'Villach',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'villach'
  },
  {
    storeID: '8135',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.83,
    longitude: 12.8,
    distance: 5354.201573418496,
    PHONE: '+43 4852 62065',
    address: 'Glocknerstr. 1a',
    POST_CODE: '9990',
    CITY: 'Nu\u00dfdorf-Debant',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'nussdorf-debant'
  },
  {
    storeID: '8789',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.413,
    longitude: 9.741,
    distance: 5356.00415546257,
    PHONE: '+43 5572 28430',
    address: 'Schulgasse 11',
    POST_CODE: '6850',
    CITY: 'Dornbirn',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'dornbirn'
  },
  {
    storeID: '8145',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.502,
    longitude: 9.746,
    distance: 5365.727530156607,
    PHONE: '+43 5574 43956',
    address: 'R\u00f6merstra\u00dfe 2',
    POST_CODE: '6900',
    CITY: 'Bregenz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'bregenz'
  },
  {
    storeID: '8107',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.798,
    longitude: 13.491,
    distance: 5366.852792736699,
    PHONE: '+43 4762 36862',
    address: 'Bahnhofstr. 16',
    POST_CODE: '9800',
    CITY: 'Spittal - Drau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'spittal-drau'
  },
  {
    storeID: '8052',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.615,
    longitude: 14.32,
    distance: 5367.9442759403755,
    PHONE: '+43 463 320991',
    address: 'S\u00fcdpark 1 - Top 14',
    POST_CODE: '9020',
    CITY: 'Klagenfurt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'klagenfurt'
  },
  {
    storeID: '8099',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.256,
    longitude: 11.326,
    distance: 5368.361390973145,
    PHONE: '+43 512 302330',
    address: 'Cytastrasse 1',
    POST_CODE: '6176',
    CITY: 'Voels-Cyta',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'voels-cyta'
  },
  {
    storeID: '8119',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.305,
    longitude: 11.07,
    distance: 5368.59778137956,
    PHONE: '+43 5262 61966',
    address: 'Wei\u00dfenbachgasse 9',
    POST_CODE: '6410',
    CITY: 'Telfs',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'telfs'
  },
  {
    storeID: '8094',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.625,
    longitude: 14.308,
    distance: 5368.689876492888,
    PHONE: '+43 463 509825',
    address: 'Kramergasse 9',
    POST_CODE: '9020',
    CITY: 'Klagenfurt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'klagenfurt'
  },
  {
    storeID: '8802',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.627,
    longitude: 14.31,
    distance: 5368.9502913046,
    PHONE: '+43 463 508489',
    address: 'St. Veiter Ring 20',
    POST_CODE: '9020',
    CITY: 'Klagenfurt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'klagenfurt'
  },
  {
    storeID: '8142',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.264,
    longitude: 11.374,
    distance: 5370.174457201629,
    PHONE: '+43 512 273719',
    address: 'H\u00f6ttinger Au 73 - Top 15-EG',
    POST_CODE: '6020',
    CITY: 'Innsbruck',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'innsbruck'
  },
  {
    storeID: '8062',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.266,
    longitude: 11.404,
    distance: 5370.987646329774,
    PHONE: '+43 512 567542',
    address: 'Museumstr. 38, 1. OG, Top 119',
    POST_CODE: '6020',
    CITY: 'Innsbruck',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'innsbruck'
  },
  {
    storeID: '8071',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.263,
    longitude: 11.431,
    distance: 5371.206141767369,
    PHONE: '+43 512 348804',
    address: 'Amraser Seestra\u00dfe 56a',
    POST_CODE: '6020',
    CITY: 'Innsbruck',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'innsbruck'
  },
  {
    storeID: '8716',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 46.725,
    longitude: 14.093,
    distance: 5373.802998668011,
    PHONE: '+43 4276 29957',
    address: '10.-Oktoberstr. 12',
    POST_CODE: '9560',
    CITY: 'Feldkirchen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'feldkirchen'
  },
  {
    storeID: '8717',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 46.66,
    longitude: 14.634,
    distance: 5380.682227410685,
    PHONE: '+43 4232 51504',
    address: 'Hauptplatz 26',
    POST_CODE: '9100',
    CITY: 'V\u00f6lkermarkt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'voelkermarkt'
  },
  {
    storeID: '8113',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.769,
    longitude: 14.36,
    distance: 5385.111514952655,
    PHONE: '+43 4212 28810',
    address: 'Unterer Platz 11',
    POST_CODE: '9300',
    CITY: 'St. Veit - Glan',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'st-veit-glan'
  },
  {
    storeID: '8737',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.349,
    longitude: 11.697,
    distance: 5385.79750322208,
    PHONE: '+43  524264677',
    address: 'An der Leiten 15 - O 03',
    POST_CODE: '6134',
    CITY: 'Vomp',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'vomp'
  },
  {
    storeID: '8732',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 46.87,
    longitude: 14.468,
    distance: 5398.435276321575,
    PHONE: '+43 4262 27663',
    address: 'Kreuzstr. 23',
    POST_CODE: '9330',
    CITY: 'Althofen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'althofen'
  },
  {
    storeID: '8085',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.84,
    longitude: 14.841,
    distance: 5404.864236152344,
    PHONE: '+43 4352 54715',
    address: 'Bamberger Str. 11',
    POST_CODE: '9400',
    CITY: 'Wolfsberg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wolfsberg'
  },
  {
    storeID: '8118',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.322,
    longitude: 12.797,
    distance: 5406.38549466282,
    PHONE: '+43 6542 72323',
    address: 'Bahnhofstr. 15',
    POST_CODE: '5700',
    CITY: 'Zell - See',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'zell-see'
  },
  {
    storeID: '8123',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.489,
    longitude: 12.07,
    distance: 5408.476946587325,
    PHONE: '+43 5332 74004',
    address: 'Salzburger Str. 32',
    POST_CODE: '6300',
    CITY: 'W\u00f6rgl',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'woergl'
  },
  {
    storeID: '8725',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.128,
    longitude: 13.812,
    distance: 5409.356375643138,
    PHONE: '+43 6474 20339',
    address: 'Kuenburgstra\u00dfe 8',
    POST_CODE: '5580',
    CITY: 'Tamsweg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'tamsweg'
  },
  {
    storeID: '8702',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 46.819,
    longitude: 15.228,
    distance: 5412.84766568419,
    PHONE: '+43 3462 30552',
    address: 'Frauentaler Stra\u00dfe 77',
    POST_CODE: '8530',
    CITY: 'Deutschlandsberg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'deutschlandsberg'
  },
  {
    storeID: '8110',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.782,
    longitude: 15.539,
    distance: 5417.3576044239935,
    PHONE: '+43 3452 73662',
    address: 'Hauptplatz 12',
    POST_CODE: '8430',
    CITY: 'Leibnitz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'leibnitz'
  },
  {
    storeID: '8116',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.585,
    longitude: 12.172,
    distance: 5420.866453130344,
    PHONE: '+43 5372 62240',
    address: 'Kaiserbergstr. 5',
    POST_CODE: '6330',
    CITY: 'Kufstein',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '16:00' }] }
      ]
    },
    CITY_URL: 'kufstein'
  },
  {
    storeID: '8120',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.416,
    longitude: 13.218,
    distance: 5425.855367367509,
    PHONE: '+43 6462 20422',
    address: 'Bahnhofstr. 7 + 7a',
    POST_CODE: '5500',
    CITY: 'Bischofshofen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'bischofshofen'
  },
  {
    storeID: '8724',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 46.974,
    longitude: 15.335,
    distance: 5431.83905623302,
    PHONE: '+43 3136 61527',
    address: 'Packerstr. 111',
    POST_CODE: '8501',
    CITY: 'Lieboch',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '08:30', UNTIL: '13:00' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '08:30', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:30' }] }
      ]
    },
    CITY_URL: 'lieboch'
  },
  {
    storeID: '8721',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.393,
    longitude: 13.693,
    distance: 5434.472595362052,
    PHONE: '+43 3687 23722',
    address: 'Coburgstr. 53',
    POST_CODE: '8970',
    CITY: 'Schladming',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '09:00', UNTIL: '13:00' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'schladming'
  },
  {
    storeID: '8712',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.054,
    longitude: 15.13,
    distance: 5434.753824906438,
    PHONE: '+43 3142 28992',
    address: 'Packer Stra\u00dfe 18',
    POST_CODE: '8582',
    CITY: 'Rosental - Baernbach',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'rosental-baernbach'
  },
  {
    storeID: '8918',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.184,
    longitude: 14.679,
    distance: 5436.689999732237,
    PHONE: '+43 3572 82847',
    address: 'Arena am Waldfeld Nr. 13',
    POST_CODE: '8753',
    CITY: 'Fohnsdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'fohnsdorf'
  },
  {
    storeID: '8097',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.034,
    longitude: 15.416,
    distance: 5440.24551066843,
    PHONE: '+43 316 292955',
    address: 'Weblinger Guertel 25',
    POST_CODE: '8054',
    CITY: 'Graz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'graz'
  },
  {
    storeID: '8111',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.041,
    longitude: 15.464,
    distance: 5442.258172848614,
    PHONE: '+43 316 483321',
    address: 'Ostbahnstr. 3',
    POST_CODE: '8041',
    CITY: 'Graz-Liebenau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'graz-liebenau'
  },
  {
    storeID: '8200',
    NAME: 'Pearle \u00d6sterreich Solaris GmbH',
    latitude: 47.041,
    longitude: 15.464,
    distance: 5442.258172848614,
    PHONE: '+43 316 424169',
    address: 'Ostbahnstra\u00dfe 3',
    POST_CODE: '8041',
    CITY: 'Graz-Liebenau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'graz-liebenau'
  },
  {
    storeID: '8098',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.06,
    longitude: 15.427,
    distance: 5443.244752744984,
    PHONE: '+43 316 760860',
    address: 'Lazarettg\u00fcrtel 55',
    POST_CODE: '8020',
    CITY: 'Graz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'graz'
  },
  {
    storeID: '8711',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.071,
    longitude: 15.429,
    distance: 5444.442716563789,
    PHONE: '+43 316 711876',
    address: 'Annenstr. 17',
    POST_CODE: '8020',
    CITY: 'Graz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:30' }] }
      ]
    },
    CITY_URL: 'graz'
  },
  {
    storeID: '8115',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 46.952,
    longitude: 15.89,
    distance: 5444.594389700034,
    PHONE: '+43 3152 23342',
    address: 'Ungarstr. 2-4',
    POST_CODE: '8330',
    CITY: 'Feldbach',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'feldbach'
  },
  {
    storeID: '8064',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.071,
    longitude: 15.438,
    distance: 5444.683189794366,
    PHONE: '+43 316 830345',
    address: 'Hauptplatz 8',
    POST_CODE: '8010',
    CITY: 'Graz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'graz'
  },
  {
    storeID: '8114',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.105,
    longitude: 15.398,
    distance: 5447.15411282461,
    PHONE: '+43 316 672008',
    address: 'Wiener Str. 351',
    POST_CODE: '8051',
    CITY: 'Graz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'graz'
  },
  {
    storeID: '8134',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.685,
    longitude: 13.105,
    distance: 5451.80713390895,
    PHONE: '+43 6245 72396',
    address: 'Kletzlgutweg 2b',
    POST_CODE: '5400',
    CITY: 'Hallein',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'hallein'
  },
  {
    storeID: '8708',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.107,
    longitude: 15.706,
    distance: 5455.639988977691,
    PHONE: '+43 3112 51884',
    address: 'Ludwig-Binder Str. 26',
    POST_CODE: '8200',
    CITY: 'Gleisdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'gleisdorf'
  },
  {
    storeID: '8705',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.05,
    longitude: 16.079,
    distance: 5459.96792715263,
    PHONE: '+43 3382 53127',
    address: 'Hauptstr. 12',
    POST_CODE: '8280',
    CITY: 'Fuerstenfeld',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:30' }] }
      ]
    },
    CITY_URL: 'fuerstenfeld'
  },
  {
    storeID: '8083',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.817,
    longitude: 13.009,
    distance: 5463.6677304928735,
    PHONE: '+43 662 854958',
    address: 'Europastr. 1',
    POST_CODE: '5020',
    CITY: 'Salzburg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'salzburg'
  },
  {
    storeID: '8138',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.814,
    longitude: 13.045,
    distance: 5464.150491026104,
    PHONE: '+43 662 454234',
    address: 'S\u00fcdtiroler Platz 11,Top 15 A',
    POST_CODE: '5020',
    CITY: 'Salzburg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'salzburg'
  },
  {
    storeID: '8719',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.218,
    longitude: 15.625,
    distance: 5464.980795508043,
    PHONE: '+43 3172 38048',
    address: 'Schulgasse 8',
    POST_CODE: '8160',
    CITY: 'Weiz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'weiz'
  },
  {
    storeID: '8801',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.562,
    longitude: 14.245,
    distance: 5465.543954662782,
    PHONE: '+43 3612 30263',
    address: 'Salzburger Str. 1, Top 07',
    POST_CODE: '8940',
    CITY: 'Liezen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'liezen'
  },
  {
    storeID: '8738',
    NAME: 'Pearle Optik Franchise Partner',
    latitude: 47.714,
    longitude: 13.624,
    distance: 5466.755372666456,
    PHONE: '+43 6132 23305',
    address: 'G\u00f6tzstrasse 3',
    POST_CODE: '4820',
    CITY: 'Bad Ischl',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'bad_ischl'
  },
  {
    storeID: '8076',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.381,
    longitude: 15.094,
    distance: 5467.9390937663065,
    PHONE: '+43 3842 44128',
    address: 'Timmersdorfergasse 8',
    POST_CODE: '8700',
    CITY: 'Leoben',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'leoben'
  },
  {
    storeID: '8141',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.861,
    longitude: 13.126,
    distance: 5470.946175591158,
    PHONE: '+43 6225 20511',
    address: 'R\u00f6merstr. 4b',
    POST_CODE: '5301',
    CITY: 'Eugendorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'eugendorf'
  },
  {
    storeID: '8701',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.41,
    longitude: 15.271,
    distance: 5475.573386389368,
    PHONE: '+43 3862 55620',
    address: 'Mittergasse 11 - 15',
    POST_CODE: '8600',
    CITY: 'Bruck - Mur',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '09:00', UNTIL: '12:00' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '09:00', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'bruck-mur'
  },
  {
    storeID: '8718',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.281,
    longitude: 15.991,
    distance: 5481.455191860656,
    PHONE: '+43 3332 61330',
    address: 'Im Hatric 2',
    POST_CODE: '8230',
    CITY: 'Hartberg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'hartberg'
  },
  {
    storeID: '8723',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.467,
    longitude: 15.343,
    distance: 5483.400790324267,
    PHONE: '+43 3862 31337',
    address: 'Siegfried-Marcus-Str. 7a, 11',
    POST_CODE: '8605',
    CITY: 'Kapfenberg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'kapfenberg'
  },
  {
    storeID: '8715',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.287,
    longitude: 16.188,
    distance: 5487.506401739772,
    PHONE: '+43 3352 31854',
    address: 'Europastr. 1',
    POST_CODE: '7400',
    CITY: 'Oberwart',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'oberwart'
  },
  {
    storeID: '8106',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.926,
    longitude: 13.79,
    distance: 5493.02185922936,
    PHONE: '+43 7612 64135',
    address: 'Druckereistra\u00dfe 3-30',
    POST_CODE: '4810',
    CITY: 'Gmunden',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'gmunden'
  },
  {
    storeID: '8727',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.9,
    longitude: 14.127,
    distance: 5498.257099481372,
    PHONE: '+43 7582 20417',
    address: 'Am Anger 1/1',
    POST_CODE: '4560',
    CITY: 'Kirchdorf an der Krems',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '09:00', UNTIL: '13:00' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'kirchdorf-an-der-krems'
  },
  {
    storeID: '8129',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.003,
    longitude: 13.669,
    distance: 5498.340009314471,
    PHONE: '+43 7672 20721',
    address: 'Linzer Str. 50',
    POST_CODE: '4840',
    CITY: 'Voecklabruck',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'voecklabruck'
  },
  {
    storeID: '8781',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.007,
    longitude: 13.653,
    distance: 5498.392755910112,
    PHONE: '+43 7672 78880',
    address: 'Salzburger Strasse 2',
    POST_CODE: '4840',
    CITY: 'Voecklabruck',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'voecklabruck'
  },
  {
    storeID: '8736',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.007,
    longitude: 13.92,
    distance: 5504.619896960095,
    PHONE: '+43 7614 52219',
    address: 'Lambacher Stra\u00dfe 32',
    POST_CODE: '4655',
    CITY: 'Vorchdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'vorchdorf'
  },
  {
    storeID: '8124',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.251,
    longitude: 13.046,
    distance: 5510.564318016238,
    PHONE: '+43 7722 65151',
    address: 'Erlachweg 13',
    POST_CODE: '5280',
    CITY: 'Braunau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'braunau'
  },
  {
    storeID: '8140',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.207,
    longitude: 13.486,
    distance: 5515.723004486684,
    PHONE: '+43 7752 70223',
    address: 'Weberzeile 1',
    POST_CODE: '4910',
    CITY: 'Ried',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] }
      ]
    },
    CITY_URL: 'ried'
  },
  {
    storeID: '8722',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.5,
    longitude: 16.506,
    distance: 5518.369602619672,
    PHONE: '+43 2612 43705',
    address: 'Hauptstra\u00dfe 33',
    POST_CODE: '7350',
    CITY: 'Oberpullendorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '08:30', UNTIL: '12:30' },
          { FROM: '13:30', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:30' },
            { FROM: '13:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:30' },
            { FROM: '13:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:30' },
            { FROM: '13:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:30' },
            { FROM: '13:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:30' },
            { FROM: '13:30', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:30' }] }
      ]
    },
    CITY_URL: 'oberpullendorf'
  },
  {
    storeID: '8752',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.146,
    longitude: 13.972,
    distance: 5520.504039865108,
    PHONE: '+43 7242 207971',
    address: 'Gunskirchener Stra\u00dfe 7',
    POST_CODE: '4600',
    CITY: 'Wels',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wels'
  },
  {
    storeID: '8704',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.676,
    longitude: 15.936,
    distance: 5520.905635616781,
    PHONE: '+43 2662 40887',
    address: 'Hauptstrasse 26',
    POST_CODE: '2640',
    CITY: 'Gloggnitz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '08:30', UNTIL: '12:00' },
          { FROM: '14:00', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '08:30', UNTIL: '12:00' },
            { FROM: '14:00', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'gloggnitz'
  },
  {
    storeID: '8147',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.048,
    longitude: 14.422,
    distance: 5520.941599475826,
    PHONE: '+43 7252 73400',
    address: 'Anton-Plochberger-Stra\u00dfe',
    POST_CODE: '4400',
    CITY: 'Steyr',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] }
      ]
    },
    CITY_URL: 'steyr'
  },
  {
    storeID: '8137',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.16,
    longitude: 14.025,
    distance: 5523.228065828274,
    PHONE: '+43 7242 224172',
    address: 'B\u00e4ckergasse 20',
    POST_CODE: '4600',
    CITY: 'Wels',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wels'
  },
  {
    storeID: '8086',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.721,
    longitude: 16.081,
    distance: 5529.495202961902,
    PHONE: '+43 2635 65522',
    address: 'Hauptplatz 4',
    POST_CODE: '2620',
    CITY: 'Neunkirchen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'neunkirchen'
  },
  {
    storeID: '8109',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.196,
    longitude: 14.247,
    distance: 5532.290466917513,
    PHONE: '+43 7229 79159',
    address: 'IKEA Platz 8',
    POST_CODE: '4053',
    CITY: 'Ansfelden-Haid',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'ansfelden-haid'
  },
  {
    storeID: '8078',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.243,
    longitude: 14.235,
    distance: 5536.951475597572,
    PHONE: '+43 7229 71029',
    address: 'Pluskaufstra\u00dfe 7',
    POST_CODE: '4061',
    CITY: 'Pasching',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:30', UNTIL: '21:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:30', UNTIL: '21:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'pasching'
  },
  {
    storeID: '8079',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.121,
    longitude: 14.869,
    distance: 5539.602191386071,
    PHONE: '+43 7472 68259',
    address: 'Waidhofner Str. 1',
    POST_CODE: '3300',
    CITY: 'Amstetten',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'amstetten'
  },
  {
    storeID: '8150',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.448,
    longitude: 13.439,
    distance: 5540.181993727407,
    PHONE: '+43 7712 606810',
    address: 'Linzer Stra\u00dfe 55',
    POST_CODE: '4780',
    CITY: 'Schaerding',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '10:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: null
  },
  {
    storeID: '8713',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.748,
    longitude: 16.421,
    distance: 5541.6072109304205,
    PHONE: '+43 2626 63600',
    address: 'Arenaplatz 1/1',
    POST_CODE: '7210',
    CITY: 'Mattersburg-Walbersdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'mattersburg'
  },
  {
    storeID: '8139',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.819,
    longitude: 16.217,
    distance: 5543.345622230644,
    PHONE: '+43 2622 26892',
    address: 'Zehnerg\u00fcrtel 12-24, Top 112',
    POST_CODE: '2700',
    CITY: 'Wiener Neustadt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wiener-neustadt'
  },
  {
    storeID: '8103',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.815,
    longitude: 16.244,
    distance: 5543.668795410508,
    PHONE: '+43 2622 81579',
    address: 'Wiener Strasse 18',
    POST_CODE: '2700',
    CITY: 'Wiener Neustadt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wiener-neustadt'
  },
  {
    storeID: '8069',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.304,
    longitude: 14.288,
    distance: 5544.636767775892,
    PHONE: '+43 732 771830',
    address: 'Schmidtorstra\u00dfe 7',
    POST_CODE: '4020',
    CITY: 'Linz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'linz'
  },
  {
    storeID: '8068',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.301,
    longitude: 14.319,
    distance: 5545.062043807931,
    PHONE: '+43 732 771155',
    address: 'Industriezeile 76',
    POST_CODE: '4020',
    CITY: 'Linz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'linz'
  },
  {
    storeID: '8149',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.315,
    longitude: 14.283,
    distance: 5545.675118871346,
    PHONE: '+43 732 711162',
    address: 'Hauptstra\u00dfe 54',
    POST_CODE: '4040',
    CITY: 'Linz',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'linz'
  },
  {
    storeID: '8730',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.25,
    longitude: 14.635,
    distance: 5547.348136709685,
    PHONE: '+43 7262 52800',
    address: 'Hauptplatz 10',
    POST_CODE: '4320',
    CITY: 'Perg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [
          { FROM: '09:00', UNTIL: '13:00' },
          { FROM: '14:30', UNTIL: '18:00' }
        ]
      },
      weekdayOpenings: [
        {
          DAY: 'Montag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Dienstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Mittwoch',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Donnerstag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:30', UNTIL: '18:00' }
          ]
        },
        {
          DAY: 'Freitag',
          SLOTS: [
            { FROM: '09:00', UNTIL: '13:00' },
            { FROM: '14:30', UNTIL: '18:00' }
          ]
        },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'perg'
  },
  {
    storeID: '8734',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.132,
    longitude: 15.139,
    distance: 5547.540001627076,
    PHONE: '+43 7416 53144',
    address: 'Wiener Stra\u00dfe 3',
    POST_CODE: '3250',
    CITY: 'Wieselburg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wieselburg'
  },
  {
    storeID: '8077',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 47.827,
    longitude: 16.523,
    distance: 5552.590882991795,
    PHONE: '+43 2682 61486',
    address: 'Haid\u00e4cker-Park 2',
    POST_CODE: '7000',
    CITY: 'Eisenstadt',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'eisenstadt'
  },
  {
    storeID: '8728',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.93,
    longitude: 16.204,
    distance: 5554.483106282515,
    PHONE: '+43 2256 20220',
    address: 'Europastra\u00dfe 5, Top B7c',
    POST_CODE: '2544',
    CITY: 'Leobersdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'leobersdorf'
  },
  {
    storeID: '8104',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.009,
    longitude: 16.235,
    distance: 5563.504211740629,
    PHONE: '+43 2252 252883',
    address: 'Pfarrgasse 4',
    POST_CODE: '2500',
    CITY: 'Baden',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'baden'
  },
  {
    storeID: '8739',
    NAME: 'Pearle- Optik Angermann',
    latitude: 48.573,
    longitude: 13.989,
    distance: 5565.947289935288,
    PHONE: '+43 7289 802050',
    address: 'Hanrieder Stra\u00dfe 8, Top 3',
    POST_CODE: '4150',
    CITY: 'Rohrbach',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'rohrbach'
  },
  {
    storeID: '8065',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.205,
    longitude: 15.624,
    distance: 5567.612202810249,
    PHONE: '+43 2742 353222',
    address: 'Kremser Gasse 15',
    POST_CODE: '3100',
    CITY: 'St. Poelten',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'st-poelten'
  },
  {
    storeID: '8101',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.206,
    longitude: 15.624,
    distance: 5567.716314231979,
    PHONE: '+43 2742 21123',
    address: 'Kremser Gasse 31',
    POST_CODE: '3100',
    CITY: 'St. Poelten',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'st-poelten'
  },
  {
    storeID: '8144',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.223,
    longitude: 15.637,
    distance: 5569.824525561675,
    PHONE: '+43 2742 31445',
    address: 'Dr. A. Sch\u00e4rf Str.5 Top S 108D',
    POST_CODE: '3107',
    CITY: 'St. P\u00f6lten',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'st-poelten'
  },
  {
    storeID: '8087',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.086,
    longitude: 16.283,
    distance: 5572.777880234141,
    PHONE: '+43 2236 46558',
    address: 'Schrannenplatz 3',
    POST_CODE: '2340',
    CITY: 'Moedling',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'moedling'
  },
  {
    storeID: '8102',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.108,
    longitude: 16.318,
    distance: 5576.005247090089,
    PHONE: '+43 1 6991021',
    address: 'Water PLaza',
    POST_CODE: '2334',
    CITY: 'Voesendorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'voesendorf'
  },
  {
    storeID: '8729',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 47.972,
    longitude: 16.841,
    distance: 5576.418722085898,
    PHONE: '+43 2167 20531',
    address: 'Altenburgerstr. 20 Top 2',
    POST_CODE: '7100',
    CITY: 'Neusiedl am See',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'neusiedl-am-see'
  },
  {
    storeID: '8128',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.138,
    longitude: 16.278,
    distance: 5578.0250565996985,
    PHONE: '+43 1 8672977',
    address: 'Breitenfurterstr. 372',
    POST_CODE: '1230',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8122',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.035,
    longitude: 16.81,
    distance: 5582.038146221361,
    PHONE: '+43 2162 62267',
    address: 'Eco Plus Park 4',
    POST_CODE: '2460',
    CITY: 'Bruck - Leitha',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'bruck-leitha'
  },
  {
    storeID: '8148',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.208,
    longitude: 16.217,
    distance: 5583.626497929956,
    PHONE: '+43 1 9794604',
    address: 'Albert-Schweitzer Gasse 6',
    POST_CODE: '1140',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8060',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.18,
    longitude: 16.33,
    distance: 5583.78178624044,
    PHONE: '+43 1 8138674',
    address: 'Meidlinger Hauptstr. 32',
    POST_CODE: '1120',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8915',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.143,
    longitude: 16.473,
    distance: 5583.852612918246,
    PHONE: '+43 1 7073545',
    address: 'Wiener Strasse 21',
    POST_CODE: '2320',
    CITY: 'Schwechat',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'schwechat'
  },
  {
    storeID: '8055',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.179,
    longitude: 16.376,
    distance: 5584.927845012969,
    PHONE: '+43 1 6043782',
    address: 'Keplerplatz 11',
    POST_CODE: '1100',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8059',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.186,
    longitude: 16.353,
    distance: 5585.026923751668,
    PHONE: '+43 1 5457712',
    address: 'Reinprechtsdorfer Str. 35-37',
    POST_CODE: '1050',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8909',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.199,
    longitude: 16.317,
    distance: 5585.395718216419,
    PHONE: '+43 1 9825218',
    address: 'H\u00fctteldorfer Strasse 93',
    POST_CODE: '1150',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8051',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.196,
    longitude: 16.341,
    distance: 5585.735912750999,
    PHONE: '+43 1 5238214',
    address: 'Mariahilfer Str.  124',
    POST_CODE: '1070',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8092',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.179,
    longitude: 16.412,
    distance: 5585.907990334757,
    PHONE: '+43 1 7498801',
    address: 'Simmeringer Hauptstr. 55-57',
    POST_CODE: '1110',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8806',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.203,
    longitude: 16.335,
    distance: 5586.297547996437,
    PHONE: '+43 1 7866458',
    address: 'Gablenzgasse 5-13',
    POST_CODE: '1150',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '21:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8050',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.169,
    longitude: 16.472,
    distance: 5586.512380036974,
    PHONE: '+43 1 7692732',
    address: 'Landwehrstra\u00dfe 6',
    POST_CODE: '1110',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8905',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.209,
    longitude: 16.337,
    distance: 5586.972685544803,
    PHONE: '+43 1 4920706',
    address: 'Thaliastrasse 9',
    POST_CODE: '1160',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8143',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.22,
    longitude: 16.307,
    distance: 5587.298625146865,
    PHONE: '+43 1 4802775',
    address: 'Sandleitengasse 41',
    POST_CODE: '1160',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8902',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.211,
    longitude: 16.345,
    distance: 5587.39659195274,
    PHONE: '+43 1 4060724',
    address: 'Josefstaedter Strasse 62',
    POST_CODE: '1080',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8108',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.199,
    longitude: 16.395,
    distance: 5587.513464945117,
    PHONE: '+43 1 7150431',
    address: 'Landstrasser Hauptstrasse 99',
    POST_CODE: '1030',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8056',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.209,
    longitude: 16.373,
    distance: 5587.949729823734,
    PHONE: '+43 1 5332090',
    address: 'Rotenturmstr. 2',
    POST_CODE: '1010',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:30', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8903',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.218,
    longitude: 16.359,
    distance: 5588.50072792416,
    PHONE: '+43 1 3179189',
    address: 'Waehringer Strasse 20',
    POST_CODE: '1090',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8084',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.215,
    longitude: 16.38,
    distance: 5588.760619777181,
    PHONE: '+43 1 2145570',
    address: 'Taborstr. 17 b',
    POST_CODE: '1020',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8057',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.225,
    longitude: 16.361,
    distance: 5589.2792684078195,
    PHONE: '+43 1 3198001',
    address: 'Julius-Tandler-Platz 11',
    POST_CODE: '1090',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8105',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.209,
    longitude: 16.424,
    distance: 5589.33724226379,
    PHONE: '+43 1 7265291',
    address: 'Olympiaplatz 2',
    POST_CODE: '1020',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8075',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.416,
    longitude: 15.622,
    distance: 5589.53244453374,
    PHONE: '+43 2732 76232',
    address: 'Wiener Str. 96 - 102',
    POST_CODE: '3500',
    CITY: 'Krems',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'krems'
  },
  {
    storeID: '8906',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.237,
    longitude: 16.349,
    distance: 5590.195503411871,
    PHONE: '+43 1 3695411',
    address: 'Billrothstrasse 20',
    POST_CODE: '1190',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '12:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8807',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.24,
    longitude: 16.387,
    distance: 5591.536962268186,
    PHONE: '+43 1 3745818',
    address: 'Handelskai 94-96',
    POST_CODE: '1200',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8126',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.331,
    longitude: 16.051,
    distance: 5591.9383436168255,
    PHONE: '+43 2272 64520',
    address: 'Hauptplatz 9',
    POST_CODE: '3430',
    CITY: 'Tulln',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'tulln'
  },
  {
    storeID: '8805',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.242,
    longitude: 16.436,
    distance: 5593.076469109243,
    PHONE: '+43 1 2023604',
    address: 'Wagramer Str. 83-85',
    POST_CODE: '1220',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8058',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.257,
    longitude: 16.399,
    distance: 5593.62144173268,
    PHONE: '+43 1 2707062',
    address: 'Franz Jonas Platz 7',
    POST_CODE: '1210',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8095',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.268,
    longitude: 16.398,
    distance: 5594.732136125092,
    PHONE: '+43 1 2719138',
    address: 'Ignaz K\u00f6ck Stra\u00dfe 1-7',
    POST_CODE: '1210',
    CITY: 'Wien',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'wien'
  },
  {
    storeID: '8726',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.149,
    longitude: 16.946,
    distance: 5597.589590864797,
    PHONE: '+43 2165 52018',
    address: 'Pressburger Reichsstr. 1',
    POST_CODE: '2410',
    CITY: 'Hainburg an der Donau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'hainburg-an-der-donau'
  },
  {
    storeID: '8125',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.602,
    longitude: 15.187,
    distance: 5597.880376822952,
    PHONE: '+43 2822 33317',
    address: 'Andre-Freyskorn-Str. 4',
    POST_CODE: '3910',
    CITY: 'Zwettl',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'zwettl'
  },
  {
    storeID: '8733',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.345,
    longitude: 16.333,
    distance: 5600.9406440296625,
    PHONE: '+43 2262 62830',
    address: 'Hauptplatz 33',
    POST_CODE: '2100',
    CITY: 'Korneuburg',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'korneuburg'
  },
  {
    storeID: '8804',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.385,
    longitude: 16.214,
    distance: 5601.8852368667995,
    PHONE: '+43 2266 65119',
    address: 'Hauptstr. 38-42',
    POST_CODE: '2000',
    CITY: 'Stockerau',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '13:00' }] }
      ]
    },
    CITY_URL: 'stockerau'
  },
  {
    storeID: '8136',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.343,
    longitude: 16.464,
    distance: 5604.281864851947,
    PHONE: '+43 2246 27776',
    address: 'G3 Platz 1',
    POST_CODE: '2201',
    CITY: 'Gerasdorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:30', UNTIL: '19:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:30', UNTIL: '20:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:30', UNTIL: '20:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'gerasdorf'
  },
  {
    storeID: '8132',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.765,
    longitude: 14.977,
    distance: 5609.733732773679,
    PHONE: '+43 2852 20659',
    address: 'Emerich-Berger Stra\u00dfe 15',
    POST_CODE: '3950',
    CITY: 'Gm\u00fcnd',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'gmuend'
  },
  {
    storeID: '8735',
    NAME: 'Pearle Optik Franchise-Partner',
    latitude: 48.341,
    longitude: 16.723,
    distance: 5611.166973619387,
    PHONE: '+43 2282 60728',
    address: 'Bahnstra\u00dfe 17',
    POST_CODE: '2230',
    CITY: 'G\u00e4nserndorf',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '12:30' }] }
      ]
    },
    CITY_URL: 'gaenserndorf'
  },
  {
    storeID: '8049',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.667,
    longitude: 15.634,
    distance: 5615.989201936885,
    PHONE: '+43 2982 20416',
    address: 'Am Kuhberg 5',
    POST_CODE: '3580',
    CITY: 'Frauenhofen',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '19:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'frauenhofen'
  },
  {
    storeID: '8133',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.569,
    longitude: 16.079,
    distance: 5617.378882330357,
    PHONE: '+43 2952 34873',
    address: 'M\u00fchlenring 38',
    POST_CODE: '2020',
    CITY: 'Hollabrunn',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '09:00', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'hollabrunn'
  },
  {
    storeID: '8146',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.812,
    longitude: 15.276,
    distance: 5622.059252857134,
    PHONE: '+43  284220639',
    address: 'Thayaparkstra\u00dfe 2a, Shop 3',
    POST_CODE: '3830',
    CITY: 'Waidhofen an der Thaya',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '09:00', UNTIL: '18:30' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] }
      ]
    },
    CITY_URL: 'waidhofen-an-der-thaya'
  },
  {
    storeID: '8131',
    NAME: 'Pearle \u00d6sterreich GmbH',
    latitude: 48.569,
    longitude: 16.572,
    distance: 5630.578582459229,
    PHONE: '+43 2572 32988',
    address: 'Hauptplatz 32',
    POST_CODE: '2130',
    CITY: 'Mistelbach',
    OPENING_HOURS: {
      openNow: false,
      openingTimesToday: {
        DAY: 'Dienstag',
        SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }]
      },
      weekdayOpenings: [
        { DAY: 'Montag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Dienstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Mittwoch', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Donnerstag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Freitag', SLOTS: [{ FROM: '08:30', UNTIL: '18:00' }] },
        { DAY: 'Samstag', SLOTS: [{ FROM: '08:30', UNTIL: '17:00' }] }
      ]
    },
    CITY_URL: 'mistelbach'
  }
]
