import React, { useState } from 'react'
import { TextInput, Text } from '../../scaling/ScaledUI'
import moment from 'moment'

export default props => {
  const { style, input, ...inputProps } = props
  const { value, onChange } = input

  const [message, setMessage] = useState(null)

  return (
    <>
      <TextInput
        style={style}
        value={isNaN(value) ? '' : value.toString()}
        onChangeText={v => {
          onChange(parseInt(v))
          if (parseInt(v) !== undefined || parseInt(v) !== null) {
            const limit = parseInt(
              moment()
                .subtract(14, 'years')
                .format('YYYY')
            )
            const birthYear = parseInt(v)
            if (birthYear < limit) setMessage(null)
            else {
              setMessage(
                `Um diese App verwenden zu dürfen, müssen Sie vor ${limit} geboren sein.`
              )
            }
          }
        }}
        keyboardType='number-pad'
        {...inputProps}
      />
      {message !== null && <Text style={{ paddingTop: 6 }}>{message}</Text>}
    </>
  )
}
