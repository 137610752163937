import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, ScrollView } from 'react-native'
import { Button, Divider, Portal, Searchbar } from 'react-native-paper'
import Fuse from 'fuse.js'
import theme from '../../styles/theme'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useOptician } from '../../hooks/opticians'
import RegisterModal from './RegisterModal'
import CustomerListItem from './Common/CustomerListItem'
import { useUser } from '../../hooks/user'

// eslint-disable-next-line no-unused-vars
export default ({ style, setCollapsed, navigate }) => {
  const [foundCustomers, setFoundCustomers] = useState([])
  const { customers, fetchCustomers } = useOptician()
  const [registerFormVisible, setRegisterFormVisible] = useState(false)
  const { userAttributes } = useUser()
  let searchRef = React.createRef()

  useEffect(() => {
    fetchCustomers()
  }, [])

  const customerSearch = new Fuse(customers, {
    keys: [
      'username',
      'userAttributes.name',
      'userAttributes.phone_number',
      'userAttributes.email'
    ]
  })

  const searchCustomer = data => {
    if (!customers || !data || data.length <= 0) return setFoundCustomers([])
    let result = []
    customerSearch.search(data).map(x => {
      // filter corrupted users and opticians
      if (
        x.item &&
        x.item.userAttributes &&
        x.item.userAttributes.name &&
        !x.item.userAttributes.name.includes('optician')
      )
        result.push(x)
    })
    setFoundCustomers(result)
  }

  return (
    <>
      <Portal>
        <RegisterModal
          visible={registerFormVisible}
          onDismiss={() => setRegisterFormVisible(false)}
        />
      </Portal>
      <View style={{ ...style }}>
        <PaperProvider
          theme={{
            ...DefaultTheme,
            colors: { ...DefaultTheme.colors, primary: '#fff' }
          }}
        >
          <Searchbar
            ref={c => {
              searchRef = c
            }}
            style={{
              shadowOpacity: 0.0,
              backgroundColor: theme.colors.background,
              borderWidth: 2,
              borderColor: '#ddd'
            }}
            iconColor='#444'
            clearButtonMode='always'
            clearIcon={() => {
              return (
                <Icon
                  name='close'
                  color='#777'
                  size={20}
                  onPress={() => searchRef.clear()}
                />
              )
            }}
            placeholder='Name, E-Mail, Tel. Nr. oder E-Mail'
            onChangeText={text => searchCustomer(text)}
          />
        </PaperProvider>
        <ScrollView style={styles.list}>
          {foundCustomers.map((customer, i) => (
            <CustomerListItem
              key={i}
              index={i}
              userObj={customer.item}
              navigate={navigate}
              onSelect={() => setCollapsed(true)}
            />
          ))}
        </ScrollView>
        {foundCustomers && foundCustomers?.length > 0 && (
          <Divider style={styles.divider} />
        )}
        <View>
          <Button
            disabled={
              !userAttributes || userAttributes['custom:localOpticianId']
            }
            mode='text'
            uppercase={false}
            onPress={() => setRegisterFormVisible(true)}
            style={styles.registerButton}
          >
            <Text style={{ fontSize: 16 }}>neuen Kunden registrieren</Text>
          </Button>
        </View>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 10
  },
  list: {
    flex: 1,
    maxHeight: 250,
    marginTop: 5,
    marginBottom: 5,
    minHeight: 24
  },
  registerButton: {
    alignSelf: 'flex-end',
    width: 250,
    marginVertical: 5
  },
  divider: {
    height: 2,
    marginTop: 2,
    color: '#aaa'
  },
  // #region ListItem
  container: {
    flexDirection: 'row',
    height: 70,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginHorizontal: 8,
    paddingHorizontal: 2,
    color: '#444'
  },
  chatIcon: {
    marginHorizontal: 8,
    paddingHorizontal: 2,
    color: theme.colors.primary
  },
  content: {
    flex: 1,
    marginHorizontal: 8,
    paddingHorizontal: 2
  },
  title: {
    fontWeight: '500',
    fontSize: 16
  },
  description: {
    fontWeight: '400',
    fontSize: 16
  }
  //#endregion ListItem
})
