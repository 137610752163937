import { useNavigation, CommonActions } from '@react-navigation/native'
import React, { useCallback, useEffect } from 'react'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import { RadioInput } from '../../form-wizard/Inputs'
import Question from '../../form-wizard/Question'
import { useUser } from '../../hooks/user'
import { usePassport } from '../../hooks/user/passport'
import LoginRequired from '../../components/general/LoginRequired'
import { Paragraph, ActivityIndicator } from 'react-native-paper'
import theme from '../../styles/theme'

export default () => {
  const navigation = useNavigation()
  const { user, userAttributes } = useUser()
  const { fetchPassports, glassesPassport, lensesPassport } = usePassport()

  useEffect(() => {
    fetchPassports()
  }, [userAttributes])

  const handleSubmit = useCallback(data => {
    if (data.requestType === 'Brillenpass') {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: 'Home'
            },
            {
              name: 'PassportEditor',
              params: {
                intent: 'Brillenpass',
                passport: glassesPassport || undefined
              }
            }
          ]
        })
      )
    } else if (data.requestType === 'Kontaktlinsenpass') {
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: 'Home'
            },
            {
              name: 'PassportEditor',
              params: {
                intent: 'Kontaktlinsenpass',
                passport: lensesPassport || undefined
              }
            }
          ]
        })
      )
    }
  }, [])

  if (user && (glassesPassport === undefined || lensesPassport === undefined))
    return (
      <ActivityIndicator
        style={{ alignSelf: 'center', flex: 1, justifyContent: 'center' }}
        size='large'
        animating={true}
        color={theme.colors.primary}
      />
    )

  return (
    <>
      {!user ? (
        <LoginRequired>
          <Paragraph style={{ textAlign: 'center', marginVertical: 20 }}>
            Bitte melden Sie sich an, damit wir Ihre optischen Daten sicher
            speichern können.
          </Paragraph>
        </LoginRequired>
      ) : (
        <FormWizard onSubmit={handleSubmit}>
          <FormScreen title=''>
            <Question
              id='requestType'
              head='Bitte wählen Sie Ihr Anliegen!'
              fieldProps={{
                validate: value => (value ? undefined : 'required')
              }}
            >
              <RadioInput
                options={[
                  !glassesPassport
                    ? { id: 'Brillenpass', text: 'Brillenpass erstellen' }
                    : { id: 'Brillenpass', text: 'Brillenpass bearbeiten' },
                  !lensesPassport
                    ? {
                        id: 'Kontaktlinsenpass',
                        text: 'Kontaktlinsenpass erstellen'
                      }
                    : {
                        id: 'Kontaktlinsenpass',
                        text: 'Kontaktlinsenpass bearbeiten'
                      }
                ]}
              />
            </Question>
          </FormScreen>
        </FormWizard>
      )}
    </>
  )
}
