import React, { useEffect, useState } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Avatar, Headline } from 'react-native-paper'
import Dashboard from '../templates/Dashboard'
import DefaultScreen from '../screens/DefaultScreen'
import { useLinkTo, useNavigation } from '@react-navigation/native'
import { scale } from '../../scaling/ScaledUI'
import { useUser } from '../../hooks/user'
import SkeletonContent from 'react-native-skeleton-content'
import theme from '../../styles/theme'

export default ({ children }) => {
  const { userAttributes, userPicture, user, fetchUserPicture } = useUser()

  const [userPictureLoaded, setUserPictureLoaded] = useState(false)

  const linkTo = useLinkTo()
  const navigation = useNavigation()

  const handleProfilePress = () => {
    if (user) linkTo('/profile')
    else navigation.navigate('Login')
  }

  useEffect(() => {
    if (userAttributes) fetchUserPicture().then(setUserPictureLoaded(true))
  }, [userAttributes])

  useEffect(() => {
    const timer = setTimeout(() => setUserPictureLoaded(true), 1250)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <DefaultScreen>
      <Dashboard>
        <Dashboard.Header>
          <TouchableOpacity
            style={styles.headerTouchable}
            onPress={handleProfilePress}
          >
            <SkeletonContent
              isLoading={!userPictureLoaded || userPicture === ''}
              containerStyle={{ width: 64 }}
              boneColor={theme.colors.bone}
              highlightColor={theme.colors.boneHighlight}
              layout={[
                {
                  key: 'avatar',
                  ...styles.avatarImage,
                  width: 64,
                  height: 64,
                  borderRadius: 32
                }
              ]}
            >
              {user && userAttributes.picture ? (
                <Avatar.Image
                  source={{ uri: userPicture }}
                  size={64}
                  style={styles.avatarImage}
                />
              ) : (
                <Avatar.Icon
                  icon='account-circle-outline'
                  size={scale(96)}
                  style={styles.avatarIcon}
                />
              )}
            </SkeletonContent>
            <View style={{ flex: 1 }}>
              <SkeletonContent
                isLoading={userAttributes === undefined}
                boneColor={theme.colors.bone}
                highlightColor={theme.colors.boneHighlight}
                layout={[{ key: 'name', width: '85%', height: 24 }]}
              >
                <Headline style={styles.name}>
                  {userAttributes?.name
                    ? userAttributes?.name.trim()
                    : 'Jetzt einloggen'}
                </Headline>
              </SkeletonContent>
            </View>
          </TouchableOpacity>
        </Dashboard.Header>
        <Dashboard.Content>{children}</Dashboard.Content>
      </Dashboard>
    </DefaultScreen>
  )
}

const styles = StyleSheet.create({
  headerTouchable: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  name: {
    color: 'white'
  },
  phone: {
    color: 'white',
    fontWeight: '300'
  },
  avatarIcon: {
    marginLeft: scale(-24),
    marginTop: scale(-16),
    marginBottom: scale(-23) // icon is slightly off-center
  },
  avatarImage: {
    marginLeft: scale(-8),
    marginRight: scale(14),
    marginTop: scale(2)
  },
  swiperScreen: {
    marginHorizontal: 12
  },
  padded: {
    paddingHorizontal: 12
  }
})
