import React from 'react'
import { Paragraph } from 'react-native-paper'
import LinkButton from '../../components/LinkButton'
import StuffToDo from '../../components/general/StuffToDo'

export default () => {
  return (
    <>
      <Paragraph style={{ paddingHorizontal: 40, marginTop: 120 }}>
        Um Ihnen ein Brillenglas empfehlen zu können, müssen Sie die
        Brillenglas-Bedarfsanalyse durchführen.
      </Paragraph>
      <LinkButton
        mode='contained'
        to='/survey'
        style={{ marginHorizontal: 40, marginVertical: 12 }}
      >
        Bedarfsanalyse durchführen
      </LinkButton>
      <StuffToDo />
    </>
  )
}
