export default [
  {
    id: 48122305,
    name: 'Hartlauer',
    address: 'Wienerstraße 10, 3300 Amstetten',
    lat: '48.122305',
    lng: '14.875905'
  },
  {
    id: 47608355,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 106, 8990 Bad Aussee',
    lat: '47.608355',
    lng: '13.78331'
  },
  {
    id: 48033786,
    name: 'Hartlauer',
    address: 'Steyrer Straße 1, 4540 Bad Hall',
    lat: '48.033786',
    lng: '14.211779'
  },
  {
    id: 47172286,
    name: 'Hartlauer',
    address: 'Salzburgerstraße 11, 5630 Bad Hofgastein',
    lat: '47.172286',
    lng: '13.104701'
  },
  {
    id: 47710275,
    name: 'Hartlauer',
    address: 'Esplanade 2, 4820 Bad Ischl',
    lat: '47.710275',
    lng: '13.62092'
  },
  {
    id: 48009221,
    name: 'Hartlauer',
    address: 'Pfarrgasse 8, 2500 Baden',
    lat: '48.009221',
    lng: '16.236365'
  },
  {
    id: 47415170,
    name: 'Hartlauer',
    address: 'Franz-Mohshammer-Platz 7, 5500 Bischofshofen',
    lat: '47.41517',
    lng: '13.21771'
  },
  {
    id: 48257357,
    name: 'Hartlauer',
    address: 'Stadtplatz 26, 5280 Braunau',
    lat: '48.257357',
    lng: '13.034955'
  },
  {
    id: 47503184,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 13, 6900 Bregenz',
    lat: '47.503184',
    lng: '9.743676'
  },
  {
    id: 48034733,
    name: 'Hartlauer',
    address: 'ECO Plus Park 4. Straße/Shop 10, 2460 Bruck a.d.Leitha',
    lat: '48.034733',
    lng: '16.806417'
  },
  {
    id: 47410200,
    name: 'Hartlauer',
    address: 'Mittergasse 1, 8600 Bruck/Mur',
    lat: '47.4102',
    lng: '15.27016'
  },
  {
    id: 47152020,
    name: 'Hartlauer',
    address: 'Lünerseepark, Hauptstraße 4a, 6706 Bürs/Bludenz',
    lat: '47.15202',
    lng: '9.81114'
  },
  {
    id: 46820729,
    name: 'Hartlauer',
    address: 'Frauentaler Straße 1, 8530 Deutschlandsberg',
    lat: '46.820729',
    lng: '15.234016'
  },
  {
    id: 47411619,
    name: 'Hartlauer',
    address: 'Marktstraße 18a, 6850 Dornbirn',
    lat: '47.411619',
    lng: '9.74224'
  },
  {
    id: 48308742,
    name: 'Hartlauer',
    address: 'Schmiedstraße 16, 4070 Eferding',
    lat: '48.308742',
    lng: '14.021859'
  },
  {
    id: 47845880,
    name: 'Hartlauer',
    address: 'Hauptstraße 18, 7000 Eisenstadt',
    lat: '47.84588',
    lng: '16.522374'
  },
  {
    id: 48214664,
    name: 'Hartlauer',
    address: 'Linzer Straße 12, 4470 Enns',
    lat: '48.214664',
    lng: '14.476316'
  },
  {
    id: 46952078,
    name: 'Hartlauer',
    address: 'Bürgergasse 3, 8330 Feldbach',
    lat: '46.952078',
    lng: '15.889928'
  },
  {
    id: 47236701,
    name: 'Hartlauer',
    address: 'Marktplatz 6, 6800 Feldkirch',
    lat: '47.236701',
    lng: '9.59668'
  },
  {
    id: 46723494,
    name: 'Hartlauer',
    address: '10.-Oktober-Straße 21, 9560 Feldkirchen',
    lat: '46.723494',
    lng: '14.096084'
  },
  {
    id: 46526306,
    name: 'Hartlauer',
    address: 'Hauptplatz 11, 9170 Ferlach',
    lat: '46.526306',
    lng: '14.300387'
  },
  {
    id: 48497420,
    name: 'Hartlauer',
    address: 'Linzer Straße 63, 4240 Freistadt',
    lat: '48.49742',
    lng: '14.503802'
  },
  {
    id: 47270580,
    name: 'Hartlauer',
    address: 'Hauptplatz 18, 8130 Frohnleiten',
    lat: '47.27058',
    lng: '15.32601'
  },
  {
    id: 47048287,
    name: 'Hartlauer',
    address: 'Hauptstraße 27, 8280 Fürstenfeld',
    lat: '47.048287',
    lng: '16.079975'
  },
  {
    id: 48340664,
    name: 'Hartlauer',
    address: 'Bahnstraße 22, 2230 Gänserndorf',
    lat: '48.340664',
    lng: '16.72296'
  },
  {
    id: 47105688,
    name: 'Hartlauer',
    address: 'Hauptplatz 3, 8200 Gleisdorf',
    lat: '47.105688',
    lng: '15.709361'
  },
  {
    id: 47675981,
    name: 'Hartlauer',
    address: 'Hauptstraße 10, 2640 Gloggnitz',
    lat: '47.675981',
    lng: '15.938429'
  },
  {
    id: 48773054,
    name: 'Hartlauer',
    address: 'Stadtplatz 50, 3950 Gmünd',
    lat: '48.773054',
    lng: '14.987386'
  },
  {
    id: 47917560,
    name: 'Hartlauer',
    address: 'Franz Josef-Platz 7, 4810 Gmunden',
    lat: '47.91756',
    lng: '13.79699'
  },
  {
    id: 47072307,
    name: 'Hartlauer',
    address: 'Sporgasse 17, 8010 Graz',
    lat: '47.072307',
    lng: '15.438703'
  },
  {
    id: 47071410,
    name: 'Hartlauer',
    address: 'Annenstraße 52, 8020 Graz',
    lat: '47.07141',
    lng: '15.423356'
  },
  {
    id: 47060207,
    name: 'Hartlauer',
    address: 'Lazarettgürtel 55/Top 2, 8020 Graz',
    lat: '47.060207',
    lng: '15.428099'
  },
  {
    id: 48233872,
    name: 'Hartlauer',
    address: 'Roßmarkt 3, 4710 Grieskirchen',
    lat: '48.233872',
    lng: '13.832137'
  },
  {
    id: 48147745,
    name: 'Hartlauer',
    address: 'Ungarstraße 11, 2410 Hainburg',
    lat: '48.147745',
    lng: '16.943539'
  },
  {
    id: 47282292,
    name: 'Hartlauer',
    address: 'Wallpachgasse 11-13, 6060 Hall i. Tirol',
    lat: '47.282292',
    lng: '11.50773'
  },
  {
    id: 47682506,
    name: 'Hartlauer',
    address: 'Ruprechtgasse 4, 5400 Hallein',
    lat: '47.682506',
    lng: '13.093476'
  },
  {
    id: 47280373,
    name: 'Hartlauer',
    address: 'Im Hatric 2/Top 12, 8230 Hartberg',
    lat: '47.280373',
    lng: '15.991099'
  },
  {
    id: 46627262,
    name: 'Hartlauer',
    address: 'Hauptstraße 13, 9620 Hermagor',
    lat: '46.627262',
    lng: '13.369361'
  },
  {
    id: 48563484,
    name: 'Hartlauer',
    address: 'Sparkassegasse 6, 2020 Hollabrunn',
    lat: '48.563484',
    lng: '16.079317'
  },
  {
    id: 48664673,
    name: 'Hartlauer',
    address: 'Pfarrgasse 1, 3580 Horn',
    lat: '48.664673',
    lng: '15.658428'
  },
  {
    id: 47217689,
    name: 'Hartlauer',
    address: 'Industriezone 39, 6460 Imst',
    lat: '47.217689',
    lng: '10.734802'
  },
  {
    id: 47266435,
    name: 'Hartlauer',
    address: 'Museumstraße 37a, 6020 Innsbruck',
    lat: '47.266435',
    lng: '11.402381'
  },
  {
    id: 47167594,
    name: 'Hartlauer',
    address: 'Burggasse 1, 8750 Judenburg',
    lat: '47.167594',
    lng: '14.660847'
  },
  {
    id: 47451408,
    name: 'Hartlauer',
    address: 'Wiener Straße 30, 8605 Kapfenberg',
    lat: '47.451408',
    lng: '15.311755'
  },
  {
    id: 47504245,
    name: 'Hartlauer',
    address: 'Hauptstraße 72, 8650 Kindberg',
    lat: '47.504245',
    lng: '15.447149'
  },
  {
    id: 47904640,
    name: 'Hartlauer',
    address: 'Hauptplatz 13, 4560 Kirchdorf',
    lat: '47.90464',
    lng: '14.12321'
  },
  {
    id: 47447163,
    name: 'Hartlauer',
    address: 'Vorderstadt 4, 6370 Kitzbühel',
    lat: '47.447163',
    lng: '12.390332'
  },
  {
    id: 46625445,
    name: 'Hartlauer',
    address: 'Alter Platz 8, 9020 Klagenfurt',
    lat: '46.625445',
    lng: '14.309215'
  },
  {
    id: 48309202,
    name: 'Hartlauer',
    address: 'Stadtplatz 30, 3400 Klosterneuburg',
    lat: '48.309202',
    lng: '16.323878'
  },
  {
    id: 47215277,
    name: 'Hartlauer',
    address: 'Frauengasse 9, 8720 Knittelfeld',
    lat: '47.215277',
    lng: '14.828798'
  },
  {
    id: 47064504,
    name: 'Hartlauer',
    address: 'Hauptplatz 8, 8580 Köflach',
    lat: '47.064504',
    lng: '15.084102'
  },
  {
    id: 48343476,
    name: 'Hartlauer',
    address: 'Hauptplatz 17, 2100 Korneuburg',
    lat: '48.343476',
    lng: '16.334287'
  },
  {
    id: 48411437,
    name: 'Hartlauer',
    address: 'Untere Landstraße 11, 3500 Krems',
    lat: '48.411437',
    lng: '15.601195'
  },
  {
    id: 47583772,
    name: 'Hartlauer',
    address: 'Theatergasse 1, 6330 Kufstein',
    lat: '47.583772',
    lng: '12.170623'
  },
  {
    id: 48720928,
    name: 'Hartlauer',
    address: 'Stadtplatz 62, 2136 Laa an der Thaya',
    lat: '48.720928',
    lng: '16.384379'
  },
  {
    id: 47139491,
    name: 'Hartlauer',
    address: 'Malserstraße 39, 6500 Landeck',
    lat: '47.139491',
    lng: '10.565974'
  },
  {
    id: 46781180,
    name: 'Hartlauer',
    address: 'R.H.Bartsch-Gasse 6, 8430 Leibnitz',
    lat: '46.78118',
    lng: '15.53531'
  },
  {
    id: 47381234,
    name: 'Hartlauer',
    address: 'Timmersdorfergasse 4, 8700 Leoben',
    lat: '47.381234',
    lng: '15.093881'
  },
  {
    id: 46829205,
    name: 'Hartlauer',
    address: 'Andrä Kranz-Gasse 5, 9900 Lienz',
    lat: '46.829205',
    lng: '12.767614'
  },
  {
    id: 47559268,
    name: 'Hartlauer',
    address: 'Gesäusestraße 18, 8940 Liezen',
    lat: '47.559268',
    lng: '14.253257'
  },
  {
    id: 48296859,
    name: 'Hartlauer',
    address: 'Landstraße 101-103, 4020 Linz',
    lat: '48.296859',
    lng: '14.291898'
  },
  {
    id: 48253201,
    name: 'Hartlauer',
    address: 'Karl-Steiger-Straße 1, 4030 Linz',
    lat: '48.253201',
    lng: '14.311352'
  },
  {
    id: 48314187,
    name: 'Hartlauer',
    address: 'Blütenstraße 15/Shop 18/EG, 4040 Linz',
    lat: '48.314187',
    lng: '14.283357'
  },
  {
    id: 48334377,
    name: 'Hartlauer',
    address: 'Altenbergerstraße 43, 4040 Linz-Auhof',
    lat: '48.334377',
    lng: '14.318978'
  },
  {
    id: 48312650,
    name: 'Hartlauer',
    address: 'Hauptstraße 36, 4040 Linz-Urfahr',
    lat: '48.31265',
    lng: '14.28224'
  },
  {
    id: 47745313,
    name: 'Hartlauer',
    address: 'Hauptplatz 14, 7210 Mattersburg',
    lat: '47.745313',
    lng: '16.490045'
  },
  {
    id: 48102634,
    name: 'Hartlauer',
    address: 'Stadtplatz 58, 5230 Mattighofen',
    lat: '48.102634',
    lng: '13.150269'
  },
  {
    id: 48239613,
    name: 'Hartlauer',
    address: 'Poschacherstraße 1/Objekt 4, 4310 Mauthausen',
    lat: '48.239613',
    lng: '14.524955'
  },
  {
    id: 48569006,
    name: 'Hartlauer',
    address: 'Hauptplatz 21, 2130 Mistelbach',
    lat: '48.569006',
    lng: '16.572383'
  },
  {
    id: 47282310,
    name: 'Hartlauer',
    address: 'Zeller Straße 2, 5730 Mittersill',
    lat: '47.28231',
    lng: '12.48266'
  },
  {
    id: 48085218,
    name: 'Hartlauer',
    address: 'Kaiserin Elisabeth-Straße 2, 2340 Mödling',
    lat: '48.085218',
    lng: '16.282964'
  },
  {
    id: 47110482,
    name: 'Hartlauer',
    address: 'Grazer Straße 2, 8850 Murau',
    lat: '47.110482',
    lng: '14.172382'
  },
  {
    id: 47606619,
    name: 'Hartlauer',
    address: 'Stadtplatz 9, 8680 Mürzzuschlag',
    lat: '47.606619',
    lng: '15.673692'
  },
  {
    id: 47945167,
    name: 'Hartlauer',
    address: 'Hauptstraße 41, 5202 Neumarkt am Wallersee',
    lat: '47.945167',
    lng: '13.224283'
  },
  {
    id: 47720973,
    name: 'Hartlauer',
    address: 'Hauptplatz 7, 2620 Neunkirchen',
    lat: '47.720973',
    lng: '16.080882'
  },
  {
    id: 47948618,
    name: 'Hartlauer',
    address: 'Hauptplatz 20, 7100 Neusiedl am See',
    lat: '47.948618',
    lng: '16.842156'
  },
  {
    id: 47942869,
    name: 'Hartlauer',
    address: 'Salzburgerstraße 56, 5110 Oberndorf bei Salzburg',
    lat: '47.942869',
    lng: '12.943186'
  },
  {
    id: 47562821,
    name: 'Hartlauer',
    address: 'Hauptstraße 14, 7350 Oberpullendorf',
    lat: '47.562821',
    lng: '16.428376'
  },
  {
    id: 47294646,
    name: 'Hartlauer',
    address: 'Wienerstraße 6, 7400 Oberwart',
    lat: '47.294646',
    lng: '16.205595'
  },
  {
    id: 48340383,
    name: 'Hartlauer',
    address: 'Hostauerstraße 62-68/Top 7, 4100 Ottensheim',
    lat: '48.340383',
    lng: '14.168764'
  },
  {
    id: 48244188,
    name: 'Hartlauer',
    address: 'Plus-Kauf-Straße 7, 4061 Pasching',
    lat: '48.244188',
    lng: '14.236489'
  },
  {
    id: 48120363,
    name: 'Hartlauer',
    address: 'Wiener Gasse 8, 2380 Perchtoldsdorf',
    lat: '48.120363',
    lng: '16.265836'
  },
  {
    id: 48249471,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 5, 4320 Perg',
    lat: '48.249471',
    lng: '14.632776'
  },
  {
    id: 47270858,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 13/Top 1, 6830 Rankweil',
    lat: '47.270858',
    lng: '9.641097'
  },
  {
    id: 48757204,
    name: 'Hartlauer',
    address: 'Hauptplatz 14, 2070 Retz',
    lat: '48.757204',
    lng: '15.951126'
  },
  {
    id: 47489638,
    name: 'Hartlauer',
    address: 'Untermarkt 2, 6600 Reutte',
    lat: '47.489638',
    lng: '10.717921'
  },
  {
    id: 48208119,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 2, 4910 Ried',
    lat: '48.208119',
    lng: '13.487068'
  },
  {
    id: 48571660,
    name: 'Hartlauer',
    address: 'Stadtplatz 19, 4150 Rohrbach',
    lat: '48.57166',
    lng: '13.991922'
  },
  {
    id: 47426552,
    name: 'Hartlauer',
    address: 'Rathausplatz 3, 5760 Saalfelden',
    lat: '47.426552',
    lng: '12.848239'
  },
  {
    id: 47804862,
    name: 'Hartlauer',
    address: 'Schallmooser Hauptstraße 6, 5020 Salzburg',
    lat: '47.804862',
    lng: '13.050536'
  },
  {
    id: 47785227,
    name: 'Hartlauer',
    address: 'Nonntaler Hauptstraße 59, 5020 Salzburg',
    lat: '47.785227',
    lng: '13.052649'
  },
  {
    id: 47813698,
    name: 'Hartlauer',
    address: 'Südtiroler Platz 13, 5020 Salzburg',
    lat: '47.813698',
    lng: '13.045098'
  },
  {
    id: 48456450,
    name: 'Hartlauer',
    address: 'Silberzeile 2, 4780 Schärding',
    lat: '48.45645',
    lng: '13.4325'
  },
  {
    id: 48054995,
    name: 'Hartlauer',
    address: 'Stadtplatz 13, 4690 Schwanenstadt',
    lat: '48.054995',
    lng: '13.773946'
  },
  {
    id: 47344716,
    name: 'Hartlauer',
    address: 'Innsbrucker Straße 1, 6130 Schwaz/Tirol',
    lat: '47.344716',
    lng: '11.708442'
  },
  {
    id: 48141244,
    name: 'Hartlauer',
    address: 'Wiener Straße 1, 2320 Schwechat',
    lat: '48.141244',
    lng: '16.475987'
  },
  {
    id: 47011550,
    name: 'Hartlauer',
    address: 'Shopping City Seiersberg 3/Top 1/2/3a, 8055 Seiersberg',
    lat: '47.01155',
    lng: '15.41016'
  },
  {
    id: 48043919,
    name: 'Hartlauer',
    address: 'Neustraße 6, 4522 Sierning',
    lat: '48.043919',
    lng: '14.308726'
  },
  {
    id: 46798620,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 3, 9800 Spittal/Drau',
    lat: '46.7986209',
    lng: '13.492603'
  },
  {
    id: 47349414,
    name: 'Hartlauer',
    address: 'Hauptstraße 24, 5600 St. Johann im Pongau',
    lat: '47.349414',
    lng: '13.2029944'
  },
  {
    id: 48204448,
    name: 'Hartlauer',
    address: 'Riemerplatz 3, 3100 St. Pölten',
    lat: '48.204448',
    lng: '15.624545'
  },
  {
    id: 47369570,
    name: 'Hartlauer',
    address: 'Dr.-Adolf-Schärf-Straße 5, 3107 St. Pölten/Viehofen',
    lat: '47.36957',
    lng: '16.11503'
  },
  {
    id: 46765300,
    name: 'Hartlauer',
    address: 'Völkermarkter Straße 40, 9300 St. Veit/Glan',
    lat: '46.7653',
    lng: '14.37306'
  },
  {
    id: 47527260,
    name: 'Hartlauer',
    address: 'Dechant-Wieshoferstraße 4, 6380 St.Johann i.T.',
    lat: '47.52726',
    lng: '12.43244'
  },
  {
    id: 48178340,
    name: 'Hartlauer',
    address: 'Westbahnstraße 30, 4300 St.Valentin',
    lat: '48.17834',
    lng: '14.523143'
  },
  {
    id: 48040213,
    name: 'Hartlauer',
    address: 'Stadtplatz 6, 4400 Steyr',
    lat: '48.040213',
    lng: '14.419766'
  },
  {
    id: 48051299,
    name: 'Hartlauer',
    address: 'Johann-Puch-Straße 25, 4403 Steyr',
    lat: '48.051299',
    lng: '14.420394'
  },
  {
    id: 48386436,
    name: 'Hartlauer',
    address: 'Hauptstraße 43, 2000 Stockerau',
    lat: '48.386436',
    lng: '16.214854'
  },
  {
    id: 47133534,
    name: 'Hartlauer',
    address: 'Marktplatz 3, 5580 Tamsweg',
    lat: '47.133534',
    lng: '13.678996'
  },
  {
    id: 47305913,
    name: 'Hartlauer',
    address: 'Michael-Seeber-Straße 2, 6410 Telfs',
    lat: '47.305913',
    lng: '11.077477'
  },
  {
    id: 48220913,
    name: 'Hartlauer',
    address: 'Hauptplatz 11, 4050 Traun',
    lat: '48.220913',
    lng: '14.237176'
  },
  {
    id: 47425199,
    name: 'Hartlauer',
    address: 'Hauptstraße 39, 8793 Trofaiach',
    lat: '47.425199',
    lng: '15.009476'
  },
  {
    id: 48330926,
    name: 'Hartlauer',
    address: 'Hauptplatz 5, 3430 Tulln',
    lat: '48.330926',
    lng: '16.051902'
  },
  {
    id: 46613932,
    name: 'Hartlauer',
    address: 'Hauptplatz 15, 9500 Villach',
    lat: '46.613932',
    lng: '13.846826'
  },
  {
    id: 48007401,
    name: 'Hartlauer',
    address: 'Salzburger Straße 11, 4840 Vöcklabruck',
    lat: '48.007401',
    lng: '13.651969'
  },
  {
    id: 47049744,
    name: 'Hartlauer',
    address: 'Hauptplatz 31, 8570 Voitsberg',
    lat: '47.049744',
    lng: '15.149949'
  },
  {
    id: 46659179,
    name: 'Hartlauer',
    address: 'Hauptplatz 11, 9100 Völkermarkt',
    lat: '46.659179',
    lng: '14.634232'
  },
  {
    id: 47255855,
    name: 'Hartlauer',
    address: 'Cytastraße 1/Top 310, 6176 Völs',
    lat: '47.255855',
    lng: '11.325306'
  },
  {
    id: 48113810,
    name: 'Hartlauer',
    address: 'SCS Allee 135 / Top 144a, 2334 Vösendorf',
    lat: '48.11381',
    lng: '16.31705'
  },
  {
    id: 48815420,
    name: 'Hartlauer',
    address: 'Hauptplatz 28, 3830 Waidhofen/Thaya',
    lat: '48.81542',
    lng: '15.2874'
  },
  {
    id: 47961077,
    name: 'Hartlauer',
    address: 'Unterer Stadtplatz 6, 3340 Waidhofen/Ybbs',
    lat: '47.961077',
    lng: '14.773729'
  },
  {
    id: 47216905,
    name: 'Hartlauer',
    address: 'Hauptplatz 9-10, 8160 Weiz',
    lat: '47.216905',
    lng: '15.62148'
  },
  {
    id: 48180462,
    name: 'Hartlauer',
    address: 'Ginzkeystraße 27/21, 4600 Wels',
    lat: '48.180462',
    lng: '14.061561'
  },
  {
    id: 48159313,
    name: 'Hartlauer',
    address: 'Bäckergasse 12, 4600 Wels',
    lat: '48.159313',
    lng: '14.024713'
  },
  {
    id: 47856770,
    name: 'Hartlauer',
    address: 'Oberer Markt 2, 3335 Weyer',
    lat: '47.85677',
    lng: '14.66336'
  },
  {
    id: 48212191,
    name: 'Hartlauer',
    address: 'Wipplingerstraße 15, 1010 Wien',
    lat: '48.212191',
    lng: '16.369507'
  },
  {
    id: 48215396,
    name: 'Hartlauer',
    address: 'Praterstraße 44-46, 1020 Wien',
    lat: '48.215396',
    lng: '16.386172'
  },
  {
    id: 48215642,
    name: 'Hartlauer',
    address: 'Taborstraße 18, 1020 Wien',
    lat: '48.215642',
    lng: '16.380533'
  },
  {
    id: 48203590,
    name: 'Hartlauer',
    address: 'Landstraßer Hauptstraße 35, 1030 Wien',
    lat: '48.20359',
    lng: '16.389703'
  },
  {
    id: 48198871,
    name: 'Hartlauer',
    address: 'Mariahilfer Straße 61, 1060 Wien',
    lat: '48.198871',
    lng: '16.352318'
  },
  {
    id: 48175887,
    name: 'Hartlauer',
    address: 'Favoritenstraße 126, 1100 Wien',
    lat: '48.175887',
    lng: '16.377348'
  },
  {
    id: 48153915,
    name: 'Hartlauer',
    address: 'Favoritenstraße 239, 1100 Wien',
    lat: '48.153915',
    lng: '16.382617'
  },
  {
    id: 48173477,
    name: 'Hartlauer',
    address: 'Simmeringer Hauptstraße 96a, 1110 Wien',
    lat: '48.173477',
    lng: '16.416838'
  },
  {
    id: 48177167,
    name: 'Hartlauer',
    address: 'Meidlinger Hauptstraße 62, 1120 Wien',
    lat: '48.177167',
    lng: '16.329892'
  },
  {
    id: 48186228,
    name: 'Hartlauer',
    address: 'Hietzinger Hauptstraße 15, 1130 Wien',
    lat: '48.186228',
    lng: '16.299182'
  },
  {
    id: 48199150,
    name: 'Hartlauer',
    address: 'Hütteldorfer Straße 100, 1140 Wien',
    lat: '48.19915',
    lng: '16.313198'
  },
  {
    id: 48208934,
    name: 'Hartlauer',
    address: 'Thaliastraße 10, 1160 Wien',
    lat: '48.208934',
    lng: '16.336885'
  },
  {
    id: 48226601,
    name: 'Hartlauer',
    address: 'Währinger Straße 120, 1180 Wien',
    lat: '48.226601',
    lng: '16.343331'
  },
  {
    id: 48238838,
    name: 'Hartlauer',
    address: 'Döblinger Hauptstraße 49, 1190 Wien',
    lat: '48.238838',
    lng: '16.354456'
  },
  {
    id: 48230137,
    name: 'Hartlauer',
    address: 'Wallensteinplatz 2, 1200 Wien',
    lat: '48.230137',
    lng: '16.371186'
  },
  {
    id: 48268421,
    name: 'Hartlauer',
    address: 'Ignaz-Köck-Straße 1/Top 16, 1210 Wien',
    lat: '48.268421',
    lng: '16.398483'
  },
  {
    id: 48259478,
    name: 'Hartlauer',
    address: 'Brünner Straße 13, 1210 Wien',
    lat: '48.259478',
    lng: '16.398543'
  },
  {
    id: 48242096,
    name: 'Hartlauer',
    address: 'Wagramer Straße 94/Top 745, 1220 Wien',
    lat: '48.242096',
    lng: '16.435834'
  },
  {
    id: 48131164,
    name: 'Hartlauer',
    address: 'Hauptplatz 8, 3250 Wieselburg',
    lat: '48.131164',
    lng: '15.138847'
  },
  {
    id: 46841150,
    name: 'Hartlauer',
    address: 'Sporergasse 11, 9400 Wolfsberg',
    lat: '46.84115',
    lng: '14.842555'
  },
  {
    id: 47488167,
    name: 'Hartlauer',
    address: 'Bahnhofstraße 26, 6300 Wörgl',
    lat: '47.488167',
    lng: '12.063558'
  },
  {
    id: 47813478,
    name: 'Hartlauer',
    address: 'Herzog Leopold-Straße 24, 2700 Wr.Neustadt',
    lat: '47.813478',
    lng: '16.240794'
  },
  {
    id: 47321352,
    name: 'Hartlauer',
    address: 'Dr.-Franz-Rehrl-Straße 1, 5700 Zell am See',
    lat: '47.321352',
    lng: '12.796806'
  },
  {
    id: 48603506,
    name: 'Hartlauer',
    address: 'Landstraße 9, 3910 Zwettl',
    lat: '48.603506',
    lng: '15.167636'
  }
]
