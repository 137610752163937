import React, { useEffect, useState } from 'react'
import { View, Image, Text, StyleSheet } from 'react-native'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useUser } from '../hooks/user'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { Button, HelperText, TextInput } from 'react-native-paper'
import Splash from '../../assets/undraw/web_login_splash.png'
import theme from '../styles/theme'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import * as Yup from 'yup'
import { Auth } from 'aws-amplify'
import { useFormik } from 'formik'

export default () => {
  const { user, reload } = useUser()

  const navigation = useNavigation()

  const { googleLogin, facebookLogin, appleLogin } = useUser()

  const [textInputFocus, setTextInputFocus] = useState([false, false])

  const [passwordShown, setPasswordShow] = useState(false)

  const [loading, showLoading] = useState(false)

  useEffect(() => {
    if (user) {
      navigation.goBack()
    }
  }, [user?.username])

  // eslint-disable-next-line no-unused-vars
  const handleGoogleLogin = () => {
    googleLogin()
  }
  // eslint-disable-next-line no-unused-vars
  const handleFacebookLogin = () => {
    facebookLogin()
  }
  // eslint-disable-next-line no-unused-vars
  const handleAppleLogin = () => {
    appleLogin()
  }

  const validationSchema = Yup.object().shape({
    user: Yup.string()
      .trim()
      .max(128, 'Zu langer Benutzername!')
      .required('Benutzername oder E-Mail erforderlich'),
    pass: Yup.string()
      .trim()
      .test(
        'pass',
        'Ihr Passwort muss mindestens 8 Zeichen lang sein',
        val => val && val.length >= 8
      )
      .required('Passwort erforderlich')
  })

  const formik = useFormik({
    initialValues: {
      user: '',
      pass: ''
    },
    onSubmit: values => {
      showLoading(true)
      Auth.signIn(values.user.toLowerCase().trim(), values.pass.trim())
        .then(reload)
        .then(() => {
          showLoading(false)
          navigation.dispatch(
            CommonActions.reset({
              index: 1,
              routes: [
                {
                  name: 'Landing'
                },
                {
                  name: 'Optician'
                }
              ]
            })
          )
        })
        .catch(err => {
          if (err.code === 'NotAuthorizedException') {
            formik.setFieldError(
              'pass',
              'Die E-Mail Adresse und das Passwort stimmen nicht überein.'
            )
          } else if (err.code === 'UserNotFoundException') {
            formik.setFieldError(
              'user',
              'Der Benutzer existiert nicht oder die E-Mail Adresse wurde noch nicht bestätigt.'
            )
          } else if (err.code === 'InvalidParameterException') {
            formik.setFieldError('pass', 'Ungültige Zugangsdaten!')
          } else {
            alert(
              'Es tut uns leid, da ist etwas schief gelaufen!\n\nFehler:\n' +
                JSON.stringify(err, null, 4)
            )
          }
          showLoading(false)
        })
        .finally(() => {
          formik.setSubmitting(false)
        })
    },
    validationSchema: validationSchema
  })

  const styles = StyleSheet.create({
    container: {
      alignSelf: 'center',
      alignItems: 'center',
      width: 1100,
      maxWidth: '90%',
      marginVertical: 20,
      padding: useMediaQuery({ maxWidth: 1000 }) ? 10 : 70,
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-around',
      backgroundColor: '#fff',
      shadowColor: '#000',
      shadowOpacity: 0.12,
      shadowRadius: 25,
      borderRadius: 12
    },
    imageBox: {
      flex: 1,
      alignItems: 'center',
      alignSelf: 'center',
      minWidth: 250,
      minHeight: 200,
      maxWidth: '80%'
    },
    divider: {
      width: 70
    },
    signUpText: {
      color: '#aaa',
      textAlign: 'center',
      textDecorationLine: 'underline'
    },
    description: {
      color: '#222',
      textAlign: 'center',
      marginBottom: useMediaQuery({ maxWidth: 1000 }) ? 10 : 40,
      paddingHorizontal: 8,
      fontSize: 15
    },
    image: {
      width: 1166 * 0.42,
      height: 885 * 0.42,
      maxWidth: '80%',
      margin: 20,
      alignSelf: 'center'
    },
    imageSmall: {
      width: 1166 * 0.22,
      height: 885 * 0.22,
      maxWidth: '70%',
      alignSelf: 'center',
      marginHorizontal: 20
    },
    loginBox: {
      flex: 1,
      minWidth: useMediaQuery({ maxWidth: 1000 }) ? 100 : 400,
      minHeight: 600,
      marginHorizontal: 20,
      height: '100%',
      justifyContent: 'space-evenly'
    },
    textInput: {
      marginVertical: 8,
      backgroundColor: '#fff',
      borderWidth: 0
      // outline: 'none'
    },
    headline: {
      alignSelf: useMediaQuery({ maxWidth: 1000 }) ? 'center' : 'flex-start',
      fontSize: 32,
      fontWeight: 'bold'
    },
    signInBtn: {
      width: useMediaQuery({ maxDeviceWidth: 1000 }) ? '100%' : 155,
      height: 54,
      justifyContent: 'center',
      marginVertical: 16
    }
  })

  return (
    <DefaultScreen>
      <View style={styles.container}>
        <MediaQuery minWidth={1000}>
          <View style={styles.imageBox}>
            <Image style={styles.image} source={Splash} resizeMode='contain' />
            <Text style={styles.description}>
              Nutzen Sie unsere Optiker Platform um modern mit Ihren Kunden in
              Kontakt zu stehen.
            </Text>
            <Text style={styles.signUpText}>
              ViSpecs Partneroptiker werden (bald)
            </Text>
          </View>
          <View style={styles.divider} />
        </MediaQuery>
        <View style={styles.loginBox}>
          <Text style={styles.headline}>Optiker-Login</Text>
          <MediaQuery maxWidth={999}>
            <Image
              style={styles.imageSmall}
              source={Splash}
              resizeMode='center'
              resizeMethod='resize'
            />
            <Text style={styles.description}>
              Nutzen Sie unsere Optiker Platform um modern mit Ihren Kunden in
              Kontakt zu stehen.
            </Text>
            {/* <Text style={styles.signUpText}>
              ViSpecs Partneroptiker werden (bald)
            </Text> */}
          </MediaQuery>
          <View>
            <TextInput
              style={styles.textInput}
              mode='flat'
              placeholder='Benutzername oder E-Mail'
              left={
                <TextInput.Icon
                  name='account-outline'
                  style={{ marginLeft: -12 }}
                />
              }
              onFocus={() => setTextInputFocus([true, false])}
              onBlur={() => setTextInputFocus([false, textInputFocus[1]])}
              error={formik.touched.user && formik.errors.user}
              value={formik.values.user}
              onChangeText={formik.handleChange('user')}
              underlineColor={textInputFocus[0] ? theme.colors.primary : '#666'}
            />
            <HelperText
              type='error'
              style={styles.error}
              visible={formik.touched.user && formik.errors.user}
            >
              {formik.errors.user}
            </HelperText>
            <TextInput
              style={styles.textInput}
              mode='flat'
              placeholder='Passwort'
              secureTextEntry={!passwordShown}
              left={
                <TextInput.Icon
                  name='lock-outline'
                  style={{
                    marginLeft: -12
                  }}
                />
              }
              right={
                <TextInput.Icon
                  name={() => (
                    <Icon
                      name={passwordShown ? 'eye-off-outline' : 'eye-outline'}
                      size={20}
                      onPress={() => setPasswordShow(!passwordShown)}
                    />
                  )}
                />
              }
              onFocus={() => setTextInputFocus([false, true])}
              onBlur={() => setTextInputFocus([textInputFocus[0], false])}
              error={
                formik.touched.pass && formik.errors.pass && formik.values.pass
              }
              value={formik.values.pass}
              onChangeText={formik.handleChange('pass')}
              underlineColor={textInputFocus[1] ? theme.colors.primary : '#666'}
            />
            <HelperText
              type='error'
              style={styles.error}
              visible={
                formik.touched.pass && formik.errors.pass && formik.values.pass
              }
            >
              {formik.errors.pass}
            </HelperText>
          </View>
          <Button
            disabled={
              !loading &&
              (!formik.dirty || !formik.isValid || formik.isSubmitting)
            }
            mode={loading ? 'outlined' : 'contained'}
            style={styles.signInBtn}
            onPress={() => {
              if (!loading) formik.handleSubmit()
            }}
            loading={loading}
          >
            Anmelden
          </Button>
        </View>
      </View>
    </DefaultScreen>
  )
}
