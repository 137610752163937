/* eslint-disable no-unreachable */
import React, { useState } from 'react'
import DefaultScreen from '../../components/screens/DefaultScreen'
import { Form, Field } from 'react-final-form'
import theme from '../../styles/theme'
import { Linking, Platform, ScrollView, StyleSheet, View } from 'react-native'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import {
  Button,
  Divider,
  Portal,
  Subheading,
  Text,
  TextInput
} from 'react-native-paper'
import { useUser } from '../../hooks/user'
import Photo from '../../form-wizard/inputs/Photo'
import MyStoreOptician from '../../components/Profile/MyStore.optician'
import ModernConfirmModal from '../../components/general/ModernConfirmModal'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const { userAttributes, updateUserAttributes, signOut } = useUser()

  const [infoVisible, setInfoVisible] = useState(false)

  const required = (value, error) =>
    value ? undefined : error || 'Gültiger Wert benötigt'
  const phone_number = value => {
    if (!value || value === '') return undefined
    if (value.trim().substring(0, 1) !== '+')
      return 'Länderkennung erforderlich (z.B. +43)'
    const phone = parsePhoneNumberFromString(value)
    return !!phone && phone.isValid() ? undefined : 'Ungültige Telefonnummer'
  }

  const email = value => {
    if (!value) return undefined
    const expr = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    )
    return expr.test(value) ? undefined : 'Ungültige E-Mail Adresse'
  }

  const linkTo = useLinkTo()

  const handleSignOut = () => {
    signOut()
    linkTo('/home')
  }

  const updateProfile = values => {
    if (values.phone_number)
      values.phone_number = values.phone_number.replace(/\s/g, '')
    delete values.identities
    updateUserAttributes(values)
  }

  const styles = StyleSheet.create({
    headline: {
      alignSelf: 'flex-start',
      fontSize: 32,
      fontWeight: 'bold',
      marginBottom: 8
    },
    titleDescription: {
      flex: 1,
      fontSize: 14,
      lineHeight: 16,
      marginTop: 6,
      color: '#3c4043'
    },
    error: {
      flex: 1,
      fontSize: 14,
      lineHeight: 16,
      marginTop: 6,
      color: theme.colors.error
    },
    itemTitle: {
      flex: 1,
      fontSize: 16,
      lineHeight: 18,
      color: '#333',
      marginRight: 20,
      minWidth: 160
    },
    button: {
      marginTop: 8,
      marginHorizontal: 4,
      height: 45,
      justifyContent: 'center'
    }
  })

  const ProfileItem = ({ title, description, error, children }) => (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 6,
        marginBottom: 16,
        minWidth: 160,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Text style={styles.itemTitle}>{title}</Text>
      <View style={{ flex: 3, flexDirection: 'column', minWidth: 300 }}>
        {children}
        {error ? (
          <Text style={styles.error}>{error}</Text>
        ) : (
          <>
            {description && (
              <Text style={styles.titleDescription}>{description}</Text>
            )}
          </>
        )}
      </View>
    </View>
  )

  const ProfileInput = ({ value, onChange, editable, error }) => (
    <TextInput
      dense
      value={value}
      onChange={onChange}
      mode='outlined'
      editable={editable}
      error={error}
    />
  )

  const ViewComponent = Platform.OS === 'web' ? View : ScrollView

  return (
    <DefaultScreen>
      <ViewComponent
        style={{
          width: 900,
          maxWidth: '99%',
          paddingHorizontal: 8,
          alignSelf: 'center'
        }}
      >
        <Text style={styles.headline}>Optiker-Profil</Text>
        <Text style={styles.titleDescription}>
          Bearbeiten Sie einfach Ihr Profil, sämtliche Daten werden automatisch
          für Ihre Kunden aktualisiert.
        </Text>
        <Divider style={{ marginTop: 16, marginBottom: 26 }} />
        <Form onSubmit={updateProfile} initialValues={userAttributes}>
          {props => (
            <>
              <Portal>
                <ModernConfirmModal
                  title='Änderungen verwerfen?'
                  buttonTitle='Verwerfen'
                  cancelTitle='Abbrechen'
                  confirmColor={theme.colors.error}
                  visible={infoVisible}
                  onDismiss={() => setInfoVisible(false)}
                  onConfirm={() => {
                    props.form.reset()
                    setInfoVisible(false)
                  }}
                  onCancel={() => setInfoVisible(false)}
                >
                  <Text style={styles.titleDescription}>
                    Alle nicht gespeicherten Änderungen gehen verloren
                  </Text>
                </ModernConfirmModal>
              </Portal>
              <Field
                name='name'
                validate={val => required(val, 'Profilname benötigt')}
              >
                {props => (
                  <ProfileItem
                    title='Profilname'
                    description='Dieser Name wird Ihren Kunden in der App angezeigt.'
                    error={props.meta.error}
                  >
                    <ProfileInput
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={props.meta.error}
                    />
                  </ProfileItem>
                )}
              </Field>

              <Field name='phone_number' validate={phone_number}>
                {props => (
                  <ProfileItem
                    title='Telefonnummer'
                    description='Die Telefonnummer dient derzeit ausschließlich zum Kontakt mit ViSpecs.'
                    error={props.meta.error}
                  >
                    <ProfileInput
                      value={props.input.value}
                      onChange={props.input.onChange}
                      error={props.meta.error}
                    />
                  </ProfileItem>
                )}
              </Field>

              <Field name='email' validate={email}>
                {props => (
                  <ProfileItem
                    title='E-Mail Adresse'
                    description='Die E-Mail Adresse bietet den Kunden eine Möglichkeit Sie zu kontaktieren.'
                    error={props.meta.error}
                  >
                    <ProfileInput
                      error={props.meta.error}
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                  </ProfileItem>
                )}
              </Field>

              <Field name='address'>
                {props => (
                  <ProfileItem
                    title='Postanschrift'
                    description='Diese Adresse wird dem Kunden auf Ihrem Profil angezeigt.'
                  >
                    <ProfileInput
                      multiline
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                  </ProfileItem>
                )}
              </Field>

              <Field name='picture'>
                {props => (
                  <ProfileItem
                    title='Profilbild'
                    description='Verwenden Sie ein aussagekräftiges Profilbild um sich von der Konkurrenz abzuheben.'
                  >
                    <Photo
                      noCamera={true}
                      input={{ ...props.input }}
                      manipulateActions={[
                        {
                          resize: { width: 200 }
                        }
                      ]}
                    />
                  </ProfileItem>
                )}
              </Field>
              <Subheading>Meine Filliale</Subheading>
              <MyStoreOptician />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  flexWrap: 'wrap',
                  marginVertical: 10
                }}
              >
                <Button
                  style={[styles.button, { width: 300 }]}
                  uppercase={false}
                  mode='outlined'
                  onPress={() =>
                    Linking.openURL(
                      'mailto:patrick.schlager@vispecs.com?subject=Fillialänderung'
                    )
                  }
                >
                  Fillialänderung beantragen
                </Button>
                <Button
                  style={[styles.button, { width: 300 }]}
                  uppercase={false}
                  mode='outlined'
                  onPress={() =>
                    Linking.openURL(
                      'mailto:patrick.schlager@vispecs.com?subject=Datenschutzanfrage'
                    )
                  }
                >
                  Datenschutzanfrage
                </Button>
              </View>
              <Divider
                style={{
                  marginVertical: 14
                }}
              />
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginBottom: 20
                }}
              >
                <Button
                  disabled={props.pristine || props.submitting}
                  style={[styles.button, {}]}
                  mode='text'
                  onPress={() => setInfoVisible(true)}
                >
                  <Text
                    style={{
                      color: '#5f6368'
                    }}
                  >
                    Änderungen verwerfen
                  </Text>
                </Button>
                <Button
                  disabled={props.pristine || props.invalid || props.submitting}
                  style={[styles.button, {}]}
                  mode='contained'
                  onPress={props.handleSubmit}
                >
                  <Text
                    style={{
                      color:
                        props.pristine || props.invalid || props.submitting
                          ? theme.colors.disabled
                          : '#fff'
                    }}
                  >
                    Speichern
                  </Text>
                </Button>
              </View>
            </>
          )}
        </Form>
        <Divider />
        <Button
          style={{ flex: 0, alignSelf: 'flex-start', marginBottom: 5 }}
          onPress={handleSignOut}
        >
          Abmelden
        </Button>
      </ViewComponent>
    </DefaultScreen>
  )
}
