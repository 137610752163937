import React from 'react'
import { View, Image, StyleSheet, TouchableOpacity } from 'react-native'
import Logo from '../../../assets/logo_text.png'
import NavbarElement from './NavbarElement'
import { useLinkProps } from '@react-navigation/native'
import NavbarUser from './NavbarUser'
import NavElementContainer from './NavElementContainer'
import MediaQuery from 'react-native-web-responsive'
import NavbarMenu from './NavbarMenu'

export default ({ width }) => {
  const { onPress } = useLinkProps({ to: '/' })
  return (
    <View style={styles.main}>
      <View
        style={{
          maxWidth: width,
          width: '100%',
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'row'
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignSelf: 'flex-start',
            minWidth: 180
          }}
          onPress={onPress}
        >
          <Image source={Logo} style={styles.logo} />
        </TouchableOpacity>
        <View style={styles.minispacer} />
        <MediaQuery minWidth={390}>
          <View style={styles.spacer} />
        </MediaQuery>

        <View
          style={{
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <MediaQuery minWidth={700}>
            <NavElementContainer>
              <NavbarElement to='/download'>App</NavbarElement>
              <NavbarElement to='/map'>Optikersuche</NavbarElement>
              <NavbarElement to='/contact'>Kontakt</NavbarElement>
            </NavElementContainer>
            <View style={styles.spacer} />
          </MediaQuery>
          <MediaQuery maxWidth={700}>
            {/* for mobile */}
            <NavbarMenu />
          </MediaQuery>
        </View>
        <MediaQuery minWidth={700}>
          <View style={styles.spacer} />
        </MediaQuery>
        <NavbarUser />
        <MediaQuery minWidth={700}>
          <View style={styles.spacer} />
        </MediaQuery>
        <View style={styles.minispacer} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  main: {
    zIndex: 9999,
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'flex-start',
    height: 70,
    width: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 0
    },
    shadowOpacity: 0.3,
    shadowRadius: 4.65,
    elevation: 0
  },
  logo: { marginTop: 5, marginLeft: 10, height: 60, width: 180 },
  spacer: {
    width: 35
  },
  minispacer: {
    width: 8
  }
})
