/* eslint-disable no-unused-vars */
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import React, { useEffect, useState } from 'react'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import theme from '../styles/theme'
import GlassesEvaluation from '../screens/Evaluation/GlassesEvaluation'
import LensesEvaluation from '../screens/Evaluation/LensesEvaluation'
import GlassEvaluationUnavailable from '../screens/Evaluation/GlassEvaluationUnavailable'
import UnderConstruction from '../components/general/UnderConstruction'
import { useAssessment } from '../hooks/user/assessment'
import LensesEvaluationUnavailable from '../screens/Evaluation/LensesEvaluationUnavailable'
import { usePassport } from '../hooks/user/passport'
import { Platform, View } from 'react-native'
import DefaultScreen from '../components/screens/DefaultScreen'
import SkeletonContent from 'react-native-skeleton-content'
import UnderRework from '../components/general/UnderRework'

const Tab = createMaterialBottomTabNavigator()

export default props => {
  const {
    lensesAssessment,
    glassAssessment,
    fetchGlassAssessment,
    fetchLensesAssessment
  } = useAssessment()
  const { fetchLensesPassport, lensesPassport } = usePassport()

  const [glassLoading, setGlassLoading] = useState(true)
  const [lenseLoading, setLenseLoading] = useState(true)
  const [lensePassLoading, setLensePassLoading] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [frameLoading, setFrameLoading] = useState(false)

  const [element, setElement] = useState()

  useEffect(() => {
    fetchGlassAssessment().then(() => {
      const timer = setTimeout(() => setGlassLoading(false), 500)
      return () => clearInterval(timer)
    })
    fetchLensesAssessment().then(() => {
      const timer = setTimeout(() => setLenseLoading(false), 500)
      return () => clearInterval(timer)
    })
    fetchLensesPassport().then(() => {
      const timer = setTimeout(() => setLensePassLoading(false), 500)
      return () => clearInterval(timer)
    })
  }, [])

  const FrameComponent = UnderConstruction

  const GlassComponent = () => (
    <SkeletonContent
      isLoading={glassLoading}
      layout={[
        {
          key: 'block0',
          width: '55%',
          height: '5%',
          alignSelf: 'flex-start',
          marginLeft: '7.5%',
          marginBottom: '4%'
        },
        { key: 'block1', width: '85%', height: '15%', marginBottom: '2%' },
        {
          key: 'group1',
          flexDirection: 'row',
          height: '4%',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '20%',
          children: [
            {
              key: 'subBlock0',
              width: '45%',
              height: '100%',
              marginLeft: '7.5%'
            },
            {
              key: 'subBlock1',
              width: '25%',
              height: '100%',
              marginRight: '7.5%'
            }
          ]
        },
        { key: 'block5', width: '75%', height: '35%' }
      ]}
    >
      {glassAssessment ? <GlassesEvaluation /> : <GlassEvaluationUnavailable />}
    </SkeletonContent>
  )

  const LensesComponent = () => (
    <SkeletonContent
      isLoading={lensePassLoading || lenseLoading}
      layout={[
        {
          key: 'block0',
          width: '55%',
          height: '5%',
          alignSelf: 'flex-start',
          marginLeft: '7.5%',
          marginBottom: '4%'
        },
        { key: 'block1', width: '85%', height: '15%', marginBottom: '2%' },
        {
          key: 'group1',
          flexDirection: 'row',
          height: '4%',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '20%',
          children: [
            {
              key: 'subBlock0',
              width: '45%',
              height: '100%',
              marginLeft: '7.5%'
            },
            {
              key: 'subBlock1',
              width: '25%',
              height: '100%',
              marginRight: '7.5%'
            }
          ]
        },
        { key: 'block5', width: '75%', height: '35%' }
      ]}
    >
      {lensesAssessment && lensesPassport ? (
        <LensesEvaluation />
      ) : (
        <LensesEvaluationUnavailable />
      )}
    </SkeletonContent>
  )

  if (Platform.OS === 'web') {
    return (
      <DefaultScreen>
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <GlassComponent noNav />
          <FrameComponent noNav />
          <LensesComponent noNav />
        </View>
      </DefaultScreen>
    )
  }

  return (
    <Tab.Navigator
      initialRouteName={props?.route?.params?.intent || 'Glasses'}
      barStyle={{
        backgroundColor: theme.colors.background
      }}
      activeColor={theme.colors.primary}
    >
      <Tab.Screen
        name='Glasses'
        component={GlassComponent}
        options={() => ({
          tabBarLabel: 'Brillenglas',
          tabBarIcon: ({ color }) => (
            <Icon name='glasses' color={color} size={24} />
          )
        })}
      />
      <Tab.Screen
        name='Frame'
        component={UnderConstruction}
        options={{
          tabBarLabel: 'Fassung',
          tabBarIcon: ({ color }) => (
            <Icon name='printer-3d' color={color} size={24} />
          )
        }}
      />
      <Tab.Screen
        name='Lenses'
        component={LensesComponent}
        options={{
          tabBarLabel: 'Kontaktlinsen',
          tabBarIcon: ({ color }) => (
            <Icon name='eye-plus-outline' color={color} size={24} />
          )
        }}
      />
    </Tab.Navigator>
  )
}
