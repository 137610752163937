import { useNavigation } from '@react-navigation/native'
import React from 'react'
import { View, Platform } from 'react-native'
import { Paragraph, Button, Text } from 'react-native-paper'
import { moderateScale } from 'react-native-size-matters'

export default ({ passportName }) => {
  const navigation = useNavigation()

  return (
    <View>
      <Paragraph
        style={{
          color: 'white',
          marginBottom: Platform.OS === 'web' ? 26 : moderateScale(12, 1),
          fontSize: Platform.OS === 'web' ? 20 : moderateScale(12, 0.7),
          lineHeight: Platform.OS === 'web' ? 32 : moderateScale(18, 0.8),
          marginHorizontal: Platform.OS === 'web' ? 36 : moderateScale(14, 0.85)
        }}
      >
        Speichern Sie Ihre optischen Daten praktisch und sicher in der
        ViSpecs-App. So gehen sie nicht mehr verloren und stehen Ihrem Optiker
        bei Bedarf zur Verfügung.
      </Paragraph>
      <Button
        labelStyle={{ color: 'white' }}
        onPress={() =>
          navigation.navigate('Passport', { passportName: passportName })
        }
      >
        <Text
          style={{
            color: 'white',
            fontSize: Platform.OS === 'web' ? 22 : undefined
          }}
        >
          {passportName} erstellen
        </Text>
      </Button>
    </View>
  )
}
