import React, { useEffect, useState } from 'react'
import { Button, Card, Title, Paragraph } from 'react-native-paper'
import { StyleSheet } from 'react-native'
import { useUser } from '../../hooks/user'

export default props => {
  const { user, isOptician } = useUser()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (props.localOpticianId === props.id) setLoading(false)
  }, [props.localOpticianId])

  return (
    <Card style={styles.card}>
      <Card.Content>
        <Title>{props.name || 'Bitte warten...'}</Title>
        <Paragraph>{props.address || '...'}</Paragraph>
      </Card.Content>
      {user && (
        <Card.Actions style={styles.buttonHolder}>
          <Button
            loading={loading}
            mode='contained'
            disabled={
              props.localOpticianId === props.id ||
              (isOptician && props.localOpticianId)
            }
            style={styles.button}
            onPress={() => {
              setLoading(true)
              props.onSelect()
              setTimeout(() => setLoading(false), 1500) // fallback if the loading fails
            }}
          >
            {props.localOpticianId === props.id ? 'Ausgewählt' : 'Auswählen'}
          </Button>
        </Card.Actions>
      )}
    </Card>
  )
}

const styles = StyleSheet.create({
  card: {
    position: 'absolute',
    bottom: 10,
    minWidth: 400,
    maxWidth: 500,
    zIndex: 10,
    alignSelf: 'center',
    paddingBottom: 20
  },
  buttonHolder: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  button: {
    bottom: 0,
    width: '100%'
  }
})
