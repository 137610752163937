import React from 'react'
import { Button, Headline } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import { View, Image } from 'react-native'

export default ({ children }) => {
  const navigation = useNavigation()

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          paddingHorizontal: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <Headline>Anmelden</Headline>
        {children}
        <Button mode='contained' onPress={() => navigation.navigate('Login')}>
          Jetzt anmelden
        </Button>
        <Image
          source={require('../../../assets/undraw/undraw_authentication_fsn5.png')}
          style={{ marginTop: 80, width: 1406 * 0.22, height: 965 * 0.22 }}
        />
      </View>
    </View>
  )
}
