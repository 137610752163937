import React from 'react'
import { View, Text } from 'react-native'
import { Button, Modal } from 'react-native-paper'
import theme from '../../styles/theme'

export default ({
  children,
  visible,
  onDismiss,
  onConfirm,
  onCancel,
  title,
  buttonTitle,
  cancelTitle,
  confirmColor,
  cancelColor
}) => {
  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      contentContainerStyle={{
        alignSelf: 'center',
        width: 500,
        padding: 20,
        maxWidth: '90%',
        justifyContent: 'space-around',
        backgroundColor: '#fff',
        shadowColor: '#000',
        shadowOpacity: 0.12,
        shadowRadius: 25,
        borderRadius: 12
      }}
    >
      {title && (
        <Text
          style={{
            lineHeight: 24,
            fontSize: 18,
            color: '#353535',
            fontWeight: 'bold',
            marginBottom: 8
          }}
        >
          {title}
        </Text>
      )}
      {children}
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginBottom: -6,
          marginTop: 10
        }}
      >
        {typeof onCancel === 'function' && (
          <Button mode='text' uppercase={false} onPress={onCancel}>
            <Text style={{ color: cancelColor || '#5f6368' }}>
              {cancelTitle || 'Abbrechen'}
            </Text>
          </Button>
        )}
        {typeof onConfirm === 'function' && (
          <Button mode='text' uppercase={false} onPress={onConfirm}>
            <Text style={{ color: confirmColor || theme.colors.primary }}>
              {buttonTitle || 'Zustimmen'}
            </Text>
          </Button>
        )}
      </View>
    </Modal>
  )
}
