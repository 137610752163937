import React from 'react'
import { Platform, View } from 'react-native'
import EvaluationElement from './EvaluationElement'

export default ({ type, evaluatedGlasses, evaluatedLenses }) => {
  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingRight: Platform.OS === 'web' ? 0 : 12
      }}
    >
      {type === 'glasses' && (
        <>
          {evaluatedGlasses ? (
            <>
              {evaluatedGlasses.map((x, i) => (
                <EvaluationElement
                  key={i}
                  title={x.Manufacturer}
                  description={x.Name}
                  antireflective={x.Antireflective}
                  blueprotection={x.Blueprotection}
                />
              ))}
            </>
          ) : (
            <EvaluationElement title='Auswertung noch nicht möglich' />
          )}
        </>
      )}
      {type === 'lenses' && (
        <>
          {evaluatedLenses ? (
            <>
              {evaluatedLenses.map((x, i) => (
                <>
                  <View
                    key={i}
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    {x.left && (
                      <EvaluationElement
                        dir='Links'
                        title={x.left.manufacturer}
                        description={x.left.name}
                      />
                    )}
                    {x.right && (
                      <EvaluationElement
                        dir='Rechts'
                        title={x.right.manufacturer}
                        description={x.right.name}
                      />
                    )}
                  </View>
                </>
              ))}
            </>
          ) : (
            <EvaluationElement title='Auswertung noch nicht möglich' />
          )}
        </>
      )}
    </View>
  )
}
