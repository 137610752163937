import React from 'react'
import ChatComponent from '../../components/Chat/Chat.component'
import { View } from 'react-native'
import OpticianDashboardCard from '../../components/Optician/OpticianDashboardCard'
import ChatblockOpticianComponent from '../../components/Chat/Chatblock.optician.component'
import { Divider } from 'react-native-paper'
import theme from '../../styles/theme'
import OpticianDrawer from '../../components/screens/OpticianDrawer'

export default ({ navigation }) => {
  return (
    <OpticianDrawer navigation={navigation}>
      <View style={{ width: '100%' }} />
      <OpticianDashboardCard icon='chat-outline' header='Chat'>
        <View style={{ flexDirection: 'row' }}>
          <View style={{ flex: 1, maxHeight: 500 }}>
            <ChatblockOpticianComponent />
          </View>
          <Divider
            style={{
              height: '100%',
              marginHorizontal: 6,
              width: 2,
              color: theme.colors.gray
            }}
          />
          <View
            style={{
              flex: 1,
              backgroundColor: '#fff',
              alignSelf: 'flex-start',
              minHeight: 500
            }}
          >
            <ChatComponent />
          </View>
        </View>
      </OpticianDashboardCard>
    </OpticianDrawer>
  )
}
