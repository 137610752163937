import React from 'react'
import { View, StyleSheet, Text, ScrollView } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useOptician } from '../../hooks/opticians'
import CustomerListItem from './Common/CustomerListItem'

// eslint-disable-next-line no-unused-vars
export default ({ style, setCollapsed, navigate, onSelect }) => {
  const { recentCustomers } = useOptician()

  return (
    <>
      <View style={{ ...style }}>
        <ScrollView style={styles.list}>
          <>
            {recentCustomers && recentCustomers.length > 0 ? (
              recentCustomers
                .slice(0, 3)
                .map((customer, i) => (
                  <CustomerListItem
                    appearance={i === 0 ? 'regular' : 'light'}
                    onSelect={() => setCollapsed(true)}
                    key={i}
                    index={i}
                    userObj={customer}
                    navigate={navigate}
                  />
                ))
            ) : (
              <>
                <Icon
                  name='account-outline'
                  color='#888'
                  size={30}
                  style={{
                    alignSelf: 'center',
                    marginTop: 20
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    justifyContent: 'center',
                    color: '#888',
                    alignSelf: 'center',
                    marginTop: 10,
                    fontSize: 16
                  }}
                >
                  Keine Kunden verfügbar
                </Text>
              </>
            )}
          </>
        </ScrollView>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  button: {
    marginVertical: 10
  },
  list: {
    flex: 1,
    maxHeight: 250,
    marginTop: 5,
    marginBottom: 5,
    minHeight: 24
  },
  registerButton: {
    alignSelf: 'flex-end',
    width: 250,
    marginVertical: 5
  },
  divider: {
    height: 2,
    marginTop: 2,
    color: '#aaa'
  }
})
