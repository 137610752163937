import React, { useState, useEffect, Component } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import Frame from '../../../assets/mockups/mockup_iphone_v2.png'
import Screen1 from '../../../assets/mockups/screen1.jpg'
import Screen2 from '../../../assets/mockups/screen5.jpg'
import Screen3 from '../../../assets/mockups/screen3.jpg'
import Screen4 from '../../../assets/mockups/screen4.jpg'
import ImageSliderz from './Slideshow'

export default class MockupShow extends Component {
  constructor (props) {
    super(props)

    const frW = 1196
    const frH = 1591

    const transW = 396 * (props.scale ? props.scale : 1)
    const transH = -1570 * (props.scale ? props.scale : 1)

    this.state = {
      scale: props.scale ? props.scale : 1,
      position: 0,
      interval: null,
      dataSource: [
        { url: Screen1 },
        { url: Screen2 },
        { url: Screen3 },
        { url: Screen4 }
      ],
      scW: 795 * (props.scale ? props.scale : 1),
      scH: 1536 * (props.scale ? props.scale : 1),
      styles: StyleSheet.create({
        container: {
          width: frW * (props.scale ? props.scale : 1),
          height: frH * (props.scale ? props.scale : 1),
          margin: 20
        },
        frame: {
          zIndex: 1,
          width: frW * (props.scale ? props.scale : 1),
          height: frH * (props.scale ? props.scale : 1)
        },
        screenshots: {
          zIndex: 0,
          width: 795 * (props.scale ? props.scale : 1),
          height: 1536 * (props.scale ? props.scale : 1),
          marginLeft: transW,
          transform: [
            {
              translateY: transH
              // translateX: transW
            }
          ]
        }
      })
    }
  }

  componentWillMount () {
    this.setState({
      interval: setInterval(() => {
        const newVal = this.state.position >= 3 ? 0 : this.state.position + 1
        this.setState({
          position: newVal
        })
        this.props.onChange(newVal)
      }, 6000)
    })
  }

  componentWillUnmount () {
    clearInterval(this.state.interval)
  }

  render () {
    return (
      <View
        onLayout={event => {
          const { x, y } = event.nativeEvent.layout
          this.setState({ parentYPosition: y, parentXPosition: x })
        }}
        style={this.state.styles.container}
      >
        <View style={this.state.styles.frame}>
          <Image source={{ uri: Frame }} style={this.state.styles.frame} />
        </View>
        <View style={this.state.styles.screenshots}>
          <ImageSliderz
            height={this.state.scH}
            width={this.state.scW}
            position={this.state.position}
            onPositionChanged={position => this.setState({ position })}
            dataSource={[
              { url: Screen1 },
              { url: Screen2 },
              { url: Screen3 },
              { url: Screen4 }
            ]}
          />
        </View>
      </View>
    )
  }
}
