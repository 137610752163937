import React, { useState } from 'react'
import { Menu, IconButton } from 'react-native-paper'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const [visible, setVisible] = useState(false)

  const linkTo = useLinkTo()

  const goDownload = () => {
    setVisible(false)
    linkTo('/download')
  }

  const goMap = () => {
    setVisible(false)
    linkTo('/map')
  }

  const goContact = () => {
    setVisible(false)
    linkTo('/contact')
  }

  return (
    <Menu
      visible={visible}
      onDismiss={() => setVisible(false)}
      style={{ marginTop: 70, width: 240 }}
      anchor={<IconButton icon='menu' onPress={() => setVisible(true)} />}
    >
      <Menu.Item
        onPress={goDownload}
        titleStyle={{ fontSize: 22 }}
        title='App'
      />
      <Menu.Item
        onPress={goMap}
        titleStyle={{ fontSize: 22 }}
        title='Optiker-Karte'
      />
      <Menu.Item
        onPress={goContact}
        titleStyle={{ fontSize: 22 }}
        title='Kontakt'
      />
    </Menu>
  )
}
