import { useFocusEffect } from '@react-navigation/native'
import React from 'react'
import {
  Platform,
  StatusBar,
  StyleSheet,
  ImageBackground,
  TouchableWithoutFeedback,
  ScrollView,
  Keyboard
} from 'react-native'
import Background from '../../../assets/bg_hexa.png'
import { useHeaderHeight } from '@react-navigation/stack'

export default ({ children, offsetHeader }) => {
  useFocusEffect(
    React.useCallback(() => {
      Platform.OS === 'android' && StatusBar.setBackgroundColor('#ffffff00')
      StatusBar.setBarStyle('dark-content')
    })
  )

  return (
    <ImageBackground
      style={{
        flex: 1,
        width: '100%',
        resizeMode: 'cover',
        justifyContent: 'flex-start'
      }}
      source={Background}
    >
      <TouchableWithoutFeedback
        onPress={Keyboard.dismiss} // eslint-disable-line react/jsx-handler-names
        accessible={false}
      >
        <ScrollView
          contentContainerStyle={{
            ...styles.container,
            paddingTop: offsetHeader ? useHeaderHeight() : 0
          }}
        >
          {children}
        </ScrollView>
      </TouchableWithoutFeedback>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 12,
    marginBottom: 12
  }
})
