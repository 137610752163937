import { useNavigation, CommonActions } from '@react-navigation/native'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import {
  SliderIntegerInput,
  RadioInput,
  SelectInput
} from '../../form-wizard/Inputs'
import Question from '../../form-wizard/Question'
import { useAssessment } from '../../hooks/user/assessment'

export default () => {
  const { storeAssessment } = useAssessment()
  const [beginTimestamp] = useState(moment().valueOf())

  const navigation = useNavigation()

  const handleSubmit = useCallback(data => {
    storeAssessment(data, 'LENSES', beginTimestamp, 2)
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {
            name: 'Home'
          },
          { name: 'Recommendation', params: { intent: 'Lenses' } }
        ]
      })
    )
  }, [])

  return (
    <FormWizard onSubmit={handleSubmit}>
      <FormScreen title='Kontaktlinsen'>
        <Question
          id='hasLenses'
          head='Verwenden Sie zurzeit Kontaktlinsen?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'sometimes', text: 'manchmal' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Stärkenbestimmung'>
        <Question
          id='lastExamYear'
          head='Wann war Ihre letzte Sehstärkenbestimmung?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[0, 1, 2]
              .map(delta => {
                const year = moment()
                  .subtract(delta, 'years')
                  .format('YYYY')
                return { id: year, text: year }
              })
              .concat({
                id: '0',
                text:
                  'vor ' +
                  moment()
                    .subtract(2, 'years')
                    .format('YYYY') +
                  ' / noch nie'
              })}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Zufrieden?'
        condition={values => values.hasLenses !== 'no'}
      >
        <Question
          id='satisfied'
          head='Sind Sie mit Ihren aktuellen Kontaktlinsen zufrieden?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      {/* <FormScreen
        title='Fortfahren?'
        condition={values =>
          values.hasLenses === 'yes' && values.satisfied === 'yes'
        }
      >
        <Question
          id='continue'
          head='Möchten Sie die Bedarfsanalyse dennoch durchführen?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'Bedarfsanalyse dennoch durchführen' },
              // { id: 'order', text: 'Kontaktlinsen nachbestellen' },
              { id: 'exit', text: 'Bedarfsanalyse beenden' }
            ]}
          />
        </Question>
      </FormScreen> */}

      <FormScreen
        title='Tragezeit'
        condition={
          values =>
            values.hasLenses === 'no' || values.continue === 'yes' || true // values.satisfied === 'no' // temporary because question was taken out
        }
      >
        <Question
          id='lensesCycle'
          head='Wie oft möchten Sie Ihre Kontaktlinsen zukünftig tragen?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'daily', text: '1-2x pro Woche' },
              { id: 'weekly', text: '2-3x pro Woche' },
              { id: 'monthly', text: '3-7x pro Woche' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Probleme'
        condition={
          values =>
            values.hasLenses === 'no' || values.continue === 'yes' || true // values.satisfied === 'no' // temporary disabled because question was taken out
        }
      >
        <Question
          id='lensesProblem'
          head='Haben Sie aktuell Probleme mit Ihren Kontaktlinsen?'
        >
          <SelectInput
            options={[
              { id: 'redness', text: 'gerötete Augen' },
              { id: 'burning', text: 'brennende Augen' },
              { id: 'headache', text: 'Kopfschmerzen' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Tragezeit'
        condition={
          values =>
            values.hasLenses === 'no' || values.continue === 'yes' || true // values.satisfied === 'no' // temporary disabled because question was taken out
        }
      >
        <Question
          id='lensesDuration'
          head='Wie oft Studen pro tag möchten Sie Ihre Kontaktlinsen zukünftig tragen?'
          fieldProps={{
            validate: value => (value >= 0 && value <= 24 ? undefined : 'error')
          }}
        >
          <SliderIntegerInput
            initialValue={4}
            minimumValue={0}
            maximumValue={16}
            unit={['Stunde', 'Stunden']}
            displayOrMore
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Bildschirmzeit'
        condition={
          values =>
            values.hasLenses === 'no' || values.continue === 'yes' || true // values.satisfied === 'no' // temporary disabled because question was taken out
        }
      >
        <Question
          id='displayDuration'
          head='Wie viele Stunden verbringen Sie täglich vor digitalen Geräten?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <SliderIntegerInput
            initialValue={6}
            minimumValue={0}
            maximumValue={16}
            unit={['Stunde', 'Stunden']}
            displayOrMore
          />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
