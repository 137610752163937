import { useNavigation, CommonActions } from '@react-navigation/native'
import moment from 'moment'
import React, { useState, useCallback } from 'react'
import ComputerGlassesHigh from '../../../assets/computer_high.jpg'
import ComputerGlassesLow from '../../../assets/computer_low.jpg'
import ComputerGlassesMedium from '../../../assets/computer_medium.jpg'
import DigitalNo from '../../../assets/digital-no.jpg'
import DigitalYes from '../../../assets/digital-yes.jpg'
import LensDistortionHigh from '../../../assets/lens-distortion-high.jpg'
import LensDistortionLow from '../../../assets/lens-distortion-low.jpg'
import LensDistortionMedium from '../../../assets/lens-distortion-medium.jpg'
import AntireflectiveHigh from '../../../assets/lens-flare-high.jpg'
import AntireflectiveLow from '../../../assets/lens-flare-low.jpg'
import AntireflectiveMedium from '../../../assets/lens-flare-medium.jpg'
import VarifocalHigh from '../../../assets/varifocal-high.jpg'
import VarifocalLow from '../../../assets/varifocal-low.jpg'
import VarifocalMedium from '../../../assets/varifocal-medium.jpg'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import {
  AnimatedSelector,
  ImageSelector,
  StepTintSelector,
  SliderIntegerInput,
  RadioInput,
  SelectInput
} from '../../form-wizard/Inputs'
import Question from '../../form-wizard/Question'
import { useUser } from '../../hooks/user'
import { useAssessment } from '../../hooks/user/assessment'

export default () => {
  const { storeAssessment } = useAssessment()
  const { user, userAttributes } = useUser()
  const [beginTimestamp] = useState(moment().valueOf())

  const navigation = useNavigation()

  const handleSubmit = useCallback(data => {
    storeAssessment(data, 'GLASSES', beginTimestamp, 1)
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          {
            name: 'Home'
          },
          { name: 'Recommendation', params: { intent: 'Glasses' } }
        ]
      })
    )
  }, [])

  return (
    <FormWizard onSubmit={handleSubmit}>
      <FormScreen title='Brille'>
        <Question
          id='hasGlasses'
          head='Verwenden Sie zurzeit eine Brille?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'sometimes', text: 'manchmal' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Alter'
        condition={() => {
          if (!user) return true
          else return false
        }}
      >
        <Question id='age' head='Wie alt sind Sie?'>
          <SliderIntegerInput
            initialValue={20}
            minimumValue={14}
            maximumValue={60}
            unit={['Jahr', 'Jahre']}
            displayOrMoreText=' oder älter'
          />
        </Question>
      </FormScreen>

      <FormScreen title='Stärkenbestimmung'>
        <Question
          id='lastExamYear'
          head='Wann war Ihre letzte Sehstärkenbestimmung?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[0, 1, 2]
              .map(delta => {
                const year = moment()
                  .subtract(delta, 'years')
                  .format('YYYY')
                return { id: year, text: year }
              })
              .concat({
                id: '0',
                text:
                  'vor ' +
                  moment()
                    .subtract(2, 'years')
                    .format('YYYY') +
                  ' / noch nie'
              })}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Probleme'
        condition={values => values.hasGlasses !== 'no'}
      >
        <Question
          id='glassesProblem'
          head='Haben Sie aktuell Probleme mit Ihrer Brille?'
        >
          <SelectInput
            options={[
              { id: 'slips', text: 'Brille verrutscht' },
              { id: 'pain', text: 'Brille drückt' },
              { id: 'dirty', text: 'Gläser verschmutzen leicht' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Smartphone'>
        <Question
          id='smartphoneDuration'
          head='Wie viele Stunden pro Tag blicken Sie auf Ihr Smartphone?'
          fieldProps={{
            validate: value => (value >= 0 && value <= 24 ? undefined : 'error')
          }}
        >
          <SliderIntegerInput
            initialValue={4}
            minimumValue={0}
            maximumValue={16}
            unit={['Stunde', 'Stunden']}
            displayOrMore
          />
        </Question>
      </FormScreen>

      <FormScreen title='Digitale Oberflächen'>
        <Question
          id='computerDuration'
          head='Wie viele Stunden pro Tag blicken Sie auf Ihren Computerbildschirm?'
          fieldProps={{
            validate: value => (value >= 0 && value <= 24 ? undefined : 'error')
          }}
        >
          <SliderIntegerInput
            initialValue={4}
            minimumValue={0}
            maximumValue={16}
            unit={['Stunde', 'Stunden']}
            displayOrMore
          />
        </Question>
      </FormScreen>

      <FormScreen title='Nahsicht'>
        <Question
          id='nearProblems'
          head='Haben Sie Probleme beim Blick in die Nähe?'
          description='z.B. beim Lesen oder bei Büroarbeit'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'sometimes', text: 'manchmal' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Fernsicht'>
        <Question
          id='farProblems'
          head='Haben Sie Probleme beim Blick in die Ferne?'
          description='z.B. beim Autofahren oder bei Arbeiten mit Weitsicht'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'sometimes', text: 'manchmal' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Entspiegelung'>
        <Question
          id='wantsAntireflective'
          head='Fühlen Sie sich bei hellen Lichtverhältnissen geblendet?'
          description='Verschieben Sie den Regler, um den gewünschten Entspiegelungsgrad zu wählen!'
        >
          <ImageSelector
            options={[
              { id: 'no', text: 'keine', image: AntireflectiveHigh },
              { id: 'standard', text: 'Standard', image: AntireflectiveMedium },
              { id: 'premium', text: 'Premium', image: AntireflectiveLow }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Gleitsicht'
        condition={values => {
          const ageYear = user
            ? userAttributes.birthdate
            : moment().subtract(values.age, 'years')
          if (
            moment(ageYear).isBefore(moment().subtract(35, 'years')) &&
            ((values.nearProblems === 'yes' && values.farProblems === 'no') ||
              (values.nearProblems === 'sometimes' &&
                values.farProblems === 'no'))
          ) {
            return true
          } else if (
            moment(ageYear).isBefore(moment().subtract(45, 'years')) &&
            ((values.nearProblems === 'no' && values.farProblems === 'yes') ||
              (values.nearProblems === 'no' &&
                values.farProblems === 'sometimes'))
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        <Question
          id='wantsVarifocal'
          head='Wünschen Sie ein Gleitsichtglas?'
          description='Aufgrund Ihrer Angaben wäre wahlweise ein Gleitsichtglas möglich.'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'no', text: 'kein Gleitsichtglas' },
              { id: 'yes', text: 'Gleitsichtglas' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Digitalglas'
        condition={values => {
          const ageYear = user
            ? userAttributes.birthdate
            : moment().subtract(values.age, 'years')
          if (
            moment(ageYear).isBetween(
              moment().subtract(45, 'years'),
              moment().subtract(35, 'years'),
              undefined,
              '[)'
            ) &&
            values.nearProblems !== 'no' &&
            values.farProblems !== 'no'
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        <Question
          id='wantsDigital'
          head='Wünschen Sie ein Digitalglas?'
          description='Aufgrund Ihrer Angaben wäre wahlweise ein Digitalglas möglich.'
          info='Ein Digitalglas, oder auch Anti-Stress-Glas genannt, unterstützt Sie bei allen Arbeiten oder Tätigkeiten in der Nähe und entlastet die Agen optimal. Das kann durch einen leichten Nahzusatz beim Blick in die Nähe gewährleistet werden. Optimal für jene Personen welche lange Tätigkeiten in der Nähe durchführen müssen und dem Auge dabei unter die Arme greifen möchten.'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <ImageSelector
            options={[
              { id: 'no', text: 'kein Digitalglas', image: DigitalNo },
              { id: 'yes', text: 'Digitalglas', image: DigitalYes }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Einstärkenglas'
        condition={values => {
          const ageYear = user
            ? userAttributes.birthdate
            : moment().subtract(values.age, 'years')
          if (values.wantsVarifocal) {
            return values.wantsVarifocal === 'no'
          } else if (values.wantsDigital) {
            return values.wantsDigital === 'no'
          } else if (
            moment(ageYear).isSameOrAfter(moment().subtract(35, 'years')) &&
            !(values.nearProblems === 'no' && values.farProblems === 'no')
          ) {
            return true
          } else if (
            moment(ageYear).isBefore(moment().subtract(35, 'years')) &&
            values.nearProblems === 'no' &&
            values.farProblems !== 'no'
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        <Question
          id='singleVisionTier'
          head='Wie wichtig ist Ihnen klare und uneingeschränkte Sicht?'
          description='Verschieben Sie den Regler, um das passende Glas zu wählen!'
          info='Jedes Brillenglas hat seinen optischen Mittelpunkt, in dem sich exakt die Stärke befindet, welche Sie benötigen. Je qualitativer ein Einstärkenglas ist, desto größer kann der optische Mittelpunkt gestaltet werden und umso besser wird die realitätsnahe Abbildung durch das Brillenglas.'
        >
          <ImageSelector
            options={[
              { id: 'standard', text: 'Standard', image: LensDistortionHigh },
              {
                id: 'bestseller',
                text: 'Bestseller',
                image: LensDistortionMedium
              },
              {
                id: 'premium',
                text: 'Premium',
                image: LensDistortionLow
              }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Gleitsichtglas'
        condition={values => {
          const ageYear = user
            ? userAttributes.birthdate
            : moment().subtract(values.age, 'years')
          if (values.wantsVarifocal) {
            return values.wantsVarifocal === 'yes'
          } else if (values.wantsDigital) {
            return false
          } else if (
            moment(ageYear).isBefore(moment().subtract(45, 'years')) &&
            !(values.nearProblems === 'no' && values.farProblems === 'no')
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        <Question
          id='varifocalTier'
          head='Wie wichtig ist Ihnen klare und uneingeschränkte Sicht?'
          description='Verschieben Sie den Regler, um das passende Glas zu wählen!'
          info='Jedes Gleitsichtglas hat seitlich Unschärfezonen. Das ist technisch nicht anders möglich, da drei Stärken in einem Glas vereint werden, um auf jede Distanz scharf sehen zu können. Je qualitativer ein Gleitsichtglas ist, desto mehr personenbezogene Daten können beim Herstellungsprozess implementiert werden. Dies hat zur Folge, dass die Unschärfezonen auf ein Minimum reduziert werden, um Ihnen somit ein möglichst gutes Seherlebnis bieten zu können.'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <ImageSelector
            options={[
              { id: 'standard', text: 'Standard', image: VarifocalHigh },
              { id: 'bestseller', text: 'Bestseller', image: VarifocalMedium },
              { id: 'premium', text: 'Premium', image: VarifocalLow }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Arbeitsplatzglas'
        condition={values => {
          const ageYear = user
            ? userAttributes.birthdate
            : moment().subtract(values.age, 'years')
          if (
            values.computerDuration <= 3 ||
            moment(ageYear).isSameOrAfter(moment().subtract(45, 'years'))
          ) {
            return false
          }
          if (values.wantsVarifocal) {
            // yes or no
            return true
          } else if (
            moment(ageYear).isBefore(moment().subtract(45, 'years')) &&
            !(values.nearProblems === 'no' && values.farProblems === 'no')
          ) {
            return true
          } else {
            return false
          }
        }}
      >
        <Question
          id='wantsComputerGlasses'
          head='Haben Sie Interesse an einer Arbeitsplatzbrille?'
          description='Deren Gläser sind speziell für Büro und Bildschirm optimiert.'
          info='Ein Arbeitsplatzglas ermöglicht Ihnen möglichst breite Sehzonen am Arbeitsplatz. Da ein Gleitsichtglas nicht darauf ausgelegt ist, damit lange am Computer zu arbeiten, wurden solche Gläser entwickelt, um Ihnen auch in dieser Situation das beste Seherlebnis bieten zu können. Hier können Sie wählen, bis auf welche Distanz sie gerne mit Ihrem neuen Arbeitsplatzgas sehen möchten. Sie können wählen zwischen 1 Meter, 2 Meter und 4 Meter. Je nach den Bedingungen an Ihrem Arbeitsplatz ist ein anderes Glas erforderlich.
          Das meistgewählte Produkt gewährt Ihnen, bis auf eine Distanz von ca. 4 Metern scharf zu sehen.'
        >
          <RadioInput
            options={[
              { id: 'no', text: 'kein Arbeitsplatzglas' },
              { id: 'yes', text: 'Arbeitsplatzglas' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Arbeitsplatzglas'
        condition={values => values.wantsComputerGlasses === 'yes'}
      >
        <Question
          id='computerGlassesTier'
          head='Wie wichtig ist Ihnen klare und uneingeschränkte Sicht?'
          description='Verschieben Sie den Regler, um das passende Glas zu wählen!'
        >
          <ImageSelector
            options={[
              { id: 'standard', text: 'Standard', image: ComputerGlassesLow },
              {
                id: 'bestseller',
                text: 'Bestseller',
                image: ComputerGlassesMedium
              },
              {
                id: 'premium',
                text: 'Premium',
                image: ComputerGlassesHigh
              }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Lichtverhältnisse'>
        <Question
          id='wantsSunProtection'
          head='Werden Sie bei sonnigen Lichtverhältnissen oft geblendet?'
          description='Würden Sie einen integrierten Sonnenschutz in Ihrer Brille bevorzugen?'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'yes', text: 'ja' },
              { id: 'no', text: 'nein' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Glastönung'
        condition={values => values.wantsSunProtection === 'yes'}
      >
        <Question
          id='sunglassesChoice'
          head='Bevorzugen Sie eine reine Sonnenbrille oder ein verfärbendes Glas?'
          info='Getönte Gläser haben eine im Glas verarbeitete Farbe. Die Intensität der Tönung kann von Ihnen gewählt werden von 25-85%.
          Selbsttönende Gläser passen sich den gegebenen Lichtverhältnissen an.
          Beispielsweise haben diese Art von Gläsern im Rauminneren keine Tönung und stellen ein klares, durchsichtiges Glas dar.
          Im Freien bei Sonnenschein dunkeln sich diese Gläser ein wie eine Sonnenbrille. Dieser Vorgang dauert normalerweise ca. 30 Sekunden.'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'tinted', text: 'Gläser mit Tönung' },
              { id: 'photochromic', text: 'selbsttönende Gläser' },
              { id: 'none', text: 'keine Auswahl treffen' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen
        title='Tönung'
        condition={values => values.sunglassesChoice === 'photochromic'}
      >
        <Question
          id='tint'
          head='Bitte wählen Sie Ihre gewünschte Tönungsfarbe!'
          fieldProps={{
            defaultValue: ['gray', 0.5]
          }}
        >
          <AnimatedSelector
            options={[
              { id: 'green', text: 'grün', color: '#608b6f' },
              { id: 'brown', text: 'braun', color: '#967556' },
              { id: 'gray', text: 'grau', color: '#9390a3' }
            ]}
            range={[0, 0.85]}
          />
        </Question>
      </FormScreen>
      <FormScreen
        title='Tönung'
        condition={values => values.sunglassesChoice === 'tinted'}
      >
        <Question
          id='tint'
          head='Bitte wählen Sie den passenden Tönungsgrad!'
          fieldProps={{
            defaultValue: ['gray', 0.5]
          }}
        >
          <StepTintSelector
            options={[
              { id: 'green', text: 'grün', color: '#608b6f' },
              { id: 'brown', text: 'braun', color: '#967556' },
              { id: 'gray', text: 'grau', color: '#9390a3' }
            ]}
            steps={[0.25, 0.5, 0.75, 0.85]}
            range={[0, 0.85]}
          />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
