/* eslint-disable no-unreachable */
import React from 'react'
import DefaultScreen from '../components/screens/DefaultScreen'
import Blank from '../components/templates/Blank'
import { Form, Field } from 'react-final-form'
import theme from '../styles/theme'
import LinkButton from '../components/LinkButton'
import { Linking, Picker, View } from 'react-native'
import moment from 'moment'
import { FORM_ERROR } from 'final-form'
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'
import {
  Button,
  Divider,
  Subheading,
  Text,
  TextInput
} from 'react-native-paper'
import * as Yup from 'yup'
import LocalOptician from '../components/Profile/LocalOptician'

import { useUser } from '../hooks/user'
import { moderateScale, scale, IntegerInput } from '../scaling/ScaledUI'
import { useLinkProps, useNavigation } from '@react-navigation/native'
import Photo from '../form-wizard/inputs/Photo'
import MyStoreOptician from '../components/Profile/MyStore.optician'

export default () => {
  const {
    user,
    userAttributes,
    updateUserAttributes,
    isFederated,
    signOut,
    isOptician
  } = useUser()

  const navigation = useNavigation()

  const handleSignOut = () => {
    signOut()
    onPress()
  }

  const { onPress } = useLinkProps({ to: '/home' })

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(),
    email: Yup.string().email(),
    phone_number: Yup.string().test(
      'isValidPhone',
      'Keine gültige Mobiltelefonnummer!',
      value => {
        if (isFederated || user?.username?.includes('email')) return true
        return true // temporary
        const phone = parsePhoneNumberFromString(value)
        return (
          (isFederated && (!value || value?.length === 0)) ||
          (!!phone && phone.isValid())
        )
      }
    ),
    gender: Yup.string()
      .oneOf(['male', 'female', 'other'])
      .required(),
    birthdate: Yup.date()
      .max(moment())
      .min('1900-01-01')
      .required(),
    address: Yup.string().trim(),
    picture: Yup.string()
  })

  const updateProfile = values => {
    if (isFederated) {
      delete values.identities
      delete values.email
      // values.phone_number = parsePhoneNumberFromString(values.phone_number)
      //   .formatInternational()
      //   .replace(/ /g, '')
      // console.log('Data: ' + JSON.stringify(values.phone_number, null, 4))
      delete values.phone_number // temporary, because of reverification
    } else {
      delete values.phone_number
    }
    updateUserAttributes(values)
    onPress()
  }

  return (
    <DefaultScreen>
      <Blank scrollable ignoreHeader>
        <Form
          onSubmit={updateProfile}
          validate={values => {
            return validationSchema
              .validate(values)
              .then(() => undefined)
              .catch(() => FORM_ERROR)
          }}
          initialValues={userAttributes}
        >
          {props => (
            <>
              <Subheading>Name</Subheading>
              <Field name='name'>
                {props => (
                  <TextInput
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Subheading>Geschlecht</Subheading>
              <Field name='gender'>
                {props => (
                  <Picker
                    selectedValue={props.input.value}
                    onValueChange={itemValue => props.input.onChange(itemValue)}
                    itemStyle={{
                      fontSize: moderateScale(14, 0.7),
                      height: moderateScale(50, 0.7)
                    }}
                  >
                    <Picker.Item label='männlich' value='male' />
                    <Picker.Item label='weiblich' value='female' />
                    <Picker.Item label='anderes / keine Angabe' value='other' />
                  </Picker>
                )}
              </Field>
              <Subheading>Geburtsjahr</Subheading>
              <Field name='birthdate'>
                {props => (
                  <IntegerInput
                    disabled
                    input={{
                      value: parseInt(props.input.value.substring(0, 4)),
                      onChange: value => props.input.onChange(value + '-01-01')
                    }}
                  />
                )}
              </Field>
              <Subheading>Telefonnummer</Subheading>
              <Field name='phone_number'>
                {props => (
                  <TextInput
                    disabled
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Subheading>E-Mail-Addresse</Subheading>
              <Field name='email'>
                {props => (
                  <TextInput
                    disabled={isFederated || user?.username.includes('email')}
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Subheading>Postanschrift</Subheading>
              <Field name='address'>
                {props => (
                  <TextInput
                    multiline
                    value={props.input.value}
                    onChange={props.input.onChange}
                  />
                )}
              </Field>
              <Button
                style={{ marginVertical: moderateScale(8) }}
                mode='outlined'
                onPress={() => navigation.navigate('PassportUpdate')}
              >
                <Text style={{ color: theme.colors.primary }}>
                  Optische Daten aktualisieren
                </Text>
              </Button>
              <Subheading>Profilbild</Subheading>
              <Field name='picture'>
                {props => (
                  <Photo
                    input={{ ...props.input }}
                    manipulateActions={[
                      {
                        resize: { width: 200 }
                      }
                    ]}
                  />
                )}
              </Field>
              <View style={{ flexDirection: 'row', marginHorizontal: -4 }}>
                <Button
                  disabled={props.pristine || props.invalid || props.submitting}
                  style={{ flex: 1, marginTop: 8, marginHorizontal: 4 }}
                  mode='contained'
                  onPress={props.handleSubmit}
                >
                  <Text
                    style={{
                      color:
                        props.pristine || props.invalid || props.submitting
                          ? theme.colors.disabled
                          : '#fff'
                    }}
                  >
                    speichern
                  </Text>
                </Button>
                <Button
                  disabled={props.pristine || props.submitting}
                  style={{ flex: 1, marginTop: 8, marginHorizontal: 4 }}
                  mode='outlined'
                  onPress={() => props.form.reset()}
                >
                  <Text
                    style={{
                      color:
                        props.pristine || props.submitting
                          ? theme.colors.disabled
                          : theme.colors.primary
                    }}
                  >
                    zurücksetzen
                  </Text>
                </Button>
              </View>
            </>
          )}
        </Form>
        <Divider
          style={{
            marginVertical: moderateScale(10),
            backgroundColor: '#666666'
          }}
        />
        <Subheading>
          {!isOptician ? 'Mein Optiker' : 'Meine Filliale'}
        </Subheading>
        {!isOptician ? <LocalOptician /> : <MyStoreOptician />}
        <Divider
          style={{
            marginVertical: moderateScale(16),
            backgroundColor: '#666666'
          }}
        />
        <Button
          style={{ flex: 1, marginHorizontal: scale(4) }}
          mode='outlined'
          onPress={() =>
            Linking.openURL(
              'mailto:patrick.schlager@vispecs.com?subject=Datenschutzanfrage'
            )
          }
        >
          <Text style={{ color: theme.colors.primary }}>
            Datenschutzanfrage
          </Text>
        </Button>
        <LinkButton icon='information-outline' to='/legal'>
          <Text style={{ color: theme.colors.primary }}>
            Impressum &amp; Datenschutzerklärung
          </Text>
        </LinkButton>
        <Divider />
        <Button onPress={handleSignOut}>Abmelden</Button>
      </Blank>
    </DefaultScreen>
  )
}
