export default [
  {
    id: 478193,
    name: 'Optik Jirasko',
    address: 'Zehnergürtel 12-24, 2700 Wiener Neustadt',
    lat: '47.819327',
    lng: '16.21714'
  },
  {
    id: 478113,
    name: 'Optik Jirasko',
    address: 'Bahngasse 44, 2700 Wiener Neustadt',
    lat: '47.8113874',
    lng: '16.2379126'
  }
]
