import Constants from 'expo-constants'
import React, { useState } from 'react'
import {
  Platform,
  Text,
  StyleSheet,
  Image,
  View,
  ScrollView
} from 'react-native'
import theme from '../styles/theme'
// import SocialItem from '../components/Social/SocialItem'
import {
  Divider,
  Subheading,
  TextInput,
  Button,
  Headline
} from 'react-native-paper'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useUser } from '../hooks/user'
import Logo from '../../assets/logo_rounded.png'
import SocialItem from '../components/Social/SocialItem'

const endpoint = 'gitlab.com'
const token = 'LVhbszrUmcXCsgseavzD'
const projectId = '15836497'

export default () => {
  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const { userAttributes } = useUser()

  const handleSend = () => {
    const formattedDescription =
      '# App Report' +
      '\n>>>\n' +
      text +
      '\n>>>\n## UserAttributes\n```json\n' +
      JSON.stringify(
        userAttributes || { userAttributes: 'no user logged in' },
        null,
        2
      ) +
      '\n```\n## Platform\n```json\n' +
      JSON.stringify(Platform, null, 2) +
      '\n```\n## Constants\n```json\n' +
      JSON.stringify(Constants, null, 2) +
      '\n```'

    fetch(`https://${endpoint}/api/v4/projects/${projectId}/issues`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'PRIVATE-TOKEN': token
      },
      body: JSON.stringify({
        title: title,
        description: formattedDescription,
        labels: 'App Report'
      })
    })
      .then(() => {
        setTitle('')
        setText('')
      })
      .catch(console.error)
  }

  return (
    <DefaultScreen>
      <ScrollView
        contentContainerStyle={{
          alignItems: Platform.OS === 'web' ? 'center' : undefined
        }}
      >
        {Platform.OS === 'web' ? (
          <>
            <Image source={Logo} style={styles.logo} />
            <Text style={styles.name}>ViSpecs GmbH</Text>
            <Text style={styles.headline}>Feedback</Text>
          </>
        ) : (
          <Headline style={{ marginTop: 50, marginLeft: 20 }}>
            Feedback
          </Headline>
        )}
        <View
          style={{
            padding: 10,
            flex: 1,
            width: Platform.OS === 'web' ? '70%' : undefined,
            marginTop: Platform.OS === 'web' ? 50 : 6,
            alignItems: 'center'
          }}
        >
          <Subheading>Betreff</Subheading>
          <TextInput
            value={title}
            onChangeText={setTitle}
            style={{ width: '90%', marginBottom: 40 }}
          />
          <Subheading>Nachricht</Subheading>
          <TextInput
            value={text}
            onChangeText={setText}
            multiline
            style={{
              width: '90%',
              height: Platform.OS === 'web' ? 200 : undefined
            }}
          />
          <Button
            mode='contained'
            disabled={title.trim() === '' || text.trim() === ''}
            onPress={handleSend}
            style={{ width: '40%', marginTop: 40 }}
          >
            <Text
              style={{
                color:
                  title.trim() === '' || text.trim() === ''
                    ? theme.colors.disabled
                    : '#fff'
              }}
            >
              Senden
            </Text>
          </Button>

          {/* {Platform.OS !== 'web' && ( */}
          <>
            <Divider
              style={{
                height: 2,
                width: '100%',
                marginVertical: 30,
                color: '#999'
              }}
            />
            <View style={{ flex: 1, flexDirection: 'column' }}>
              <Subheading>
                Sie können uns auch gerne über unsere Social-Media Kanäle
                kontaktieren.
              </Subheading>
              <View
                style={{
                  marginTop: 40,
                  height: 40,
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <SocialItem
                  width='33%'
                  url='https://www.instagram.com/vispecs/'
                  image={require('../../assets/social/instagram.png')}
                />
                <SocialItem
                  width='33%'
                  url='https://www.facebook.com/vispecs/'
                  image={require('../../assets/social/facebook.png')}
                />
                <SocialItem
                  width='33%'
                  url='https://www.linkedin.com/in/vispecs-gmbh-6026311a3/'
                  image={require('../../assets/social/linkedin.png')}
                />
              </View>
              <View
                style={{
                  marginTop: 5,
                  alignItems: 'center',
                  flexDirection: 'row'
                }}
              >
                <Text
                  style={{
                    width: '33%',
                    alignSelf: 'center',
                    textAlign: 'center'
                  }}
                >
                  Instagram
                </Text>
                <Text
                  style={{
                    width: '33%',
                    alignSelf: 'center',
                    textAlign: 'center'
                  }}
                >
                  Facebook
                </Text>
                <Text
                  style={{
                    width: '33%',
                    alignSelf: 'center',
                    textAlign: 'center'
                  }}
                >
                  LinkedIn
                </Text>
              </View>
            </View>
          </>
          {/* )} */}
        </View>
      </ScrollView>
    </DefaultScreen>
  )
}

const styles = StyleSheet.create({
  headline: {
    fontSize: 36,
    marginBottom: -40
  },
  name: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 20
  },
  contact: {
    fontSize: 22
  },
  logo: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10,
    height: 280,
    width: 280
  }
})
