import React from 'react'
import PlainSurface from '../Surfaces/PlainSurface'
import { Headline, Paragraph } from 'react-native-paper'
import { View } from 'react-native'
import DefaultScreen from '../screens/DefaultScreen'
import { ScrollView } from 'react-native-gesture-handler'
import Hexagon from '../templates/Hexagon'

export default ({ children, description }) => {
  return (
    <DefaultScreen>
      <Hexagon>
        <ScrollView style={{ width: '100%' }}>
          <View
            style={{
              marginTop: 6,
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <PlainSurface style={{ width: '100%' }}>
              <Headline style={{ marginTop: -10 }}>
                Empfehlung von ViSpecs
              </Headline>
              {description && <Paragraph>{description}</Paragraph>}
              {children}
            </PlainSurface>
          </View>
        </ScrollView>
      </Hexagon>
    </DefaultScreen>
  )
}
