import { useHeaderHeight } from '@react-navigation/stack'
import React, { useEffect } from 'react'
import { Platform, ScrollView, View } from 'react-native'
import AssessmentDetails from '../../components/Optician/AssessmentDetails'
// eslint-disable-next-line no-unused-vars
import CompactEvaluationCustomer from '../../components/Optician/Evaluation/CompactEvaluation.customer'
import OpticianDashboardCard from '../../components/Optician/OpticianDashboardCard'
import DefaultScreen from '../../components/screens/DefaultScreen'
import { useAssessment } from '../../hooks/user/assessment'
import { translateAssessment } from '../../services/assessmentFormatter'

export default () => {
  const { formattedGlassAssessment, fetchGlassAssessment } = useAssessment()

  const headerHeight = useHeaderHeight()

  useEffect(() => {
    fetchGlassAssessment()
  }, [])

  const ViewComponent = Platform.OS === 'web' ? View : ScrollView

  return (
    <DefaultScreen isOptician>
      <ViewComponent
        style={{
          marginTop: Platform.OS === 'web' ? 6 : headerHeight,
          alignSelf: 'flex-start',
          flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap',
          flexDirection: Platform.OS === 'web' ? 'row' : 'column',
          width: '100%'
        }}
      >
        {/* <OpticianDashboardCard
          icon='shopping'
          header='Empfehlung Brillenglas'
          collapsible
        >
          <CompactEvaluationCustomer type='glasses' />
        </OpticianDashboardCard> */}
        <OpticianDashboardCard
          icon='account-badge-outline'
          header='Bedarfsanalyse'
          collapsible
        >
          {formattedGlassAssessment && (
            <AssessmentDetails
              type='GLASSES'
              assessment={translateAssessment(formattedGlassAssessment)}
            />
          )}
        </OpticianDashboardCard>
      </ViewComponent>
    </DefaultScreen>
  )
}
