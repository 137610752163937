export default [
  {
    name: 'Alcon',
    image: 'https://vispecs.com/appdata/Lenses/Alcon.png',
    homepage: 'https://de.myalcon.com',
    standardTier: {
      regular: 'https://de.myalcon.com/de/professional/products/',
      toric: 'https://de.myalcon.com/de/professional/products/',
      mf: 'https://de.myalcon.com/de/professional/products/'
    },
    premiumTier: {
      regular: 'https://de.myalcon.com/de/professional/products/',
      toric: 'https://de.myalcon.com/de/professional/products/',
      mf: 'https://de.myalcon.com/de/professional/products/'
    }
  },
  {
    name: 'Johnson & Johnson',
    image: 'https://vispecs.com/appdata/Lenses/johnson.png',
    homepage: 'https://www.jnjvisioncare.at/',
    standardTier: {
      regular:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
      toric:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
      mf:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys'
    },
    premiumTier: {
      regular:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
      toric:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
      mf:
        'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys'
    }
  },
  {
    name: 'Coopervision',
    image: 'https://vispecs.com/appdata/Lenses/cooper.png',
    homepage: 'https://coopervision.de',
    standardTier: {
      regular: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
      toric: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
      mf: 'https://coopervision.de/kontaktlinsen/biofinity-familie'
    },
    premiumTier: {
      regular: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
      toric: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
      mf: 'https://coopervision.de/kontaktlinsen/biofinity-familie'
    }
  },
  {
    name: 'Bausch & Lomb',
    image: 'https://vispecs.com/appdata/Lenses/Bausch.png',
    homepage: 'https://www.bausch-lomb.de',
    standardTier: {
      regular: null,
      toric: null,
      mf: null
    },
    premiumTier: {
      regular: null,
      toric: null,
      mf: null
    }
  },
  {
    name: 'Menicon',
    image: 'https://vispecs.com/appdata/Lenses/menicon.png',
    homepage: 'https://www.menicon.de/consumer/',
    standardTier: {
      regular: null,
      toric: null,
      mf: null
    },
    premiumTier: {
      regular: null,
      toric: null,
      mf: null
    }
  }
]
