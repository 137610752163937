import React, { createContext, useContext, useState } from 'react'
import * as queries from '../../graphql/queries'
import * as subscriptions from '../../graphql/subscriptions'
import { API, graphqlOperation } from '@aws-amplify/api'
import { useUser } from './index'

const useProvidePassport = () => {
  const [glassesPassport, setGlassesPassport] = useState()
  const [lensesPassport, setLensesPassport] = useState()
  const { user, userAttributes } = useUser()

  const fetchGlassesPassport = () => {
    return fetchPassport('Brillenpass')
  }

  const fetchLensesPassport = () => {
    return fetchPassport('Kontaktlinsenpass')
  }

  const fetchPassports = () => {
    Promise.all([fetchGlassesPassport(), fetchLensesPassport()]).then(() => {})
    const passportSubscription = subscribePassport()

    return () => {
      passportSubscription.unsubscribe()
    }
  }

  const fetchPassport = type => {
    if (!user) {
      setGlassesPassport(null)
      setLensesPassport(null)
      return Promise.resolve()
    } else {
      return API.graphql(
        graphqlOperation(queries.listPassports, {
          userId: userAttributes.sub
        })
      )
        .then(({ data }) => {
          let lense = undefined
          let glass = undefined
          for (let i = data.listPassports.items.length - 1; i >= 0; i--) {
            if (
              type === 'Brillenpass' &&
              data.listPassports.items[i].type === 'GLASSES'
            ) {
              glass = data.listPassports.items[i]
              break
            } else if (
              type === 'Kontaktlinsenpass' &&
              data.listPassports.items[i].type !== 'GLASSES'
            ) {
              lense = data.listPassports.items[i]
              break
            }
          }
          if (type === 'Brillenpass') {
            if (glass === undefined) setGlassesPassport(null)
            else setGlassesPassport(glass)
          } else if (type === 'Kontaktlinsenpass') {
            if (lense === undefined) setLensesPassport(null)
            else setLensesPassport(lense)
          }
        })
        .catch(console.error)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const subscribePassport = () => {
    return API.graphql(
      graphqlOperation(subscriptions.onCreatePassport, {
        userId: userAttributes?.sub
      })
    ).subscribe({
      next: fetchPassports // fixme
    })
  }

  return {
    glassesPassport,
    lensesPassport,
    fetchGlassesPassport,
    fetchLensesPassport,
    fetchPassports
  }
}

const PassportContext = createContext()

export const PassportProvider = ({ children }) => {
  const value = useProvidePassport()

  return (
    <PassportContext.Provider value={value}>
      {children}
    </PassportContext.Provider>
  )
}

export const usePassport = () => useContext(PassportContext)
