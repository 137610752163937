import React, { useState, useEffect } from 'react'
import { Card, TextInput, Button, List } from 'react-native-paper'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { useMarker } from '../../hooks/map/marker'
import Fuse from 'fuse.js'

export default props => {
  const [searchText, setSearchText] = useState('')
  const [searchResults, setSearchResults] = useState()
  const [showResultList, setShowResultList] = useState(false)

  const { markerList, fetchMarkerList } = useMarker()

  useEffect(() => {
    fetchMarkerList()
  }, [markerList])

  const markerSearch = new Fuse(markerList, {
    keys: ['name', 'address']
  })

  const handleSearchChange = () => {
    setSearchResults(markerSearch.search(searchText).slice(0, 6))
    setShowResultList(true)
  }

  return (
    <View style={styles.search}>
      <Card style={styles.searchCard}>
        <TextInput
          style={styles.textInput}
          placeholder='Suche'
          mode='outlined'
          value={searchText}
          onChangeText={text => setSearchText(text)}
        />
        <Button
          style={styles.button}
          mode='contained'
          onPress={() => handleSearchChange()}
        >
          Suchen
        </Button>
        {/* <Button style={{ marginTop: 5, marginBottom: 0 }} mode='outlined' onPress={props.onCancelClick}>Abbrechen</Button> */}
      </Card>
      {showResultList && (
        <Card style={styles.resultCard}>
          {searchResults.map(res => (
            <TouchableOpacity
              key={Math.random()}
              onPress={() => props.onSearchResultClick(res.item)}
            >
              <List.Item title={res.item.name} description={res.item.address} />
            </TouchableOpacity>
          ))}
        </Card>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  search: {
    position: 'absolute',
    top: 10,
    left: 10,
    width: 400,
    zIndex: 10
  },
  searchCard: {
    padding: 10,
    paddingTop: 5
  },
  resultCard: {
    padding: 10,
    paddingTop: 5,
    marginTop: 10
  },
  button: {
    bottom: 0,
    width: '100%',
    marginTop: 10
  }
})
