import { useNavigation } from '@react-navigation/native'
import React, { useLayoutEffect } from 'react'
import { View } from 'react-native'
import Hexagon from '../components/templates/Hexagon'
import NextButton from './NextButton'
import DefaultScreen from '../components/screens/DefaultScreen'
import ExitButton from '../components/general/ExitButton'

export default React.memo(({ children }) => {
  const navigation = useNavigation()

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => <ExitButton />
    })
  })

  return (
    <DefaultScreen hideNavbar>
      <Hexagon offsetHeader>
        <View
          style={{
            flex: 1,
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          {children}
        </View>
        <NextButton>weiter</NextButton>
      </Hexagon>
    </DefaultScreen>
  )
})
