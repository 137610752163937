import React from 'react'
import ActionCard from '../../components/ActionCard'
import Background from '../../../assets/backgrounds/alphabet-table-with-glasses-pink-background/2992926lr.jpg'
import { Paragraph } from 'react-native-paper'
import { useLinkProps } from '@react-navigation/native'

export default props => {
  const { onPress } = useLinkProps({ to: '/survey' })

  return (
    <ActionCard
      title='Bedarfsanalyse'
      subtitle='Ihre Auswertungen'
      background={Background}
      handlePress={onPress}
    >
      <Paragraph>
        Finden Sie alle Ihre Bedarfsanalysen gesammelt für Ihren Optiker.
      </Paragraph>
    </ActionCard>
  )
}
