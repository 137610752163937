import React from 'react'
import { View, Text, Platform } from 'react-native'
import theme from '../../../styles/theme'

export default ({
  title,
  description,
  antireflective,
  blueprotection,
  dir
}) => {
  return (
    <View
      style={{
        flex: Platform.OS === 'web' ? 1 : 0,
        minWidth: Platform.OS === 'web' ? 200 : undefined,
        backgroundColor: theme.colors.pinkShade,
        border: 1,
        borderRadius: theme.roundness,
        padding: 12,
        margin: 6,
        width: Platform.OS === 'web' ? undefined : dir ? '45%' : '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      {dir && (
        <View style={{ flex: 1, alignItems: 'flex-start' }}>
          <Text style={{ fontSize: 18, fontWeight: '600' }}>{dir}</Text>
        </View>
      )}
      <View style={{ flex: 1, alignItems: dir ? 'flex-end' : 'flex-start' }}>
        <Text style={{ fontSize: 18, fontWeight: '700' }}>{title}</Text>
        {description && (
          <Text style={{ fontSize: 16, fontWeight: '600' }}>{description}</Text>
        )}
        {antireflective && (
          <Text style={{ fontSize: 14, fontWeight: '500' }}>
            Entspiegelung: {antireflective}
          </Text>
        )}
        {blueprotection && (
          <Text style={{ fontSize: 14, fontWeight: '500' }}>
            Blueprotection
          </Text>
        )}
      </View>
    </View>
  )
}
