import React from 'react'
import Badge from './Badge'

export default ({ width, height }) => {
  return (
    <Badge
      width={width || 270}
      height={height || 80}
      padding={16}
      uri='https://linkmaker.itunes.apple.com/de-de/badge-lrg.svg?releaseDate=2020-07-13&kind=iossoftware&bubble=ios_apps'
      url='https://apps.apple.com/at/app/vispecs/id1508489528?mt=8'
      alt='Laden im App Store'
    />
  )
}
