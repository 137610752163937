import React, { useState } from 'react'
import { Platform, View } from 'react-native'
import theme from '../../styles/theme'
import Slider from '@react-native-community/slider'
import { Subheading } from 'react-native-paper'
import { scale } from '../../scaling/ScaledUI'

export default props => {
  const { options, input, name, initital, size, disabled } = props
  const { onChange } = input
  const [step, setStep] = useState(getInitial())

  function getInitial () {
    // console.log(initital + '    ' + JSON.stringify(options, null, 4))
    if (options.length === 2) {
      if (initital === 'standard') return 0
      if (initital === 'no') return 0
      else return 1
    }
    if (initital === 'standard') return 0
    if (initital === 'bestseller') return 1
    if (initital === 'premium') return 2
    if (initital === 'daily') return 0
    if (initital === 'weekly') return 1
    if (initital === 'monthly') return 2
    return 0
  }

  const handleSlidingComplete = step => {
    if (options && options[step] && options[step].id) {
      onChange(options[step].id)
    }
  }

  return (
    <View style={{ flex: 1, flexDirection: 'row' }}>
      <Subheading style={{ flex: 1, alignSelf: 'center' }}>{name}</Subheading>
      <View style={{ flex: 1 }}>
        <View
          style={{ zIndex: 2, flex: 1, flexDirection: 'row', width: '100%' }}
        >
          <Subheading
            style={{
              zIndex: 2,
              color: disabled ? '#deb8cf' : theme.colors.primary
            }}
          >
            {options && options[step] && options[step].text
              ? options[step].text
              : ' '}
          </Subheading>
        </View>
        <Slider
          disabled={disabled}
          style={{
            zIndex: 0,
            height: Platform.OS === 'ios' ? scale(45) : 20,
            backgroundColor: 'transparent',
            backfaceVisibility: 'hidden'
          }}
          value={step}
          onValueChange={setStep}
          onSlidingComplete={handleSlidingComplete}
          thumbTintColor={theme.colors.primary}
          minimumTrackTintColor={theme.colors.primary}
          maximumTrackTintColor='#d991bd'
          minimumValue={0}
          maximumValue={size - 1}
          step={1}
        />
      </View>
    </View>
  )
}
