export default {
  Hoya: {
    image: 'https://vispecs.com/appdata/Images/hoya.png',
    agreement: true,
    homepage: 'https://www.hoyavision.com/de',
    singleVisionTier: {
      standard: null,
      bestseller: [
        'Hi-Lux',
        172,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/glas-materialien/hi-lux-150/'
      ],
      premium: [
        'Nulux iDentity V+',
        290,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/einstarken-glaser/individuelle/'
      ]
    },
    varifocalTier: {
      standard: null,
      bestseller: [
        'Life Style 3',
        406,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/einstarken-glaser/digitaler-lebensstil/'
      ],
      premium: [
        'Life Style 3i',
        440,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/gleitsicht-glaser/lifestlye-3/'
      ]
    },
    digitalTier: {
      standard: [
        'Einstärken Sync IIIi',
        468,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/gleitsicht-glaser/lifestlye-3/'
      ],
      bestseller: [
        'Einstärken Sync IIIi',
        468,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/gleitsicht-glaser/lifestlye-3/'
      ],
      premium: [
        'Einstärken Sync IIIi',
        468,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/gleitsicht-glaser/lifestlye-3/'
      ]
    },
    workstationTier: {
      standard: [
        'Lecture B True Form',
        420,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/glaser-nahbereich/premium-pc-glaeser/'
      ],
      bestseller: [
        'Hoyalux Tact TrueForm',
        500,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/glaser-nahbereich/premium-pc-glaeser/'
      ],
      premium: [
        'Hoyalux iD WorkStyle V+',
        740,
        'https://www.hoyavision.com/de/produkte-entdecken/fur-optiker/glaser-nahbereich/individuelle/'
      ]
    },
    antireflectiveTier: {
      none: null,
      standard: ['HVA', 0],
      premium: ['HVL', 30]
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  Zeiss: {
    image: 'https://vispecs.com/appdata/Images/zeiss.png',
    homepage: 'https://www.zeiss.at',
    singleVisionTier: {
      standard: null,
      bestseller: [
        'Einstärken',
        232,
        'https://www.zeiss.at/vision-care/zeiss-brillenglaeser/einstaerken-brillenglaeser.html'
      ],
      premium: [
        'Einstärken Superb',
        322,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/einstaerkenglaeser/einstaerken-superb.html'
      ]
    },
    varifocalTier: {
      standard: null,
      bestseller: [
        'Precision Plus',
        806,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/gleitsichtglaeser-uebersicht.html'
      ],
      premium: [
        'Precision Plus Superb',
        930,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/gleitsichtglaeser-uebersicht.html '
      ]
    },
    digitalTier: {
      standard: [
        'Digital',
        554,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/digital-brillenglaeser.html'
      ],
      bestseller: [
        'Digital',
        554,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/digital-brillenglaeser.html'
      ],
      premium: [
        'Digital',
        554,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/digital-brillenglaeser.html'
      ]
    },
    workstationTier: {
      standard: [
        'Office Lens Plus',
        420,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/officelens-portfolio.html'
      ],
      bestseller: [
        'Office Lens Superb',
        560,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/officelens-portfolio.html'
      ],
      premium: [
        'Office Lens Individual',
        710,
        'https://www.zeiss.at/vision-care/fuer-augenoptiker/produkte/brillenglaeser/officelens-portfolio.html'
      ]
    },
    antireflectiveTier: {
      none: null,
      standard: ['Lotu Tec', 0],
      premium: ['Dura Vision Platinum', 42]
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  Essilor: {
    image: 'https://vispecs.com/appdata/Images/essilor.png',
    homepage: 'https://www.essilor.at',
    singleVisionTier: {
      standard: null,
      bestseller: [
        'Einstärken',
        332,
        'https://www.essilor.at/rund-ums-sehen/sehanforderungen/einstaerkenglaeser'
      ],
      premium: [
        'Essidrive Einstärken',
        360,
        'https://www.essilor.at/rund-ums-sehen/sehanforderungen/einstaerkenglaeser'
      ]
    },
    varifocalTier: {
      standard: null,
      bestseller: [
        'Varilux Comfort 3.0',
        770,
        'https://www.essilorpro.de/Produkte/brillenglaser/Varilux/Seiten/VariluxComfort3.aspx'
      ],
      premium: [
        'Varilux Physio 3.0',
        892,
        'https://www.essilorpro.de/Produkte/brillenglaser/Varilux/Seiten/VariluxPhysio3.aspx'
      ]
    },
    digitalTier: {
      standard: [
        'Eyezen',
        450,
        'https://www.essilor.at/brillenglaeser/eyezen '
      ],
      bestseller: [
        'Eyezen',
        450,
        'https://www.essilor.at/brillenglaeser/eyezen '
      ],
      premium: ['Eyezen', 450, 'https://www.essilor.at/brillenglaeser/eyezen ']
    },
    workstationTier: {
      standard: [
        'Eyezen Start',
        400,
        'https://www.essilorpro.de/Produkte/brillenglaser/Essilor%20Eyezen/Eyezen%20Start/Seiten/default.aspx'
      ],
      bestseller: [
        'Eyezen Boost',
        444,
        'https://www.essilorpro.de/Produkte/brillenglaser/Essilor%20Eyezen/Seiten/Eyezenboost.aspx'
      ],
      premium: [
        'Eyezen Boost f-360°',
        490,
        'http://portal.essilor.hr/Produkte/brillenglaser/einstaerken/Seiten/Essilor%20f-360.aspx'
      ]
    },
    antireflectiveTier: {
      none: null,
      standard: ['Crizal Easy UV', 0],
      premium: ['Crizal Sapphire UV', 68]
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  Rodenstock: {
    image: 'https://vispecs.com/appdata/Images/rodenstock.png',
    agreement: true,
    homepage: 'https://www.rodenstock.at',
    singleVisionTier: {
      standard: null,
      bestseller: [
        'Superior Einstärkenglas',
        404,
        'https://www.rodenstock.at/at/de/fernbrille.html'
      ],
      premium: [
        'Multigressive Mono 2',
        440,
        'https://www.rodenstock.at/at/de/fernbrille.html'
      ]
    },
    varifocalTier: {
      standard: null,
      bestseller: [
        'Multigressive My View 2',
        778,
        'https://www.rodenstock.at/at/de/gleitsichtbrille.html'
      ],
      premium: [
        'Pure Life Free 2',
        922,
        'https://www.rodenstock.at/at/de/gleitsichtbrille.html'
      ]
    },
    digitalTier: {
      standard: [
        'Mono Plus 2',
        440,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ],
      bestseller: [
        'Mono Plus 2',
        440,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ],
      premium: [
        'Mono Plus 2',
        440,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ]
    },
    workstationTier: {
      standard: [
        'Progressiv Ergo PC',
        500,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ],
      bestseller: [
        'Multigressiv Ergo Room',
        540,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ],
      premium: [
        'Impression Ergo Room',
        620,
        'https://www.rodenstock.at/at/de/bildschirmbrille.html'
      ]
    },
    antireflectiveTier: {
      none: null,
      standard: ['Solitaire Protect Plus 2', 0],
      premium: ['Solitaire Protect Pro 2', 30]
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  'Brille & Glas': {
    image: 'https://vispecs.com/appdata/Images/brille_u_glas.png',
    homepage: 'https://www.brilleundglas.at',
    singleVisionTier: {
      standard: ['Einstärkenglas Standard', 60],
      bestseller: null,
      premium: null
    },
    varifocalTier: {
      standard: ['Gleitsichtglas Standard', 250],
      bestseller: null,
      premium: null
    },
    digitalTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    workstationTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    antireflectiveTier: {
      none: ['Ohne Entspiegelung', 0],
      standard: ['Standard Entspiegelung', 20],
      premium: null
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  'Austrian Optic Technologies': {
    image: 'https://vispecs.com/appdata/Images/austrian.png',
    homepage: 'http://www.austrian-optic-technologies.at',
    singleVisionTier: {
      standard: [
        'Einstärkenglas Standard',
        70,
        'http://www.austrian-optic-technologies.at/brillenglaeser/'
      ],
      bestseller: null,
      premium: null
    },
    varifocalTier: {
      standard: [
        'Gleitsichtglas Standard',
        300,
        'http://www.austrian-optic-technologies.at/brillenglaeser/'
      ],
      bestseller: null,
      premium: null
    },
    digitalTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    workstationTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    antireflectiveTier: {
      none: ['Ohne Entspiegelung', 0],
      standard: ['Standard Entspiegelung', 20],
      premium: null
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  OptoVision: {
    image: 'https://vispecs.com/appdata/Images/optovision.png',
    homepage: 'https://www.optovision.com',
    singleVisionTier: {
      standard: [
        'Einstärkenglas Standard',
        70,
        'https://www.optovision.com/brillenglaeser/einstaerkenglaeser/einstaerkenglaeser.html'
      ],
      bestseller: null,
      premium: null
    },
    varifocalTier: {
      standard: [
        'Gleitsichtglas Standard',
        300,
        'https://www.optovision.com/brillenglaeser/einstaerkenglaeser/gleitsichtglaeser.html'
      ],
      bestseller: null,
      premium: null
    },
    digitalTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    workstationTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    antireflectiveTier: {
      none: ['Ohne Entspiegelung', 0],
      standard: ['Standard Entspiegelung', 20],
      premium: null
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  },
  MPO: {
    image: 'https://vispecs.com/appdata/Images/mpo.png',
    homepage: 'https://www.mpo-lenses.com',
    singleVisionTier: {
      standard: [
        'Einstärkenglas Standard',
        70,
        'https://www.mpo-lenses.com/mpo-brillenglaeser/einstaerkenglaeser'
      ],
      bestseller: null,
      premium: null
    },
    varifocalTier: {
      standard: [
        'Gleitsichtglas Standard',
        300,
        'https://www.mpo-lenses.com/mpo-brillenglaeser/gleitsichtglaeser'
      ],
      bestseller: null,
      premium: null
    },
    digitalTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    workstationTier: {
      standard: null,
      bestseller: null,
      premium: null
    },
    antireflectiveTier: {
      none: ['Ohne Entspiegelung', 0],
      standard: ['Standard Entspiegelung', 20],
      premium: null
    },
    sunglassesChoice: {
      none: ['Keiner', 0, 0],
      photochromic: {
        name: 'Selbsttönend',
        singleVisionTier: 70,
        varifocalTier: 90,
        digitalTier: 90,
        workstationTier: 90
      },
      tinted: {
        name: '',
        singleVisionTier: 60,
        varifocalTier: 60,
        digitalTier: 60,
        workstationTier: 60
      }
    },
    blueprotection: {
      standard: 30,
      bestseller: 0,
      premium: 0
    }
  }
}
