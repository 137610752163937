import React, { useState } from 'react'
import DefaultScreen from '../components/screens/DefaultScreen'
import LandingBlock from '../components/Landing/LandingBlock'
import Background from '../../assets/bg_hexa@3.5x_mirror.png'
import MockupShow from '../components/Landing/MockupShow'
import { View, SectionList, Text, StyleSheet, Image } from 'react-native'
import MediaQuery from 'react-native-web-responsive'

export default () => {
  const [mark, setMark] = useState(0)

  const styles = StyleSheet.create({
    header: {
      fontSize: 34,
      fontWeight: '600',
      color: '#d5d5d5'
    },
    markHeader: {
      fontSize: 34,
      fontWeight: '700',
      color: '#000'
      // textShadowOffset: { width: 1, height: 1 },
      // textShadowRadius: 4,
      // textShadowColor: '#888'
    },
    text: {
      marginTop: 0,
      marginBottom: 60,
      fontSize: 23,
      fontWeight: '500',
      color: '#d5d5d5'
    },
    markText: {
      marginTop: 0,
      marginBottom: 60,
      fontSize: 23,
      fontWeight: '500',
      color: '#000'
      // textShadowOffset: { width: 1, height: 1 },
      // textShadowRadius: 2,
      // textShadowColor: '#444'
    },
    desc: {
      marginTop: 20,
      fontStyle: 'italic',
      fontSize: 28,
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center'
    },
    descMobile: {
      marginTop: 0,
      fontStyle: 'italic',
      fontSize: 28,
      justifyContent: 'center',
      alignSelf: 'center',
      textAlign: 'center'
    }
  })

  const handleImageChange = val => {
    setMark(val)
  }

  const getIndex = str => {
    let i = 0
    let result = -1
    data.forEach(x => {
      if (x.data[0] === str) result = i
      else i++
    })
    return result
  }

  const getIndexFromTitle = str => {
    let i = 0
    let result = -1
    data.forEach(x => {
      if (x.title === str) result = i
      else i++
    })
    return result
  }

  const data = [
    {
      title: 'Digitaler Brillenpass',
      data: [
        'Digitalisieren Sie Ihre optischen Daten und sparen Sie Zeit bei Ihrem nächsten Optikerbesuch'
      ]
    },
    {
      title: 'Optimale Gläserwahl',
      data: [
        'Finden Sie Ihr optimales Glas mittels Ihrer optischen Daten und der individuellen Bedarfsanalyse'
      ]
    },
    {
      title: 'Ihre Bedarfsanalyse',
      data: [
        'Mit einer individuellen Bedarfsanalyse werden Ihre optischen Bedürfnisse ermittelt'
      ]
    },
    {
      title: 'Optikersuche',
      data: [
        'Für Ihr optisches Anliegen finden Sie über ViSpecs ganz einfach einen Optiker in Ihrer Umgebung'
      ]
    }
  ]

  const Item = ({ title }) => (
    <Text style={getIndex(title) === mark ? styles.markText : styles.text}>
      {title}
    </Text>
  )

  const MobileItem = ({ title }) => <Text style={styles.markText}>{title}</Text>

  return (
    <DefaultScreen background={Background} isLanding>
      <MediaQuery minWidth={1150}>
        <View
          style={{
            alignSelf: 'center',
            flexDirection: 'row',
            height: 1150,
            alignItems: 'center'
          }}
        >
          <View style={{ height: 800, marginRight: 80, marginTop: -60 }}>
            <LandingBlock width='40%'>
              <MockupShow
                scale={0.55}
                style={{ alignSelf: 'center' }}
                onChange={val => handleImageChange(val)}
              />
            </LandingBlock>
          </View>

          {/* <ScrollView style={{ flex: 1, flexDirection: 'column', height: '90%' }}> */}
          <View
            style={{
              alignSelf: 'center',
              alignItems: 'center',
              flex: 1,
              flexDirection: 'column',
              minWidth: 300,
              maxWidth: 480
            }}
          >
            <SectionList
              showsVerticalScrollIndicator={false}
              sections={data}
              keyExtractor={(item, index) => item + index}
              renderItem={({ item }) => <Item title={item} />}
              renderSectionHeader={({ section: { title } }) => (
                <Text
                  style={
                    getIndexFromTitle(title) === mark
                      ? styles.markHeader
                      : styles.header
                  }
                >
                  {title}
                </Text>
              )}
              // onViewableItemsChanged={({ viewableItems }) => setCurrentItem(viewableItems)}
            />
          </View>
          <MediaQuery minWidth={1500}>
            <View
              style={{
                flex: 1,
                alignSelf: 'center',
                marginLeft: 100,
                marginTop: -300,
                alignItems: 'center'
              }}
            >
              <Image
                source={require('../../assets/logo_text_full.png')}
                style={{
                  width: '100%',
                  height: 300,
                  flex: 1,
                  resizeMode: 'contain'
                }}
              />
              <Text style={styles.desc}>
                „Eine personalisierte Vorabberatung für Ihr optisches Anliegen“
              </Text>
            </View>
          </MediaQuery>
        </View>
      </MediaQuery>
      <MediaQuery maxWidth={1149}>
        <View style={{ width: '100%', flex: 1, alignItems: 'center' }}>
          <View
            style={{
              flex: 1,
              alignSelf: 'center',
              marginTop: 0,
              marginBottom: 50,
              alignItems: 'center'
            }}
          >
            <Image
              source={require('../../assets/logo_text_full.png')}
              style={{
                width: '100%',
                height: 300,
                flex: 1,
                resizeMode: 'contain'
              }}
            />
            <Text style={styles.descMobile}>
              „Eine personalisierte Vorabberatung für Ihr optisches Anliegen“
            </Text>
          </View>
          <SectionList
            showsVerticalScrollIndicator={false}
            sections={data}
            keyExtractor={(item, index) => item + index}
            renderItem={({ item }) => <MobileItem title={item} />}
            renderSectionHeader={({ section: { title } }) => (
              <Text style={styles.markHeader}>{title}</Text>
            )}
          />
        </View>
      </MediaQuery>
    </DefaultScreen>
  )
}
