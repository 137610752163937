export default {
  daily: {
    standardTier: {
      regular: [
        {
          manufacturer: 'Dailies',
          name: 'Aqua Comfort +',
          sphRange: [-15, 8],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC.png',
          price: [
            {
              qty: 30,
              price: '18,49'
            },
            {
              qty: 90,
              price: '36,20'
            }
          ]
        },
        {
          manufacturer: '1 Day',
          name: 'Acuvue Moist',
          sphRange: [-12, 6],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue.png',
          price: [
            {
              qty: 30,
              price: '19,89'
            },
            {
              qty: 90,
              price: '49,79'
            },
            {
              qty: 120,
              price: '96,99'
            }
          ]
        },
        {
          manufacturer: 'Softlense',
          name: 'Daily Disposable',
          sphRange: [-9, 6.5],
          url: 'https://vispecs.com/appdata/Lenses/Bausch.png',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue.png',
          price: [
            {
              qty: 30,
              price: '9,99€'
            },
            {
              qty: 90,
              price: '27,99€'
            }
          ]
        },
        {
          manufacturer: 'Miru',
          name: '1 day',
          sphRange: [-10, 0],
          url: 'https://www.menicon.de/consumer/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC.png',
          price: [
            {
              qty: 30,
              price: '24,99'
            }
          ]
        }
      ],
      toric: [
        {
          manufacturer: 'Dailies',
          name: 'Aqua Comfort + toric',
          sphRange: [-9, 4],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC_toric.png',
          price: [
            {
              qty: 30,
              price: '24,89'
            },
            {
              qty: 90,
              price: '49,50'
            }
          ]
        },
        {
          manufacturer: '1 Day',
          name: 'Acuvue Moist toric',
          sphRange: [-9, 6],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue_toric.png',
          price: [
            {
              qty: 30,
              price: '23,79'
            },
            {
              qty: 90,
              price: '64,59'
            }
          ]
        },
        {
          manufacturer: 'Softlense',
          name: 'Daily Disposable toric',
          sphRange: [-9.0, 0.0],
          url: 'https://vispecs.com/appdata/Lenses/Bausch.png',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue.png',
          price: [
            {
              qty: 30,
              price: '16,99€'
            },
            {
              qty: 90,
              price: '42,99€'
            }
          ]
        }
      ],
      mf: [
        {
          manufacturer: 'Dailies',
          name: 'Aqua Comfort + Multifocal',
          sphRange: [-10, 6],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC_mf.png',
          price: [
            {
              qty: 30,
              price: '24,89'
            },
            {
              qty: 90,
              price: '49,50'
            }
          ],
          mfTier: [
            ['Low', '1,25'],
            ['Med', '2,00'],
            ['High', '2,50']
          ]
        },
        {
          manufacturer: '1 Day',
          name: 'Acuvue Moist Multifokal',
          sphRange: [-9, 6],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue_mf.png',
          price: [
            {
              qty: 30,
              price: '28,49'
            },
            {
              qty: 90,
              price: '76,89'
            }
          ],
          mfTier: [
            ['Low', '0,75 - 1,25'],
            ['Med', '1,50 - 1,75'],
            ['High', '2,00 - 2,50']
          ]
        }
      ]
    },
    premiumTier: {
      regular: [
        {
          manufacturer: 'Dailies',
          name: 'Total 1',
          sphRange: [-12, 6],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Total1.png',
          price: [
            {
              qty: 30,
              price: '20,02'
            },
            {
              qty: 90,
              price: '69,49'
            }
          ]
        }
      ],
      toric: [],
      mf: [
        {
          manufacturer: 'Dailies',
          name: 'Total 1 Multifokal',
          sphRange: [-10, 6],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Total1_mf.png',
          price: [
            {
              qty: 30,
              price: '36,79'
            },
            {
              qty: 90,
              price: '99,89'
            }
          ],
          mfTier: [
            ['Low', '1,25'],
            ['Med', '2,00'],
            ['High', '2,50']
          ]
        }
      ]
    },
    wearDuration: 1
  },
  weekly: {
    wearDuration: 14,
    standardTier: {
      regular: [
        {
          manufacturer: 'Acuvue',
          name: 'Oasys',
          sphRange: [-12, 8],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue_Oasys.png',
          price: [
            {
              qty: 6,
              price: '19,90'
            },
            {
              qty: 12,
              price: '37,99'
            }
          ]
        }
      ],
      toric: [
        {
          manufacturer: 'Acuvue',
          name: 'Oasys toric',
          sphRange: [-9, 6],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue_Oasys_toric.png',
          price: [
            {
              qty: 6,
              price: '27,99'
            },
            {
              qty: 12,
              price: '52,99'
            }
          ]
        }
      ],
      mf: [
        {
          manufacturer: 'Acuvue',
          name: 'Oasys Multifocal',
          sphRange: [-9, 6],
          url:
            'https://www.jnjvisioncare.at/kontaktlinsen/alle-acuvue-kontaktlinsen/sphaerische-linsen/acuvue-oasys',
          image: 'https://vispecs.com/appdata/Lenses/Acuvue_Oasys_mf.png',
          price: [
            {
              qty: 6,
              price: '38,49'
            }
          ],
          mfTier: [
            ['Low', '0,75 - 1,25'],
            ['Med', '1,50 - 1,75'],
            ['High', '2,00 - 2,50']
          ]
        }
      ]
    },
    premiumTier: {
      regular: [],
      toric: [],
      mf: []
    }
  },
  monthly: {
    wearDuration: 30,
    standardTier: {
      regular: [
        {
          manufacturer: 'Air Optix',
          name: 'Hydraglyde',
          sphRange: [-12, 8],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Air_Optix.png',
          price: [
            {
              qty: 6,
              price: '20,39'
            }
          ]
        },
        {
          manufacturer: 'Pure Vision',
          name: ' ',
          sphRange: [-12, 6],
          url: 'https://www.bausch-lomb.de',
          image: 'https://www.bausch-lomb.de',
          price: [
            {
              qty: 6,
              price: '16,99'
            }
          ]
        },
        {
          manufacturer: 'Miru',
          name: '1 month',
          sphRange: [-13, 6],
          url: 'https://www.menicon.de/consumer/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC.png',
          price: [
            {
              qty: 6,
              price: '23,99'
            }
          ]
        }
      ],
      toric: [
        {
          manufacturer: 'Air Optix',
          name: 'Hydraglyde toric',
          sphRange: [-10, 6],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Air_Optix_toric.png',
          price: [
            {
              qty: 6,
              price: '16,99'
            }
          ]
        },
        {
          manufacturer: 'Pure Vision',
          name: 'toric',
          sphRange: [-9, 6],
          url: 'https://www.bausch-lomb.de',
          image: 'https://www.bausch-lomb.de',
          price: [
            {
              qty: 6,
              price: '31,99'
            }
          ]
        },
        {
          manufacturer: 'Miru',
          name: '1 month toric',
          sphRange: [-10, 0],
          url: 'https://www.menicon.de/consumer/',
          image: 'https://vispecs.com/appdata/Lenses/Dailies_AC.png',
          price: [
            {
              qty: 6,
              price: '30,99'
            }
          ]
        }
      ],
      mf: [
        {
          manufacturer: 'Air Optix',
          name: 'Hydraglyde Multifocal',
          sphRange: [-10, 6],
          url: 'https://de.myalcon.com/de/professional/products/',
          image: 'https://vispecs.com/appdata/Lenses/Air_Optix_mf.png',
          price: [
            {
              qty: 6,
              price: '39,38'
            }
          ],
          mfTier: [
            ['Low', '1,25'],
            ['Med', '2,00'],
            ['High', '2,50']
          ]
        }
      ]
    },
    premiumTier: {
      regular: [
        {
          manufacturer: 'Biofinity',
          name: ' ',
          sphRange: [-12, 8],
          url: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
          image: 'https://vispecs.com/appdata/Lenses/Biofinity.png',
          price: [
            {
              qty: 3,
              price: '15,99'
            },
            {
              qty: 6,
              price: '22,59'
            }
          ]
        }
      ],
      toric: [
        {
          manufacturer: 'Biofinity',
          name: 'toric',
          sphRange: [-10, 8],
          url: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
          image: 'https://vispecs.com/appdata/Lenses/Biofinity_toric.png',
          price: [
            {
              qty: 3,
              price: '16,99'
            },
            {
              qty: 6,
              price: '32,39'
            }
          ]
        }
      ],
      mf: [
        {
          manufacturer: 'Biofinity',
          name: 'Multifokal',
          sphRange: [-10, 6],
          url: 'https://coopervision.de/kontaktlinsen/biofinity-familie',
          image: 'https://vispecs.com/appdata/Lenses/Biofinity_mf.png',
          price: [
            {
              qty: 3,
              price: '25,99'
            },
            {
              qty: 6,
              price: '46,78'
            }
          ],
          mfTier: [
            ['N', '1,0'],
            ['D', '1,0'],
            ['N', '1,5'],
            ['D', '1,5'],
            ['N', '2,0'],
            ['D', '2,0'],
            ['N', '2,5'],
            ['D', '2,5']
          ]
        }
      ]
    }
  }
}
