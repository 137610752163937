import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { useGlassEvaluation } from '../../hooks/general/glassEvaluation'
import { useAssessment } from '../../hooks/user/assessment'
import GlassAssessmentManipulator from '../../components/Evaluation/GlassAssessmentManipulator'
import EvaluationWrapper from '../../components/Evaluation/EvaluationWrapper'
import { Divider, Text } from 'react-native-paper'
import GenericGlassEvaluation from '../../components/Evaluation/GenericGlassEvaluation'
import ManufacturerList from '../../components/Evaluation/ManufacturerList'
import SkeletonContent from 'react-native-skeleton-content'

export default ({ noWrapper }) => {
  const {
    evaluatedGlasses,
    evaluateAssessment,
    getCategory,
    getTier,
    getUvpRange,
    assessmentRuleEnforcer,
    getManufacturers
  } = useGlassEvaluation()

  const { formattedGlassAssessment } = useAssessment()

  const [modifiedAssessment, setAssessment] = useState(formattedGlassAssessment)
  const [manufacturerList, setManufacturerList] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 200)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (modifiedAssessment) {
      evaluateAssessment(modifiedAssessment.assessment)
    }
  }, [modifiedAssessment])

  const enforcedAss = modifiedAssessment
    ? assessmentRuleEnforcer(modifiedAssessment.assessment)
    : null

  const WrapperComponent = noWrapper ? View : EvaluationWrapper

  useEffect(() => {
    if (enforcedAss) {
      setManufacturerList(getManufacturers(enforcedAss, true))
    }
  }, [modifiedAssessment])

  useEffect(() => {
    setAssessment(formattedGlassAssessment)
  }, [formattedGlassAssessment])

  return (
    <WrapperComponent>
      {evaluatedGlasses && modifiedAssessment && enforcedAss && (
        <>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: 17,
              marginTop: 4,
              marginBottom: 6
            }}
          >
            Kategorie: {getCategory(modifiedAssessment.assessment)}
          </Text>
          <View
            style={{
              flexDirection: 'row'
            }}
          >
            <GenericGlassEvaluation
              data={[
                ['Glasqualität', getTier(enforcedAss)],
                ['Entspiegelung', enforcedAss?.wantsAntireflective[0]],
                ['Blaulichtschutz', enforcedAss?.applyBlueprotection[0]],
                ['Sonnenschutz', enforcedAss?.sunglassesChoice[0]]
              ]}
            />
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={{ fontWeight: 'bold' }}>Preisschätzung: </Text>
            <Text style={{ fontWeight: 'bold' }}>
              {getUvpRange(modifiedAssessment.assessment)}
            </Text>
            <Text
              style={{
                fontWeight: '300',
                fontSize: 12,
                alignSelf: 'flex-end'
              }}
            >
              {'  '}
              zzgl. Extras
            </Text>
          </View>
          <Divider
            style={{
              marginTop: 14,
              marginBottom: 8,
              height: 1.5,
              backgroundColor: '#ccc'
            }}
          />

          {modifiedAssessment && getTier(modifiedAssessment.assessment) !== '' && (
            <GlassAssessmentManipulator
              assessmentData={modifiedAssessment.assessment}
              width={'80%'}
              alignSelf='flex-start'
              input={{
                onChange: value => {
                  const t = modifiedAssessment
                  t.assessment = value
                  setAssessment(t)
                  setManufacturerList(getManufacturers(t.assessment, true))
                  evaluateAssessment(t.assessment)
                }
              }}
            />
          )}
          <Divider
            style={{
              marginTop: 8,
              marginBottom: 4,
              height: 1.5,
              backgroundColor: '#ccc'
            }}
          />
          {loading && (
            <Text style={{ marginVertical: 10, alignSelf: 'center' }}>
              Erfahren Sie mehr über Brillengläser in Ihrer Kategorie:
            </Text>
          )}
          <SkeletonContent
            isLoading={loading}
            // boneColor={theme.colors.bone}
            // highlightColor={theme.colors.boneHighlight}
            layout={[
              {
                key: 'line1',
                width: '90%',
                height: 60,
                alignSelf: 'center',
                marginBottom: 8
              },
              {
                key: 'line2',
                width: '90%',
                height: 60,
                alignSelf: 'center',
                marginBottom: 8
              },
              {
                key: 'line3',
                width: '60%',
                height: 60,
                alignSelf: 'center',
                marginBottom: 8
              }
            ]}
          >
            {manufacturerList && (
              <ManufacturerList
                description={
                  'Erfahren Sie mehr über Brillengläser in Ihrer Kategorie:'
                }
                data={manufacturerList}
              />
            )}
          </SkeletonContent>
        </>
      )}
    </WrapperComponent>
  )
}
