import Frame1 from '../../assets/frames/unisex/Brillenformen1.png'
import Frame2 from '../../assets/frames/unisex/Brillenformen2.png'
import Frame3 from '../../assets/frames/unisex/Brillenformen3.png'
import Frame4 from '../../assets/frames/unisex/Brillenformen4.png'
import Frame5 from '../../assets/frames/unisex/Brillenformen5.png'
import Frame6 from '../../assets/frames/unisex/Brillenformen6.png'
import Frame7 from '../../assets/frames/unisex/Brillenformen7.png'
import Frame8 from '../../assets/frames/male/Brillenformen8.png'
import Frame9 from '../../assets/frames/male/Brillenformen9.png'
import Frame10 from '../../assets/frames/female/Brillenformen10.png'
import Frame11 from '../../assets/frames/female/Brillenformen11.png'

const frameChoices = [
  { id: '1', source: Frame1 }, // uni
  { id: '2', source: Frame2 }, // uni
  { id: '3', source: Frame3 }, // uni
  { id: '4', source: Frame4 }, // uni
  { id: '5', source: Frame5 }, // uni
  { id: '6', source: Frame6 }, // uni
  { id: '7', source: Frame7 }, // uni
  { id: '8', source: Frame8 }, // male
  { id: '9', source: Frame9 }, // male
  { id: '10', source: Frame10 }, // female
  { id: '11', source: Frame11 } // female
]

export default gender => {
  if (gender === 'male') return frameChoices.slice(0, 8)
  else if (gender === 'female') {
    return frameChoices.slice(0, 6).concat([frameChoices[9], frameChoices[10]])
  } else return frameChoices
}
