import React from 'react'
import Badge from './Badge'

export default ({ width, height }) => {
  return (
    <Badge
      width={width || 270}
      height={height || 80}
      padding={0}
      uri='https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png'
      url='https://play.google.com/store/apps/details?id=com.vispecs.app'
      alt='Jetzt bei Google Play'
    />
  )
}
