const formatValues = val => {
  switch (val) {
    case 'no':
      return 'none'
    default:
      return val
  }
}

const formatProblems = a => {
  if (!Array.isArray(a)) return a
  let r = ''
  a.forEach(s => {
    if (r !== '') r *= ', '
    if (s === 'redness') r += 'gerötete Augen'
    else if (s === 'burning') r += 'brennende Augen'
    else if (s === 'headache') r += 'Kopfschmerzen'
    else if (s === 'slips') r += 'verrutscht'
    else if (s === 'pain') r += 'drückt'
    else if (s === 'dirty') r += 'verschmutzt leicht'
  })
  return r
}

const formatGlassAssessmentDataV1 = entry => {
  return {
    hasGlasses: [formatValues(entry.hasGlasses), 'Verwendet Brille'],
    lastExamYear: [
      formatValues(entry.lastExamYear),
      'Letzte Sehstärkenbestimmung'
    ],
    glassesProblem: [
      formatProblems(entry.glassesProblem),
      'Probleme mit der Brille'
    ],
    smartphoneDuration: [
      formatValues(entry.smartphoneDuration),
      'Zeit am Smartphone (Stunden)'
    ],
    computerDuration: [
      formatValues(entry.computerDuration),
      'Zeit am Computer (Stunden)'
    ],
    nearProblems: [formatValues(entry.nearProblems), 'Probleme auf der Nähe'],
    farProblems: [formatValues(entry.farProblems), 'Probleme bei der Ferne'],
    wantsAntireflective: [
      formatValues(entry.wantsAntireflective),
      'Bevorzugt Entspiegelung'
    ],
    singleVisionTier: [
      formatValues(entry.singleVisionTier),
      'Bevorzugt Einstärken'
    ],
    varifocalTier: [formatValues(entry.varifocalTier), 'Bevorzugt Gleitsicht'],
    workstationTier: [
      formatValues(entry.workstationTier),
      'Bevorzugt Arbeitsplatzglas'
    ],
    wantsDigital: [
      entry.wantsDigital === 'yes' ? 'yes' : null,
      'Bevorzugt Digital'
    ],
    wantsSunProtection: [
      formatValues(entry.wantsSunProtection),
      'Bevorzugt Sonnenschutz'
    ],
    sunglassesChoice: [
      formatValues(entry.sunglassesChoice) !== 'none'
        ? formatValues(entry.sunglassesChoice)
        : null,
      'Gewählter Sonnenschutz'
    ],
    applyBlueprotection: [
      entry.smartphoneDuration + entry.computerDuration > 4 ? 'yes' : 'no',
      'Bevorzugt Blaulichtfilter'
    ],
    tint: [entry.tint, 'Sonnenschutz Tönungswert']
  }
}

const formatLenseAssessmentDataV1 = entry => {
  return {
    hasLenses: [entry.hasLenses, 'Besitzt Kontaktlinsen'],
    lastExamYear: [entry.lastExamYear, 'Letzte Sehstärkenbestimmung'],
    satisfied: [entry?.satisfied, 'Zufrieden mit Kontaktlinsen'],
    lensesCycle: [entry.lensesCycle, 'Linsen Typ'],
    lensesProblem: [
      formatProblems(entry.lensesProblem),
      'Probleme mit Kontaktlinsen'
    ],
    lensesDuration: [entry.lensesDuration, 'Tägliche Tragezeit (Stunden)'],
    displayDuration: [
      entry.displayDuration,
      'Täglich Zeit vor Display (Stunden)'
    ]
  }
}

const processJson = data => {
  data.assessmentData = JSON.parse(data.assessmentData)
  return data
}

const formatAssessmentV1 = (entry, type) => {
  return {
    userId: [entry.userId, 'Benuter ID'],
    createdAt: [entry.createdAt, 'Erstellzeitpunkt'],
    version: [entry.version, 'Version'],
    type: [entry.type, 'Typ'],
    assessment:
      type === 'GLASSES'
        ? formatGlassAssessmentDataV1(processJson(entry).assessmentData)
        : formatLenseAssessmentDataV1(processJson(entry).assessmentData)
  }
}

const formatAssessment = (assessment, version, type) => {
  // eslint-disable-next-line no-constant-condition
  if (true || version.toFixed(0) === '1')
    return formatAssessmentV1(assessment, type)
}

const translateValue = v => {
  let r = v

  if (Array.isArray(v)) {
    const a = v[0]
    let b = (parseFloat(v[1]) * 100).toFixed(0)
    if (isNaN(b)) b = ''
    else b = ': ' + b + '%'
    switch (a) {
      case 'grey':
        r = 'Grau' + b
        break
      case 'gray':
        r = 'Grau' + b
        break
      case 'brown':
        r = 'Braun' + b
        break
      case 'green':
        r = 'Grün' + b
        break
      default:
        r = a + '' + b
        break
    }
    if (b === '') return 'Selbsttönend ' + r // Photocromic + Color
    return r // Color + Percentage
  }

  switch (v) {
    case 'yes':
      r = 'Ja'
      break
    case 'no':
      r = 'Nein'
      break
    case 'sometimes':
      r = 'Manchmal'
      break
    case 'standard':
      r = 'Standard'
      break
    case 'premium':
      r = 'Premium'
      break
    case 'bestseller':
      r = 'Bestseller'
      break
    case 'tinted':
      r = 'Tönung'
      break
    case 'photochromic':
      r = 'Selbsttönend'
      break
    case 'none':
      r = 'Keine'
      break
    case 'daily':
      r = 'Tageslinsen'
      break
    case 'weekly':
      r = 'Wochenlinsen'
      break
    case 'monthly':
      r = 'Monatslinsen'
      break
    case 'standardTier':
      r = 'Standard'
      break
    case 'premiumTier':
      r = 'Premium'
      break
    case 'toric':
      r = 'Torische'
      break
    case 'mf':
      r = 'Gleitsicht'
      break
    case 'regular':
      r = 'Standard'
      break
    case 'custom':
      r = 'Spezial'
      break
  }

  return r
}

const translateAssessment = inp => {
  if (!inp) return inp
  const a = JSON.parse(JSON.stringify(inp)) // create a deep clone
  if (!a?.assessment) return inp
  // iterates over the keys, if value is empty delete property else translate property
  Object.keys(a.assessment).map(x => {
    if (a.assessment[x] && (!a.assessment[x][0] || a.assessment[x][0] === '')) {
      delete a.assessment[x]
    } else {
      a.assessment[x][0] = translateValue(a.assessment[x][0])
    }
  })
  return a
}

export { formatAssessment, translateAssessment, translateValue }
