/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { View, Text, Image } from 'react-native'
import Packaging from '../../../assets/lenses_packaging_400.png'
import { translateValue } from '../../services/assessmentFormatter'
import theme from '../../styles/theme'
import Logo from '../../../assets/lense_evaluation_logo_purple.png'

export default ({ data, lenseProperties }) => {
  const typeBlock = [
    ['Linsenqualität:', translateValue(lenseProperties?.tier)],
    ['Tragerythmus:', translateValue(lenseProperties?.cycle)]
  ]

  const priceBlock = () => {
    // returns array with the cheapest qty/price pairs available
    const resultLeft = []
    const resultRight = []
    data.map(x => {
      let priceObjLeft = x?.left?.price
      let priceObjRight = x?.right?.price

      priceObjLeft?.map(y => {
        let found = false
        let newValue = [undefined, undefined]
        resultLeft.map(z => {
          if (z[0] === y.qty) {
            found = true
            if (parseFloat(z[1]) < parseFloat(y.price)) z[0] = y.qty
          }
        })
        if (!found) resultLeft.push([y.qty, y.price])
        // else if (newValue[1]) resultLeft[newValue[0]] = [newValue[1]]
      })

      priceObjRight?.map(y => {
        let found = false
        let newValue = [undefined, undefined]
        resultRight.map(z => {
          if (z[0] === y.qty) {
            found = true
            if (parseFloat(z[1]) < parseFloat(y.price)) z[0] = y.qty
          }
        })
        if (!found) resultRight.push([y.qty, y.price])
        // else if (newValue[1]) resultRight[newValue[0]] = [newValue[1]]
      })
    })
    return [resultLeft, resultRight]
  }

  const formattedPriceBlock = b => {
    b.map(x => {
      x[0] = x[0].toString() + ' Stk.'
      x[1] = x[1] + '€'
    })
    return b
  }

  const TableItem = (index, key, value) => {
    return (
      <View
        key={index}
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignSelf: 'flex-end',
          paddingRight: 8
        }}
      >
        <Text style={{ fontWeight: '300' }}>{key}</Text>
        <Text style={{ fontWeight: 'bold' }}>{value}</Text>
      </View>
    )
  }

  const HeaderBlock = (
    leftTitle,
    leftDescription,
    rightTitle,
    rightDescription
  ) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <View
          style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-start' }}
        >
          <Text style={{ fontWeight: '300' }}>{leftTitle}</Text>
          <Text style={{ fontWeight: 'bold' }}>{leftDescription}</Text>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', alignSelf: 'flex-end' }}>
          <Text style={{ fontWeight: '300' }}>{rightTitle}</Text>
          <Text style={{ fontWeight: 'bold' }}>{rightDescription}</Text>
        </View>
      </View>
    )
  }

  const LenseBlock = prices => {
    return (
      <View style={{ flex: 1, flexDirection: 'column' }}>
        {/* <Text style={{ fontWeight: 'bold' }}>{title}</Text> */}
        <View
          style={{
            width: '100%',
            flexDirection: 'row',
            marginTop: 6
          }}
        >
          <Image
            source={Packaging}
            style={{ flex: 6, maxHeight: 100 }}
            resizeMode='contain'
          />
          <View
            style={{
              flex: 5,
              flexDirection: 'column',
              marginLeft: '-15%',
              marginBottom: -20,
              zIndex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}
          >
            {prices.map((item, i) => {
              if (item && item[0] && item[1])
                return TableItem(i, item[0], item[1])
            })}
          </View>
        </View>
      </View>
    )
  }

  return (
    <>
      <View style={{ width: '100%', flexDirection: 'row' }}>
        <Image
          source={Logo}
          style={{ flex: 3, maxHeight: 70 }}
          resizeMode='contain'
        />
        <View
          style={{
            flex: 7,
            marginTop: 8,
            padding: 8,
            borderRadius: theme.roundness
          }}
        >
          {typeBlock.map((item, i) => {
            if (item && item[0] && item[1])
              return TableItem(i, item[0], item[1])
          })}
        </View>
      </View>
      {HeaderBlock(
        'Links: ',
        translateValue(lenseProperties?.typeLeft) + '-Linse',
        'Rechts: ',
        translateValue(lenseProperties?.typeRight) + '-Linse'
      )}
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          marginTop: 0,
          marginBottom: 6,
          paddingBottom: 20
        }}
      >
        {LenseBlock(formattedPriceBlock(priceBlock()[0]))}
        {LenseBlock(formattedPriceBlock(priceBlock()[1]))}
      </View>
    </>
  )
}
