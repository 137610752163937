import React from 'react'
import ActionCard from '../../components/ActionCard'
import Background from '../../../assets/backgrounds/ophthalmologist-doctor-holding-pair-eyeglasses/2952525lr.jpg'
import { Paragraph } from 'react-native-paper'
import { useLinkProps } from '@react-navigation/native'

export default props => {
  const { onPress } = useLinkProps({ to: '/map' })

  return (
    <ActionCard
      title='Optikersuche'
      subtitle='Ihr Partner vor Ort'
      background={Background}
      handlePress={onPress}
    >
      <Paragraph>
        Finden Sie einen niedergelassenen Optiker in Ihrer Nähe und nutzen Sie
        die einzigartigen Vorteile aus ständiger Betreuung in Ihrer ViSpecs-App
        und persönlicher Beratung vor Ort.
      </Paragraph>
    </ActionCard>
  )
}
