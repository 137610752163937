import { Auth } from '@aws-amplify/auth'
import React, { useEffect, useState } from 'react'
import { View, Text, Image, Dimensions } from 'react-native'
import PlainSurface from '../../components/Surfaces/PlainSurface'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import { TextInput } from '../../form-wizard/Inputs'
import Question from '../../form-wizard/Question'
import { Headline, Paragraph } from '../../scaling/ScaledUI'
import { useUser } from '../../hooks/user'
import { Credentials } from '@aws-amplify/core'
import Splash from '../../../assets/undraw/optician_mobile_splash.png'
import Shop from '../../../assets/undraw/shop.png'
import { CommonActions, useNavigation } from '@react-navigation/native'

export default () => {
  const { user, userAttributes, reload } = useUser()

  const navigation = useNavigation()

  const [identityId, setIdentityId] = useState(userAttributes?.identityId)

  useEffect(() => {
    if (!identityId)
      fetchId().then(() => {
        if (identityId) {
          console.log('id: ' + JSON.stringify(identityId, null, 4))
          return null
        }
        const timer = setTimeout(() => {
          // try again a second time
          fetchId()
        }, 200)
        return () => clearInterval(timer)
      })
  }, [])

  const fetchId = () => {
    return Credentials.get().then(credentials => {
      setIdentityId(credentials.identityId)
    })
  }

  const handleSubmit = data => {
    Auth.updateUserAttributes(user, {
      name: data.name,
      'custom:identityId': identityId
    })
      .then(() => {
        reload()
        navigation.dispatch(
          CommonActions.reset({
            index: 1,
            routes: [
              {
                name: 'Home'
              },
              {
                name: 'Map'
              }
            ]
          })
        )
      })
      .catch(console.error)
  }

  return (
    <FormWizard
      onSubmit={handleSubmit}
      initialValues={{
        name: userAttributes?.name
      }}
    >
      <FormScreen title='Willkommen'>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            marginTop: 24
          }}
        >
          <Image
            source={Splash}
            style={{
              width: Dimensions.get('window').width / 1.5,
              height: Dimensions.get('window').width / 1.5,
              alignSelf: 'center',
              marginBottom: 12
            }}
          />
          <PlainSurface style={{ width: '100%' }}>
            <Headline>Willkommen bei ViSpecs,</Headline>
            <Paragraph>
              Bevor wir starten können, benötigen wir jedoch ein paar
              grundlegende Informationen von Ihnen.
            </Paragraph>
          </PlainSurface>
        </View>
      </FormScreen>

      <FormScreen title='Name' condition={() => !userAttributes?.name}>
        <Question
          id='name'
          head='Bitte geben Sie den Namen Ihrer Filiale ein'
          fieldProps={{
            validate: value =>
              value && value.trim() !== '' ? undefined : 'required'
          }}
        >
          <TextInput placeholder='Ihre Filiale' />
        </Question>
      </FormScreen>

      <FormScreen
        title='Filiale'
        condition={() => !userAttributes['custom:localOpticianId']}
      >
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            marginTop: 24
          }}
        >
          <Image
            source={Shop}
            style={{
              width: Dimensions.get('window').width / 1.5,
              height: Dimensions.get('window').width / 1.5,
              alignSelf: 'center',
              marginBottom: 12
            }}
          />
          <PlainSurface style={{ width: '100%' }}>
            <Headline>Filiale verknüpfen</Headline>
            <Text style={{ marginTop: 16, fontSize: 17 }}>
              Bitte wählen Sie im nächsten Schritt Ihre Filiale auf der
              Optiker-Karte aus.
            </Text>
            <Text style={{ marginTop: 8, fontWeight: 'bold', fontSize: 17 }}>
              Wichtig: Sie können Ihre Filiale nur einmal auswählen.
            </Text>
          </PlainSurface>
        </View>
      </FormScreen>
    </FormWizard>
  )
}
