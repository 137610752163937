import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet, TouchableOpacity, Image } from 'react-native'
import theme from '../../styles/theme'
import MaleAvatar from '../../../assets/undraw/undraw_male_avatar_323b.png'
import FemaleAvatar from '../../../assets/undraw/undraw_female_avatar_w3jk.png'
import UnisexAvatar from '../../../assets/undraw/undraw_unisex_avatar_323b.png'

export default ({
  index,
  userObj,
  picture,
  appearance,
  onSelect,
  messageCount,
  basic
}) => {
  const [latestMessage, setLatestMessage] = useState('loading')
  const [timestamp, setTimestamp] = useState('loading')

  const selectUser = () => {
    if (typeof onSelect === 'function') onSelect(userObj)
  }

  const demoPhrases = [
    'Frage bezüglich Brille ...',
    'Danke!',
    'Kein Problem',
    'Benötigen Sie sonst ...',
    'Wie geht es Ihnen?',
    'Neues Angebot! ...',
    'Ihre Brille ist ...',
    'Servus'
  ]

  const demoWeekdays = [
    '5 min',
    '14 min',
    '13:37',
    'Gestern',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
    'Sonntag',
    'März',
    'Jänner',
    'letztes Jahr'
  ]

  const fetchLatestMessage = () => {
    // logic todo
    setTimestamp(
      index < demoWeekdays.length ? demoWeekdays[index] : 'vor längerer Zeit'
    )
    setLatestMessage(
      demoPhrases[Math.floor(Math.random() * demoPhrases.length)]
    )
  }

  useEffect(() => {
    fetchLatestMessage()
  }, [])

  const styles = StyleSheet.create({
    container: {
      padding: 8,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor:
        appearance === 'selected' ? theme.colors.primary : 'transparent'
    },
    content: {
      flex: 1,
      marginHorizontal: 8,
      paddingHorizontal: 2,
      marginVertical: 6
    },
    title: {
      fontWeight: '500',
      color: appearance === 'selected' ? '#fff' : '#000',
      fontSize: 16
    },
    description: {
      fontWeight: '400',
      fontSize: 16,
      color: appearance === 'selected' ? '#eee' : theme.colors.gray
    },
    timestamp: {
      color: appearance === 'selected' ? '#fff' : theme.colors.gray,
      alignSelf: 'flex-start'
    }
  })

  return (
    <View key={index} style={styles.container}>
      <Image
        style={{
          height: 50,
          width: 50,
          maxWidth: '100%',
          maxHeight: '100%',
          borderRadius: 25
        }}
        resizeMode='contain'
        source={
          picture
            ? { uri: picture }
            : userObj?.userAttributes?.gender === 'female'
            ? FemaleAvatar
            : userObj?.userAttributes?.gender === 'male'
            ? MaleAvatar
            : UnisexAvatar
        }
      />
      <TouchableOpacity onPress={selectUser} style={styles.content}>
        <Text style={styles.title}>{userObj?.userAttributes?.name}</Text>
        {!basic && latestMessage && (
          <Text style={styles.description}>{latestMessage}</Text>
        )}
      </TouchableOpacity>
      {!basic && (
        <View
          style={{
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            height: '100%',
            paddingVertical: 4
          }}
        >
          <Text style={styles.timestamp}>{timestamp}</Text>
          {messageCount > 0 && (
            <View
              style={{
                height: 20,
                width: 20,
                borderRadius: 10,
                backgroundColor: theme.colors.vibrant,
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <Text style={{ color: '#fff', alignSelf: 'center' }}>
                {messageCount > 9 ? messageCount + '+' : messageCount}
              </Text>
            </View>
          )}
        </View>
      )}
    </View>
  )
}
