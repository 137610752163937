import React, { useState } from 'react'
import { Paragraph, Button } from 'react-native-paper'
import { useNavigation } from '@react-navigation/native'
import LinkButton from '../../components/LinkButton'
import MultipleTasksToDo from '../../components/general/MultipleTasksToDo'
import { useAssessment } from '../../hooks/user/assessment'
import { usePassport } from '../../hooks/user/passport'

export default () => {
  const navigation = useNavigation()
  const { lensesAssessment } = useAssessment()
  const { lensesPassport } = usePassport()

  return (
    <>
      <Paragraph
        style={{ paddingHorizontal: 40, marginTop: 100, marginBottom: 12 }}
      >
        Um Ihnen Kontaktlinsen empfehlen zu können, müssen Sie einen
        Kontaktlinsenpass erstellt und die Kontaktlinsen-Bedarfsanalyse erledigt
        haben.
      </Paragraph>
      {!lensesAssessment && (
        <LinkButton
          mode='contained'
          to='/survey'
          style={{ marginHorizontal: 40, marginBottom: 12 }}
        >
          Bedarfsanalyse durchführen
        </LinkButton>
      )}
      {!lensesPassport && (
        <Button
          mode='outlined'
          style={{ marginHorizontal: 40 }}
          onPress={() => navigation.navigate('PassportUpdate')}
        >
          Kontaktlinsenpass erstellen
        </Button>
      )}
      <MultipleTasksToDo />
    </>
  )
}
