import React from 'react'
import UnderConstruction from '../../components/general/UnderConstruction'
import OpticianDrawer from '../../components/screens/OpticianDrawer'

export default ({ navigation }) => {
  return (
    <OpticianDrawer navigation={navigation}>
      <UnderConstruction />
    </OpticianDrawer>
  )
}
