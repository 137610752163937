import React from 'react'
import { Paragraph } from 'react-native-paper'
import { Image, View } from 'react-native'

export default () => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        minWidth: 200,
        alignSelf: 'center',
        alignItems: 'center'
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          width: '100%',
          paddingHorizontal: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <Image
          source={require('../../../assets/undraw/forbidden.png')}
          style={{ width: 1386 * 0.28, height: 980 * 0.28, marginTop: 10 }}
        />
        <Paragraph>
          Es tut uns leid, Sie haben nicht ausreichend Berechtigungen um diese
          Seite zu sehen.
        </Paragraph>
      </View>
    </View>
  )
}
