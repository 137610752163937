import React, { useState, useEffect, useCallback } from 'react'
import { View } from 'react-native'
import {
  Button,
  DataTable,
  Divider,
  IconButton,
  Menu,
  Paragraph,
  Text
} from 'react-native-paper'
import { useOptician } from '../../hooks/opticians'
import theme from '../../styles/theme'
import PassportEditField from './Passport/PassportEditField'
import PassportViewField from './Passport/PassportViewField'
import { Formik } from 'formik'
import { API, graphqlOperation } from 'aws-amplify'
import * as mutations from '../../graphql/mutations'
import { useUser } from '../../hooks/user'

export default (props, { style }) => {
  const {
    customerGlassPassports,
    customerLensePassports,
    getCustomerPassports,
    customerId
  } = useOptician()

  const { userAttributes } = useUser()

  const [passportIndex, setPassportIndex] = useState(0)

  const [menuVisible, setMenuVisible] = useState(false)

  const [cardMode, setCardMode] = useState('view')

  const isGlass = props?.passportName === 'Brillenpass'

  const [formErrors, setFormErrors] = useState([])

  useEffect(() => {
    getCustomerPassports()
  }, [])

  const boilerplateGlassPassport = {
    type: 'GLASSES',
    metaData: null,
    rightData: {
      sphere: 0.0,
      cylinder: 0.0,
      axis: 90,
      add: null,
      prism: null,
      base: null,
      pd: 31,
      height: null,
      diameter: null,
      radius: null
    },
    leftData: {
      sphere: 0.0,
      cylinder: 0.0,
      axis: 90,
      add: null,
      prism: null,
      base: null,
      pd: 31,
      height: null,
      diameter: null,
      radius: null
    }
  }

  const boilerplateLensePassport = {
    type: 'LENSES',
    metaData: null,
    rightData: {
      sphere: 0.0,
      cylinder: 0.0,
      axis: 80,
      add: null,
      prism: null,
      base: null,
      pd: 31,
      height: null,
      diameter: 10,
      radius: 8.0
    },
    leftData: {
      sphere: 0.0,
      cylinder: 0.0,
      axis: 90,
      add: null,
      prism: null,
      base: null,
      pd: 31,
      height: null,
      diameter: 10,
      radius: 8.0
    }
  }

  const boilerplatePassport = isGlass
    ? boilerplateGlassPassport
    : boilerplateLensePassport

  const isOldestPassport = isGlass
    ? customerGlassPassports?.length - 1 === passportIndex
    : customerLensePassports?.length - 1 === passportIndex

  const passport =
    cardMode === 'create'
      ? boilerplatePassport
      : isGlass
      ? customerGlassPassports?.[
          customerGlassPassports?.length - 1 - passportIndex
        ]
      : customerLensePassports?.[
          customerLensePassports?.length - 1 - passportIndex
        ]

  const movePassportIndex = val => {
    let old = passportIndex
    const pass = isGlass ? customerGlassPassports : customerLensePassports
    if (old + val >= pass?.length) setPassportIndex(pass.length - 1 || 0)
    else if (old + val <= 0) setPassportIndex(0)
    else setPassportIndex(old + val)
  }

  function getReplacementCycle (rc) {
    if (rc === 'LENSESDAILY') return 'Tageslinsen'
    else if (rc === 'LENSESWEEKLY') return 'Wochenlinsen'
    else if (rc === 'LENSESMONTHLY') return 'Monatslinsen'
    else return 'Unbekannt'
  }

  const formatTimestamp = t => {
    if (!t) return 'neuer Pass'
    const d = new Date(t)
    const minutes = d.getMinutes()
    return (
      d.toLocaleDateString() +
      ', ' +
      d.getHours() +
      ':' +
      (minutes.length === 1 ? '0' + minutes : minutes)
    )
  }

  const TableItem = cardMode === 'view' ? PassportViewField : PassportEditField

  const handleFormError = (valid, val) => {
    let newArray = formErrors
    if (!valid && !newArray.includes(val)) newArray.push(val)
    else if (valid && newArray.includes(val))
      newArray.splice(newArray.indexOf(val), 1)
    setFormErrors(newArray)
  }

  const formatResult = data => {
    Object.keys(data).map(keyName => {
      data[keyName] =
        !data[keyName] || data[keyName] === '' || isNaN(data[keyName])
          ? null
          : parseFloat(data[keyName])
    })
    return data
  }

  const handleSubmit = useCallback(data => {
    let rightData = data.rightData
    let leftData = data.leftData

    rightData = formatResult(rightData)
    leftData = formatResult(leftData)

    API.graphql(
      graphqlOperation(mutations.createPassport, {
        input: {
          userId: customerId,
          createdTimestamp: Date.now(),
          createdByUserId: userAttributes.sub,
          metaData: data.metaData,
          rightData,
          leftData,
          type: isGlass ? 'GLASSES' : data.type
        }
      })
    )
      .then(() => {
        getCustomerPassports()
      })
      .then(() => {
        setCardMode('view')
      })
      .catch(console.error)
  }, [])

  if (!passport && cardMode === 'view')
    return (
      <View
        style={{
          height: '100%',
          width: '100%',
          paddingTop: 40,
          alignSelf: 'center',
          alignItems: 'center'
        }}
      >
        <View
          style={{
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            paddingHorizontal: 20,
            alignSelf: 'center',
            alignItems: 'center'
          }}
        >
          <Paragraph>
            Es wurde noch kein {props.passportName} erstellt.
          </Paragraph>
          <Button
            mode='contained'
            style={{ margin: 20 }}
            onPress={() => setCardMode('create')}
          >
            {props.passportName} erstellen
          </Button>
        </View>
      </View>
    )

  // if (cardMode === 'create' && !isGlass) return <UnderRework />

  return (
    <Formik
      enableReinitialize={true}
      initialValues={passport}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleSubmit, values }) => (
        <View style={{ ...style, width: '100%' }}>
          <DataTable>
            <DataTable.Header>
              <DataTable.Title
                style={{ marginRight: cardMode === 'view' ? '-18%' : '-30%' }}
              />
              <DataTable.Title numeric>Sph</DataTable.Title>
              {!isGlass && <DataTable.Title numeric>Dia</DataTable.Title>}
              {!isGlass && <DataTable.Title numeric>Bc</DataTable.Title>}
              <DataTable.Title numeric>Cyl</DataTable.Title>
              <DataTable.Title numeric>A</DataTable.Title>
              <DataTable.Title numeric>Add</DataTable.Title>
              {isGlass && <DataTable.Title numeric>H</DataTable.Title>}
            </DataTable.Header>
            <DataTable.Row>
              <DataTable.Title
                style={{
                  marginRight: isGlass || cardMode === 'view' ? '-15%' : '-10%'
                }}
              >
                Re
              </DataTable.Title>
              <TableItem
                value={values?.rightData?.sphere}
                range={isGlass ? [-8, 8, 0.25] : [-20, 20, 0.25]}
                required
                onErrorChange={valid => {
                  handleFormError(valid, '0')
                }}
                onChangeText={handleChange('rightData.sphere')}
              />
              {!isGlass && (
                <TableItem
                  value={values?.rightData?.diameter}
                  range={[8, 16, 0.1]}
                  required
                  onErrorChange={valid => handleFormError(valid, '1')}
                  onChangeText={handleChange('rightData.diameter')}
                />
              )}
              {!isGlass && (
                <TableItem
                  value={values?.rightData?.radius}
                  range={[7.0, 9.5, 0.1]}
                  required
                  onErrorChange={valid => handleFormError(valid, '2')}
                  onChangeText={handleChange('rightData.radius')}
                />
              )}
              <TableItem
                value={values?.rightData?.cylinder}
                range={[-8, 8, 0.25]}
                required
                onErrorChange={valid => handleFormError(valid, '3')}
                onChangeText={handleChange('rightData.cylinder')}
              />
              <TableItem
                value={values?.rightData?.axis}
                unit='°'
                noFormat
                range={[0, 180, 1]}
                required
                onErrorChange={valid => handleFormError(valid, '4')}
                onChangeText={handleChange('rightData.axis')}
              />
              <TableItem value={values?.rightData?.add} range={[0, 3, 0.25]} />
              {isGlass && (
                <TableItem
                  value={values?.rightData?.height?.toFixed(1)}
                  unit='mm'
                  noFormat
                  range={[0, 50]}
                  onErrorChange={valid => handleFormError(valid, '5')}
                  onChangeText={handleChange('rightData.add')}
                />
              )}
            </DataTable.Row>
            <DataTable.Row>
              <DataTable.Title
                style={{
                  marginRight: isGlass || cardMode === 'view' ? '-15%' : '-10%'
                }}
              >
                Li
              </DataTable.Title>
              <TableItem
                value={values?.leftData?.sphere}
                range={isGlass ? [-8, 8, 0.25] : [-20, 20, 0.25]}
                required
                onErrorChange={valid => handleFormError(valid, '6')}
                onChangeText={handleChange('leftData.sphere')}
              />
              {!isGlass && (
                <TableItem
                  value={values?.leftData?.diameter}
                  range={[8, 16, 0.1]}
                  required
                  onErrorChange={valid => handleFormError(valid, '7')}
                  onChangeText={handleChange('leftData.diameter')}
                />
              )}
              {!isGlass && (
                <TableItem
                  value={values?.leftData?.radius}
                  range={[7.0, 9.5, 0.1]}
                  required
                  onErrorChange={valid => handleFormError(valid, '8')}
                  onChangeText={handleChange('leftData.radius')}
                />
              )}
              <TableItem
                value={values?.leftData?.cylinder}
                range={[-8, 8, 0.25]}
                required
                onErrorChange={valid => handleFormError(valid, '9')}
                onChangeText={handleChange('leftData.cylinder')}
              />
              <TableItem
                value={values?.leftData?.axis}
                unit='°'
                noFormat
                range={[0, 180, 1]}
                required
                onErrorChange={valid => handleFormError(valid, '10')}
                onChangeText={handleChange('leftData.axis')}
              />
              <TableItem value={values?.leftData?.add} range={[0, 3, 0.25]} />
              {isGlass && (
                <TableItem
                  value={values?.leftData?.height?.toFixed(1)}
                  unit='mm'
                  noFormat
                  range={[0, 50]}
                  onErrorChange={valid => handleFormError(valid, '11')}
                  onChangeText={handleChange('leftData.add')}
                />
              )}
            </DataTable.Row>
            <DataTable.Row>
              {isGlass ? (
                <>
                  <DataTable.Title style={{ marginRight: '-18%' }}>
                    PD
                  </DataTable.Title>
                  <TableItem
                    value={values?.rightData?.pd}
                    noFormat
                    unit='mm'
                    onErrorChange={valid => handleFormError(valid, '12')}
                    onChangeText={handleChange('rightData.pd')}
                  />
                  <TableItem
                    value={values?.leftData?.pd}
                    noFormat
                    unit='mm'
                    onErrorChange={valid => handleFormError(valid, '13')}
                    onChangeText={handleChange('leftData.pd')}
                  />
                </>
              ) : (
                <>
                  <DataTable.Title style={{ marginRight: '-18%' }}>
                    Typ
                  </DataTable.Title>
                  <TableItem
                    lenseCycle={true}
                    value={
                      cardMode !== 'view'
                        ? values.type
                        : getReplacementCycle(values.type)
                    }
                    noFormat
                    long
                    onErrorChange={valid => handleFormError(valid, '14')}
                    onChangeText={handleChange('type')}
                  />
                </>
              )}
            </DataTable.Row>
            {(cardMode === 'edit' || cardMode === 'create') && (
              <DataTable.Row>
                <DataTable.Title style={{ marginRight: '-55%' }}>
                  Anmerkung
                </DataTable.Title>
                <TableItem
                  value={values?.metaData}
                  large={true}
                  onChangeText={handleChange('metaData')}
                />
              </DataTable.Row>
            )}
          </DataTable>
          <Divider />
          <View
            style={{
              flex: 1,
              // width: '100%',
              maxWidth: '98%',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'flex-end'
            }}
          >
            <IconButton
              disabled={isOldestPassport || cardMode !== 'view'}
              icon='chevron-double-left'
              onPress={() => movePassportIndex(10)}
            />
            <IconButton
              disabled={isOldestPassport || cardMode !== 'view'}
              icon='chevron-left'
              onPress={() => movePassportIndex(1)}
            />
            <Text
              style={{
                margin: 6,
                fontWeight: passportIndex === 0 ? 'bold' : '400'
              }}
            >
              {formatTimestamp(passport?.createdTimestamp)}
            </Text>
            <IconButton
              disabled={passportIndex === 0 || cardMode !== 'view'}
              icon='chevron-right'
              onPress={() => movePassportIndex(-1)}
            />
            <IconButton
              disabled={passportIndex === 0 || cardMode !== 'view'}
              icon='chevron-double-right'
              onPress={() => movePassportIndex(-10)}
            />
            {cardMode === 'view' && (
              <>
                <Menu
                  visible={menuVisible}
                  onDismiss={() => setMenuVisible(false)}
                  style={{
                    marginTop: 40,
                    paddingHorizontal: 18,
                    paddingVertical: 10
                  }}
                  contentStyle={{ paddingHorizontal: 6, paddingVertical: 2 }}
                  anchor={
                    <IconButton
                      disabled={!passport?.metaData}
                      icon='information-outline'
                      color={
                        !passport?.metaData ? undefined : theme.colors.primary
                      }
                      onPress={() => setMenuVisible(true)}
                    />
                  }
                >
                  <Paragraph style={{ fontSize: 16, fontWeight: '500' }}>
                    Anmerkung: {passport?.metaData}
                  </Paragraph>
                </Menu>
                <IconButton
                  icon='pencil'
                  color={theme.colors.primary}
                  onPress={() => {
                    setCardMode('edit')
                  }}
                />
                {/* <IconButton
                  icon='plus'
                  color={theme.colors.primary}
                  onPress={() => {
                    setCardMode('create')
                  }}
                /> */}
              </>
            )}
            {(cardMode === 'edit' || cardMode === 'create') && (
              <>
                <IconButton
                  icon='close'
                  color={theme.colors.primary}
                  onPress={() => {
                    setPassportIndex(0)
                    setCardMode('view')
                  }}
                />
                <IconButton
                  icon='content-save'
                  disabled={formErrors?.length > 0}
                  color={theme.colors.primary}
                  onPress={() => {
                    handleSubmit()
                    setCardMode('view')
                  }}
                />
              </>
            )}
          </View>
        </View>
      )}
    </Formik>
  )
}
