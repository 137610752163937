import React from 'react'
import { TextInput } from './ScaledUI'

export default props => {
  const { style, input, disabled, ...inputProps } = props
  const { value, onChange } = input

  return (
    <TextInput
      style={style}
      disabled={disabled || false}
      value={isNaN(value) ? '' : value.toString()}
      onChangeText={v => onChange(parseInt(v))}
      keyboardType='number-pad'
      {...inputProps}
    />
  )
}
