import Face1 from '../../assets/faces/Gesichtsformen1.png'
import Face2 from '../../assets/faces/Gesichtsformen2.png'
import Face3 from '../../assets/faces/Gesichtsformen3.png'
import Face4 from '../../assets/faces/Gesichtsformen4.png'
import Face5 from '../../assets/faces/Gesichtsformen5.png'
import Face6 from '../../assets/faces/Gesichtsformen6.png'

export default [
  { id: 'round', source: Face1, text: 'rund' },
  { id: 'oval', source: Face2, text: 'oval' },
  { id: 'square', source: Face3, text: 'quadratisch' },
  { id: 'heart', source: Face4, text: 'herzförmig' },
  { id: 'diamond', source: Face5, text: 'rautenförmig' },
  { id: 'rectangle', source: Face6, text: 'rechteckig' }
]
