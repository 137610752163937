import React, { useEffect } from 'react'
import ForbiddenBlock from '../components/general/ForbiddenBlock'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useUser } from '../hooks/user'

export default () => {
  const { loadUserGroups } = useUser()
  useEffect(() => {
    loadUserGroups()
  }, [])

  return (
    <DefaultScreen>
      <ForbiddenBlock />
    </DefaultScreen>
  )
}
