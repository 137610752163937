import React from 'react'
import { TextInput } from '../../scaling/ScaledUI'

export default props => {
  const { style, input, placeholder, ...inputProps } = props
  const { value, onChange } = input

  return (
    <TextInput
      style={style}
      placeholder={placeholder || null}
      value={value}
      onChangeText={onChange}
      keyboardType='default'
      {...inputProps}
    />
  )
}
