import React, { useEffect, useState } from 'react'
import GoogleMap from '../components/Map/GoogleMap'
import DefaultScreen from '../components/screens/DefaultScreen'
import { useMarker } from '../hooks/map/marker'
import { Marker } from 'react-google-maps'
import DigitalOpticianCard from '../components/Map/DigitalOpticianCard'
import SearchCard from '../components/Map/SearchCard'
import { useUser } from '../hooks/user'

export default () => {
  const [activeMarker, setActiveMarker] = useState()
  const [infoVisible, setInfoVisible] = useState()
  const [position, setPosition] = useState({ lat: 48.21, lng: 16.363 })

  const { markerList, fetchMarkerList } = useMarker()
  const { userAttributes, updateLocalOptician } = useUser()

  useEffect(() => {
    fetchMarkerList()
  }, [markerList])

  console.log('Data: ' + JSON.stringify(markerList?.length, null, 4))

  const handleMarkerClick = marker => {
    setActiveMarker(marker)
    setInfoVisible(true)
  }

  const handleSearchResultClick = marker => {
    setActiveMarker(marker)
    setPosition({ lat: marker.lat, lng: marker.lng })
    setInfoVisible(true)
  }

  const handleSelectOpticianClick = () => {
    console.log('id: ' + JSON.stringify(activeMarker, null, 4))
    updateLocalOptician(activeMarker.id)
  }

  // key=AIzaSyDjNBEldZ_-sEy0eRpRsLNa0h7VwNSd1zo&s

  return (
    <DefaultScreen fullscreen>
      <SearchCard
        onSearchResultClick={marker => handleSearchResultClick(marker)}
      />
      <GoogleMap
        googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyBG6U1tBzc0yu0vZaHTIwJ-8ntCXLwwxJk&libraries=geometry,drawing'
        // googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp'
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '100%', width: '100%' }} />}
        defaultCenter={position}
        mapElement={<div style={{ height: '100%' }} />}
        markers={markerList?.map(marker => (
          <Marker
            key={marker.id}
            position={{
              lat: parseFloat(marker.location.latitude),
              lng: parseFloat(marker.location.longitude)
            }}
            onClick={() => handleMarkerClick(marker)}
          />
        ))}
        onClick={() => setInfoVisible(false)}
      />
      {infoVisible && (
        <DigitalOpticianCard
          id={activeMarker.id}
          name={activeMarker.name}
          address={activeMarker.address}
          onSelect={() => handleSelectOpticianClick()}
          localOpticianId={userAttributes?.['custom:localOpticianId']}
        />
      )}
    </DefaultScreen>
  )
}
