import React, { useCallback } from 'react'
import { Image, Linking, View, Text, Pressable } from 'react-native'
import theme from '../../styles/theme'

export default ({ data, description }) => {
  const handlePress = useCallback(async url => {
    const supported = await Linking.canOpenURL(url)

    if (supported) {
      await Linking.openURL(url)
    }
  })

  const GridItem = (index, image, link, agreement, name) => {
    return (
      <View
        key={index}
        style={{
          width: 120,
          height: 60,
          padding: 5,
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          borderColor: '#ccc',
          borderWidth: 1,
          borderRadius: theme.roundness,
          marginBottom: 8
        }}
      >
        <Pressable
          onPress={() => handlePress(link)}
          style={{
            alignSelf: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
            width: 110
          }}
        >
          {agreement ? (
            <Image
              source={{ uri: image }}
              style={{
                height: 50,
                width: 110
              }}
            />
          ) : (
            <Text
              style={{
                color: theme.colors.primary, //  '#3c4043'
                fontSize: 15,
                textAlign: 'center',
                marginHorizontal: 5
              }}
            >
              {name}
            </Text>
          )}
        </Pressable>
      </View>
    )
  }

  return (
    <View
      style={{
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 10,
        justifyContent: 'space-evenly'
      }}
    >
      <Text>{description}</Text>
      <View style={{ width: '100%', height: 16 }} />
      {data &&
        data.map((item, i) => {
          if (item && item[0] && item[1])
            return GridItem(i, item[0], item[1], item[2], item[3])
        })}
    </View>
  )
}
