import React, { useState } from 'react'
import {
  Checkbox,
  Divider,
  IconButton,
  Modal,
  RadioButton
} from 'react-native-paper'
import { View, Text, StyleSheet } from 'react-native'
import { Button, HelperText, TextInput } from 'react-native-paper'
import { useMediaQuery } from 'react-responsive'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import theme from '../../styles/theme'
import SwitchSelector from 'react-native-switch-selector'
import parsePhoneNumberFromString from 'libphonenumber-js/min'
import { useOptician } from '../../hooks/opticians'
import { useUser } from '../../hooks/user'

export default ({ visible, onDismiss }) => {
  const [textInputFocus, setTextInputFocus] = useState([false, false])
  const [loading, showLoading] = useState(false)
  const [userType, setuserType] = useState('phone_number')
  const [acceptedLegal, setAcceptedLegal] = useState(false)
  const [errorResponse, setErrorResponse] = useState()
  const [gender, setGender] = useState('unknown')

  const { registerCustomer, fetchCustomers } = useOptician()
  const { userAttributes } = useUser()

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .required('Name des Kunden erforderlich'),
    loginInfo:
      userType === 'phone_number'
        ? Yup.string()
            .test('isValidPhone', 'Telefonnummer erforderlich', value => {
              return value?.length > 2
            })
            .test(
              'isValidPhone',
              'Länderkennung erforderlich (z.B. +43)',
              value => {
                return value?.trim()?.substring(0, 1) === '+'
              }
            )
            .test(
              'isValidPhone',
              'Keine gültige Mobiltelefonnummer!',
              value => {
                const phone = parsePhoneNumberFromString(value)
                return !!phone && phone.isValid()
              }
            )
        : Yup.string().email('Ungültige E-Mail Adresse!')
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      loginInfo: ''
    },
    onSubmit: values => {
      showLoading(true)
      setErrorResponse(undefined)
      registerCustomer(
        values?.name,
        userType === 'phone_number' ? values.loginInfo : undefined,
        userType === 'email' ? values.loginInfo : undefined,
        gender === 'male' || gender === 'female' ? gender : undefined,
        userAttributes ? userAttributes['custom:localOpticianId'] : undefined
      )
        .then(response => {
          if (response?.User?.Attributes[0]?.Value) {
            fetchCustomers(response?.User?.Attributes[0]?.Value).then(() => {
              setTimeout(() => {
                showLoading(false)
                formik.setSubmitting(false)
                formik.resetForm()
                onDismiss()
              }, 350) // wait a bit before closing that components can update
            })
          } else if (response?.err) {
            if (response.err === 'UsernameExistsException') {
              setErrorResponse('Benutzer existiert bereits!')
            } else {
              setErrorResponse('Unbekannter Fehler: ' + response.err)
            }
            showLoading(false)
            formik.setSubmitting(false)
          } else if (!response) {
            setErrorResponse('Server antwortet nicht!')
            showLoading(false)
            formik.setSubmitting(false)
          }
        })
        .catch(() => {
          showLoading(false)
          formik.setSubmitting(false)
        })
    },
    validationSchema: validationSchema
  })

  const styles = StyleSheet.create({
    container: {
      alignSelf: 'center',
      alignItems: 'center',
      width: 1100,
      maxWidth: '90%',
      marginVertical: 20,
      padding: useMediaQuery({ maxWidth: 1000 }) ? 10 : 70,
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-around',
      backgroundColor: '#fff',
      shadowColor: '#000',
      shadowOpacity: 0.12,
      shadowRadius: 25,
      borderRadius: 12
    },
    imageBox: {
      flex: 1,
      alignItems: 'center',
      alignSelf: 'center',
      minWidth: 250,
      minHeight: 200,
      maxWidth: '80%'
    },
    divider: {
      width: 70
    },
    signUpText: {
      color: '#aaa',
      textAlign: 'center',
      textDecorationLine: 'underline'
    },
    description: {
      color: '#222',
      textAlign: 'center',
      marginBottom: useMediaQuery({ maxWidth: 1000 }) ? 10 : 40,
      paddingHorizontal: 8,
      fontSize: 15
    },
    image: {
      width: 1166 * 0.42,
      height: 885 * 0.42,
      maxWidth: '80%',
      margin: 20,
      alignSelf: 'center'
    },
    imageSmall: {
      width: 1166 * 0.22,
      height: 885 * 0.22,
      maxWidth: '70%',
      alignSelf: 'center',
      marginHorizontal: 20
    },
    loginBox: {
      flex: 1,
      minWidth: useMediaQuery({ maxWidth: 1000 }) ? 100 : 400,
      minHeight: 600,
      marginHorizontal: 20,
      height: '100%',
      justifyContent: 'space-evenly'
    },
    textInput: {
      marginVertical: 8,
      backgroundColor: '#fff',
      borderWidth: 0
    },
    headline: {
      alignSelf: 'flex-start',
      marginTop: useMediaQuery({ maxDeviceWidth: 1000 }) ? -50 : 0,
      fontSize: useMediaQuery({ maxDeviceWidth: 700 }) ? 22 : 32,
      fontWeight: 'bold',
      marginBottom: 16
    },
    signInBtn: {
      width: useMediaQuery({ maxDeviceWidth: 1000 }) ? '100%' : 220,
      height: 54,
      justifyContent: 'center',
      marginVertical: 16
    },
    rowFlex: {
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    linkText: {
      color: theme.colors.primary
    },
    radioText: {
      marginLeft: 10,
      fontSize: 18
    },
    radioWrapper: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 160
    },
    radioArea: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%'
    }
  })

  return (
    <Modal
      visible={visible}
      onDismiss={() => {
        if (!loading) onDismiss()
      }}
      contentContainerStyle={styles.container}
    >
      <View style={styles.loginBox}>
        <IconButton
          icon='close'
          onPress={() => onDismiss()}
          size={30}
          color='#aaa'
          style={{
            alignSelf: 'flex-end',
            marginRight: useMediaQuery({ maxDeviceWidth: 1000 }) ? -10 : -50,
            marginTop: useMediaQuery({ maxDeviceWidth: 1000 }) ? -10 : -50
          }}
        />
        <Text style={styles.headline}>Kunden Registrierung</Text>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Text
            style={{ flex: 1, fontSize: 18, fontWeight: '500', marginRight: 8 }}
          >
            Anmeldung mit:
          </Text>
          <SwitchSelector
            style={{ flex: 2, minWidth: 300 }}
            value={userType === 'phone_number' ? 0 : 1}
            textColor={theme.colors.primary}
            selectedColor='#fff'
            buttonColor={theme.colors.primary}
            borderColor='#ccc'
            buttonMargin={4}
            borderWidth={2}
            hasPadding
            fontSize={16}
            options={[
              { label: 'Telefonnummer', value: 'phone_number' },
              { label: 'E-Mail', value: 'email' }
            ]}
            initial={0}
            onPress={value => {
              if (value !== userType) {
                setErrorResponse(undefined)
                formik.setFieldValue('loginInfo', '')
              }
              setuserType(value)
            }}
          />
          <Divider
            style={{
              width: '100%',
              color: '#ccc',
              marginTop: 16,
              marginBottom: 8
            }}
          />
          <View style={styles.radioArea}>
            <Text
              style={{
                flex: 1,
                fontSize: 18,
                marginRight: 8,
                alignSelf: 'center',
                fontWeight: '500'
              }}
            >
              Geschlecht:
            </Text>
            <View style={styles.radioWrapper}>
              <RadioButton
                value='male'
                status={gender === 'male' ? 'checked' : 'unchecked'}
                onPress={() => setGender('male')}
              />
              <Text style={styles.radioText}>Männlich</Text>
            </View>
            <View style={styles.radioWrapper}>
              <RadioButton
                value='female'
                status={gender === 'female' ? 'checked' : 'unchecked'}
                onPress={() => setGender('female')}
              />
              <Text style={styles.radioText}>Weiblich</Text>
            </View>
            <View style={styles.radioWrapper}>
              <RadioButton
                value='unknown'
                status={gender === 'unknown' ? 'checked' : 'unchecked'}
                onPress={() => setGender('unknown')}
              />
              <Text style={styles.radioText}>Keine Angabe</Text>
            </View>
          </View>
        </View>
        <View>
          <TextInput
            style={styles.textInput}
            mode='flat'
            placeholder='Name des Kunden'
            left={
              <TextInput.Icon
                name='account-outline'
                style={{ marginLeft: -12 }}
              />
            }
            onFocus={() => setTextInputFocus([true, false])}
            onBlur={() => setTextInputFocus([false, textInputFocus[1]])}
            error={formik.touched.name && formik.errors.name}
            value={formik.values.name}
            onChangeText={formik.handleChange('name')}
            underlineColor={textInputFocus[0] ? theme.colors.primary : '#666'}
          />
          <HelperText
            type='error'
            style={styles.error}
            visible={formik.touched.name && formik.errors.name}
          >
            {formik.errors.name}
          </HelperText>
          <TextInput
            style={styles.textInput}
            mode='flat'
            placeholder={
              userType === 'phone_number' ? 'Telefonnummer' : 'E-Mail Adresse'
            }
            left={
              <TextInput.Icon
                name={
                  userType === 'phone_number'
                    ? 'phone-outline'
                    : 'email-outline'
                }
                style={{
                  marginLeft: -12
                }}
              />
            }
            onFocus={() => setTextInputFocus([false, true])}
            onBlur={() => setTextInputFocus([textInputFocus[0], false])}
            error={
              formik.touched.loginInfo &&
              formik.errors.loginInfo &&
              formik.values.loginInfo
            }
            value={formik.values.loginInfo}
            onChangeText={formik.handleChange('loginInfo')}
            underlineColor={textInputFocus[1] ? theme.colors.primary : '#666'}
          />
          <HelperText
            type='error'
            visible={
              formik.touched.loginInfo &&
              formik.errors.loginInfo &&
              formik.values.loginInfo
            }
          >
            {formik.errors.loginInfo}
          </HelperText>
        </View>
        <View style={styles.rowFlex}>
          <Checkbox
            disabled={loading}
            status={acceptedLegal ? 'checked' : 'unchecked'}
            onPress={() => {
              setAcceptedLegal(!acceptedLegal)
            }}
          />
          <Text style={{ fontSize: 14 }}>
            Ich bestätige hiermit, dass der Kunde die{' '}
            <Text
              onPress={() => window.open('https://vispecs.com/legal', '_blank')} // hardcoded because a component inside a portal can't access the navigation prop
              style={styles.linkText}
            >
              Datenschutzerklärung
            </Text>{' '}
            von ViSpecs akzeptiert.
          </Text>
        </View>
        <Button
          disabled={
            !acceptedLegal ||
            (!loading &&
              (!formik.dirty || !formik.isValid || formik.isSubmitting))
          }
          mode={loading ? 'outlined' : 'contained'}
          style={styles.signInBtn}
          onPress={() => {
            if (!loading) formik.handleSubmit()
          }}
          loading={loading}
        >
          Kunde Registrieren
        </Button>
        <HelperText
          type='error'
          style={{ fontSize: 22 }}
          visible={errorResponse}
        >
          {errorResponse}
        </HelperText>
      </View>
    </Modal>
  )
}
