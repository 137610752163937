import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs'
import React from 'react'
import theme from '../../styles/theme'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import UnderConstruction from '../../components/general/UnderConstruction'
import AssessmentGlassResult from '../../screens/Assessment/AssessmentGlassResult'
import AssessmentLenseResult from '../../screens/Assessment/AssessmentLenseResult'

export default () => {
  const Tab = createMaterialBottomTabNavigator()
  return (
    <Tab.Navigator
      barStyle={{
        backgroundColor: theme.colors.background
      }}
      activeColor={theme.colors.primary}
    >
      <Tab.Screen
        name='Glasses'
        component={AssessmentGlassResult}
        options={() => ({
          tabBarLabel: 'Brillenglas',
          tabBarIcon: ({ color }) => (
            <Icon name='glasses' color={color} size={24} />
          )
        })}
      />
      <Tab.Screen
        name='Frame'
        component={UnderConstruction}
        options={{
          tabBarLabel: 'Fassung',
          tabBarIcon: ({ color }) => (
            <Icon name='printer-3d' color={color} size={24} />
          )
        }}
      />
      <Tab.Screen
        name='Lenses'
        component={AssessmentLenseResult}
        options={{
          tabBarLabel: 'Kontaktlinsen',
          tabBarIcon: ({ color }) => (
            <Icon name='eye-plus-outline' color={color} size={24} />
          )
        }}
      />
    </Tab.Navigator>
  )
}
