import React, { useEffect, useState } from 'react'
import OpticianDashboardCard from '../../components/Optician/OpticianDashboardCard'
import OpticianPassportCard from '../../components/Optician/OpticianPassportCard'
import AssessmentDetails from '../../components/Optician/AssessmentDetails'
import CustomerSelection from '../../components/Optician/CustomerSelection'
import { useOptician } from '../../hooks/opticians'
import CompactEvaluationOptician from '../../components/Optician/Evaluation/CompactEvaluation.optician'
import { translateAssessment } from '../../services/assessmentFormatter'
import OpticianSplash from '../../components/Optician/OpticianSplash'
import CustomerAccountDetails from '../../components/Optician/CustomerAccountDetails'
import CustomerQuickSelect from '../../components/Optician/CustomerQuickSelect'
import OpticianDrawer from '../../components/screens/OpticianDrawer'

export default ({ navigation }) => {
  const {
    customerId,
    customerGlassAssessment,
    customerLenseAssessment,
    getCustomerAssessments
  } = useOptician()

  useEffect(() => {
    getCustomerAssessments()
  }, [])

  const [quickSelectCollapsed, setQuickSelectCollapsed] = useState(false)

  return (
    <OpticianDrawer navigation={navigation}>
      <OpticianDashboardCard
        icon='account-search-outline'
        header='Kundenauswahl'
        innerStyle={{ paddingHorizontal: 10 }}
        scrollable
        collapsible
        minWidth={430}
        height={420}
      >
        <CustomerSelection
          navigate={val => navigation.navigate(val)}
          setCollapsed={val => setQuickSelectCollapsed(val)}
        />
      </OpticianDashboardCard>
      <OpticianDashboardCard
        icon='history'
        header='Schnellauswahl'
        innerStyle={{ paddingHorizontal: 10 }}
        scrollable
        collapsible
        minWidth={300}
        height={260}
        collapsed={quickSelectCollapsed}
        setCollapsed={val => setQuickSelectCollapsed(val)}
      >
        <CustomerQuickSelect
          navigate={val => navigation.navigate(val)}
          setCollapsed={val => setQuickSelectCollapsed(val)}
        />
      </OpticianDashboardCard>
      {customerId ? (
        <>
          <OpticianDashboardCard
            icon='account-details'
            header='Kundeninformationen'
            minWidth={415}
            collapsible
          >
            <CustomerAccountDetails />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='account-badge-horizontal-outline'
            header='Brillenpass'
            minWidth={430}
            collapsible
          >
            <OpticianPassportCard passportName='Brillenpass' />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='account-card-details-outline'
            header='Kontaktlinsenpass'
            minWidth={430}
            collapsible
          >
            <OpticianPassportCard passportName='Kontaktlinsenpass' />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='shopping'
            header='Empfehlung Brille'
            minWidth={300}
            collapsible
          >
            <CompactEvaluationOptician type='glasses' />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='shopping'
            header='Empfehlung Linsen'
            minWidth={440}
            collapsible
          >
            <CompactEvaluationOptician type='lenses' />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='account-badge-outline'
            header='Bedarfsanalyse - Brille'
            minWidth={300}
            scrollable
            collapsible
          >
            <AssessmentDetails
              type='GLASSES'
              assessment={translateAssessment(customerGlassAssessment)}
            />
          </OpticianDashboardCard>
          <OpticianDashboardCard
            icon='account-badge-outline'
            header='Bedarfsanalyse - Kontaktlinsen'
            minWidth={300}
            scrollable
            collapsible
          >
            <AssessmentDetails
              type='LENSES'
              assessment={translateAssessment(customerLenseAssessment)}
            />
          </OpticianDashboardCard>
        </>
      ) : (
        <OpticianSplash
          title='Willkommen bei ViSpecs'
          description='Wählen Sie im Suchfeld einen Kunden und legen Sie los!'
        />
      )}
    </OpticianDrawer>
  )
}
