import React from 'react'
import { StyleSheet } from 'react-native'
import { DataTable, Text, Subheading } from 'react-native-paper'

export default ({ assessment }) => {
  const assessmentData = assessment?.assessment
  return (
    <>
      {assessment &&
        (assessment === 'unfinished' ? (
          <Subheading style={{ alignSelf: 'center', marginTop: 20 }}>
            Der Kunde hat die Bedarfsanalyse noch nicht durchgeführt.
          </Subheading>
        ) : (
          <DataTable style={styles.table}>
            {Object.keys(assessmentData).map((keyName, i) => (
              <DataTable.Row style={styles.row} key={i}>
                <DataTable.Cell style={styles.cell1}>
                  <Text style={styles.cellText}>
                    {assessmentData[keyName][1]}
                  </Text>
                </DataTable.Cell>
                <DataTable.Cell style={styles.cell2} numeric>
                  <Text style={styles.cellValue}>
                    {assessmentData[keyName][0]}
                  </Text>
                </DataTable.Cell>
              </DataTable.Row>
            ))}
          </DataTable>
        ))}
    </>
  )
}
const styles = StyleSheet.create({
  table: {
    backgroundColor: '#fff',
    flex: 1
  },
  title: {
    fontSize: 18
  },
  row: {
    borderColor: '#ffebf7',
    borderWidth: 2
  },
  titleCell: {
    backgroundColor: '#ffebf7'
  },
  cell1: {
    paddingVertical: 0,
    marginRight: '-20%'
  },
  cell2: {
    paddingVertical: 0
  },
  cellText: {
    color: '#333333',
    fontSize: 14,
    marginLeft: 12
  },
  cellValue: {
    color: 'black',
    fontSize: 14,
    marginLeft: 12,
    textAlign: 'right'
  }
})
