import React from 'react'
import { DataTable } from 'react-native-paper'

export default ({
  value,
  noFormat,
  isCycle,
  isTimestamp,
  unit,
  notNumeric
}) => {
  const format = val => {
    if (val === null || val === undefined) return ' '
    const extra = unit ? ' ' + unit : ''
    if (noFormat) return formatNoSign(val) + extra
    else if (Array.isArray(val)) return formatDual(val[0], val[1], unit)
    else if (isCycle) return getReplacementCycle(val)
    else if (isTimestamp) return formatTimestamp(val)
    else return formatSign(val) + extra
  }

  const formatDual = (val1, val2, unit) => {
    if (!val1 && !val2) return ' '
    return val1?.toFixed(1) + ' / ' + val2?.toFixed(1) + ' ' + unit?.toString()
  }

  const formatSign = val => {
    if (!val || val === 0) return '0'
    return (Math.sign(val) === 1 ? '+' : '') + isNaN(val)
      ? val
      : val?.toFixed(2)
  }

  const formatNoSign = val => {
    if (val === 0) return '0'
    if (!val) return ''
    return val.toString()
  }

  function getReplacementCycle (rc) {
    if (rc === 'LENSESDAILY') return 'Tageslinsen'
    else if (rc === 'LENSESWEEKLY') return 'Wochenlinsen'
    else if (rc === 'LENSESMONTHLY') return 'Monatslinsen'
    else return 'unbekannt'
  }

  const formatTimestamp = t => {
    if (!t) return ''
    const d = new Date(t)
    return (
      d.toLocaleDateString() +
      ', ' +
      d.getHours() +
      ':' +
      ('0' + d.getMinutes()).substring(0, 2)
    )
  }

  return <DataTable.Cell numeric={!notNumeric}>{format(value)}</DataTable.Cell>
}
