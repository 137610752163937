import React, { useState } from 'react'
import {
  Image,
  Picker,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import { Button, IconButton, Portal, TextInput } from 'react-native-paper'
import { scale } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'
import InfoModal from '../InfoModal'

export default props => {
  const {
    header,
    description,
    image,
    min,
    max,
    steps,
    initialValue,
    input,
    optionalText,
    dropDown,
    unit,
    staticUnit,
    dropDownDescription,
    info,
    decimalPlaces
  } = props
  const { onChange } = input

  const [containerWidth, setContainerWidth] = useState(0)
  const [ratio, setRatio] = useState(2 / 3)

  const [valuesUnknown, setValuesUnknown] = useState(
    initialValue[0] === null || initialValue[1] === null
  )

  const [rightValue, setRightValue] = useState(
    initialValue[0] === null
      ? addDecimalPoint(min, decimalPlaces || 0)
      : addDecimalPoint(initialValue[0], decimalPlaces || 0)
  )
  const [leftValue, setLeftValue] = useState(
    initialValue[1] === null
      ? addDecimalPoint(min, decimalPlaces || 0)
      : addDecimalPoint(initialValue[1], decimalPlaces || 0)
  )

  const [pickerSelectRight, setPickerSelectRight] = useState(
    dropDown === undefined ? 0 : getIndexOfSelected(initialValue[0])
  )
  const [pickerSelectUnitRight, setPickerSelectUnitRight] = useState(
    unit === undefined ? 0 : unit[0]
  )

  const [pickerSelectLeft, setPickerSelectLeft] = useState(
    dropDown === undefined ? 0 : getIndexOfSelected(initialValue[1])
  )
  const [pickerSelectUnitLeft, setPickerSelectUnitLeft] = useState(
    unit === undefined ? 0 : unit[0]
  )

  const handleSize = e => {
    setContainerWidth(e.nativeEvent.layout.width)
    const { width, height } = Image.resolveAssetSource(image)
    setRatio(height / width)
  }

  const handleLeftMinus = () => {
    const n = adaptNumber(parseFloat(leftValue) - steps)
    if (isNaN(n)) setLeftValue(initialValue[1])
    else if (n >= min) {
      setLeftValue(n.toFixed(decimalPlaces))
      setReturnValue(rightValue, n)
    }
  }

  const handleLeftPlus = () => {
    const n = adaptNumber(parseFloat(leftValue) + steps)
    if (isNaN(n)) setLeftValue(initialValue[1])
    else if (n <= max) {
      setLeftValue(n.toFixed(decimalPlaces))
      setReturnValue(rightValue, n)
    }
  }

  const handleRightMinus = () => {
    const n = adaptNumber(parseFloat(rightValue) - steps)
    if (isNaN(n)) setRightValue(initialValue[0])
    else if (n >= min) {
      setRightValue(n.toFixed(decimalPlaces))
      setReturnValue(n, leftValue)
    }
  }

  const handleRightPlus = () => {
    const n = adaptNumber(parseFloat(rightValue) + steps)
    if (isNaN(n)) setRightValue(initialValue[0])
    else if (n <= max) {
      setRightValue(n.toFixed(decimalPlaces))
      setReturnValue(n, leftValue)
    }
  }

  const handleLeftTextChange = inputText => {
    inputText = inputText.replace(',', '.')
    // inputText += inputText.lastIndexOf('.') === (inputText.length-1) ? '0' : ''
    if (!isNaN(inputText)) {
      const n = adaptNumber(parseFloat(inputText))
      if (!isNaN(n)) {
        setReturnValue(rightValue, n)
      } else setReturnValue(undefined, undefined)
    }
    // console.log('inputText: ' + inputText)
    setLeftValue(inputText)
  }

  const handleRightTextChange = inputText => {
    inputText = inputText.replace(',', '.')
    if (!isNaN(inputText)) {
      const n = adaptNumber(parseFloat(inputText))
      if (!isNaN(n)) {
        setReturnValue(n, leftValue)
      } else setReturnValue(undefined, undefined)
    }
    setRightValue(inputText)
  }

  function getIndexOfSelected (str) {
    let res = 0
    for (let i = 0; i < dropDown.length; i++) {
      if (dropDown[i] === str) {
        res = i
        break
      }
    }
    return res
  }

  function setReturnValue (right, left) {
    if (dropDown) {
      onChange([right, left])
    } else {
      const r = adaptNumber(right)
      const l = adaptNumber(left)
      if (right === null && left === null) onChange([null, null])
      else if (
        right === undefined ||
        left === undefined ||
        isNaN(r) ||
        isNaN(l) ||
        r < min ||
        r > max ||
        l < min ||
        l > max
      ) {
        // console.log("NAN: r: " + right + ", l: " + left + ", range: " + min + " - " + max)
        onChange(undefined)
      } else if (
        steps === undefined ||
        (testSteps(r, steps) && testSteps(l, steps))
      ) {
        // console.log("Value set: Right: " + r + ", Left: " + l)
        onChange([r, l])
      } else {
        // console.log("NAN due steps violation: r: " + r + ", l: " + l + ", range: " + min + " - " + max + "; " +steps)
        onChange(undefined)
      }
    }
  }

  function testSteps (val, stepval) {
    return parseFloat((val * 1000) % (stepval * 1000)) === 0
  }

  function adaptNumber (val) {
    if (isNaN(val) || steps === undefined || val % steps === 0) {
      return val
    } else {
      return Math.round(parseFloat(val) * 100) / 100 // do nothing
      // return parseInt(Math.round(val / steps) * steps * 100) / 100.0 // round to nearest step
    }
  }

  function formatNumber (num) {
    if (valuesUnknown) return ''
    if (num === undefined || num === null) return ''
    return addDecimalPoint(num, -1).toString()
  }

  function addDecimalPoint (num, decimals) {
    if (decimals < 0 || isNaN(num)) return num
    return parseFloat(num).toFixed(decimals)
  }

  const [infoVisible, setInfoVisible] = useState(false)

  return (
    <View onLayout={() => onChange([initialValue[0], initialValue[1]])}>
      <Text>{header === undefined ? '' : header.replace(/\s+/gm, ' ')}</Text>
      <View onLayout={handleSize}>
        <Image
          style={{
            width: containerWidth,
            height: containerWidth * ratio,
            marginBottom: 2
          }}
          source={image}
        />
      </View>
      {description && (
        <Text style={{ marginVertical: 6 }}>
          {description.replace(/  +/gm, ' ')}
        </Text>
      )}
      <View
        style={{
          flex: 1,
          flexDirection: 'column'
        }}
      >
        <View
          style={{
            marginTop: 4,
            paddingBottom: 2,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderColor: theme.colors.background
          }}
        >
          <Text style={{ marginLeft: 2, width: '15%' }}>Rechts</Text>
          <View
            style={{
              marginStart: 10,
              marginEnd: 10,
              width: unit ? '35%' : '50%'
            }}
          >
            {!dropDown ? (
              <TextInput
                disabled={valuesUnknown}
                value={formatNumber(adaptNumber(rightValue))}
                onChangeText={handleRightTextChange}
                keyboardType='numeric'
              />
            ) : (
              <Picker
                disabled={valuesUnknown}
                selectedValue={dropDown[pickerSelectRight]}
                onValueChange={(itemValue, itemIndex) => {
                  setPickerSelectRight(itemIndex)
                  setReturnValue(
                    dropDown[pickerSelectLeft],
                    dropDown[leftValue]
                  )
                }}
              >
                {dropDown.map(i => {
                  return <Picker.Item label={i} value={i} key={i} />
                })}
              </Picker>
            )}
          </View>
          <View
            style={{
              position: 'absolute',
              right: 0,
              flexDirection: 'row',
              flex: 1,
              width: unit ? '45%' : '30%'
            }}
          >
            {!unit && !staticUnit && !dropDownDescription ? (
              !dropDown &&
              steps && (
                <>
                  <IconButton
                    disabled={valuesUnknown}
                    icon='plus'
                    onPress={handleRightPlus}
                  />
                  <IconButton
                    disabled={valuesUnknown}
                    icon='minus'
                    onPress={handleRightMinus}
                  />
                </>
              )
            ) : staticUnit || dropDownDescription ? (
              <Text
                style={{
                  justifyContent: 'center',
                  alignSelf: 'center',
                  marginLeft: 6
                }}
              >
                {staticUnit || dropDownDescription[pickerSelectRight]}
              </Text>
            ) : (
              <>
                <Text style={{ justifyContent: 'center', alignSelf: 'center' }}>
                  Einheit:{' '}
                </Text>
                <Picker
                  disabled={valuesUnknown}
                  style={{ paddingLeft: '80%' }}
                  selectedValue={pickerSelectUnitRight}
                  onValueChange={(itemValue, itemIndex) =>
                    setPickerSelectUnitRight(itemValue)
                  }
                >
                  {unit.map(i => {
                    return <Picker.Item label={i} value={i} key={i} />
                  })}
                </Picker>
              </>
            )}
          </View>
        </View>
        <View
          style={{
            marginTop: 4,
            paddingBottom: 2,
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            borderBottomWidth: 1,
            borderColor: theme.colors.background
          }}
        >
          <Text style={{ marginLeft: 2, width: '15%' }}>Links</Text>
          <View
            style={{
              marginStart: 10,
              marginEnd: 10,
              width: unit ? '35%' : '50%'
            }}
          >
            {!dropDown ? (
              <TextInput
                disabled={valuesUnknown}
                value={formatNumber(adaptNumber(leftValue))}
                onChangeText={handleLeftTextChange}
                keyboardType='numeric'
              />
            ) : (
              <>
                <Picker
                  disabled={valuesUnknown}
                  selectedValue={dropDown[pickerSelectLeft]}
                  onValueChange={(itemValue, itemIndex) => {
                    setPickerSelectLeft(itemIndex)
                    setReturnValue(
                      dropDown[pickerSelectRight],
                      dropDown[itemIndex]
                    )
                  }}
                >
                  {dropDown.map(i => {
                    return <Picker.Item label={i} value={i} key={i} />
                  })}
                </Picker>
              </>
            )}
          </View>
          <View
            style={{
              position: 'absolute',
              right: 0,
              flexDirection: 'row',
              flex: 1,
              width: unit ? '45%' : '30%'
            }}
          >
            {!unit && !staticUnit && !dropDownDescription ? (
              !dropDown &&
              steps && (
                <>
                  <IconButton
                    disabled={valuesUnknown}
                    icon='plus'
                    onPress={handleLeftPlus}
                  />
                  <IconButton
                    disabled={valuesUnknown}
                    icon='minus'
                    onPress={handleLeftMinus}
                  />
                </>
              )
            ) : staticUnit || dropDownDescription ? (
              <Text
                style={{
                  justifyContent: 'center',
                  alignSelf: 'center',
                  marginLeft: 6
                }}
              >
                {staticUnit || dropDownDescription[pickerSelectLeft]}
              </Text>
            ) : (
              <>
                <Text style={{ justifyContent: 'center', alignSelf: 'center' }}>
                  Einheit:{' '}
                </Text>
                <Picker
                  disabled={valuesUnknown}
                  style={{ paddingLeft: '80%' }}
                  selectedValue={pickerSelectUnitLeft}
                  onValueChange={(itemValue, itemIndex) =>
                    setPickerSelectUnitLeft(itemValue)
                  }
                >
                  {unit.map(i => {
                    return <Picker.Item label={i} value={i} key={i} />
                  })}
                </Picker>
              </>
            )}
          </View>
        </View>
        {optionalText && optionalText !== '' && (
          <TouchableOpacity
            onPress={() => {
              setValuesUnknown(!valuesUnknown)
              if (valuesUnknown) {
                setRightValue(initialValue[0] === null ? min : initialValue[0])
                setLeftValue(initialValue[1] === null ? min : initialValue[1])
                setReturnValue(initialValue[0], initialValue[1])
              } else {
                setLeftValue(undefined)
                setRightValue(undefined)
                setReturnValue(null, null)
              }
            }}
            style={styles.container}
          >
            <Text style={styles.label}>{optionalText}</Text>
            <IconButton
              icon={
                valuesUnknown
                  ? 'check-circle-outline'
                  : 'checkbox-blank-circle-outline'
              }
              size={scale(25)}
              style={styles.checkbox}
              color={
                valuesUnknown ? theme.colors.primary : theme.colors.borders
              }
            />
          </TouchableOpacity>
        )}
        {info && info !== '' && (
          <View style={{ flex: 1, marginTop: 8 }}>
            <Button
              icon='information-outline'
              style={{ marginTop: 6 }}
              onPress={() => setInfoVisible(true)}
            >
              Information
            </Button>
            <Portal>
              <InfoModal
                info={info}
                visible={infoVisible}
                onDismiss={() => setInfoVisible(false)}
              />
            </Portal>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: scale(42),
    paddingHorizontal: scale(12)
  },
  label: {
    fontSize: scale(15),
    flex: 1
  },
  checkbox: {
    marginHorizontal: scale(-10)
  }
})
