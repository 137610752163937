import * as WebBrowser from 'expo-web-browser'
import { Linking, Platform } from 'react-native'

export const urlOpener = async (url, redirectUrl) => {
  const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(
    url,
    redirectUrl
  )

  if (type === 'success' && Platform.OS === 'ios') {
    WebBrowser.dismissBrowser()
    return Linking.openURL(newUrl)
  }
}
