import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { Button, Text, Paragraph, Portal } from 'react-native-paper'
import { useUser } from '../../hooks/user'
import { useMarker } from '../../hooks/map/marker'
import { useLinkTo } from '@react-navigation/native'
import ConfirmModal from '../../form-wizard/ConfirmModal'

export default () => {
  const { userAttributes, updateLocalOptician } = useUser()
  const { markerList, fetchMarkerList, getLocalOpticianFromId } = useMarker()

  const [infoVisible, setInfoVisible] = useState(false)

  const linkTo = useLinkTo()

  const [localOptician, setLocalOptician] = useState()

  useEffect(() => {
    fetchMarkerList()
  }, [markerList])

  useEffect(() => {
    if (markerList && userAttributes?.['custom:localOpticianId']) {
      setLocalOptician(
        getLocalOpticianFromId(userAttributes['custom:localOpticianId'])
      )
    }
  }, [userAttributes?.['custom:localOpticianId'], markerList])

  const handleRemove = () => {
    updateLocalOptician(null)
  }

  const handleChangeOptician = () => {
    linkTo('/map')
  }

  if (!userAttributes?.['custom:localOpticianId']) {
    return (
      <>
        <Button
          icon='map-search'
          mode='contained'
          style={{ marginTop: 6 }}
          onPress={handleChangeOptician}
        >
          Optiker wählen
        </Button>
      </>
    )
  }

  return (
    <>
      <View style={{ flexDirection: 'row' }}>
        <View style={{ flex: 1 }}>
          <Text style={{ fontWeight: 'bold', fontSize: 20, marginBottom: 3 }}>
            {localOptician?.name}
          </Text>
          <Text>{localOptician?.address}</Text>
        </View>
      </View>
      <View style={{ marginTop: 12 }}>
        <Button
          style={{ marginBottom: 5 }}
          icon='pencil'
          mode='outlined'
          onPress={handleChangeOptician}
        >
          Optiker ändern
        </Button>
        <Button
          style={{ marginBottom: 10 }}
          icon='delete'
          mode='outlined'
          onPress={() => setInfoVisible(true)}
        >
          Optiker entfernen
        </Button>
      </View>
      <Portal>
        <ConfirmModal
          title='Datenweitergabe'
          buttonTitle='Zustimmen'
          cancelTitle='Abbrechen'
          visible={infoVisible}
          onDismiss={() => setInfoVisible(false)}
          onConfirm={() => {
            handleRemove()
            setInfoVisible(false)
          }}
          onCancel={() => setInfoVisible(false)}
        >
          <Paragraph style={{ textAlign: 'justify' }}>
            Hiermit entziehe ich meinem gewählten Optiker (
            <Text style={{ fontWeight: 'bold' }}>
              {localOptician?.name ? localOptician?.name : ''},{' '}
              {localOptician?.address ? localOptician?.address : ''}
            </Text>
            ) den Zugriff auf meine personenbezogenen Daten.
          </Paragraph>
          <Paragraph style={{ textAlign: 'justify' }}>
            Ich nehme zur Kenntnis, dass ViSpecs lediglich den Kontakt mit dem
            von mir ausgewählten Optiker vermittelt und für die Leistungen des
            Optikers nicht haftet. Verträge werden direkt mit dem jeweiligen
            Optiker geschlossen. Im Hinblick auf vorvertragliche Maßnahmen und
            die Vertragsabwicklung wird ViSpecs bloß als Auftragsverarbeiter des
            jeweiligen Optikers tätig.
          </Paragraph>
        </ConfirmModal>
      </Portal>
    </>
  )
}
