export default [
  {
    zip: '80339',
    city: 'München',
    street: 'Franziska-Bilek-Weg 9',
    country: 'Deutschland',
    lat: '48.1333057',
    lng: '11.5451718',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Absolut UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Absolut/Standort/80339-Muenchen',
    openingHoursToday: '09:30 - 14:00 und 15:00 bis 19:30 Uhr',
    distance: null,
    objectId: '47352'
  },
  {
    zip: '4770',
    city: 'Andorf',
    street: 'Hauptstraße 16',
    country: 'Österreich',
    lat: '48.371847',
    lng: '13.5783211',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Andorf',
    openingHoursToday: '8:30 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37053'
  },
  {
    zip: '4710',
    city: 'Grieskirchen',
    street: 'Oberer Stadtplatz 25',
    country: 'Österreich',
    lat: '48.2359588',
    lng: '13.8248901',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Grieskirchen-Oberer-Stadtplatz',
    openingHoursToday: '8:30 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37054'
  },
  {
    zip: '4710',
    city: 'Grieskirchen',
    street: 'Stadtplatz 3',
    country: 'Österreich',
    lat: '48.2348783',
    lng: '13.8311659',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Grieskirchen-Stadtplatz-3',
    openingHoursToday: '08:30 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37055'
  },
  {
    zip: '4910',
    city: 'Ried',
    street: 'Stelzhamerplatz 18',
    country: 'Österreich',
    lat: '48.2090063',
    lng: '13.4871475',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink: 'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Ried',
    openingHoursToday: '8:30 - 18:00 Uhr',
    distance: null,
    objectId: '37056'
  },
  {
    zip: '4780',
    city: 'Schärding',
    street: 'Silberzeile 9',
    country: 'Österreich',
    lat: '48.4570648',
    lng: '13.4324676',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Schaerding',
    openingHoursToday: '8:30 - 18:00 Uhr',
    distance: null,
    objectId: '37057'
  },
  {
    zip: '4722',
    city: 'Peuerbach',
    street: 'Hauptstraße 9',
    country: 'Österreich',
    lat: '48.3436653',
    lng: '13.7719351',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Peuerbach',
    openingHoursToday: '8:00 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37058'
  },
  {
    zip: '4701 ',
    city: 'Bad Schallerbach',
    street: 'Badstraße 1',
    country: 'Österreich',
    lat: '48.2310918',
    lng: '13.9206832',
    openingHours: null,
    openToday: true,
    name: 'Aigner UNITED OPTICS',
    detailLink:
      'http://www.optiker-aigner.at/Aigner/Unternehmen/Filialen/Bad-Schallerbach',
    openingHoursToday: '08:30 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37059'
  },
  {
    zip: '6370',
    city: 'Kitzbühel',
    street: 'Hinterstadt 12',
    country: 'Österreich',
    lat: '47.4468401',
    lng: '12.3898589',
    openingHours: null,
    openToday: true,
    name: 'Allmoslechner UNITED OPTICS',
    detailLink:
      'http://www.optic.at/Allmoslechner/Unternehmen/Unser-Standort/Hinterstadt-12',
    openingHoursToday: '9.00 - 12.30 und 14.15 Uhr bis 18.00 Uhr',
    distance: null,
    objectId: '41977'
  },
  {
    zip: '2410',
    city: 'Hainburg / Donau',
    street: 'Wiener Strasse 1',
    country: 'Österreich',
    lat: '48.1468501',
    lng: '16.9407988',
    openingHours: null,
    openToday: false,
    name: 'Alschinger UNITED OPTICS',
    detailLink:
      'http://www.alschinger.at/Alschinger/Unternehmen/Filialen/Hainburg-D',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42004'
  },
  {
    zip: '7132',
    city: 'Frauenkirchen',
    street: 'Franziskanerstrasse 17',
    country: 'Österreich',
    lat: '47.8354531',
    lng: '16.9255783',
    openingHours: null,
    openToday: false,
    name: 'Alschinger UNITED OPTICS',
    detailLink:
      'http://www.alschinger.at/Alschinger/Unternehmen/Filialen/Frauenkirchen',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42005'
  },
  {
    zip: '7100',
    city: 'Neusiedl am See',
    street: 'Hauptplatz 34',
    country: 'Österreich',
    lat: '47.9491982',
    lng: '16.8412992',
    openingHours: null,
    openToday: false,
    name: 'Alschinger UNITED OPTICS',
    detailLink:
      'http://www.alschinger.at/Alschinger/Unternehmen/Filialen/Neusiedl-am-See',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42006'
  },
  {
    zip: '2230',
    city: 'Gänserndorf',
    street: 'Bahnstraße 2',
    country: 'Österreich',
    lat: '48.3413898',
    lng: '16.720451',
    openingHours: null,
    openToday: false,
    name: 'Alschinger UNITED OPTICS',
    detailLink:
      'http://www.alschinger.at/Alschinger/Unternehmen/Filialen/Gaenserndorf',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42007'
  },
  {
    zip: '90419',
    city: 'Nürnberg',
    street: 'Kirchenweg 3',
    country: 'Deutschland',
    lat: '49.4623044',
    lng: '11.0701587',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Bierlein UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Bierlein/Standort/90419-Nuernberg',
    openingHoursToday: '09:00 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '48868'
  },
  {
    zip: '3830',
    city: 'Waidhofen an der Thaya',
    street: 'Thayaparkstraße 1',
    country: 'Österreich',
    lat: '48.8110272',
    lng: '15.27211',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Die Optikerin UNITED OPTICS',
    detailLink:
      'http://www.optikerin.at/Die-Optikerin/Unternehmen/Standorte/Thayapark',
    openingHoursToday: '09:00 - 18:30 Uhr',
    distance: null,
    objectId: '36950'
  },
  {
    zip: '3812',
    city: 'Groß Siegharts',
    street: 'Dr. Rudolf Kraus-Platz 3',
    country: 'Österreich',
    lat: '48.7908865',
    lng: '15.4043219',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Die Optikerin UNITED OPTICS',
    detailLink:
      'http://www.optikerin.at/Die-Optikerin/Unternehmen/Standorte/Dr.-Rudolf-Kraus-Platz',
    openingHoursToday: '08:30 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '45267'
  },
  {
    zip: '4400 ',
    city: 'Steyr',
    street: 'Stadtplatz 34',
    country: 'Österreich',
    lat: '48.0388179',
    lng: '14.4185796',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Fenzl UNITED OPTICS',
    detailLink:
      'http://www.optik-fenzl.at/Fenzl/Unternehmen/Filialen/Stadtplatz',
    openingHoursToday: '8.00 bis 18.00 Uhr',
    distance: null,
    objectId: '37629'
  },
  {
    zip: '4400',
    city: 'Steyr',
    street: 'Enge Gasse 21',
    country: 'Österreich',
    lat: '48.0410236',
    lng: '14.4205146',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Fenzl UNITED OPTICS',
    detailLink:
      'http://www.optik-fenzl.at/Fenzl/Unternehmen/Filialen/Enge-Gasse',
    openingHoursToday: '8.00 - 12.00 und 14.00 bis 18.00 Uhr',
    distance: null,
    objectId: '37630'
  },
  {
    zip: '3100',
    city: 'St. Pölten',
    street: 'Wiener Straße 11-13',
    country: 'Österreich',
    lat: '48.2047242',
    lng: '15.6250233',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-St.-Poelten',
    openingHoursToday: '09:00 - 18:00 Uhr',
    distance: null,
    objectId: '36982'
  },
  {
    zip: '3370',
    city: 'Ybbs',
    street: 'Bahnhofstraße 11',
    country: 'Österreich',
    lat: '48.1726506',
    lng: '15.0853433',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Ybbs',
    openingHoursToday: '08:30 - 18:30 Uhr',
    distance: null,
    objectId: '36983'
  },
  {
    zip: '3390',
    city: 'Melk',
    street: 'Rathausplatz 6',
    country: 'Österreich',
    lat: '48.2275873',
    lng: '15.3326691',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Melk-Rathausplatz',
    openingHoursToday: '08:00 - 18:00 Uhr',
    distance: null,
    objectId: '36984'
  },
  {
    zip: '3390',
    city: 'Melk',
    street: 'Am Löwenpark 1',
    country: 'Österreich',
    lat: '48.2245799',
    lng: '15.3378608',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Melk-Loewenpark',
    openingHoursToday: '8:30 - 18:30 Uhr',
    distance: null,
    objectId: '36985'
  },
  {
    zip: '3270',
    city: 'Scheibbs',
    street: 'Hauptstraße 21',
    country: 'Österreich',
    lat: '48.0064879',
    lng: '15.1668364',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Scheibbs',
    openingHoursToday: '08:00 - 18:00 Uhr',
    distance: null,
    objectId: '36986'
  },
  {
    zip: '3250',
    city: 'Wieselburg',
    street: 'Hauptplatz 12',
    country: 'Österreich',
    lat: '48.1312304',
    lng: '15.1386409',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Wieselburg',
    openingHoursToday: '08:00 - 18:00 Uhr',
    distance: null,
    objectId: '36987'
  },
  {
    zip: '3300 ',
    city: 'Amstetten',
    street: 'Hauptplatz 25',
    country: 'Österreich',
    lat: '48.1228773',
    lng: '14.8721708',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Amstetten',
    openingHoursToday: '08:30 - 18:00 Uhr',
    distance: null,
    objectId: '36988'
  },
  {
    zip: '3340',
    city: 'Waidhofen',
    street: 'Unterer Stadtplatz 32',
    country: 'Österreich',
    lat: '47.9598466',
    lng: '14.7732993',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Waidhofen',
    openingHoursToday: '08:15 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '36989'
  },
  {
    zip: '3335',
    city: 'Weyer',
    street: 'Marktplatz 15',
    country: 'Österreich',
    lat: '47.8583399',
    lng: '14.6645425',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-Weyer',
    openingHoursToday: '8:00-12:00 Uhr',
    distance: null,
    objectId: '36990'
  },
  {
    zip: '3300',
    city: 'Amstetten',
    street: 'Waidhofner Str. 1',
    country: 'Österreich',
    lat: '48.1212448',
    lng: '14.8686207',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Forster UNITED OPTICS',
    detailLink:
      'http://www.forster-optik.at/Forster/Unternehmen/Filialen/Filiale-CCA-Amstetten',
    openingHoursToday: '09:00 - 18:30 Uhr',
    distance: null,
    objectId: '48727'
  },
  {
    zip: '8850',
    city: 'Murau',
    street: 'Schwarzenbergstraße 2',
    country: 'Österreich',
    lat: '47.1111394',
    lng: '14.1711122',
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Fuchs UNITED OPTICS',
    detailLink:
      'http://www.optikfuchs.at/Fuchs/Unternehmen/Filialen/Schwarzenbergstrasse-2-8850-Murau',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42178'
  },
  {
    zip: '8850',
    city: 'Murau',
    street: 'Liechtensteinstraße 2-4',
    country: 'Österreich',
    lat: '47.1103024',
    lng: '14.1711343',
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Fuchs UNITED OPTICS',
    detailLink:
      'http://www.optikfuchs.at/Fuchs/Unternehmen/Filialen/Liechtensteinstrasse-2-4-8850-Murau',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42179'
  },
  {
    zip: '8750',
    city: 'Judenburg',
    street: 'Burggasse 28',
    country: 'Österreich',
    lat: '47.1683362',
    lng: '14.6577777',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Fuchs UNITED OPTICS',
    detailLink:
      'http://www.optikfuchs.at/Fuchs/Unternehmen/Filialen/Burggasse-28-8750-Judenburg',
    openingHoursToday: '9:00 - 18:00 Uhr',
    distance: null,
    objectId: '42180'
  },
  {
    zip: '9010',
    city: 'Klagenfurt',
    street: 'Südpark 1',
    country: 'Österreich',
    lat: '46.614607',
    lng: '14.3204535',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Gottwald UNITED OPTICS',
    detailLink:
      'http://www.gottwald.co.at/Gottwald/Unternehmen/Filialen/Suedpark',
    openingHoursToday: '09:00 Uhr bis 19:00 Uhr',
    distance: null,
    objectId: '42103'
  },
  {
    zip: '9010',
    city: 'Klagenfurt',
    street: 'Heiligengeistplatz 1',
    country: 'Österreich',
    lat: '46.62415',
    lng: '14.3052097',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Gottwald UNITED OPTICS',
    detailLink:
      'http://www.gottwald.co.at/Gottwald/Unternehmen/Filialen/Heiligengeistplatz',
    openingHoursToday: '08:30 - 18:00 Uhr',
    distance: null,
    objectId: '42104'
  },
  {
    zip: '74321',
    city: 'Bietigheim-Bissingen',
    street: 'Hauptstraße 23',
    country: 'Deutschland',
    lat: '48.95944',
    lng: '9.1281617',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Grimm UNITED OPTICS',
    detailLink:
      'http://www.unitedoptics.de/Grimm/Standort/74321-Bietigheim-Bissingen',
    openingHoursToday: '09:00 - 13:00 und 14:30 - 18:30 Uhr',
    distance: null,
    objectId: '47535'
  },
  {
    zip: '74405',
    city: 'Gaildorf',
    street: 'Schulstraße 17',
    country: 'Deutschland',
    lat: '49.0003944',
    lng: '9.7682152',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Hermann UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Hermann/Standort/Gaildorf',
    openingHoursToday: '08:30 - 12:30 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '47534'
  },
  {
    zip: '79576',
    city: 'Weil am Rhein',
    street: 'Hauptstraße 165',
    country: 'Deutschland',
    lat: '47.5940462',
    lng: '7.6283959',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Hess UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Hess/Standort/79576-Weil-am-Rhein',
    openingHoursToday: '08:30 - 12:30 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '47544'
  },
  {
    zip: '90542',
    city: 'Eckental',
    street: 'Eschenauer Hauptstrasse 21',
    country: 'Deutschland',
    lat: '49.57477',
    lng: '11.1959374',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Hofgaarden UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Hofgaarden/Standort/90542-Eckental',
    openingHoursToday: '09:30 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '48645'
  },
  {
    zip: '83022',
    city: 'Rosenheim',
    street: 'Münchener Str. 4',
    country: 'Deutschland',
    lat: '47.85489',
    lng: '12.12563',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Häckl UNITED OPTICS',
    detailLink:
      'http://www.optik-haeckl.de/Haeckl/Unternehmen/Standorte/83022-Rosenheim',
    openingHoursToday: '09:00 - 18:00 Uhr',
    distance: null,
    objectId: '44564'
  },
  {
    zip: '83512',
    city: 'Wasserburg a. Inn',
    street: 'Bruckgasse 1',
    country: 'Deutschland',
    lat: '48.0602194',
    lng: '12.2326141',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Häckl UNITED OPTICS',
    detailLink:
      'http://www.optik-haeckl.de/Haeckl/Unternehmen/Standorte/83512-Wasserburg-a.-Inn',
    openingHoursToday: '09:00 - 13:00 und 14:00 bis 18:00 Uhr',
    distance: null,
    objectId: '44584'
  },
  {
    zip: '83607',
    city: 'Holzkirchen',
    street: 'Marktplatz 19',
    country: 'Deutschland',
    lat: '47.8775171',
    lng: '11.7009872',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Häckl UNITED OPTICS',
    detailLink:
      'http://www.optik-haeckl.de/Haeckl/Unternehmen/Standorte/83607-Holzkirchen',
    openingHoursToday: '09:00 - 13:00 und 14:00 bis 18:00 Uhr',
    distance: null,
    objectId: '46664'
  },
  {
    zip: '83022',
    city: 'Rosenheim',
    street: 'Heilig-Geist-Straße 2a',
    country: 'Deutschland',
    lat: '47.85649',
    lng: '12.12693',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Häckl UNITED OPTICS',
    detailLink:
      'http://www.optik-haeckl.de/Haeckl/Unternehmen/Standorte/83022-Rosenheim2',
    openingHoursToday: '09:00 - 13:00 und 14:00 bis 18:00 Uhr',
    distance: null,
    objectId: '49918'
  },
  {
    zip: '4320',
    city: 'Perg',
    street: 'Schulstraße 4',
    country: 'Österreich',
    lat: '48.2496748',
    lng: '14.6307681',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Hörtenhuber UNITED OPTICS',
    detailLink:
      'http://www.hoertenhuber.at/Hoertenhuber/Unternehmen/Standort/Perg',
    openingHoursToday: '08:30 - 12:00 / 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '4116'
  },
  {
    zip: '3500',
    city: 'Krems',
    street: 'Dinstlstrasse 2',
    country: 'Österreich',
    lat: '48.4106373',
    lng: '15.6026938',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Jell UNITED OPTICS',
    detailLink: 'http://www.jell.at/Jell/Unternehmen/Unser-Geschaeft/Krems',
    openingHoursToday: '09:00 - 18:00',
    distance: null,
    objectId: '42782'
  },
  {
    zip: '5620',
    city: 'Schwarzach',
    street: 'Salzleckerstraße 8',
    country: 'Österreich',
    lat: '47.3212571',
    lng: '13.1520073',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Mayr UNITED OPTICS',
    detailLink: 'http://www.optik-mayr.at/Mayr/Unternehmen/Filialen/Schwarzach',
    openingHoursToday: '09.00 - 12.00 und 14.00 - 18.00 Uhr',
    distance: null,
    objectId: '42246'
  },
  {
    zip: '5600',
    city: 'St. Johann iPg',
    street: 'Hans Kappacher Straße 8',
    country: 'Österreich',
    lat: '47.3469173',
    lng: '13.2022325',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Mayr UNITED OPTICS',
    detailLink: 'http://www.optik-mayr.at/Mayr/Unternehmen/Filialen/St.-Johann',
    openingHoursToday: '08.45 - 12.30 Uhr   14.00 - 18.00 Uhr',
    distance: null,
    objectId: '42247'
  },
  {
    zip: '6020',
    city: 'Innsbruck',
    street: 'Maria Theresien Str. 31',
    country: 'Österreich',
    lat: '47.2655377',
    lng: '11.3951725',
    openingHours: null,
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Kaufhaus-Tyrol',
    openingHoursToday: '09:00 - 19:00 Uhr',
    distance: null,
    objectId: '42276'
  },
  {
    zip: '6020',
    city: 'Innsbruck',
    street: 'Meraner Str. 3',
    country: 'Österreich',
    lat: '47.2650076',
    lng: '11.3954913',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Innsbruck',
    openingHoursToday: '08:00-18:00 Uhr',
    distance: null,
    objectId: '42277'
  },
  {
    zip: '6020',
    city: 'Innsbruck',
    street: 'Amraser-See-Str. 56a',
    country: 'Österreich',
    lat: '47.2625704',
    lng: '11.4308086',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-DEZ',
    openingHoursToday: '09:00 - 19:00 Uhr',
    distance: null,
    objectId: '42278'
  },
  {
    zip: '6060',
    city: 'Hall in Tirol',
    street: 'Wallpachgasse 4',
    country: 'Österreich',
    lat: '47.2822023',
    lng: '11.5078304',
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Hall',
    openingHoursToday: '08:30 - 12:30 Uhr und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '42279'
  },
  {
    zip: '6300',
    city: 'Wörgl',
    street: 'Bahnhofstr. 19',
    country: 'Österreich',
    lat: '47.4880283',
    lng: '12.0641733',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Woergl',
    openingHoursToday: '09:00 - 12:30 Uhr und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '42280'
  },
  {
    zip: '6410',
    city: 'Telfs',
    street: 'Eduard-Wallnöfer-Platz 3',
    country: 'Österreich',
    lat: '47.3072293',
    lng: '11.072018',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Telfs',
    openingHoursToday: '09:00 - 17:00 Uhr',
    distance: null,
    objectId: '42282'
  },
  {
    zip: '6020',
    city: 'Innsbruck',
    street: 'Maria Theresien Str. 38',
    country: 'Österreich',
    lat: '47.2645381',
    lng: '11.3945431',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/Theresienoptik',
    openingHoursToday: '09:00 - 18:00 Uhr',
    distance: null,
    objectId: '42283'
  },
  {
    zip: '6130',
    city: 'Schwaz',
    street: 'Innsbrucker Str. 2',
    country: 'Österreich',
    lat: '47.3447657',
    lng: '11.707847',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Schwaz',
    openingHoursToday: '08:30-12:30 und 14:00-18:00 Uhr',
    distance: null,
    objectId: '42284'
  },
  {
    zip: '6460',
    city: 'Imst',
    street: 'Dr. Pfeiffenbergerstr. 14a',
    country: 'Österreich',
    lat: '47.2381099',
    lng: '10.7385041',
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/MILLER-United-Optics-Imst',
    openingHoursToday: '09:00 - 17:00 Uhr',
    distance: null,
    objectId: '42285'
  },
  {
    zip: '6176',
    city: 'Völs',
    street: 'Cytastraße 1',
    country: 'Österreich',
    lat: '47.2561895',
    lng: '11.3258764',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Miller UNITED OPTICS',
    detailLink:
      'http://www.milleroptik.at/Miller/Unternehmen/Filialen/Miller-United-Optics-Cyta-Voels',
    openingHoursToday: '09:00 - 19:00 Uhr',
    distance: null,
    objectId: '48409'
  },
  {
    zip: '6800',
    city: 'Feldkirch',
    street: 'Neustadt 27',
    country: 'Österreich',
    lat: '47.2369801',
    lng: '9.5989069',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Müller UNITED OPTICS',
    detailLink:
      'http://www.mueller-optik.at/Mueller/Unternehmen/Standorte/Feldkirch',
    openingHoursToday: '08:30 - 18:30 Uhr',
    distance: null,
    objectId: '37673'
  },
  {
    zip: '6830',
    city: 'Rankweil',
    street: 'Sigmund-Nachbauer-Straße 2',
    country: 'Österreich',
    lat: '47.2692663',
    lng: '9.6426678',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Müller UNITED OPTICS',
    detailLink:
      'http://www.mueller-optik.at/Mueller/Unternehmen/Standorte/Rankweil',
    openingHoursToday: '08:00 - 12:00 und 13:30 - 18:00 Uhr',
    distance: null,
    objectId: '37674'
  },
  {
    zip: '7000 ',
    city: 'Eisenstadt',
    street: 'Beim Alten Stadttor 5',
    country: 'Österreich',
    lat: '47.8468375',
    lng: '16.5267396',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Nechansky UNITED OPTICS',
    detailLink:
      'http://www.nechansky.eu/Nechansky/Unternehmen/Standorte/7000-Eisenstadt-Beim-Alten-Stadttor-5',
    openingHoursToday: '08:30 - 13:00 und 14:00 - 18.00 Uhr',
    distance: null,
    objectId: '37740'
  },
  {
    zip: '2491',
    city: 'Neufeld a.d. Leitha',
    street: 'Sportplatzgasse 10',
    country: 'Österreich',
    lat: '47.8692418',
    lng: '16.3750099',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Nechansky UNITED OPTICS',
    detailLink:
      'http://www.nechansky.eu/Nechansky/Unternehmen/Standorte/2491-Neufeld-a.d.-Leitha-Sportplatzgasse-10',
    openingHoursToday: '08:15 - 12:00 und 14:30 - 18:00 Uhr',
    distance: null,
    objectId: '37741'
  },
  {
    zip: '7000',
    city: 'Eisenstadt',
    street: 'EZE, Ruster Straße 82-104',
    country: 'Österreich',
    lat: '47.834919',
    lng: '16.5275058',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Nechansky UNITED OPTICS',
    detailLink:
      'http://www.nechansky.eu/Nechansky/Unternehmen/Standorte/7000-Eisenstadt-EZE-Ruster-Strasse-82-104',
    openingHoursToday: '09:00 - 18:30 Uhr',
    distance: null,
    objectId: '37742'
  },
  {
    zip: '7210',
    city: 'Mattersburg',
    street: 'Bahnstraße 4b',
    country: 'Österreich',
    lat: '47.7372809',
    lng: '16.4002711',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Nechansky UNITED OPTICS',
    detailLink:
      'http://www.nechansky.eu/Nechansky/Unternehmen/Standorte/7210-Mattersburg-Bahnstrasse-4b',
    openingHoursToday: '08:15 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37743'
  },
  {
    zip: '9800',
    city: 'Spittal/Drau',
    street: 'Bahnhofstr. 4',
    country: 'Österreich',
    lat: '46.7988426',
    lng: '13.4925399',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Nitsch UNITED OPTICS',
    detailLink:
      'http://www.optiker-nitsch.at/Nitsch/Unternehmen/Filialen/Spittal-Drau-Bahnhofstrasse-4',
    openingHoursToday: '08:30 - 18:00 Uhr',
    distance: null,
    objectId: '36865'
  },
  {
    zip: '78176',
    city: 'Blumberg',
    street: 'Tevesstraße 37',
    country: 'Deutschland',
    lat: '47.83863',
    lng: '8.5363',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Olbrich UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Olbrich/Standorte/78176-Blumberg',
    openingHoursToday: '09:00 - 12:30 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '47285'
  },
  {
    zip: '78224',
    city: 'Singen',
    street: 'Scheffelstraße 14',
    country: 'Deutschland',
    lat: '47.75951',
    lng: '8.83737',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Olbrich UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Olbrich/Standorte/78224-Singen',
    openingHoursToday: '09:00 - 13:00 und 14:00 bis 18:00 Uhr',
    distance: null,
    objectId: '47286'
  },
  {
    zip: '78166',
    city: 'Donaueschingen',
    street: 'Karlstraße 19',
    country: 'Deutschland',
    lat: '47.9529607',
    lng: '8.4978452',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Olbrich UNITED OPTICS',
    detailLink:
      'http://www.unitedoptics.de/Olbrich/Standorte/78166-Donaueschingen',
    openingHoursToday: '09:00 - 12:30 und 14:00 bis 18:30 Uhr',
    distance: null,
    objectId: '47287'
  },
  {
    zip: '63505',
    city: 'Langenselbold',
    street: 'Friedrichstrasse 42a',
    country: 'Deutschland',
    lat: '50.1794323',
    lng: '9.0437355',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Optik Ott UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Optik-Ott/63505-Langenselbold',
    openingHoursToday: '9:00 - 13:00 und 14:30 - 18:30 Uhr',
    distance: null,
    objectId: '48822'
  },
  {
    zip: '64331',
    city: 'Weiterstadt',
    street: 'Rudolf-Diesel-Straße 26a',
    country: 'Deutschland',
    lat: '49.9028588',
    lng: '8.6081516',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Optik Seitenblick UNITED OPTICS',
    detailLink:
      'http://www.unitedoptics.de/Optik-Seitenblick/Standort/64331-Weiterstadt',
    openingHoursToday: '09:30 - 19:30 Uhr',
    distance: null,
    objectId: '47559'
  },
  /* {
    zip: '4020 ',
    city: ' Linz',
    street: 'Herrenstraße 48 (Zentrale)',
    country: 'Österreich',
    lat: null,
    lng: null,
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Pippig UNITED OPTICS',
    detailLink:
      'http://www.pippig.at/Pippig/Unternehmen/Filialen/Pippig-Herrenstrasse',
    openingHoursToday: '09:00 - 18:00 Uhr ',
    distance: null,
    objectId: '36897'
  }, */
  {
    zip: '4020',
    city: 'Linz',
    street: 'Gruberstraße 94',
    country: 'Österreich',
    lat: '48.3025425',
    lng: '14.3003265',
    openingHours: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'],
    openToday: true,
    name: 'Pippig UNITED OPTICS',
    detailLink:
      'http://www.pippig.at/Pippig/Unternehmen/Filialen/Pippig-Gruberstrasse',
    openingHoursToday: '08:30 - 18:00 Uhr ',
    distance: null,
    objectId: '36899'
  },
  {
    zip: '4020',
    city: 'Linz',
    street: 'Landstraße 33',
    country: 'Österreich',
    lat: '48.3017004',
    lng: '14.2903333',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Pippig UNITED OPTICS',
    detailLink:
      'http://www.pippig.at/Pippig/Unternehmen/Filialen/Pippig-Landstrasse',
    openingHoursToday: '09:00 - 18:00 Uhr ',
    distance: null,
    objectId: '36900'
  },
  {
    zip: '3470',
    city: 'Kirchberg',
    street: 'Marktplatz 22',
    country: 'Österreich',
    lat: '48.4352047',
    lng: '15.8971635',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Schmied UNITED OPTICS',
    detailLink:
      'http://www.optik-schmied.at/Schmied/Unternehmen/Filialen/Marktplatz-Kirchberg',
    openingHoursToday: '08:00-12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37773'
  },
  {
    zip: '3430',
    city: 'Tulln a.d. Donau',
    street: 'Bahnhofstraße 13',
    country: 'Österreich',
    lat: '48.3300078',
    lng: '16.0541544',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Schmied UNITED OPTICS',
    detailLink:
      'http://www.optik-schmied.at/Schmied/Unternehmen/Filialen/Bahnhofstrasse-Tulln-a.d.-Donau',
    openingHoursToday: '08:00 - 18:00 Uhr',
    distance: null,
    objectId: '37774'
  },
  {
    zip: '3133',
    city: 'Traismauer',
    street: 'Wiener Straße 9',
    country: 'Österreich',
    lat: '48.3501267',
    lng: '15.7444641',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Schmied UNITED OPTICS',
    detailLink:
      'http://www.optik-schmied.at/Schmied/Unternehmen/Filialen/Wiener-Strasse-Traismauer',
    openingHoursToday: '08:00 - 12:00 und 14:00 - 18:00 Uhr ',
    distance: null,
    objectId: '37775'
  },
  {
    zip: '08393',
    city: 'Meerane',
    street: 'Poststraße 4',
    country: 'Deutschland',
    lat: '50.8511662',
    lng: '12.4621784',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Schmiedel UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Schmiedel/Standort/08393-Meerane',
    openingHoursToday: '09:00 - 18:00 Uhr',
    distance: null,
    objectId: '47553'
  },
  {
    zip: '6866',
    city: 'Andelsbuch',
    street: 'Scheidbuchen 654',
    country: 'Österreich',
    lat: '47.4202357',
    lng: '9.8987377',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Simma UNITED OPTICS',
    detailLink:
      'http://www.simma-optik.at/Simma/Unternehmen/Unsere-Standorte/Andelsbuch-Scheidbuchen-654',
    openingHoursToday: '08:15 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '42428'
  },
  {
    zip: '1150',
    city: 'Wien',
    street: 'Stiegergasse 8',
    country: 'Österreich',
    lat: '48.1865259',
    lng: '16.3343169',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Spellitz UNITED OPTICS',
    detailLink:
      'http://www.spellitz.at/Spellitz/Unternehmen/Standort/Stiegergasse',
    openingHoursToday: '08:00 - 18:00 Uhr',
    distance: null,
    objectId: '37785'
  },
  {
    zip: '57334',
    city: 'Bad Laasphe',
    street: 'Königstr. 8',
    country: 'Deutschland',
    lat: '50.927361',
    lng: '8.4110123',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Staehler UNITED OPTICS',
    detailLink: 'http://www.unitedoptics.de/Staehler/57334-Bad-Laasphe',
    openingHoursToday: '9.30 - 12.30 und 14.30 - 18.00 Uhr',
    distance: null,
    objectId: '48879'
  },
  {
    zip: '4600',
    city: 'Wels',
    street: 'Schmidtgasse 13 - vis á vis Café Urbann',
    country: 'Österreich',
    lat: '48.158037',
    lng: '14.025826',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Stockinger UNITED OPTICS',
    detailLink:
      'http://www.stockinger.at/Stockinger/Unternehmen/Standorte/Schmidtgasse',
    openingHoursToday: '09:00 - 12:30 ',
    distance: null,
    objectId: '41746'
  },
  {
    zip: '4600',
    city: 'Wels',
    street: 'im max.center - Gunskirchenerstraße 7',
    country: 'Österreich',
    lat: '48.1460847',
    lng: '13.9720327',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Stockinger UNITED OPTICS',
    detailLink:
      'http://www.stockinger.at/Stockinger/Unternehmen/Standorte/Gunskirchenerstrasse-im-max.center',
    openingHoursToday: '10:00-18:00',
    distance: null,
    objectId: '48604'
  },
  {
    zip: '4840',
    city: 'Vöcklabruck',
    street: 'Stadtplatz 22',
    country: 'Österreich',
    lat: '48.0084064',
    lng: '13.6541851',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Teufelberger & Friedl UNITED OPTICS',
    detailLink:
      'http://www.teufelberger-friedl.at/Teufelberger-Friedl/Unternehmen/Filialen/Stadtsaal-Passage',
    openingHoursToday: '08:30 - 13:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '42472'
  },
  {
    zip: '4810',
    city: 'Gmunden',
    street: 'Druckereistraße 3-30',
    country: 'Österreich',
    lat: '47.9261242',
    lng: '13.7905645',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Teufelberger & Friedl UNITED OPTICS',
    detailLink:
      'http://www.teufelberger-friedl.at/Teufelberger-Friedl/Unternehmen/Filialen/SEP',
    openingHoursToday: '09:00 - 18:30 Uhr',
    distance: null,
    objectId: '42473'
  },
  {
    zip: '4840',
    city: 'Vöcklabruck',
    street: 'Robert-Kunz-Straße 11',
    country: 'Österreich',
    lat: '48.000901',
    lng: '13.6505436',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Teufelberger & Friedl UNITED OPTICS',
    detailLink:
      'http://www.teufelberger-friedl.at/Teufelberger-Friedl/Unternehmen/Filialen/Gesundheitszentrum',
    openingHoursToday: '08:30 - 18:00 Uhr',
    distance: null,
    objectId: '42474'
  },
  {
    zip: '2620',
    city: 'Neunkirchen',
    street: 'Triester Straße 9',
    country: 'Österreich',
    lat: '47.719869',
    lng: '16.0811066',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Vogelhuber UNITED OPTICS',
    detailLink:
      'http://www.vogelhuber.at/Vogelhuber/Unternehmen/Standorte/Triester-Strasse-9-Neunkirchen',
    openingHoursToday: '08:00 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37112'
  },
  {
    zip: '2640',
    city: 'Gloggnitz',
    street: 'Dr. Karl Renner Platz 3',
    country: 'Österreich',
    lat: '47.6757863',
    lng: '15.9359284',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Vogelhuber UNITED OPTICS',
    detailLink:
      'http://www.vogelhuber.at/Vogelhuber/Unternehmen/Standorte/Dr.-Karl-Renner-Platz-3-Gloggnitz',
    openingHoursToday: '08:00 - 12:00 und 14:00 - 18:00 Uhr',
    distance: null,
    objectId: '37113'
  },
  {
    zip: '2700',
    city: 'Wr. Neustadt',
    street: 'Hauptplatz 30',
    country: 'Österreich',
    lat: '47.8131713',
    lng: '16.2436027',
    openingHours: [
      'MONDAY',
      'TUESDAY',
      'WEDNESDAY',
      'THURSDAY',
      'FRIDAY',
      'SATURDAY'
    ],
    openToday: true,
    name: 'Vogelhuber UNITED OPTICS',
    detailLink:
      'http://www.vogelhuber.at/Vogelhuber/Unternehmen/Standorte/Hauptplatz-30-Wr.-Neustadt',
    openingHoursToday: '09:00 - 18:00',
    distance: null,
    objectId: '48513'
  }
]
