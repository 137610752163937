import markersFielmann from '../data/fielmannList'
import markersHartlauer from '../data/hartlauerList'
import markersMisc from '../data/miscOpticianList'
import markersOpticon from '../data/opticonList'
import markersPearl from '../data/pearlList'
import markersUnited from '../data/unitedList'
import markersWutscher from '../data/wutscherList'

const formatOpticon = list =>
  list.map(entry => {
    return {
      location: {
        latitude: entry.lat,
        longitude: entry.lng
      },
      id: 'opticon_' + entry.id,
      address: entry.address.replace('<br/>', ', '),
      name: entry.name || ' '
    }
  })

const formatMisc = list =>
  list.map(entry => {
    return {
      location: {
        latitude: entry.lat,
        longitude: entry.lng
      },
      id: 'misc_' + entry.id,
      address: entry.address,
      name: entry.name || ' '
    }
  })

const formatWutscher = list =>
  list.map(entry => {
    return {
      location: {
        latitude: entry.lat,
        longitude: entry.lng
      },
      id: 'wutscher_' + entry.id,
      address: entry.address,
      name: entry.name || ' '
    }
  })

const formatHartlauer = list =>
  list.map(entry => {
    return {
      location: {
        latitude: entry.lat,
        longitude: entry.lng
      },
      id: 'hartlauer_' + entry.id,
      address: entry.address,
      name: entry.name || ' '
    }
  })

const formatUnited = list =>
  list.map(entry => {
    return {
      location: {
        latitude: entry.lat,
        longitude: entry.lng
      },
      id: 'united_' + entry.objectId,
      address: `${entry.street}, ${entry.zip} ${entry.city}`,
      name: entry.name || ' '
    }
  })

const formatFielmann = list =>
  list.map(entry => {
    return {
      id: 'fielmann_' + entry.uid,
      address: `${entry.street}, ${entry.zip} ${entry.city}`,
      location: {
        latitude: entry.xCoordinates,
        longitude: entry.yCoordinates
      },
      name: `${entry.firmierung} ${entry.title}`
    }
  })

const formatPearl = list =>
  list.map(entry => {
    return {
      id: 'pearl_' + entry.storeID,
      address: `${entry.address}, ${entry.POST_CODE} ${entry.CITY}`,
      location: {
        latitude: entry.latitude,
        longitude: entry.longitude
      },
      name: entry.NAME
    }
  })

// {id, lat, lng, name, address}
const markers = [
  formatOpticon(markersOpticon),
  formatWutscher(markersWutscher),
  formatHartlauer(markersHartlauer),
  formatMisc(markersMisc),
  formatUnited(markersUnited),
  formatFielmann(markersFielmann),
  formatPearl(markersPearl)
].flat()

function getAllMarkers () {
  return markers
  // filter corrupted locations
  // return markers.filter(
  //   entry =>
  //     (
  //       !isNaN(entry?.location?.latitude) &&
  //       !isNaN(entry?.location?.longitude) &&
  //       !isNaN(entry.id)
  //     )
  // )
}

export { getAllMarkers }
