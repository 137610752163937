import React, { useState, useEffect } from 'react'
import { Text, Divider } from 'react-native-paper'
import { View, Platform } from 'react-native'
import { scale } from '../../scaling/ScaledUI'
import theme from '../../styles/theme'
import Slider from '@react-native-community/slider'

export default props => {
  const {
    input,
    initialValue,
    minimumValue,
    maximumValue,
    displayOrMore,
    displayOrMoreText,
    unit
  } = props
  const { onChange } = input
  const [step, setStep] = useState(initialValue || minimumValue)

  const isWeb = Platform.OS === 'web'

  const handleUpdateStep = value => {
    if (!isWeb) {
      setStep(value)
    }
  }

  useEffect(() => {
    onChange(step)
  }, [])

  function getUnit () {
    return step === 1 ? unit[0] : unit[1]
  }

  function disp () {
    if (displayOrMore) {
      return step >= maximumValue
        ? maximumValue.toString() + ' ' + getUnit() + ' oder mehr'
        : step.toString() + ' ' + getUnit()
    } else if (displayOrMoreText) {
      return step >= maximumValue
        ? maximumValue.toString() + ' ' + getUnit() + displayOrMoreText
        : step.toString() + ' ' + getUnit()
    } else return step.toString() + ' ' + getUnit()
  }

  const handleSlidingComplete = step => {
    setStep(step)
    onChange(step)
  }

  return (
    <View>
      <View style={{ flex: 1, flexDirection: 'column', marginTop: scale(24) }}>
        <Text
          style={{
            fontSize: scale(20),
            alignSelf: 'center',
            color: theme.colors.primary
          }}
        >
          {disp()}
        </Text>
      </View>

      <Divider style={{ marginVertical: scale(20) }} />
      <Slider
        style={{ flex: 1, height: scale(70) }}
        value={step}
        onValueChange={handleUpdateStep}
        onSlidingComplete={handleSlidingComplete}
        minimumTrackTintColor={theme.colors.primary}
        // thumbTintColor={theme.colors.background}
        minimumValue={minimumValue || 0}
        maximumValue={maximumValue}
        // thumbImage={require('../../../assets/slider_hollow_100x100.png')}
        thumbTintColor={theme.colors.primary}
        step={1}
      />
    </View>
  )
}
