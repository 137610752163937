import React from 'react'
import { Platform, Image, View, ScrollView, StatusBar } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import theme from '../../styles/theme'
import Logo from '../../../assets/logo_text.png'
import { useUser } from '../../hooks/user'
import { useFocusEffect } from '@react-navigation/native'
import { Subheading } from 'react-native-paper'

export default ({ navigation, children }) => {
  const { userAttributes } = useUser()

  if (Platform.OS !== 'web') {
    useFocusEffect(
      React.useCallback(() => {
        Platform.OS === 'android' && StatusBar.setBackgroundColor('#ffffff00')
        StatusBar.setBarStyle('dark-content')
      })
    )

    return (
      // mobile top navbar
      <View>
        <View
          style={{
            width: '100%',
            height: 90,
            paddingTop: 40,
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 0
            },
            shadowOpacity: 0.3,
            shadowRadius: 4.65,
            elevation: 10,
            backgroundColor: theme.colors.background
          }}
        >
          <Icon
            style={{ alignSelf: 'center' }}
            name='menu'
            size={30}
            color={theme.colors.gray}
            onPress={() => navigation.toggleDrawer()}
          />
          <Subheading style={{ alignSelf: 'center', fontSize: 18 }}>
            {userAttributes?.name}
          </Subheading>
          <Image source={Logo} style={{ height: 40, width: 120, margin: 5 }} />
        </View>
        <ScrollView style={{ marginTop: 20 }}>{children}</ScrollView>
      </View>
    )
  }

  return (
    <View
      style={{
        marginTop: 15,
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap'
      }}
    >
      {children}
    </View>
  ) // web only
}
