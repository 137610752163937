/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:bd6da233-5902-4bb2-b608-f693da40a4d1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_mZXsKrgVA",
    "aws_user_pools_web_client_id": "1frdjmn6n14igl9ki6mvfbgnu2",
    "oauth": {
        "domain": "vispecs0ecc3d03-0ecc3d03-production.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "vispecs://,exp://192.168.0.210:19000/--/,http://localhost:19006/--/,https://ln-2-master.d25810vot2j20.amplifyapp.com/,https://staging.vispecs.com/,https://vispecs.com/",
        "redirectSignOut": "vispecs://,exp://192.168.0.210:19000/--/,http://localhost:19006/--/,https://ln-2-master.d25810vot2j20.amplifyapp.com/,https://staging.vispecs.com/,https://vispecs.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://sgvcpscmkjcanc6l2ahlvw6pnu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "OpticianQueries",
            "endpoint": "https://ryscigguwc.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "vispecs-storage164809-production",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_mobile_analytics_app_id": "992ce8898dd14f95b917b6261e83bab1",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
