import { createStackNavigator } from '@react-navigation/stack'
import React from 'react'
// import DigitalOpticianHome from '../screens/Optician/DigitalOpticianHome'
// import OpticianCoupon from '../screens/Optician/OpticianCoupon'
// import OpticianRepair from '../screens/Optician/OpticianRepair'
// import OpticianOrder from '../screens/Optician/OpticianOrder'
// import OpticianInitial from '../screens/Optician/OpticianInitial'
import UnderConstruction from '../../components/general/UnderConstruction'
import DigitalOpticianHome from '../../screens/DigitalOptician/DigitalOpticianHome'

const Stack = createStackNavigator()

export default () => {
  return (
    <Stack.Navigator initialRouteName='DigitalOpticianHome' headerMode='none'>
      <Stack.Screen
        name='DigitalOpticianHome'
        component={DigitalOpticianHome}
      />
      {/* {userAttributes .['custom:localOpticianId'] ? (
        <>
          <Stack.Screen name='OpticianCoupon' component={OpticianCoupon} />
          <Stack.Screen name='OpticianRepair' component={OpticianRepair} />
          <Stack.Screen name='OpticianOrder' component={OpticianOrder} />
        </>
      ) : (
        <>
          <Stack.Screen name='OpticianInitial' component={OpticianInitial} />
        </>
      )} */}
      <Stack.Screen name='UnderConstruction' component={UnderConstruction} />
    </Stack.Navigator>
  )
}
