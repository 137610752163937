import { createStackNavigator } from '@react-navigation/stack'
import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { FormWizardContext } from './FormWizardContext'

const Stack = createStackNavigator()

export default ({ onSubmit, children, initialValues }) => {
  const screens = useMemo(() => React.Children.toArray(children), [])

  return (
    <FormWizardContext.Provider value={{ screens }}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        {() => (
          <Stack.Navigator
            screenOptions={{
              headerShown: true,
              headerTransparent: true
            }}
          >
            <Stack.Screen
              name='wizard'
              options={({ route }) => ({
                title: screens[route.params.index].props.title
              })}
              initialParams={{ index: 0 }}
              children={props =>
                React.cloneElement(screens[props.route.params.index], props)
              }
            />
          </Stack.Navigator>
        )}
      </Form>
    </FormWizardContext.Provider>
  )
}
