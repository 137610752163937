import React, { useEffect, useState } from 'react'
import { View, Platform } from 'react-native'
import { Portal, Paragraph } from 'react-native-paper'
import { moderateScale } from 'react-native-size-matters'
import { useUser } from '../hooks/user'
import { PassportProvider } from '../hooks/user/passport'
import PassportCard from './Cards/PassportCard'
import ShareCard from './Cards/ShareCard'
import MapCard from './Cards/MapCard'
import AssessmentDashboardCard from './Cards/AssessmentDashboardCard'
import LinkButton from '../components/LinkButton'
import EvaluationCard from './Cards/EvaluationCard'
import HomeLayout from '../components/templates/HomeLayout'
import LoginPopUpNative from '../components/Auth/LoginPopUp.native'
import { useLinkTo } from '@react-navigation/native'

export default () => {
  const { user } = useUser()
  const linkTo = useLinkTo()

  const [popUpShown, setPopUpShown] = useState(!!user)

  const [loginPopUpWait, setLoginPopUpWait] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setLoginPopUpWait(true), 1500) // show popup after 1.5s if no user is present
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <HomeLayout>
      <Portal>
        <LoginPopUpNative
          visible={loginPopUpWait && !popUpShown}
          onCancel={() => setPopUpShown(true)}
          onDismiss={() => setPopUpShown(true)}
          onConfirm={() => {
            setPopUpShown(true)
            linkTo('/login')
          }}
        >
          <Paragraph>
            Um Ihre Daten sicher bei uns speichern zu können, empfehlen wir
            Ihnen sich anzumelden.
          </Paragraph>
        </LoginPopUpNative>
      </Portal>
      <PassportProvider>
        <PassportCard />
      </PassportProvider>
      <View style={{ height: 0, marginVertical: moderateScale(1, 8.0) }} />
      <View
        style={{
          flex: Platform.OS === 'web' ? 1 : 0,
          width: Platform.OS === 'web' ? undefined : '100%',
          paddingHorizontal: Platform.OS === 'web' ? 0 : 20,
          flexDirection: Platform.OS === 'web' ? 'row' : 'column',
          // alignItems: 'center',
          flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap'
        }}
      >
        <EvaluationCard />
        {Platform.OS === 'web' && (
          <>
            <AssessmentDashboardCard />
            <MapCard />
          </>
        )}
        <ShareCard />
      </View>
      {/* <View style={{ flex: 1, flexDirection: Platform.OS === 'web' ? 'row' : 'column', alignItems: 'center' }}>
            <MapCard />
            <AssessmentDashboardCard />
          </View> */}
      <LinkButton icon='information-outline' to='/legal'>
        Impressum &amp; Datenschutzerklärung
      </LinkButton>
    </HomeLayout>
  )
}
