import React from 'react'
// import CouponDigitalOpticianCard from '../../components/DigitalOptician/CouponDigitalOpticianCard'
// import OrderDigitalOpticianCard from '../../components/DigitalOptician/OrderDigitalOpticianCard'
// import RepairDigitalOpticianCard from '../../components/DigitalOptician/RepairDigitalOpticianCard'
import CreatePassportCard from '../Cards/CreatePassportCard'
import AssessmentDashboardCard from '../Cards/AssessmentDashboardCard'
import EvaluationCard from '../Cards/EvaluationCard'
import MapCard from '../Cards/MapCard'
import HomeLayout from '../../components/templates/HomeLayout'
import { View } from 'react-native'

export default () => {
  return (
    <HomeLayout>
      <View
        style={{
          width: '100%',
          height: 1,
          marginVertical: 10
        }}
      />
      <View
        style={{
          width: '100%',
          paddingHorizontal: 20
        }}
      >
        <CreatePassportCard />
        <AssessmentDashboardCard />
        <EvaluationCard />
        <MapCard />
        {/* <DashboardBlock>
        <LocalOptician />
      </DashboardBlock> */}
        {/* <RepairDigitalOpticianCard />
      <OrderDigitalOpticianCard />
    <CouponDigitalOpticianCard /> */}
      </View>
    </HomeLayout>
  )
}
