import { Auth } from '@aws-amplify/auth'
import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import PlainSurface from '../../components/Surfaces/PlainSurface'
import FormScreen from '../../form-wizard/FormScreen'
import FormWizard from '../../form-wizard/FormWizard'
import {
  RadioInput,
  TextInput,
  YearOfBirthInput
} from '../../form-wizard/Inputs'
import Question from '../../form-wizard/Question'
import {
  Headline,
  ImageChri,
  Paragraph,
  Subheading
} from '../../scaling/ScaledUI'
import { useUser } from '../../hooks/user'
import { Credentials } from '@aws-amplify/core'

export default () => {
  const { user, userAttributes, reload } = useUser()

  const [identityId, setIdentityId] = useState(userAttributes?.identityId)

  useEffect(() => {
    if (!identityId)
      fetchId().then(() => {
        if (identityId) {
          console.log('id: ' + JSON.stringify(identityId, null, 4))
          return null
        }
        const timer = setTimeout(() => {
          // try again a second time
          fetchId()
        }, 200)
        return () => clearInterval(timer)
      })
  }, [])

  const fetchId = () => {
    return Credentials.get().then(credentials => {
      console.log(
        'identityId: ' + JSON.stringify(credentials.identityId, null, 4)
      )
      setIdentityId(credentials.identityId)
    })
  }

  const handleSubmit = data => {
    Auth.updateUserAttributes(user, {
      name: data.name,
      birthdate: data.birthdate + '-01-01',
      gender: data.gender,
      'custom:identityId': identityId
    })
      .then(() => reload())
      .catch(console.error)
  }

  return (
    <FormWizard
      onSubmit={handleSubmit}
      initialValues={{
        name: userAttributes?.name,
        birthdate: userAttributes?.birthdate?.substring(0, 4),
        gender: userAttributes?.gender
      }}
    >
      <FormScreen title='Willkommen'>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            marginTop: 24
          }}
        >
          <ImageChri />
          <PlainSurface style={{ width: '100%' }}>
            <Headline>Willkommen bei ViSpecs,</Headline>
            <Subheading>Ihr digitaler Optiker!</Subheading>
            <Paragraph>
              Die Kombination von Online-Betreuung und stationären Fachoptikern
              ermöglicht Ihnen ein optimales Seherlebnis.
            </Paragraph>
            <Paragraph>
              Bevor wir starten können, benötigen wir jedoch ein paar
              grundlegende Informationen über Sie.
            </Paragraph>
          </PlainSurface>
        </View>
      </FormScreen>

      <FormScreen title='Name' condition={() => !userAttributes?.name}>
        <Question
          id='name'
          head='Wie dürfen wir Sie ansprechen?'
          fieldProps={{
            validate: value =>
              value && value.trim() !== '' ? undefined : 'required'
          }}
        >
          <TextInput placeholder='Ihr Name' />
        </Question>
      </FormScreen>

      <FormScreen title='Geschlecht' condition={() => !userAttributes?.gender}>
        <Question
          id='gender'
          head='Wählen Sie Ihr Geschlecht'
          fieldProps={{
            validate: value => (value ? undefined : 'required')
          }}
        >
          <RadioInput
            options={[
              { id: 'male', text: 'männlich' },
              { id: 'female', text: 'weiblich' },
              { id: 'other', text: 'anderes / keine Angabe' }
            ]}
          />
        </Question>
      </FormScreen>

      <FormScreen title='Alter' condition={() => !userAttributes?.birthdate}>
        <Question
          id='birthdate'
          head='In welchem Jahr sind Sie geboren?'
          fieldProps={{
            defaultValue: 1990,
            validate: value =>
              !isNaN(value) &&
              new Date().getUTCFullYear() - value >= 14 &&
              value > 1900
                ? undefined
                : 'error'
          }}
        >
          <YearOfBirthInput />
        </Question>
      </FormScreen>
    </FormWizard>
  )
}
